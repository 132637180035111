import React, { useRef, useCallback, memo } from 'react';
import moment from 'moment';
import Select from 'react-select';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
import { Alert, Space, Spin } from 'antd';
const Text = styled.p`
  margin: 0;
  font-family: Montserrat, sans-serif;
  padding-right: 15px;
  color: #3a3a4a;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  min-width: 40%;
  max-width: 40%;
`;
const override = {
  borderColor: '#714FFF',
};
const SubText = styled.p`
  margin: 0;
  font-family: Montserrat, sans-serif;
  color: #3a3a4a;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  word-break: break-all;
  white-space: normal;
  margin-left: 12px;
`;

export default function UserClaimDetail({
  prettyPrintPayoutStatus,
  reimbursementDetail,
  availableProcedures,
  procedureSelectionRef,
  handleSelectReimbursementType,
  procedureDetail,
  policyDetail,
  bankDetail,
  walletBalanceAmount,
  ciWalletAmount,
  amountClaimedForProcedure,
  isWalletLoading,
}) {
  return (
    <div className="bg-white rounded  p-3 h-100">
      <div className="flex ">
        <div className="basis-3/4">
          <p
            className="text-[#714FFF] align-top flex"
            style={{
              fontFamily: 'Montserrat',
              fontStyle: 'Bold',
              fontSize: '18px',
              lineHeight: '22px',
            }}
          >
            Claim & Procedure Details
          </p>
        </div>
        <div className="basis-1/2">
          <h1 className="flex justify-end ...">
            {prettyPrintPayoutStatus(reimbursementDetail.claimStatus)}
          </h1>
        </div>
      </div>
      <br />

      <div className="flex ">
        <Text>Reimbursement Type</Text>
        <span style={{ marginRight: '18px' }}>=</span>
        <Select
          isSearchable={false}
          defaultValue={{
            value: reimbursementDetail.procedureId,
            label: reimbursementDetail.reimbursementType,
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: '5px',
            boxShadow: 'none',
            colors: {
              ...theme.colors,
              primary25: 'grey',
              primary: '#B8A7FF',
            },
          })}
          options={availableProcedures}
          autosize={true}
          ref={procedureSelectionRef}
          onChange={handleSelectReimbursementType}
        />
        <br></br>
      </div>
      <hr></hr>
      <div className="flex mt-2">
        <Text>Claim Amount</Text>=
        <p className="font-family: Montserrat  ml-2">
          <SubText>{reimbursementDetail.reimbursementAmount}</SubText>
        </p>
      </div>
      <hr></hr>
      <div className="flex mt-2 ">
        <Text>Already Claimed Amount</Text>=
        <p className="font-family: Montserrat  ml-2">
          <SubText>
            {reimbursementDetail.approvedAmount
              ? reimbursementDetail.approvedAmount
              : 'N.A'}
          </SubText>
        </p>
      </div>
      <hr></hr>
      <div className="flex mt-2 ">
        <Text>User Raised Amount</Text>=
        <p className="font-family: Montserrat  ml-2">
          <SubText>
            {reimbursementDetail.userRaisedAmount
              ? reimbursementDetail.userRaisedAmount
              : 'N.A'}
          </SubText>
        </p>
      </div>
      <hr></hr>

      <div className="flex mt-2">
        <Text>Procedure Limits</Text>=
        <p className="font-family: Montserrat  ml-2">
          {procedureDetail ? (
            <SubText>
              {`${procedureDetail.maxCap} (${procedureDetail.capFrequency})`}
            </SubText>
          ) : (
            'N.A'
          )}
        </p>
      </div>
      <hr></hr>
      <div className="flex mt-2">
        <Text>Policy Name</Text>=
        <p className="font-family: Montserrat  ml-2">
          <SubText>{policyDetail ? policyDetail.policyName : 'N.A'}</SubText>
        </p>
      </div>
      <hr></hr>
      <div className="flex mt-2">
        <Text>Invoice Date</Text>=
        <p className="font-family: Montserrat  ml-2">
          <SubText>
            {reimbursementDetail.invoiceDate
              ? reimbursementDetail.invoiceDate
              : 'N.A'}
          </SubText>
        </p>
      </div>
      <hr></hr>
      <div className="flex mt-2">
        <Text>UTR number</Text>=
        <p className="font-family: Montserrat  ml-2">
          <SubText>{bankDetail.claimUtr ? bankDetail.claimUtr : 'N.A'}</SubText>
        </p>
      </div>
      <hr></hr>
      <div className="flex mt-2">
        <Text>Cashfree Transaction ID</Text>=
        <p className="font-family: Montserrat  ml-2">
          <SubText>
            {bankDetail.claimTransactionId
              ? bankDetail.claimTransactionId
              : 'N.A'}
          </SubText>
        </p>
      </div>
      <hr></hr>
      <div className="flex mt-2">
        <Text>Amount already claimed for this category</Text>=
        <p className="font-family: Montserrat  ml-2">
          <SubText>{amountClaimedForProcedure}</SubText>
        </p>
      </div>
      <hr></hr>
      <div className="flex mt-2">
        <Text>Wallet Balance</Text>=
        <p className="font-family: Montserrat  ml-2">
          {isWalletLoading ? (
            <ClipLoader
              color={'#714FFF'}
              loading={isWalletLoading}
              //cssOverride={override}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <SubText>
              {walletBalanceAmount === null
                ? 'N.A'
                : `₹ ${walletBalanceAmount > 0 ? walletBalanceAmount : '0.00'}`}
            </SubText>
          )}
        </p>
      </div>
      <hr></hr>
      {ciWalletAmount ? (
        <div className="flex mt-2">
          <Text>CiWallet Balance</Text>=
          <p className="font-family: Montserrat  ml-2">
            <SubText>{ciWalletAmount}</SubText>
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
