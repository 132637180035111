import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from '../../queries/reimbursementQuery';
import ReimbursementClaims from './ReimbursementClaims';
import { getReimbursementClaimsReqColumn } from './ReimbursementTables';
import ReactTable from 'react-table';

export default function ReimbursementClaimsRequestsTable(props) {
  const { requestId } = props;
  const {
    isLoading,
    error,
    data: historyData,
    isFetching,
    refetch: refreshHistory,
  } = useHistory(requestId);

  let lastWorkedDate =
    historyData && historyData.length
      ? historyData.history[0].eventDateTime
      : null;

  return (
    <ReactTable
      data={props.details}
      columns={getReimbursementClaimsReqColumn(lastWorkedDate)}
      style={{
        marginLeft: 10,
        fontFamliy: 'Montserrat',
        fontStyle: 'normal',
        lineHeight: '18px',
      }}
      defaultPageSize={1}
      showPaginationBottom={false}
    />
  );
}
