import React from 'react';
import styled from 'styled-components';
import { RightIcon } from '../../components/common/svgFiles';

const Text = styled.p`
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding-right: 15px;
  color: #3a3a4a;
  min-width: 40%;
  max-width: 40%;
`;
const TextForHealth = styled.p`
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding-right: 15px;
  color: #3a3a4a;
  min-width: 60%;
  max-width: 60%;
`;
const SubText = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  font-family: Montserrat, sans-serif;
  color: #3a3a4a;
  min-width: 60%;
  max-width: 60%;
  word-break: break-all;
  white-space: normal;
  margin-left: 12px;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #ededed;
`;
const Span = styled.span`
  margin-left: 1px;
`;

export default function ReimbursementUser({
  reimbursementDetail = {},
  policyDetail = {},
  bankDetail = {},
  openPastClaimModal,
  pastClaimsArr,
  openCopyClaimModal,
  copyClaimList,
  prettyPrintPayoutStatus,
  ahcDetails,
}) {
  return (
    <div className="bg-white rounded  p-3 h-100">
      <div className="flex ">
        <div className="basis-3/4">
          <p
            className="text-[#714FFF] align-top flex"
            style={{
              fontFamily: 'Montserrat',
              fontStyle: 'Bold',
              fontSize: '18px',
              lineHeight: '22px',
            }}
          >
            Patient Details
          </p>
        </div>
        {reimbursementDetail.isSuspicious ? (
          <div className="basis-1/2">
            <h1 className="flex justify-end ...">
              {prettyPrintPayoutStatus('suspicious')}
            </h1>
          </div>
        ) : (
          ''
        )}
      </div>
      <br />
      <Container>
        <Text>Patient Name</Text>
        <Span>=</Span>
        <SubText>
          {reimbursementDetail.relative || reimbursementDetail.userName}
        </SubText>
      </Container>

      <Container>
        <Text>Policy Start Date</Text>
        <Span>=</Span>
        <SubText>
          {policyDetail.policyStartDate ? policyDetail.policyStartDate : 'N.A'}
        </SubText>
      </Container>

      <Container>
        <Text>Policy End Date</Text>
        <Span>=</Span>
        <SubText>
          {policyDetail.policyEndDate ? policyDetail.policyEndDate : 'N.A'}
        </SubText>
      </Container>
      <Container>
        <Text>Date of Birth</Text>
        <Span>=</Span>
        <SubText>{policyDetail.dob ? policyDetail.dob : 'N.A'}</SubText>
      </Container>

      <Container>
        <Text>Phone Number</Text>
        <Span>=</Span>
        <SubText>
          {reimbursementDetail.phone ? reimbursementDetail.phone : 'N.A'}
        </SubText>
      </Container>
      <Container>
        <Text>Email Address</Text>
        <Span>=</Span>
        <SubText>
          {reimbursementDetail.email ? reimbursementDetail.email : 'N.A'}
        </SubText>
      </Container>
      <Container>
        <Text>Request Date</Text>
        <Span>=</Span>
        <SubText>
          {reimbursementDetail.requestDate
            ? reimbursementDetail.requestDate
            : 'N.A'}
        </SubText>
      </Container>
      <Container>
        <Text>Bank Account</Text>
        <Span>=</Span>
        <SubText>
          {bankDetail.bankAccount ? bankDetail.bankAccount : 'N.A'}
        </SubText>
      </Container>
      <Container>
        <TextForHealth>Health checkups booked by Dependents</TextForHealth>
        <Span>=</Span>
        <SubText>
          {ahcDetails.filter((item) => item.relativeId != null).length || 0}
        </SubText>
      </Container>
      <Container>
        <TextForHealth> Health checkups booked by employee</TextForHealth>
        <Span>=</Span>
        <SubText>
          {ahcDetails.filter((item) => item.relativeId == null).length || 0}
        </SubText>
      </Container>
      <Container>
        <Text>IFSC</Text>
        <Span>=</Span>
        <SubText>{bankDetail.bankAccount ? bankDetail.ifsc : 'N.A'}</SubText>
      </Container>
      <div className="flex justify-evenly">
        <div className="mb-3 mt-1">
          {pastClaimsArr && pastClaimsArr.count > 0 ? (
            <button
              className="btn
          btn text-white font-semibold bg-[#714FFF]
                                                py-2 px-2 border-2 border-[#714FFF] hover:bg-[#714FFF]
                                                 rounded flex items-center
          "
              onClick={openPastClaimModal}
            >
              Past Claims ({pastClaimsArr && pastClaimsArr.count})
              <RightIcon />
            </button>
          ) : (
            ''
          )}
        </div>
        <div className="  mr-0 mb-3 mt-1">
          {copyClaimList?.length > 0 && copyClaimList[0].RID ? (
            <button
              className="btn
          btn text-white font-semibold bg-[#714FFF]
                                                py-2 px-2 border-2 border-[#714FFF] hover:bg-[#714FFF]
                                                 rounded flex items-center
          "
              onClick={openCopyClaimModal}
            >
              Copy Claims ({copyClaimList && copyClaimList.length})
              <RightIcon />
            </button>
          ) : (
            <button
              className="btn
          btn text-white font-semibold bg-[#714FFF]
                                                py-2 px-2 border-2 border-[#714FFF] hover:bg-[#714FFF]
                                                 rounded flex items-center"
              disabled={true}
            >
              Copy Claims
              <RightIcon />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
