import axios from 'axios';
import config from '../config';
import { store } from '../store/';

export async function opsUserLogin(body) {
  return await axios
    .post(`${config.baseUrl}/new-ops/login`, body)
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      //return err
      throw err;
    });
}
