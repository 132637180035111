import React from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';

const SubHeading = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  color: #3a3a4a;
  font-weight: 500;
`;
export default function AddRemark({
  isOpen,
  remarkData,
  updateRemarkInput,
  remarkSolution,
  onClose,
}) {
  const header = () => (
    <h2
      style={{
        color: '#714FFF',
        fontFamily: 'Montserrat',
        fontSize: '18px',
      }}
    >
      Remark {remarkData}
    </h2>
  );
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>{header()}</ModalHeader>
      <ModalBody>
        <SubHeading>Enter Solution</SubHeading>
        <textarea
          style={{
            backgroundColor: '#F3F0FF',
            border: '1px solid #714FFF',
            borderRadius: '6px',
          }}
          onChange={(e) => updateRemarkInput(e)}
          placeholder="Enter Solution"
          className="form-control mt-2"
        ></textarea>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn"
          style={{ backgroundColor: '#714fff', color: '#fff' }}
          onClick={remarkSolution}
        >
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
}
