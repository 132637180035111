import React from 'react';
import { SearchIcon } from './svgFiles';
import { Input } from 'antd';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';

export default ({ filter, onChange }) => {
  return (
    <Input
      style={{
        border: '1px solid rgba(113, 79, 255, 0.3)',
        borderRadius: '10px',
      }}
      placeholder="Search..."
      prefix={<SearchIcon className="site-form-item-icon" />}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
