import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import { getReimbursementInfo } from '../services/reimbursementClaims';
import {
  getReimbursementDoctorList,
  transferReimbursementCase,
  getReimbursementSponsorDoctorList,
  getSponsorList,
  addDoctorSponsors,
  removeDoctorSponsors,
  updateDoctorSponsors,
  addClarificationSolution,
  getClaimsRemarkList,
  getSuspiciousClaimUsers,
} from '../services/reimbursementAdmin';

const useFetchAllReimbursement = (
  page,
  pageSize,
  sponsor,
  searchString,
  reason,
  status,
  claimedBy,
  startDate,
  endDate,
  sortBy,
  sortOrder,
  remark,
  tatDay
) => {
  if (tatDay) {
    startDate = moment()
      .subtract(tatDay < 16 ? tatDay : 365, 'days')
      .startOf('day')
      .unix();
    endDate = moment().subtract(tatDay, 'days').endOf('day').unix();
  }
  return useQuery(
    [
      {
        entity: 'getReimbursementInfo',
        page,
        pageSize,
        sponsor,
        searchString,
        reason,
        status,
        claimedBy,
        startDate,
        endDate,
        sortBy,
        sortOrder,
        remark,
      },
    ],
    ({ queryKey }) => {
      const {
        page,
        pageSize,
        sponsor,
        searchString,
        reason,
        status,
        claimedBy,
        startDate,
        endDate,
        sortBy,
        sortOrder,
        remark,
      } = queryKey[0];

      return getReimbursementInfo(
        page,
        pageSize,
        sponsor,
        searchString,
        reason,
        status,
        claimedBy,
        startDate,
        endDate,
        sortBy,
        sortOrder,
        remark
      );
    }
  );
};
const useReimbursementDocList = () => {
  return useQuery(
    'doctorList',
    () => getReimbursementDoctorList(),

    {
      select: (res) => {
        const doctorList = res.map((d) => ({
          value: d.opsId,
          label: `${d.name} ( ${d.opsId} )`,
        }));
        return doctorList;
      },
    }
  );
};
const useTransferReimbursementCase = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return transferReimbursementCase(body);
    },
    { onSuccess, onError }
  );
};
const useDoctorSponsorList = () => {
  return useQuery(
    'getDoctorSponsorList',

    () => getReimbursementSponsorDoctorList(),

    {
      select: (res) => res,
    }
  );
};
const useAddClarificationSolution = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return addClarificationSolution(body);
    },
    { onSuccess, onError }
  );
};
const useRemarkList = () => {
  return useQuery('getRemarksList', () => getClaimsRemarkList(), {
    select: (res) => {
      const reasonList = res.map((l) => ({
        value: l.remark,
        label: l.remark,
      }));
      return reasonList;
    },
  });
};

const useSponsorList = () => {
  return useQuery(
    'getSponsorList',

    () => getSponsorList(),

    {
      select: (res) => {
        const sponsor = res.map((elem) => ({
          value: elem.sponsorId,
          label: `${elem.name} ( ${elem.sponsorId} )`,
        }));
        return sponsor;
      },
    }
  );
};
const useAddDoctorSponsor = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return addDoctorSponsors(body);
    },
    { onSuccess, onError }
  );
};
const useRemoveDoctorSponsor = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return removeDoctorSponsors(body);
    },
    { onSuccess, onError }
  );
};
const useUpdateDoctorSponsor = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return updateDoctorSponsors(body);
    },
    { onSuccess, onError }
  );
};
const useFetchSuspiciousClaimData = () => {
  return useQuery(
    'getSuspiciousClaimData',
    () => getSuspiciousClaimUsers(),

    {
      select: (res) => res,
    }
  );
};
export {
  useFetchAllReimbursement,
  useReimbursementDocList,
  useTransferReimbursementCase,
  useDoctorSponsorList,
  useSponsorList,
  useAddDoctorSponsor,
  useRemoveDoctorSponsor,
  useUpdateDoctorSponsor,
  useAddClarificationSolution,
  useRemarkList,
  useFetchSuspiciousClaimData,
};
