/* Redux actions are defined here
function action(payload) {
  return {
    type: 'ACTION_NAME',
    payload,
  };
}
*/

export function setAuthToken(authToken) {
  return {
    type: 'SET_AUTH_TOKEN',
    payload: authToken,
  };
}
export function setVerticals(verticals) {
  return {
    type: 'SET_VERTICALS',
    payload: verticals,
  };
}
export function setOpsUser(opsUser) {
  return {
    type: 'SET_OPS_USER',
    payload: opsUser,
  };
}

export function setReimbursement(reimbursementTableData) {
  return {
    type: 'SET_REIMBURSEMENT',
    payload: reimbursementTableData,
  };
}
export function toggleSidebarAction(value) {
  return {
    type: 'TOGGLE_SIDEBAR',
    value,
  };
}
export function setSearchStringAction(searchString) {
  return {
    type: 'SET_SEARCH_STRING',
    payload: searchString,
  };
}
export function setOnBreak(value) {
  return {
    type: 'SET_IS_ON_BREAK',
    payload: value,
  };
}
export function setNotificationModalClose(value) {
  return {
    type: 'SET_BELL_CLOSE',
    payload: value,
  };
}
export function setOnBreakModalClose(value) {
  return {
    type: 'SET_ON_BREAK_CLOSE',
    payload: value,
  };
}
export function setSponsorDataWitCount(value) {
  return {
    type: 'SET_SPONSOR_DATA_WITH_COUNT',
    payload: value,
  };
}
