import React, { useCallback, useState } from 'react';
import { useMapsLibrary, useApiIsLoaded, Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import { NetworkCenterOption, SimpleColumn, SimpleRow, Spinner } from '../styledCommonComponents';
import SingleSelectionSearchDropdown from './SingleSelectionSearchDropdown';
import CommonPlainInput from './CommonPlainInput';
import SingleCommonSelectionDropdown from './SingleCommonSelectionDropdown';
import { connect } from 'react-redux';
import CommonButton from './CommonButton';

const GOOGLE_MAPS_ID = 'google_maps_id';
const position = { lat: 28.5681, lng: 77.3162 };

const MapWidget = ({ mapStyles = {}, verticals, onChange = () => { }, onClose = () => { } }) => {

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [doctorSelection, setDoctorSelection] = useState(null);
    const [verticalSelection, setVerticalSelection] = useState(null);
    const isApiLoaded = useApiIsLoaded();
    const library = useMapsLibrary('places');

    const fetchGoogleMapPlaces = useCallback((query) => {

        const request = {
            input: query,
            locationRestriction: { west: 68.1, north: 37.6, east: 97.4, south: 8.1 },
            language: 'en-US',
            region: 'us',
        };

        const token = new library.AutocompleteSessionToken();
        request.sessionToken = token;

        return library.AutocompleteSuggestion.fetchAutocompleteSuggestions(request).then(({ suggestions }) => {
            return suggestions.map(suggestion => {

                const [first, second, ...rest] = suggestion.placePrediction.text.toString().split(', ');
                return {
                    label: suggestion.placePrediction.text.toString(),
                    title: `${first}, ${second}`,
                    address: rest.join(', '),
                    value: suggestion.placePrediction.placeId,
                    data: suggestion.placePrediction,
                };
            });
        });
    }, [library]);

    const handleChoosing = useCallback(() => {
        onChange(
            { label: `${doctorSelection.value} [${verticalSelection.label}], ${selectedLocation.displayName}`, value: null },
            { label: doctorSelection.value, value: null, isUpdated: true, verticalId: verticalSelection.value, verticalName: verticalSelection.label },
            { label: selectedLocation.displayName, value: null, isUpdated: true, address: selectedLocation.formattedAddress, centerAddress: selectedLocation.formattedAddress },
            selectedLocation
        );

        onClose();
        setSelectedLocation(null);
        setVerticalSelection(null);
        setDoctorSelection(null);
    }, [onChange, selectedLocation, verticalSelection, doctorSelection, onClose]);

    const handleChooseLocation = useCallback((location) => {

        const extractLocationData = (data) => {
            let city = '';
            let state = '';
            let pincode = '';

            data.forEach(item => {
                if (item.types.includes('locality')) {
                    city = item.longText;
                }
                if (item.types.includes('administrative_area_level_1')) {
                    state = item.longText;
                }
                if (item.types.includes('postal_code')) {
                    pincode = item.longText;
                }
            });

            return { city, state, pincode: pincode ? Number(pincode) : pincode };
        }

        location.data.toPlace().fetchFields({
            fields: [
                'displayName', 'formattedAddress', 'location', 'displayName',
                'googleMapsURI', 'id', 'nationalPhoneNumber', 'addressComponents'
            ]
        }).then(data => {

            setSelectedLocation({
                displayName: data.place.displayName,
                formattedAddress: data.place.formattedAddress,
                mapsUrl: data.place.googleMapsURI,
                lat: data.place.location.lat(),
                lng: data.place.location.lng(),
                phoneNumber: data.place.nationalPhoneNumber,
                value: data.place.id,
                label: `${data.place.displayName}, ${data.place.formattedAddress}`,
                ...extractLocationData(data.place.addressComponents),
            });
        })
    }, []);

    if (!isApiLoaded || !library) return <Spinner primary />;

    return <SimpleColumn style={{ width: '100%' }}>

        <SimpleRow style={{ width: '100%', columnGap: '12.5px' }}>

            <SingleSelectionSearchDropdown
                helpText='Find hospital on map'
                placeholderText='Search on google maps'
                required
                apiCallback={fetchGoogleMapPlaces}
                debounceTimeout={1200}
                onChange={(_, location) => handleChooseLocation(location)}
                style={{ flex: .5, maxWidth: '50%' }}
                selection={selectedLocation}
                emptyStateText='Please type in the hospital/clinic name to search'
                optionJSX={option => <NetworkCenterOption>
                    <span style={{ fontWeight: '600', fontSize: '12.5px' }}>{option.label}</span>
                    <span style={{ fontWeight: '500', fontSize: '10px', color: '#777' }}>{option.data.address}</span>
                </NetworkCenterOption>}
            />

            <CommonPlainInput
                helpText='Add Doctor'
                style={{ flex: .25 }}
                disabled={!selectedLocation}
                selection={doctorSelection?.label}
                onChange={value => setDoctorSelection({ value, label: value })}
            />
            <SingleCommonSelectionDropdown
                style={{ flex: .25 }}
                defaultOptions={verticals.map(item => ({ value: item.verticalId, label: item.verticalName, ...item }))}
                helpText='Select Vertical' required
                disabled={!selectedLocation}
                selection={verticalSelection}
                onChange={setVerticalSelection}
            />

        </SimpleRow>

        <Map style={{ width: '100%', height: '45vh', outline: 'none', ...mapStyles }}
            renderingType='VECTOR' disableDefaultUI defaultCenter={position} defaultZoom={16} mapId={GOOGLE_MAPS_ID}
            center={{ lat: (selectedLocation || position).lat, lng: (selectedLocation || position).lng }}
        >
            <AdvancedMarker position={{ lat: (selectedLocation || position).lat, lng: (selectedLocation || position).lng }} />
        </Map>

        <CommonButton
            onClick={handleChoosing} style={{ marginTop: '15px' }} text='Choose Center and doctor'
            disabled={!selectedLocation || !verticalSelection || !doctorSelection}
        />

    </SimpleColumn>

};

const matchStateToProps = (state) => ({
    authToken: state.app.authToken,
    verticals: state?.app?.verticals || [],
});

export default connect(matchStateToProps)(MapWidget);
