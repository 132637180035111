//  * Redux reducers are defined in this folder
//  * Export them from your index file
//  *
//  * DO NOT call combineReducers here. That is done for you
//  * in store/index.js
//  *
//  *

//import app from './app';

import app from './app';

export default {
  app,
};
