import React, { useCallback } from 'react';
import { MainDigitisationContainer } from './styledCommonComponents';
import DiseaseSelectionInput from './DiseaseSelectionInput';
import AppointmentDateSelectionInput from './AppointmentDateSelectionInput';
import { connect } from 'react-redux';
import PracticeSelectionInput from './PracticeSelectionInput';
import { APIProvider } from '@vis.gl/react-google-maps';
import config from '../../config';

const CommonFirstStep = ({
  commonDigitisationState,
  setCommonDigitisationState,
}) => {
  const updateCommonDigitisationState = useCallback(
    (key, value) => {
      setCommonDigitisationState((prevState) => ({
        ...prevState,
        [key]: typeof value === 'function' ? value(prevState[key]) : value,
      }));
    },
    [setCommonDigitisationState]
  );

  return (
    <APIProvider apiKey={config.googleMapsApiKey}>
      <MainDigitisationContainer
        style={{ height: 'fit-content', boxShadow: 'none' }}
      >
        <section style={{ width: '100%' }}>
          <DiseaseSelectionInput
            helpText="Probable Diagnosis (Primary)"
            required
            placeholderText="Search Primary Diagnosis"
            selectedDiseases={commonDigitisationState.primaryDiagnosis}
            onSelectDisease={(value) =>
              updateCommonDigitisationState('primaryDiagnosis', value)
            }
            disabled={commonDigitisationState?.isFullDisabled}
            isAdditionDisabled={
              commonDigitisationState.primaryDiagnosis?.length
            }
          />

          <DiseaseSelectionInput
            helpText="Probable Diagnosis (Secondary)"
            placeholderText="Search Secondary Diagnosis (Optional)"
            selectedDiseases={commonDigitisationState.secondaryDiagnosis}
            onSelectDisease={(value) =>
              updateCommonDigitisationState('secondaryDiagnosis', value)
            }
            isAdditionDisabled={
              commonDigitisationState?.secondaryDiagnosis?.length
            }
            // disabled={commonDigitisationState?.isFullDisabled || commonDigitisationState?.isPartialDisabled}
          />

          <PracticeSelectionInput
            helpText="Select Doctor & Associated Centre"
            onChange={(practice, doctor, center, googlePlace) => {
              updateCommonDigitisationState(
                'networkPractice',
                (prev) => practice || prev
              );
              updateCommonDigitisationState(
                'networkDoctor',
                (prev) => doctor || prev
              );
              updateCommonDigitisationState(
                'networkCenter',
                (prev) => center || prev
              );
              updateCommonDigitisationState(
                'googlePlace',
                (prev) => googlePlace || prev
              );
            }}
            networkPractice={commonDigitisationState.networkPractice}
            disabled={
              commonDigitisationState?.isFullDisabled ||
              commonDigitisationState?.isPartialDisabled
            }
          />

          <AppointmentDateSelectionInput
            helpText="Appointment Date"
            onChange={(value) =>
              updateCommonDigitisationState('appointmentDate', value)
            }
            appointmentDate={commonDigitisationState.appointmentDate}
            disabled={commonDigitisationState?.isFullDisabled}
          />
        </section>
      </MainDigitisationContainer>
    </APIProvider>
  );
};

const matchStateToProps = (state) => ({
  activeConsult: state?.consults?.activeConsult || {},
  offlineConsultation: state?.consultation || {},
});

export default connect(matchStateToProps)(CommonFirstStep);
