import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
const customStylesModal = {
  content: {
    left: '50%',
    right: 'auto',
    borderRadius: '0.5em',
    display: 'block',
    position: 'absolute',
    padding: '0px !important',
    flex: 1,
    marginRight: '-50%',
    width: '90%',
    height: '40%',
    transform: 'translate(-50%, -50%)',
    top: '60%',
    backgroundColor: 'white',
    border: '1px solid #714FFF ',
  },
};
const CrossIcon = styled(FaTimes)`
  font-size: 1.5rem;
  font-weight: normal;
  color: white;
`;

export default function ReimbursementPrePost(props) {
  const { isOpen, prepostData, onClose } = props;
  return (
    <Modal isOpen={isOpen} style={customStylesModal} toggle={onClose}>
      <div className="">
        <div className="row">
          <div className="col-11 bg-[#714FFF]">
            <h1
              className="text-white text-center m-1"
              style={{ fontSize: '22px' }}
            >
              Reimbursement Pre-Post Table
            </h1>
          </div>
          <div className="col-1 bg-[#714FFF] ">
            <button className="float-right m-1" onClick={onClose}>
              <CrossIcon />
            </button>
          </div>
        </div>

        <Row className="m-4">
          <Col xs={12} md={12}>
            <table>
              <tr>
                <th>Id</th>
                <th>Amount</th>
                <th>Reimbursement Type</th>
                <th>Prescription Link</th>
                <th>Lab Invoice Link</th>
                <th>Medicine Invoice Link</th>
                <th>Invoice Link</th>
                <th>LabReport Link</th>
                <th>MER Link</th>
                <th>Time Type</th>
                <th>Reason</th>
                <th>Comment</th>
                <th>Date</th>
                <th>Discharge Date</th>
                <th>Addmission Date</th>
                <th>Request Date</th>
              </tr>
              {prepostData &&
                prepostData.length > 0 &&
                prepostData.map((data) => {
                  return (
                    <tr style={{ background: 'white' }}>
                      <td>{data.reimbursementPrePostReqId}</td>
                      <td>{data.amount}</td>
                      <td>{data.reimbursementType}</td>
                      <td>
                        {data.prescriptionLink ? (
                          <a
                            href={data.prescriptionLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Prescription Link
                          </a>
                        ) : (
                          'N.A'
                        )}
                      </td>
                      <td>
                        {data.labInvoiceLink ? (
                          <a
                            href={data.labInvoiceLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Lab Invoice Link
                          </a>
                        ) : (
                          'N.A'
                        )}
                      </td>

                      <td>
                        {data.medicineInvoiceLink ? (
                          <a
                            href={data.medicineInvoiceLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Lab Invoice Link
                          </a>
                        ) : (
                          'N.A'
                        )}
                      </td>
                      <td>
                        {data.invoiceLink ? (
                          <a
                            href={data.invoiceLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Invoice Link
                          </a>
                        ) : (
                          'N.A'
                        )}
                      </td>
                      <td>
                        {data.labReportLink ? (
                          <a
                            href={data.labReportLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Lab Link
                          </a>
                        ) : (
                          'N.A'
                        )}
                      </td>
                      <td>
                        {data.merLink ? (
                          <a
                            href={data.merLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Mer Link
                          </a>
                        ) : (
                          'N.A'
                        )}
                      </td>
                      <td>{data.selectedTimeType}</td>

                      <td>{data.reason ? data.reason : 'N.A'}</td>
                      <td>{data.comments ? data.comments : 'N.A'}</td>
                      <td>{data.date ? data.date : 'N.A'}</td>
                      <td>{data.disChargeDate ? data.disChargeDate : 'N.A'}</td>
                      <td>
                        {data.addmissionDate ? data.addmissionDate : 'N.A'}
                      </td>

                      <td>{data.requestDate ? data.requestDate : 'N.A'}</td>
                    </tr>
                  );
                })}
            </table>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
