import React from 'react';
import visitLogo from '../../assets/images/visit-logo-white.svg';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  z-index: 10;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #714fff;
  border-radius: 0px 0px 25px 25px;
  padding: 0.875rem;
`;
const LogoContainer = styled.img`
  max-width: 9.8rem;
`;

export default function LoginHeader() {
  return (
    <>
      <HeaderContainer>
        <LogoContainer src={visitLogo} alt="Visit Logo" />
      </HeaderContainer>
    </>
  );
}
