import React, { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReimbursementNavbar from '../../components/ReimbursementNavbar/ReimbursementNavbar';
import { UpArrow, ArrowRightLeft } from '../../components/common/svgFiles';
import ColumnFilter from '../../components/common/ColumnFilter';
import {
  useFetchReimbursementData,
  useClaimRequest,
} from '../../queries/reimbursementQuery';
import '../../styling/react-table.css';
import '../../styling/table.css';
import SponsorFilter from '../../components/common/SponsorFilter';
import ReClaimIcon from '../../assets/images/re-claim.png';
import ViewClaim from '../../assets/images/EyeIcon.png';
import { useEffect } from 'react';
import { downloadReimbursementCsvFile } from '../../services/reimbursementClaims';
import VendorFilter from '../../components/common/VendorFilter';

const Heading = styled.h2`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  color: #714fff;
`;

const LogoContainer = styled.img`
  max-width: 1.8rem;
`;

const styleobj = {
  'font-size': '15px',
  overflow: 'hidden',
  'background-color': '#FFF',
  'font-weight': 'semi-bold',
  'font-family': 'Montserrat',
};

export default function QcReimbursementClaimsTable() {
  const searchKeyword = useSelector((state) => state.app.searchString);
  const [rId, setReimbursementId] = useState('');
  let [pages, setCount] = useState(-1);
  const [sponsorName, setSponsorName] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState({ sd: '', ed: '' });
  const [myCasesFilter, setAllCasesFilter] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [claimBy, setClaimBy] = useState('');
  const [searchString, setSearchString] = useState('');
  const toastTheme = 'dark';
  const [status, setStatus] = useState('qc-check');
  const [claimStatus, setClaimStatus] = useState('');
  const [reimbursementType, setReimbursementType] = useState('');
  const [vendorName, setVendorName] = useState('');
  const {
    isLoading,
    error,
    data: qcTableDataSource,
    isFetching,
    refetch: refetchQcData,
  } = useFetchReimbursementData(
    page,
    pageSize,
    sortBy,
    sortOrder,
    myCasesFilter,
    claimBy ? claimBy : '',
    selectedDate.sd && moment(selectedDate.sd).unix(),
    selectedDate.ed && moment(selectedDate.ed).unix(),
    rId || '',
    sponsorName || '',
    searchString || '',
    status || '',
    claimStatus || '',
    reimbursementType,
    null,
    vendorName
  );
  pages =
    qcTableDataSource && Math.ceil(qcTableDataSource.totalRecords / pageSize);
  const OnSuccessClaimProcess = (success) => {
    toast.success('Request is claimed successfully', {
      icon: '✅',
      theme: toastTheme,
    });
    refetchQcData();
  };
  const OnErrorClaimProcess = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      { icon: '❌', theme: toastTheme }
    );
    refetchQcData();
  };
  const { mutate: claimRequest } = useClaimRequest(
    OnSuccessClaimProcess,
    OnErrorClaimProcess
  );

  const downloadCsv = async () => {
    const params = {
      sponsorName,
      myCasesFilter,
      size: 50000,
      page: 1,
      claimBy,
      status,
      claimStatus,
      q: searchString,
      rId,
      startDate: selectedDate.sd && moment(selectedDate.sd).unix(),
      endDate: selectedDate.ed && moment(selectedDate.ed).unix(),
      isQcCsvDownload: true,
    };
    await downloadReimbursementCsvFile(params);
  };
  const getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          minHeight: '70px',

          background:
            rowInfo.original.subStatus === 'clarification-needed'
              ? 'rgba(236, 151, 25, 0.48)'
              : '',
        },
      };
    }
    return {};
  };

  function ReimbursementType(props) {
    const { onChange, filter } = props;
    const [value, setValue] = useState(filter ? filter.value || '' : '');
    /* eslint-disable react-hooks/exhaustive-deps */
    // useEffect(() => {
    //     onChange(value);
    // }, [value]);
    /* eslint-enable */
    const handleChange = (event) => {
      setValue(event.target.value);
      onChange(event.target.value);
    };
    return (
      <div>
        <select
          onChange={handleChange}
          style={{
            width: '100%',
            height: '40px',
            fontSize: '12px',
          }}
          value={value}
        >
          <option value="all">All</option>
          <option value="normal-spectacles">Normal Spectacles</option>
          <option value="bifocal-spectacles">Bifocal Spectacles</option>
          <option value="normal-lens">Normal Contact Lens</option>
          <option value="bifocal-lens">Bifocal Contact Lens</option>
          <option value="ivf">Infertility Treatment</option>
          <option value="tooth-ext">Simple Tooth Extraction</option>
          <option value="prebooked-consult">Prebook Consult</option>
          <option value="prebooked-medicine">
            Prebooked Prescribed Medicines
          </option>
          <option value="prebooked-lab">
            Prebooked Prescribed Diagnostics
          </option>
          <option value="fracture">Surgical Cast</option>
          <option value="vaccine">Vaccinations</option>
          <option value="emergency-consult">
            Urgent Consults, Medicines or Labs
          </option>
          <option value="surgical-tooth-ext">
            Surgical extraction of tooth
          </option>
          <option value="surgical-molar-ext">
            Surgical extraction of molar tooth
          </option>
          <option value="tooth-filling">Tooth Filling</option>
          <option value="tooth-scaling">Tooth Scaling</option>
          <option value="tooth-orthodontal">Orthodontal</option>
          <option value="denture-single">Simple Single Tooth Denture</option>
          <option value="denture-complex">
            Complex single/two teeth denture
          </option>
          <option value="denture-full">Full upper/lower teeth denture</option>
          <option value="denture-set">Full set of dentures</option>
          <option value="denture-capping">Tooth Capping</option>
          <option value="root-canal">Root canal treatment</option>
          <option value="dental-proflaxes">Dental Proflaxes</option>
        </select>
      </div>
    );
  }

  const prepareSorting = (state) => {
    const { sorted } = state;
    const reimbursementIdSort = Array.isArray(sorted)
      ? sorted.find((ele) => ele.id === 'reimbursementId')
        ? 'reimbursementId'
        : undefined
      : undefined;
    const reimbursementIdSortOrder = Array.isArray(sorted)
      ? sorted.find((ele) => ele.id === 'reimbursementId' || {}).desc
        ? 'desc'
        : 'asc'
      : undefined;
    setSortOrder(reimbursementIdSortOrder);
    setSortBy(reimbursementIdSort);
  };
  const filterData = (state) => {
    console.log('filter---', state.filtered);
    const { filtered } = state;
    const idFilter =
      filtered && filtered.find((f) => f.id === 'reimbursementId');
    const claimFilter = filtered && filtered.find((f) => f.id === 'claimBy');
    const sponsorFilter =
      filtered && filtered.find((f) => f.id === 'sponsorName');
    const statusFilter =
      filtered && filtered.find((f) => f.id === 'requestStatus');
    const insurerFilter =
      filtered && filtered.find((f) => f.id === 'claimStatus');
    const reimbursementType =
      filtered && filtered.find((f) => f.id === 'reimbursementType');
    const vendorFilter =
      filtered && filtered.find((f) => f.id === 'vendorName');
    console.log('reimbursementType', reimbursementType);
    if (idFilter) {
      setReimbursementId(idFilter.value);
    }

    if (!idFilter) {
      setReimbursementId('');
    }
    if (claimFilter) {
      setClaimBy(claimFilter.value);
    }
    if (sponsorFilter) {
      setSponsorName(sponsorFilter.value.value);
    }
    if (!sponsorFilter) {
      setSponsorName('');
    }
    if (vendorFilter) {
      setVendorName(vendorFilter.value.value);
    }
    if (!vendorFilter) {
      setVendorName('');
    }
    if (reimbursementType) {
      setReimbursementType(reimbursementType.value);
    }
    if (reimbursementType && reimbursementType.value === 'all') {
      setReimbursementType('');
    }
  };

  const claimRequestProcess = (reimbursementId) => () => {
    claimRequest(reimbursementId);
  };

  const onStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  useEffect(() => {
    if (startDate && endDate) {
      setSelectedDate({ sd: startDate, ed: endDate });
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (searchKeyword) {
      setSearchString(searchKeyword);
      refetchQcData();
    }
  }, [refetchQcData, searchKeyword, searchString]);

  const reimbursementQCColumn = () => [
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '2px' }}
        >
          Reimbursement ID
          <UpArrow />
        </div>
      ),
      accessor: 'reimbursementId',
      filterable: true,
      Filter: ColumnFilter,
      width: 190,
      style: { padding: '10px', textAlign: 'center' },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          {' '}
          Claimed By <UpArrow />
        </div>
      ),
      accessor: 'claimBy',
      filterable: true,
      width: 170,
      Filter: ColumnFilter,
      style: { textAlign: 'center' },
    },
    {
      Header: 'Sponsor Name ',
      accessor: 'sponsorName',
      width: 170,
      filterable: true,
      style: { textAlign: 'center' },
      Filter: ({ filter, onChange }) => (
        <SponsorFilter onChangeValue={onChange} value={filter ? filter : ''} />
      ),
    },
    {
      Header: 'User Name',
      accessor: 'userName',
      width: 170,
      defaultHeight: 140,
      style: { textAlign: 'center' },
    },
    {
      Header: 'User Phone Number',
      accessor: 'userPhone',
      width: 170,
      defaultHeight: 140,
      style: { textAlign: 'center' },
    },
    {
      Header: 'User Email',
      accessor: 'userEmail',
      width: 170,
      defaultHeight: 140,
      style: { textAlign: 'center' },
    },

    {
      Header: () => (
        <div className="flex flex-row">
          Patient Name
          <UpArrow />
        </div>
      ),
      width: 150,
      style: { textAlign: 'center' },

      Cell: (row) => {
        return <span>{row.original.relative || row.original.userName}</span>;
      },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Claim Amount
          <UpArrow />
        </div>
      ),
      width: 150,
      accessor: 'reimbursementAmount',
      filterable: true,
      style: { textAlign: 'center' },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Approved Amount
          <UpArrow />
        </div>
      ),
      width: 150,
      accessor: 'approvedAmount',
      filterable: true,
      style: { textAlign: 'center' },
    },

    {
      Header: () => (
        <div className="flex flex-row">
          Insurer Name
          <UpArrow />
        </div>
      ),
      filterable: true,
      width: 150,
      accessor: 'vendorName',
      style: { textAlign: 'center' },
      Filter: ({ filter, onChange }) => (
        <VendorFilter onChangeValue={onChange} value={filter ? filter : ''} />
      ),
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Claim Request Date
          <UpArrow />
        </div>
      ),
      width: 150,
      style: { textAlign: 'center' },

      Cell: (row) => {
        return (
          <span>
            {moment(row.original.createdAt).format('DD/MM/YYYY HH:mm')}
          </span>
        );
      },
    },

    {
      Header: () => (
        <div className="flex flex-row">
          QC Allotted On
          <UpArrow />
        </div>
      ),
      width: 150,
      style: { textAlign: 'center' },

      Cell: (row) => {
        return (
          <span>
            {row.original.qcAllotedTime
              ? moment(row.original.qcAllotedTime).format('DD/MM/YYYY HH:mm')
              : 'N.A'}
          </span>
        );
      },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Reimbursement Type
          <UpArrow />
        </div>
      ),
      width: 150,
      accessor: 'reimbursementType',
      filterable: true,
      Filter: ReimbursementType,
      style: { textAlign: 'center' },
    },
    {
      Header: 'Actions',
      width: 110,
      style: {
        whiteSpace: 'unset',
        position: 'absolute',
        right: 0,
        background: 'white',
        height: '1110px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Montserrat',
      },
      headerStyle: {
        overflow: 'visible',
        position: 'absolute',
        right: 0,
        background: '#FFFFFF',
        textAlign: 'center',
      },
      Cell: (row) => {
        return (
          <div className="flex flex-row cursor-pointer ml-3">
            <ReactTooltip id="claimView" place="top" effect="float" type="info">
              View Claim
            </ReactTooltip>
            <Link
              to={`/reimburse-claim/${row.original.reimbursementId}`}
              target="_blank"
            >
              <LogoContainer
                data-tip
                data-for="claimView"
                className="mr-2"
                src={ViewClaim}
                alt="view claim"
              />
            </Link>
            <ReactTooltip
              id="reClaimReq"
              place="top"
              effect="float"
              type="success"
            >
              Re-Claim Request
            </ReactTooltip>
            <LogoContainer
              data-tip
              data-for="reClaimReq"
              className="mr-1"
              src={ReClaimIcon}
              alt="Reclaim Logo"
              onClick={claimRequestProcess(row.original.reimbursementId)}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div
      className="container-fluid w-full px-0 h-full flex flex-col primary-bg m-0 p-0"
      style={{ backgroundColor: '#F5F3F8' }}
    >
      {/* <ReimbursementNavbar
                onSearch={(value) => setSearchString(value ? value : '')}
            /> */}
      <div className=" m-4 flex flex-row">
        <div className="col-3 mt-2">
          <Heading>QC Tab</Heading>
        </div>
        <div className=" col-4 rounded-md ">
          <table className="" style={{ minWidth: '150px' }}>
            <thead className="">
              <tr>
                <th style={{ backgroundColor: '#EBE7FF', color: '#1E1E1E' }}>
                  Today Completed Cases
                </th>
                <th style={{ backgroundColor: '#EBE7FF', color: '#1E1E1E' }}>
                  Pending Cases
                </th>
              </tr>
            </thead>
            <tbody style={{ color: '#714FFF' }}>
              <tr>
                <td>
                  {qcTableDataSource?.dailyQcCompletedCase?.qcCompletedCount ||
                    0}
                </td>
                <td>{qcTableDataSource?.totalRecords}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className=" flex mt-1">
          <div
            className="border-2 p-1"
            style={{
              border: '2px solid #FFFFFF',
              borderRadius: '10px',
              backgroundColor: '#FFFFFF',
              paddingBottom: '28px',
              height: '3rem',
            }}
          >
            <label
              className="block p-0"
              style={{
                color: '#714FFF',
                fontSize: '12px',
                fontFamily: 'Montserrat',
                fontStyle: 'bold',
                marginBottom: '0rem',
                fontWeight: 'bold',
              }}
            >
              Start From
            </label>

            <input
              className="p-0"
              type="date"
              style={{ color: '#3A3A49' }}
              onChange={(e) => onStartDateChange(e)}
            ></input>
          </div>
          <div className=" mt-3">
            <ArrowRightLeft />
          </div>

          <div
            className="border-2 p-1"
            style={{
              border: '2px solid #FFFFFF',
              borderRadius: '10px',
              backgroundColor: '#FFFFFF',
              height: '3rem',
            }}
          >
            <label
              className="block p-0"
              style={{
                color: '#714FFF',
                fontSize: '12px',
                fontFamily: 'Montserrat',
                fontStyle: 'bold',
                marginBottom: '0rem',
                fontWeight: 'bold',
              }}
            >
              End on
            </label>

            <input
              className="p-0"
              type="date"
              style={{ color: '#3A3A49' }}
              onChange={onEndDateChange}
            ></input>
          </div>
        </div>
        <div className=" mt-2">
          <button
            className="ml-4 btn float-right"
            style={{ backgroundColor: '#714fff', color: '#fff' }}
            onClick={downloadCsv}
          >
            Download CSV
          </button>
        </div>
      </div>
      <div className="pt-1">
        <ReactTable
          keyField="reimbursementId"
          //className="-striped -highlight"
          data={qcTableDataSource && qcTableDataSource.results}
          pages={pages}
          manual
          //   defaultSorted={[
          //     {
          //       id: 'reimbursementId',
          //       desc: true,
          //     },
          //   ]}
          defaultSorted={[{ id: 'qcAllotedTime', desc: true }]}
          columns={reimbursementQCColumn()}
          showPaginationTop={false}
          //filtered={filtered}
          //onFilteredChange={onFilteredChange}
          useFlexLayout={true}
          showPageJump={false}
          loading={isLoading || isFetching}
          style={styleobj}
          pageSizeOptions={[5, 10, 20, 25, 50, 100, 150]}
          onFetchData={(state, instance) => {
            prepareSorting(state);
            filterData(state);
            setPage(state.page ? state.page + 1 : 1);
            setPageSize(state.pageSize);
          }}
          getTrProps={getTrProps}
        />
      </div>
    </div>
  );
}
