import axios from 'axios';
import config from '../config';
import { store } from '../store/';

export async function sendLogoutRequest() {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  const url = `${config.baseUrl}/new-ops/logout`;
  const options = {
    url,
    method: 'POST',
    headers,
  };
  return await axios(options).then((res) => {
    const responseBody = res.data;
    if (responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    }
  });
}
