
import React from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import ShowIcon from '../../assets/images/show.svg';
const LogoContainer = styled.img`
  width: 23px;
height: 23px;
`;

const header = () => {
    return (
        <h2 style={{
            color: '#ffff', fontFamily: 'Montserrat', fontSize: '18px'
        }}>
            Verify Doctor Registration

        </h2>
    )
}


export default function DoctorVerifyModal({
    doctorRegModal,
    closeDoctorModal,
    OnRegistrationNumber,
    doctorNameSuggestions,
    OnChangeDoctorName,
    onDoctorNameSearch,
    verifyDoctorReg,
    reimbursementDetail

}) {
    return (
        < Modal
            isOpen={doctorRegModal}
            style={{ borderRadius: '6px' }}
            toggle={closeDoctorModal}

        >
            <ModalHeader
                style={{ backgroundColor: '#714FFF', }}
                toggle={closeDoctorModal}
            >
                {header()}

            </ModalHeader>
            <ModalBody>

                <Row>
                    <Col xs={5} md={5}>
                        <Label className='m-2 ml-2'>Registration No  </Label>
                    </Col>

                    <Col xs={7} md={7} className='mb-2'>

                        <input
                            className='m-2'
                            style={{ height: '2rem', border: '2px solid #714FFF', borderRadius: '4px', width: '200px' }}
                            type="text"
                            placeholder=" Enter Registration No "
                            onChange={OnRegistrationNumber}
                        ></input>
                    </Col>
                </Row>
                <Row>
                    {
                        reimbursementDetail.prescriptionLink && reimbursementDetail.prescriptionLink
                            .split(',')
                            .map((link, idx) => {
                                return (
                                    <Col xs={6} md={6} lg={6} className="mb -2">
                                        <div key={idx} className='flex'>
                                            <div className="w-44 m-2 pl-1 flex border-2 rounded  border-[#714FFF]">
                                                {` Prescription${idx + 1}_${link.split('.').pop()}`}
                                                <a className='flex ml-2  '
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    href={link}
                                                >
                                                    <button className='flex ml-2'
                                                    >
                                                        <LogoContainer src={ShowIcon} alt="show Icon" />
                                                    </button>
                                                </a>
                                            </div>

                                        </div>
                                    </Col>
                                );
                            })}
                </Row>

            </ModalBody>
            {/* <Row>
                    <Col xs={6} md={4} className='m-2'>
                        <lable>Doctor Name :</lable>
                    </Col>
                    <Col xs={6} md={4}>
                        <AutoComplete
                            options={doctorNameSuggestions}
                            style={{ width: 150, border: '1px solid #714FFF', borderRadius: '4px' }}
                            onSelect={OnChangeDoctorName}
                            onSearch={onDoctorNameSearch}
                            placeholder="Enter Doctor Name"
                        />
                    </Col>
                </Row> */}
            <ModalFooter>
                <div class="">
                    <button
                        style={{ backgroundColor: '#714FFF', color: 'white' }}
                        className="btn"
                        onClick={verifyDoctorReg}
                    >
                        Find Doctors
                    </button>
                </div>
            </ModalFooter>
        </Modal >
    )

}