import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import ViewClaim from '../../assets/images/EyeIcon.png';
import { Link, useNavigate } from 'react-router-dom';
import { UpArrow, ArrowRightLeft } from '../../components/common/svgFiles';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import selectTableHoc from 'react-table/lib/hoc/selectTable';
import {
  useFetchAllReimbursement,
  useReimbursementDocList,
  useTransferReimbursementCase,
  useAddClarificationSolution,
  useRemarkList,
} from '../../queries/reimbursementAdminQuery';
import ColumnFilter from '../../components/common/ColumnFilter';
import SponsorFilter from '../../components/common/SponsorFilter';
import DoctorsFilter from './DoctorsFilter';
import BulkTransferModal from './BulkTransferModal';
import { toast } from 'react-toastify';
import { ModifyIcon } from '../../components/common/svgFiles';
import AddRemark from './AddRemark';
import {
  downloadAdminReimbursementCsvFile,
  downloadReimbursementDoctorsCountFile,
} from '../../services/reimbursementAdmin';
import { setNotificationModalClose, setOnBreakModalClose } from '../../actions';

const SelectTable = selectTableHoc(ReactTable);
const Title = styled.p`
  font-size: 24px;
  line-height: 37px;
  color: #714fff;
  font-family: Montserrat;
  font-weight: bold;
`;
const PencilIcon = styled(BsPencil)`
  font-size: 1.9rem;
  font-weight: normal;
  color: #714fff;
  padding-left: 2px;
  border: 2px solid #714fff;
  padding: 2px;
  border-radius: 5px;
`;
const LogoContainer = styled.img`
  max-width: 2rem;
`;
const SubText = styled.p`
  font-weight: bold;
  color: ${(props) => (props.toggle ? '' : '#714fff')};
  font-size: 14px;
  font-family: Montserrat;
`;
const ManageText = styled.p`
  font-family: Montserrat;
  font-style: SemiBold;
  font-size: 14px;
`;
const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
const styleObj = {
  'font-size': '15px',
  overflow: 'hidden',
  'background-color': '#FFF',
  'font-weight': '500',
  'font-family': 'Montserrat',
};
const getTrProps = (state, rowInfo, instance) => {
  if (rowInfo && rowInfo.row) {
    return {
      style: {
        minHeight: '70px',
      },
    };
  }

  return {};
};
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '2px solid #B9A7FE',
    width: '250px',
    borderColor: state.isFocused ? '#B9A7FE' : '##B9A7FE', // change border color based on focus state
    '&:hover': { borderColor: '#B9A7FE' }, // change border color on hover
  }),
};
const customStylesForTat = {
  control: (provided, state) => ({
    ...provided,
    border: '2px solid #B9A7FE',
    borderColor: state.isFocused ? '#B9A7FE' : '##B9A7FE', // change border color based on focus state
    '&:hover': { borderColor: '#B9A7FE' }, // change border color on hover
  }),
};
const tatDaysOptions = new Array(16).fill(0).map((e, i) => {
  if (i < 15) {
    return {
      label: `${i + 1} Day`,
      value: i + 1,
    };
  }
  return {
    label: `15+ Day`,
    value: i + 1,
  };
});

export default function Admin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  let [pages, setCount] = useState(-1);
  const [page, setPage] = useState(1);
  const [sponsor, setSponsor] = useState('');
  const [remarkModal, setRemarkModal] = useState(false);
  let reimbursementTableData = [];
  const [sortOrder, setSortOrder] = useState('');
  const [sortBy, setSortBy] = useState();
  const [searchString, setSearchString] = useState('');
  const [reason, setReason] = useState('');
  const [status, setStatus] = useState('documents-uploaded');
  const [statusForCsv, setStatusForCsv] = useState('');
  const [deficientValue, setDeficientValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState({ sd: null, ed: null });
  const [focusedInput, setFocusedInput] = useState(null);
  const [claimedBy, setClaimedBy] = useState('');
  const [transferCasesDoctorId, setTransferCasesDoctorId] = useState(-1);
  const [remarkCasesSelected, setRemarkCasesSelected] = useState(new Set());
  const [pageSize, setPageSize] = useState(20);
  const [showBulkTransferModal, setShowBulkTransferModal] = useState(false);
  const [remarkData, setRemark] = useState(null);
  const [updateRemark, setUpdateRemark] = useState('');
  const [updateSolutionRId, setUpdateSolutionRId] = useState(null);
  const [rejectionRemark, setRejectionRemark] = useState('');
  const [rejectionRemarkList, setRejectionRemarkList] = useState([]);
  const [tatDay, setTatDay] = useState([]);
  const [isTransfer, setIsTransfer] = useState(false);
  const searchKeyword = useSelector((state) => state.app.searchString);
  if (toggle) {
    navigate('/reimbursement-doctor');
  }

  const {
    isLoading,
    error,
    data: tableData,
    isFetching,
    refetch: refreshReimburseData,
  } = useFetchAllReimbursement(
    page,
    pageSize,
    sponsor,
    searchString,
    reason,
    status,
    claimedBy,
    selectedDate.sd && moment(selectedDate.sd).unix(),
    selectedDate.ed && moment(selectedDate.ed).unix(),
    sortBy,
    sortOrder,
    rejectionRemark,
    tatDay && tatDay.value ? tatDay.value : null
  );
  pages = tableData && Math.ceil(tableData.count / pageSize);
  reimbursementTableData = tableData && tableData.result;
  const { error: ErrorInDoctorList, data: opsDoctorList } =
    useReimbursementDocList();
  const { data: rejectionReasonList, error: errorInRejectionReason } =
    useRemarkList();

  const OnTransferSuccess = (success) => {
    setTransferCasesDoctorId(-1);
    setIsTransfer(false);
    toast.success(success, { theme: 'dark' });
    refreshReimburseData();
    setShowBulkTransferModal(false);
  };
  const OnTransferError = (err) => {
    toast.error('Error while transferring cases', { theme: 'dark' });
  };
  const OnAddSuccess = (success) => {
    toast.success('Solution Added', { theme: 'dark' });
    setRemarkModal(false);
  };
  const OnAddError = (err) => {
    toast.error('Error', { theme: 'dark' });
  };
  const {
    mutate: addClarificationSolution,
    error: errorInaddClarificationSolution,
  } = useAddClarificationSolution(OnAddSuccess, OnAddError);

  // useEffect(() => {
  //   refreshReimburseData();
  // }, [
  //   page,
  //   pageSize,
  //   sponsor,
  //   searchString,
  //   reason,
  //   status,
  //   claimedBy,
  //   selectedDate.sd && moment(selectedDate.ed).unix(),
  //   selectedDate.ed && moment(selectedDate.ed).unix(),
  //   sortBy,
  //   sortOrder,
  //   refreshReimburseData,
  //   rejectionRemark,
  //   tatDay,
  // ]);

  const { mutate: transferCases } = useTransferReimbursementCase(
    OnTransferSuccess,
    OnTransferError
  );
  const prepareSorting = (state) => {
    const { sorted } = state;
    const reimbursementIdSort = Array.isArray(sorted)
      ? sorted.find((ele) => ele.id === 'reimbursementId')
        ? 'reimbursementId'
        : undefined
      : undefined;
    const reimbursementIdSortOrder = Array.isArray(sorted)
      ? sorted.find((ele) => ele.id === 'reimbursementId' || {}).desc
        ? 'desc'
        : 'asc'
      : undefined;
    setSortOrder(reimbursementIdSortOrder);
    setSortBy(reimbursementIdSort);
  };
  if (opsDoctorList) {
    opsDoctorList.push({
      value: 'unassigned',
      label: 'unassigned',
    });
  }
  const AdminColumns = [
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          Reimbursement ID
          <UpArrow />
        </div>
      ),
      accessor: 'reimbursementId',
      // filterable: true,
      //Filter: ColumnFilter,
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          {' '}
          Claimed By <UpArrow />
        </div>
      ),
      accessor: 'claimBy',
      filterable: true,
      width: 170,
      Filter: ({ filter, onChange }) => (
        <DoctorsFilter onChangeValue={onChange} value={filter ? filter : ''} />
      ),
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Sponsor Name ',
      accessor: 'sponsorName',
      width: 170,
      filterable: true,
      style: { textAlign: 'center', fontWeight: '600' },
      Filter: ({ filter, onChange }) => (
        <SponsorFilter
          onChangeValue={onChange}
          value={filter ? filter : ''}
          isAdmin={true}
        />
      ),
    },
    {
      Header: 'User Name',
      accessor: 'name',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Patient Name
          <UpArrow />
        </div>
      ),
      width: 150,
      style: { textAlign: 'center', fontWeight: '600' },

      Cell: (row) => {
        return <span>{row.original.patientName || row.original.name}</span>;
      },
    },
    {
      Header: 'User Phone',
      accessor: 'phone',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Status
          <UpArrow />
        </div>
      ),
      accessor: 'requestStatus',
      width: 200,
      filterable: true,
      style: { textAlign: 'center', fontWeight: '600' },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            // style={{ width: '100%' }}
            value={filter ? filter.value : 'documents-uploaded'}
          >
            <option value="all">Show All</option>
            <option value="documents-uploaded">Documents Uploaded</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
            <option value="documents-deficient">Documents Deficient</option>
            <option value="documents-verified">Documents Verified</option>
            <option value="clarification-needed">Clarification Needed</option>
            <option value="deficiency submitted">Deficiency Submitted</option>
            <option value="clarification-resolved">
              Clarification Resolved
            </option>
            <option value="on-hold">On Hold</option>
          </select>
        );
      },
      Cell: (row) => {
        if (row.original.deletionDate) {
          return <h5 className="text-[#D53815] font-bold">Deleted</h5>;
        }
        switch (row.original.requestStatus.toUpperCase()) {
          case 'DOCUMENTS-UPLOADED':
            return (
              <h5
                className="text-[#714FFF] font-bold"
                style={{
                  border: '1px solid #DDD6FC',
                  padding: '10px',
                  backgroundColor: '#DDD6FC',
                  borderRadius: '4px',
                }}
              >
                {row.original.documentDefUpdatedOn
                  ? capitalizeFirst('Deficiency-submitted')
                  : capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          case 'DOCUMENTS-VERIFIED':
            return (
              <h5
                className="text-[#3899D3] font-bold"
                style={{
                  border: '1px solid #DDD6FC',
                  padding: '10px',
                  backgroundColor: '#DDD6FC',
                  borderRadius: '4px',
                }}
              >
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          case 'APPROVED':
            return (
              <h5
                className="text-[#72BE11] font-bold"
                style={{
                  border: '1px solid #DDD6FC',
                  padding: '10px',
                  backgroundColor: '#DDD6FC',
                  borderRadius: '4px',
                }}
              >
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          case 'REJECTED':
            return (
              <h5
                className="text-[#D53815] font-bold"
                style={{
                  border: '1px solid #DDD6FC',
                  padding: '10px',
                  backgroundColor: '#DDD6FC',
                  borderRadius: '4px',
                }}
              >
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          default:
            return (
              <h5
                className="text-[#008B8B] font-bold"
                style={{
                  border: '1px solid #DDD6FC',
                  padding: '10px',
                  backgroundColor: '#DDD6FC',
                  borderRadius: '4px',
                }}
              >
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
        }
      },
    },

    {
      Header: () => (
        <div className="flex flex-row">
          Reimbursement Type
          <UpArrow />
        </div>
      ),
      width: 150,
      accessor: 'reimbursementType',
      filterable: true,
      Filter: ReimbursementType,
      style: { textAlign: 'center', fontWeight: '600' },
    },

    {
      Header: 'Invoice Date',
      accessor: 'invoiceDate',
      width: 170,
      style: {
        fontWeight: '600',
      },
    },
    {
      Header: 'Approved Amount',
      accessor: 'approvedAmount',
      width: 170,
      style: {
        fontWeight: '600',
      },
    },

    {
      Header: 'Request Date',
      accessor: 'requestDate',
      width: 170,
      style: {
        fontWeight: '600',
      },
    },
    {
      Header: 'Document Deficient Marked Date',
      accessor: 'documentDefMarkedOn',
      width: 170,
      style: {
        fontWeight: '600',
      },
    },
    {
      Header: 'Document Deficient Updated on',
      accessor: 'documentDefUpdatedOn',
      width: 170,
      style: {
        fontWeight: '600',
      },
      Cell: (row) => (
        <p>
          {row.original.documentDefUpdatedOn
            ? moment(row.original.documentDefUpdatedOn).format('DD/MM/YYYY')
            : null}
        </p>
      ),
    },
    {
      Header: 'UTR No.',
      accessor: 'claimUtr',
      width: 170,
      style: {
        fontWeight: '600',
      },
    },
    {
      Header: 'Payout Date',
      accessor: 'paymentSettlementDate',
      width: 170,
      style: {
        fontWeight: '600',
      },
    },
    {
      Header: 'Payout Date',
      accessor: 'paymentSettlementDate',
      width: 170,
      style: {
        fontWeight: '600',
      },
    },
    {
      Header: 'Clarification requested date',
      accessor: 'clarificationReqOn',
      width: 170,
      style: {
        fontWeight: '600',
      },
    },
    // {
    //   Header: 'Actions',
    //   Cell: (row) => {
    //     return row.original.subStatus ? (
    //       <button
    //         className="btn btn-success"
    //         //onClick={showRemarkModal(row.original)}
    //       >
    //         Add Solution
    //       </button>
    //     ) : null;
    //   },
    // },

    {
      Header: 'Actions',
      width: 110,
      numeric: false,
      disablePadding: false,
      style: {
        whiteSpace: 'unset',
        position: 'absolute',
        right: 0,
        background: 'white',
        height: '1110px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Montserrat',
      },
      headerStyle: {
        overflow: 'visible',
        position: 'absolute',
        right: 0,
        background: '#FFFFFF',
        textAlign: 'center',
      },
      Cell: (row) => {
        return (
          <div className="flex flex-row cursor-pointer ml-3">
            <ReactTooltip id="claimView" place="top" effect="float" type="info">
              View Claim
            </ReactTooltip>
            <Link
              to={`/reimburse-claim/${row.original.reimbursementId}`}
              target="_blank"
            >
              <LogoContainer
                data-tip
                data-for="claimView"
                className="mr-2"
                src={ViewClaim}
                alt="view claim"
              />
            </Link>
            {row.original.subStatus ? (
              <>
                <ReactTooltip
                  id="AddSolution"
                  place="top"
                  effect="float"
                  type="info"
                >
                  Add Solution
                </ReactTooltip>
                <button
                  className=""
                  data-tip
                  data-for="AddSolution"
                  onClick={showRemarkModal(row.original)}
                >
                  <ModifyIcon />
                </button>
              </>
            ) : (
              ''
            )}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (searchKeyword) {
      setSearchString(searchKeyword);
      setStatus('');
      refreshReimburseData();
    } else {
      setStatus('documents-uploaded');
    }
  }, [refreshReimburseData, searchKeyword]);
  const TatDayChanges = (v) => {
    if (v) {
      return setTatDay({ value: v.value, label: v.label });
    }
    return setTatDay([]);
  };

  const updateRemarkInput = (e) => {
    setUpdateRemark(e.target.value);
  };
  const remarkSolution = () => {
    const body = {
      reimbursementId: updateSolutionRId,
      solution: updateRemark,
    };
    addClarificationSolution(body);
  };
  const filterData = (state) => {
    console.log('filter---', state.filtered);
    const { filtered } = state;
    const idFilter =
      filtered && filtered.find((f) => f.id === 'reimbursementId');
    const claimFilter = filtered && filtered.find((f) => f.id === 'claimBy');
    const sponsorFilter =
      filtered && filtered.find((f) => f.id === 'sponsorName');
    const statusFilter =
      filtered && filtered.find((f) => f.id === 'requestStatus');
    const insurerFilter =
      filtered && filtered.find((f) => f.id === 'claimStatus');
    const reimbursementType =
      filtered && filtered.find((f) => f.id === 'reimbursementType');

    console.log('reimbursementType', reimbursementType);
    //  if (idFilter) {
    //    setReimbursementId(idFilter.value);
    //  }

    //  if (!idFilter) {
    //    setReimbursementId('');
    //  }
    if (claimFilter) {
      setClaimedBy(claimFilter.value.value);
    }
    if (!claimFilter) {
      setClaimedBy('');
    }
    if (sponsorFilter) {
      setSponsor(sponsorFilter.value.value);
    }
    if (!sponsorFilter) {
      setSponsor('');
    }
    if (statusFilter) {
      setStatus(statusFilter.value);
    }
    if (statusFilter?.value === 'all') {
      setStatus('');
    }
    if (reimbursementType) {
      setReason(reimbursementType.value);
    }
    if (reimbursementType?.value === 'all') {
      setReason('');
    }
    if (statusFilter && statusFilter?.value !== 'deficiency-submitted') {
      setStatus(statusFilter?.value);
      setDeficientValue('true');
    }
    if (statusFilter && statusFilter?.value === 'deficiency-submitted') {
      setStatus('documents-uploaded');
      setDeficientValue('true');
    }
  };
  const handleChangeRejectionRemark = (selectedValue) => {
    if (selectedValue) {
      setRejectionRemark(selectedValue.label);
      setRejectionRemarkList({
        value: selectedValue.value,
        label: selectedValue.label,
      });
    } else {
      setRejectionRemark('');
      setRejectionRemarkList([]);
    }
  };
  const isRemarksCaseSelected = (ele) => {
    return remarkCasesSelected.has(ele);
  };
  const toggleRemarksCase = (ele, blah, obj) => {
    const updatedRemarkCasesSelected = new Set(remarkCasesSelected);
    if (isRemarksCaseSelected(obj.reimbursementId)) {
      updatedRemarkCasesSelected.delete(obj.reimbursementId);
      setRemarkCasesSelected(updatedRemarkCasesSelected);
    } else {
      updatedRemarkCasesSelected.add(obj.reimbursementId);
      setRemarkCasesSelected(updatedRemarkCasesSelected);
    }
  };
  const toggleAllRemarksCases = () => {
    if (remarkCasesSelected.size === reimbursementTableData.length) {
      setRemarkCasesSelected(new Set());
    } else {
      setRemarkCasesSelected(
        new Set(reimbursementTableData.map((ele) => ele.reimbursementId)),
        () => console.log(`Option selected:`, reimbursementTableData)
      );
    }
  };
  const handleChangeTransferDoctorId = (v) => {
    setTransferCasesDoctorId(v.value);
  };
  const bulkTransferCase = () => {
    setIsTransfer(true);
    const reimbursementIds = [];
    remarkCasesSelected.forEach((ele) => {
      const reimbursementData = reimbursementTableData.find(
        (cons) => ele === cons.reimbursementId
      );
      if (reimbursementData) {
        reimbursementIds.push(reimbursementData.reimbursementId);
      }
    });
    transferCases({
      doctorId: transferCasesDoctorId,
      reimbursements: reimbursementIds,
    });
  };
  const showRemarkModal = (data) => () => {
    console.log('data' + data);
    setRemark(data.clarificationRemarks);
    setUpdateSolutionRId(data.reimbursementId);
    setRemarkModal(true);
  };
  const onStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  useEffect(() => {
    if (startDate && endDate) {
      setSelectedDate({ sd: startDate, ed: endDate });
    }
  }, [endDate, startDate]);
  function ReimbursementType(props) {
    const { onChange, filter } = props;
    const [value, setValue] = useState(filter ? filter.value || '' : '');
    /* eslint-disable react-hooks/exhaustive-deps */
    // useEffect(() => {
    //     onChange(value);
    // }, [value]);
    /* eslint-enable */
    const handleChange = (event) => {
      setValue(event.target.value);
      onChange(event.target.value);
    };
    return (
      <div>
        <select
          onChange={handleChange}
          style={{
            width: '100%',
            height: '40px',
            fontSize: '12px',
          }}
          value={value}
        >
          <option value="all">All</option>
          <option value="normal-spectacles">Normal Spectacles</option>
          <option value="bifocal-spectacles">Bifocal Spectacles</option>
          <option value="normal-lens">Normal Contact Lens</option>
          <option value="bifocal-lens">Bifocal Contact Lens</option>
          <option value="ivf">Infertility Treatment</option>
          <option value="tooth-ext">Simple Tooth Extraction</option>
          <option value="prebooked-consult">Prebook Consult</option>
          <option value="prebooked-medicine">
            Prebooked Prescribed Medicines
          </option>
          <option value="prebooked-lab">
            Prebooked Prescribed Diagnostics
          </option>
          <option value="fracture">Surgical Cast</option>
          <option value="vaccine">Vaccinations</option>
          <option value="emergency-consult">
            Urgent Consults, Medicines or Labs
          </option>
          <option value="surgical-tooth-ext">
            Surgical extraction of tooth
          </option>
          <option value="surgical-molar-ext">
            Surgical extraction of molar tooth
          </option>
          <option value="tooth-filling">Tooth Filling</option>
          <option value="tooth-scaling">Tooth Scaling</option>
          <option value="tooth-orthodontal">Orthodontal</option>
          <option value="denture-single">Simple Single Tooth Denture</option>
          <option value="denture-complex">
            Complex single/two teeth denture
          </option>
          <option value="denture-full">Full upper/lower teeth denture</option>
          <option value="denture-set">Full set of dentures</option>
          <option value="denture-capping">Tooth Capping</option>
          <option value="root-canal">Root canal treatment</option>
          <option value="dental-proflaxes">Dental Proflaxes</option>
        </select>
      </div>
    );
  }
  const downloadCsvFile = () => {
    const params = {
      sponsorName: sponsor,
      claimBySearchId: claimedBy,
      size: 50000,
      page: 1,
      reimbursementType: reason,
      status: deficientValue ? 'documents-uploaded' : status,
      startDate: selectedDate.sd && moment(selectedDate.sd).unix(),
      endDate: selectedDate.ed && moment(selectedDate.ed).unix(),
      deficientTab: deficientValue ? deficientValue : '',
      remark: rejectionRemark,
    };
    if (tatDay && tatDay.value) {
      params.startDate = moment()
        .subtract(tatDay.value < 16 ? tatDay.value : 365, 'days')
        .startOf('day')
        .unix();
      params.endDate = moment()
        .subtract(tatDay.value, 'days')
        .endOf('day')
        .unix();
    }
    downloadAdminReimbursementCsvFile(params);
  };
  const downloadDoctorsCount = () => {
    const params = {
      startDate: selectedDate.sd
        ? moment(selectedDate.sd).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      endDate: selectedDate.ed
        ? moment(selectedDate.ed).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
    };
    downloadReimbursementDoctorsCountFile(params);
  };

  return (
    <div
      className="container-fluid w-full "
      style={{ backgroundColor: '#F5F3F8' }}
    >
      <div
        className="flex flex-row"
        style={{ justifyContent: 'space-between' }}
      >
        <div className="mt-3">
          <Title>Reimbursement Admin</Title>
        </div>
        <div
          className="flex flex-row mt-3 pl-2 pr-2"
          style={{
            border: '2px solid #714FFF',
            borderRadius: '8px',
            height: '3rem',
            padding: '4px',
          }}
        >
          <SubText className="mt-2 mr-2" toggle={toggle}>
            Reimbursement Admin
          </SubText>
          <Switch
            className="mt-2"
            onChange={(e) => setToggle(e)}
            style={{
              backgroundColor: toggle ? '#714FFF' : '#D6CCFF',
              width: '40px',
            }}
          ></Switch>
          <ManageText className="ml-2 mt-2">Manage Doctor</ManageText>
        </div>
        <div className="rounded-md  mt-3" style={{}}>
          <table className="" style={{ minWidth: '200px' }}>
            <thead className="">
              <tr>
                <th style={{ backgroundColor: '#EBE7FF', color: '#1E1E1E' }}>
                  Total Cases
                </th>
                {/* <th>Completed Cases</th>
                                    <th>Pending Cases</th> */}
                <th style={{ backgroundColor: '#EBE7FF', color: '#1E1E1E' }}>
                  Selected Cases
                </th>
              </tr>
            </thead>
            <tbody style={{ color: '#714FFF' }}>
              <tr>
                <td>{tableData?.count}</td>
                {/* <td>{ApprovedCases && ApprovedCases.records ? ApprovedCases.records : 0}</td> */}
                <td>{remarkCasesSelected.size}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex pt-4">
          <div
            className="border-2 p-1"
            style={{
              border: '2px solid #FFFFFF',
              borderRadius: '8px',
              backgroundColor: '#FFFFFF',
              paddingBottom: '28px',
              height: '3rem',
            }}
          >
            <p
              className="block p-0"
              style={{
                color: '#714FFF',
                fontSize: '8px',
                fontFamily: 'Montserrat',
                fontStyle: 'bold',
                marginBottom: '0rem',
                fontWeight: 'bold',
              }}
            >
              Start From
            </p>

            <input
              className="p-0"
              type="date"
              style={{ color: '#3A3A49' }}
              onChange={(e) => onStartDateChange(e)}
            ></input>
          </div>
          <div className="mt-3">
            <ArrowRightLeft />
          </div>
          <div
            className="border-2 p-1"
            style={{
              border: '2px solid #FFFFFF',
              borderRadius: '10px',
              backgroundColor: '#FFFFFF',
              height: '3rem',
            }}
          >
            <p
              className="block p-0"
              style={{
                color: '#714FFF',
                fontSize: '8px',
                fontFamily: 'Montserrat',
                fontStyle: 'bold',
                marginBottom: '0rem',
                fontWeight: 'bold',
              }}
            >
              End on
            </p>

            <input
              className="p-0"
              type="date"
              style={{ color: '#3A3A49' }}
              onChange={onEndDateChange}
            ></input>
          </div>
        </div>
        <div className=" mt-4 mr-3">
          <button
            className="ml-4 btn float-right"
            style={{ backgroundColor: '#714fff', color: '#fff' }}
            onClick={downloadCsvFile}
          >
            Download Csv
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-end mt-3">
        <div>
          <button
            className="btn"
            style={{
              backgroundColor:
                remarkCasesSelected.size === 0 ? '#E8E8E8' : '#714fff',
              color: remarkCasesSelected.size !== 0 ? '#fff' : '#B5B6BA',
            }}
            onClick={() => setShowBulkTransferModal(true)}
            disabled={remarkCasesSelected.size === 0}
          >
            Transfer Cases
          </button>
        </div>
        <div>
          <Select
            className="ml-4"
            value={tatDay}
            isClearable={true}
            styles={customStylesForTat}
            placeholder="Select TAT Day"
            onChange={TatDayChanges}
            options={tatDaysOptions}
            theme={(theme) => ({
              ...theme,
              borderRadius: '5px',
              boxShadow: 'none',
              colors: {
                ...theme.colors,
                primary25: '#EAE7FF',
                primary: '#B8A7FF',
              },
            })}
          />
        </div>
        <div className="">
          <Select
            className="ml-4"
            isClearable={true}
            styles={customStyles}
            value={rejectionRemarkList}
            placeholder="Select DD/Rejection Reas.."
            onChange={handleChangeRejectionRemark}
            options={rejectionReasonList}
            theme={(theme) => ({
              ...theme,
              borderRadius: '5px',
              boxShadow: 'none',
              colors: {
                ...theme.colors,
                primary25: '#EAE7FF',
                primary: '#B8A7FF',
              },
            })}
          />
        </div>
        <div className="ml-auto">
          <button
            className="ml-4 btn float-right"
            style={{ backgroundColor: '#714fff', color: '#fff' }}
            onClick={downloadDoctorsCount}
          >
            Download Doctor Count
          </button>
        </div>
      </div>

      <div className="pt-12"></div>
      <SelectTable
        data={reimbursementTableData && reimbursementTableData}
        columns={AdminColumns}
        keyField="reimbursementId"
        pages={pages}
        style={styleObj}
        showPageJump={false}
        rowSelection={true}
        selectType="checkbox"
        manual
        defaultSorted={[
          {
            id: 'reimbursementId',
            desc: true,
          },
        ]}
        pageSizeOptions={[5, 10, 20, 25, 50, 100, 150]}
        onFetchData={(state, instance) => {
          prepareSorting(state);
          filterData(state);
          setPage(state.page);
          setPageSize(state.pageSize);
        }}
        loading={isLoading || isFetching}
        isSelected={isRemarksCaseSelected}
        toggleSelection={toggleRemarksCase}
        selectAll={remarkCasesSelected.size === reimbursementTableData}
        toggleAll={toggleAllRemarksCases}
        getTrProps={getTrProps}
      />

      <BulkTransferModal
        isOpen={showBulkTransferModal}
        opsDoctorList={opsDoctorList}
        transferCasesDoctorId={transferCasesDoctorId}
        handleChangeTransferDoctorId={handleChangeTransferDoctorId}
        onClose={() => setShowBulkTransferModal(false)}
        bulkTransferCase={bulkTransferCase}
        isTransfer={isTransfer}
      ></BulkTransferModal>
      <AddRemark
        isOpen={remarkModal}
        remarkData={remarkData}
        updateRemarkInput={updateRemarkInput}
        remarkSolution={remarkSolution}
        onClose={() => setRemarkModal(false)}
      ></AddRemark>
    </div>
  );
}
