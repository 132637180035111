import React from 'react';
import { CommonHelpText, SimpleColumn, SimpleRow } from '../styledCommonComponents';
import { Input } from 'antd';

const CommonPlainInput = ({
    helpText = '',
    required = true,
    placeholderText = '',
    selection = null,
    onChange = () => {},
    disabled = false,
    style = {},
}) => {

    return <SimpleColumn style={{ marginBottom: '20px', ...style }}>
        {helpText ? <CommonHelpText>
            {helpText}{required ? <span style={{ color: '#f50', marginLeft: '2px' }}>*</span> : <></>}
        </CommonHelpText> : <></>}
        
        <SimpleRow style={{ columnGap: '7.5px', width: '100%' }}>
            <div style = {{ flex: 1, display: 'flex', position: 'relative' }}>
                <Input
                    style = {{ width: '100%' }}
                    disabled = {disabled}
                    value = {selection}
                    onChange = {(e) => onChange(e.target.value)}
                    placeholder = {placeholderText || helpText}
                />
            </div>

        </SimpleRow>
    </SimpleColumn>
};

export default CommonPlainInput;
