
import React, { useRef } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import {
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    Tooltip
} from 'reactstrap';


const customStyles = {
    content: {
        left: '40%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: '40%',
        height: '15%',
        transform: 'translate(-40%, -10%)',
        position: "absolute",
        top: '30%',
        backgroundColor: '#F3F0FF'
    },
};
const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Button = styled.button`
background: #FFFFFF;
border: 1px solid #714FFF;
border-radius: 6px;
padding: 10px 16px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
`;
const CrossIcon = styled(FaTimes)`
  font-size: 1.5rem;
  font-weight: normal;
  color:white;
`;

export default function EditAmountModal({
    modalIsOpen,
    closeAmountModal,
    saveApprovedAmount

}) {

    return (
        <div className="col-8 mb-2 text-left">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeAmountModal}
                style={customStyles}
            >
                <div className='float-right'>
                    {/* <Col>
                        <Row className='float-right'>
                            <ButtonsComponent>
                                <button onClick={closeAmountModal}>
                                    <CrossIcon />
                                </button>
                            </ButtonsComponent>
                        </Row>
                    </Col> */}
                </div>
                <Form inline className="justify-content-center ">
                    <FormGroup check>
                        <Label for="finalApprovedPrice">Approved Amount</Label>
                        <Input
                            type="text"
                            className="ml-2"
                            name="finalApprovedPrice"
                            id="finalApprovedPrice"
                        />
                    </FormGroup>
                    <Button
                        className="ml-2"
                        outline
                        color="primary"
                        onClick={saveApprovedAmount}
                    >
                        Save
                    </Button>
                </Form>
            </Modal>
        </div>
    )


}