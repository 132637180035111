import React, { useState } from 'react';
import SingleSelectionSearchDropdown from './widgets/SingleSelectionSearchDropdown';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { CommonHyperlink, NetworkCenterOption, SimpleColumn } from './styledCommonComponents';
import NetworkDoctorUpdateModal from './modals/NetworkDoctorUpdateModal';
import { getCenterDoctorPractices } from '../../services';


const PracticeSelectionInput = ({
    helpText = '',
    required = true,
    authToken,
    onChange = () => { },
    networkPractice = null,
    disabled = false,
}) => {

    const [isAddingManually, setIsAddingManually] = useState(false);

    const getPracticeFromServer = useCallback((query) => {
        return getCenterDoctorPractices(authToken, query)
            .then(result => {
                return result.map(item => ({
                    ...item,
                    label: `${item.doctorName} [${item.verticalName || ''}], ${item.centerTitle}`,
                    value: item.practiceId,
                    verticalId: item.verticalId,
                    data: item
                }));
            });
    }, [authToken]);

    const handleChange = useCallback((value, data) => {
        const practice = { value: value.value, label: `${data.doctorName} [${data.verticalName || ''}], ${data.centerTitle}` };
        const doctor = { value: data.doctorId, label: data.doctorName, verticalName: data.verticalName, verticalId: data.verticalId || value?.verticalId, isValidated: true, isUpdated: false };
        const center = { value: data.centerId, label: data.centerTitle, address: data.address || '' };

        onChange(practice, doctor, center);
    }, [onChange]);

    return <SimpleColumn style={{ rowGap: '5px', width: '100%', alignItems: 'flex-start', marginBottom: '20px' }}>
        <SingleSelectionSearchDropdown
            required={required}
            helpText={helpText}
            apiCallback={getPracticeFromServer}
            onChange={handleChange}
            style={{ width: '100%', marginBottom: 0 }}
            selection={networkPractice}
            emptyStateText='Start with searching center or doctor'
            disabled={disabled}
            optionJSX={option => {

                const { doctorName, verticalName, centerTitle, address, locality } = option.data;

                return <NetworkCenterOption>
                    <div>
                        <span style={{ fontWeight: '600', color: '#714FFF' }}>{doctorName}</span>
                        <span style={{ fontWeight: '400', color: '#455', fontSize: '10px' }}>{verticalName ? `(${verticalName})` : ''}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: '500', color: '#000' }}>{centerTitle}</span>
                        <span style={{ fontWeight: '400', color: '#455' }}>{address || ''}</span>
                        <span style={{ fontWeight: '400', color: '#455' }}>{locality || ''}</span>
                    </div>
                </NetworkCenterOption>
            }}
        />
        <CommonHyperlink disabled={disabled} onClick={!disabled ? () => setIsAddingManually(true) : () => { }}>
            Doctor & Associated Centre not found? Add manually
        </CommonHyperlink>

        <NetworkDoctorUpdateModal
            open={isAddingManually && !disabled}
            onClose={() => setIsAddingManually(false)}
            onChange={onChange}
        />
    </SimpleColumn>
};

const matchStateToProps = (state) => ({
    authToken: state.app.authToken
});

export default connect(matchStateToProps)(PracticeSelectionInput);
