import moment from 'moment';
import successDigitiseImage from '../../assets/images/EyeIcon.png';
import rejectDigitiseImage from '../../assets/images/EyeIcon.png';
import queryDigitiseImage from '../../assets/images/EyeIcon.png';

export const resetArrowDown = () => {
  const arrowDownSpans = document.querySelectorAll('.anticon-down');
  arrowDownSpans.forEach((span) => {
    const svg = span.querySelector('svg');
    const path = svg.querySelector('path');
    if (svg && path) {
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      svg.setAttribute('fill', 'none');
      svg.setAttribute('viewBox', '0 0 8 5');
      svg.setAttribute('width', '8');
      svg.setAttribute('height', '5');
      path.setAttribute(
        'd',
        'M7.27701 0.713867C7.73403 0.713867 7.95151 1.27641 7.61335 1.58384L4.33522 4.56395C4.14451 4.73732 3.85326 4.73732 3.66255 4.56395L0.384423 1.58384C0.0462589 1.27642 0.263743 0.713867 0.720759 0.713867L7.27701 0.713867Z'
      );
      path.setAttribute('fill', '#767787');
      path.setAttribute('clip-rule', 'evenodd');
      path.setAttribute('fill-rule', 'evenodd');
    }
  });
};

export const getFinishRequestDetails = (digitisation) => {
  if (
    digitisation.status === 'complete' &&
    !digitisation.invalidReason &&
    !digitisation.invalidReasonId
  ) {
    return {
      background: '#91C14426',
      image: successDigitiseImage,
      data: [
        { key: 'Status', value: 'Request digitised' },
        { key: 'Diagnosis', value: digitisation.diagnosis },
        {
          key: 'Secondary Diagnosis',
          value: digitisation.secondaryDiagnosis || 'N/A',
        },
        {
          key: 'Doctor',
          value: `${digitisation.doctorName}, ${digitisation.centerName}`,
        },
        {
          key: 'Appointment Date',
          value: moment(digitisation.appointmentDate).isValid()
            ? moment(digitisation.appointmentDate).format('MMM Do YYYY, h:mm A')
            : 'N/A',
        },
        {
          key: 'Digitised On',
          value: moment(digitisation.completedAt).format('MMM Do YYYY, h:mm A'),
        },
      ],
    };
  } else if (
    digitisation.status === 'complete' &&
    (digitisation.invalidReasonId || digitisation.invalidReason) &&
    digitisation.invalidReasonFor === 'query'
  ) {
    return {
      background: '#F18E3A26',
      image: queryDigitiseImage,
      data: [
        { key: 'Reason', value: digitisation.reasonTitle },
        { key: 'Remark', value: digitisation.invalidReason },
      ],
    };
  } else {
    return {
      background: '#DB4F4826',
      image: rejectDigitiseImage,
      data: [
        {
          key: 'Reason',
          value: digitisation.reasonTitle || digitisation.invalidReason,
        },
        { key: 'Remark', value: digitisation.invalidReason },
      ],
    };
  }
};

export const validateDigitisationData = (digitisationState) => {
  let response = { canProceed: true, reason: null };

  if (
    digitisationState.commonDocumentDetails?.data?.commonDigitisationRequest
  ) {
    return response;
  } else if (digitisationState.commonDocumentDetails?.data?.offlineRequestId) {
    if (!digitisationState?.primaryDiagnosis?.length) {
      response = {
        canProceed: false,
        reason:
          'Please choose at least one primary diagnosis to digitise the request.',
      };
    } else if (
      !digitisationState?.appointmentDate?.isValidated &&
      !digitisationState?.appointmentDate?.isUpdated
    ) {
      response = {
        canProceed: false,
        reason: 'Please validate the appointment date to digitise the request.',
      };
    }
  } else {
    if (!digitisationState?.primaryDiagnosis?.length) {
      response = {
        canProceed: false,
        reason:
          'Please choose at least one primary diagnosis to digitise the request.',
      };
    } else if (!Array.isArray(digitisationState?.secondaryDiagnosis)) {
      response = {
        canProceed: false,
        reason:
          'Please choose correct secondary diagnosis to digitise the request.',
      };
    } else if (
      !digitisationState?.patientDetails?.value ||
      !(
        digitisationState.patientDetails.isValidated ||
        digitisationState.patientDetails.isUpdated
      )
    ) {
      response = {
        canProceed: false,
        reason:
          'Please choose or validate the patient name to digitise the request.',
      };
    } else if (!digitisationState?.networkCenter?.label) {
      response = {
        canProceed: false,
        reason: 'Please choose a valid center name to digitise the request.',
      };
    } else if (!digitisationState?.networkDoctor?.label) {
      response = {
        canProceed: false,
        reason:
          'Please choose a valid doctor and center to digitise the request.',
      };
    } else if (!digitisationState?.appointmentDate?.value) {
      response = {
        canProceed: false,
        reason:
          'Please choose a valid appointment date to digitise the request.',
      };
    } else if (
      digitisationState?.appointmentDate?.value &&
      !digitisationState?.appointmentDate?.isValidated &&
      !digitisationState?.appointmentDate?.isUpdated &&
      !digitisationState.commonDocumentDetails?.data?.commonDigitisationRequest
    ) {
      response = {
        canProceed: false,
        reason: 'Please validate the appointment date to digitise the request.',
      };
    }
  }

  if (!response.canProceed && digitisationState.currentStep === 2) {
    response.reason += ' Please go back to step 1 (validation)';
  }

  return response;
};

export const getMarkDigitisationBody = (digitisationState, digitisationId) => {
  const requestBody = {
    action: 'complete',
    digitisationId,
    appointmentDate: digitisationState.appointmentDate.value,
    prescriptionValidityDate: digitisationState?.prescriptionValidUptoDate
      ? moment(digitisationState.prescriptionValidUptoDate.$d).format(
          'YYYY-MM-DD'
        )
      : null,
    doctorName: digitisationState.networkDoctor?.label,
    centerName: digitisationState.networkCenter?.label,
    centerAddress: digitisationState.networkCenter?.address,
    consultationId: digitisationState?.consultationDetails?.value,
    doctorId: parseInt(digitisationState.networkDoctor?.value) || null,
    centerId: parseInt(digitisationState.networkCenter?.value) || null,
    verticalId: parseInt(digitisationState.networkDoctor?.verticalId) || null,
    practiceId: digitisationState.networkPractice?.value,
    diagnosis: digitisationState.primaryDiagnosis[0].label,
    icdCode: digitisationState.primaryDiagnosis[0].value,
    secondaryDiagnosis: digitisationState.secondaryDiagnosis[0]?.label,
    secondaryDiagnosisIcdCode: digitisationState.secondaryDiagnosis[0]?.value,
    blockerConsultationId:
      digitisationState.commonDocumentDetails?.data?.offlineRequestId,
    commonDigitisationId:
      digitisationState.commonDocumentDetails?.data?.commonDigitisationRequest,
    exceptionalAdditionReason: digitisationState.commonDocumentDetails?.data
      ?.commonDigitisationRequest
      ? digitisationState.exceptionalAdditionReason?.value
      : null,
    googlePlace: digitisationState.googlePlace,
  };

  if (
    digitisationState.patientDetails?.relativeId &&
    digitisationState.patientDetails.relativeId !== -1
  ) {
    requestBody.relativeId = digitisationState.patientDetails.relativeId;
  }

  return requestBody;
};

export const getRejectQueryBody = (
  action,
  digitisationId,
  selectedReason,
  userRemarks
) => {
  return {
    action,
    digitisationId,
    reasonTitle: selectedReason.label,
    reasonId: selectedReason.value,
    reasonDescription: selectedReason.description,
    invalidReason: userRemarks || selectedReason.description,
  };
};

export const pharmacyProviderMap = {
  1: {
    name: 'Apollo Pharmacy',
    logo: 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/apollo-logo.png',
  },
  2: {
    name: 'Netmeds',
    logo: 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/netmeds-logo.png',
  },
  3: {
    name: 'Pharmeasy',
    logo: 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/pharmeasy_logo.png',
  },
  5: {
    name: 'TATA 1mg',
    logo: 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/1mg-logo.png',
  },
  7: {
    name: 'Pharmeasy',
    logo: 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/pharmeasy_logo.png',
  },
  8: {
    name: 'MedPay',
    logo: 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/medpayLogo.png',
  },
  9: {
    name: 'Wellness Forever',
    logo: 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/wellness_logo.png',
  },
  10: {
    name: 'Farmako',
    logo: 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/Farmako.png',
  },
};

export const groupPharmacyCart = (cartItems) => {
  const groupedCarts = {};
  if (!cartItems || !cartItems.length) {
    return {};
  }

  cartItems.forEach((cart) => {
    if (groupedCarts.hasOwnProperty(cart.ph_partner_id)) {
      groupedCarts[cart.ph_partner_id].push(cart);
    } else {
      groupedCarts[cart.ph_partner_id] = [cart];
    }
  });

  return groupedCarts;
};

export const commonDigitisationServices = [
  'uploaded-labs',
  'uploaded-meds',
  'offline-consultations',
  'cashless-dental',
  'cashless-physio',
];
