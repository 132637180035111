import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGetDailyReport } from '../../queries/reimbursementQuery';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { BiDownload } from 'react-icons/bi';
import { UpArrow, ArrowRightLeft } from '../../components/common/svgFiles';
import { getDailyReportsDataDownload } from '../../services/reimbursementClaims';
import moment from 'moment';
const Heading = styled.h2`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  color: #714fff;
`;
const CountLabel = styled.label`
  position: relative;
  right: -2px;
  background-color: #714fff;
  color: #fff;
  font-size: 0.8rem;
`;
const getTrProps = (state, rowInfo, instance) => {
  if (rowInfo) {
    return {
      //className: 'center',
      style: {
        minHeight: '70px',
        verticalAlign: 'center',
      },
    };
  }
  return {};
};
const DownloadIcon = styled(BiDownload)`
  color: #714fff;
  font-size: 1.5rem;
  margin-right: 4px;
`;

export default function DoctorDailyReport() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState({ sd: '', ed: '' });

  const dateFormatted = (date) => {
    const momentDate = moment(date, 'DD-MM-YYYY');
    const formattedDate = momentDate.format('DD MMMM YYYY');
    return formattedDate;
  };
  const onEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const onStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const columns = () => [
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{
            backgroundColor: '',
            marginLeft: '40px',
            textAlign: 'center',
            paddingBottom: '5px',
          }}
        >
          Date
          <UpArrow />
        </div>
      ),
      width: 150,
      accessor: 'claimDate',
      Cell: (row) => (
        <div className="ml-3 font-semibold w-full h-full flex items-center ">
          {dateFormatted(row.original.claimDate)}
        </div>
      ),
    },
    {
      Header: 'Check-In-Time',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'checkedInAt',
      Cell: (row) => (
        <div className="font-semibold w-full h-full flex items-center justify-center ">
          {row.original.checkedInAt ? (
            <>
              {moment(row.original.checkedInAt).format('hh:mm A')}{' '}
              <CountLabel
                style={{
                  borderRadius: '50%',
                  width: '18px',
                  height: '18px',
                  top: '-5px',
                }}
              >
                {row.original.noOfCheckIn}
              </CountLabel>
            </>
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      Header: 'Check-Out-Time',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'checkedOutAt',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center">
          {row.original.checkedOutAt
            ? moment(row.original.checkedOutAt).format('hh:mm A')
            : '-'}
        </span>
      ),
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px', textAlign: 'center' }}
        >
          Claims Assigned
          <UpArrow />
        </div>
      ),
      width: 160,
      style: { textAlign: 'center' },
      accessor: 'totalCount',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center ">
          {row.original.assignedCasesCount}
        </span>
      ),
    },
    {
      Header: 'Claims Updated',
      width: 500,
      Cell: (row) => {
        return (
          <div className="font-semibold w-full h-full flex items-center justify-center ">
            <h1
              className="font-semibold"
              style={{
                border: `2px solid #F7D3C8`,
                borderRadius: '20px',
                padding: '5px',
                backgroundColor: '#FFF8F7',
                fontFamily: 'Montserrat',
                fontWeight: '14px',
              }}
            >
              {row.original.reject} Rejected
            </h1>
            <h1
              className="ml-1 font-semibold"
              style={{
                border: `2px solid #C2E0FC`,
                borderRadius: '20px',
                padding: '5px',
                backgroundColor: '#F5FAFE',
                fontFamily: 'Montserrat',
                fontWeight: '14px',
              }}
            >
              {row.original.underCheck} Document Verified
            </h1>
            <h1
              className="ml-1 font-semibold"
              style={{
                border: `2px solid #DFD9FE`,
                borderRadius: '20px',
                padding: '5px',
                backgroundColor: '#F8F9FF',
                fontFamily: 'Montserrat',
                fontWeight: '14px',
              }}
            >
              {row.original.documentDef} DD Case
            </h1>
            <h1
              className="ml-1 font-semibold"
              style={{
                border: `2px solid #D9F1BD`,
                borderRadius: '20px',
                padding: '5px',
                backgroundColor: '#F9FEEF',
                fontFamily: 'Montserrat',
                fontWeight: '14px',
              }}
            >
              {row.original.qcCheck} QC Check
            </h1>
          </div>
        );
      },
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px', textAlign: 'center' }}
        >
          Avg. Claim Processing TAT
          <UpArrow />
        </div>
      ),
      width: 150,
      style: { textAlign: 'center' },
      accessor: 'avgTat',
      Cell: (row) => (
        <span className="font-semibold s w-full h-full flex items-center justify-center ">
          {row.original.avgTat ? row.original.avgTat : '-'}
        </span>
      ),
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px', textAlign: 'center' }}
        >
          Productive Hours
          <UpArrow />
        </div>
      ),
      width: 160,
      style: { textAlign: 'center' },
      accessor: 'totalHours',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center ">
          {row.original.totalHours ? row.original.totalHours : '-'}
        </span>
      ),
    },
  ];
  const {
    isLoading,
    data: getDailyReport,
    error: errorInGetReport,
    refetch: refetchDailyReport,
  } = useGetDailyReport(
    selectedDate.sd && selectedDate.sd,
    selectedDate.ed && selectedDate.ed
  );

  useEffect(() => {
    if (startDate && endDate) {
      setSelectedDate({ sd: startDate, ed: endDate });
    }
  }, [endDate, startDate]);
  const styleObj = {
    'font-size': '15px',
    overflow: 'scroll',
    height: '100vh',
    'background-color': '#FFF',
    'font-weight': 'semi-bold',
    'font-family': 'Montserrat',
    'vertical-align': 'middle',
  };
  const downloadFile = async () => {
    getDailyReportsDataDownload(
      selectedDate.sd && selectedDate.sd,
      selectedDate.ed && selectedDate.ed
    );
  };

  const sortDate = (a, b, id) => {
    const dateA = new Date(a[id]);
    const dateB = new Date(b[id]);

    if (dateA < dateB) {
      return -1;
    } else if (dateA > dateB) {
      return 1;
    } else {
      return 0;
    }
  };

  const sortedArr =
    getDailyReport &&
    getDailyReport
      .map((obj) => ({ key: new Date(obj.claimDate), value: obj }))
      .sort((a, b) => b.key - a.key)
      .map((obj) => obj.value);
  return (
    <div
      className="container-fluid w-full px-0  flex flex-col primary-bg m-0 p-0"
      style={{ backgroundColor: '#F5F3F8' }}
    >
      <div className="flex flex-row justify-end">
        <div className="basis-3/4 m-4">
          <Heading>Daily Report</Heading>
        </div>
        <div className="flex mr-3 m-4 ">
          <div
            className="border-2 p-1"
            style={{
              border: '2px solid #FFFFFF',
              borderRadius: '10px',
              backgroundColor: '#FFFFFF',
              paddingBottom: '28px',
              height: '3rem',
            }}
          >
            <label
              className="block p-0"
              style={{
                color: '#714FFF',
                fontSize: '12px',
                fontFamily: 'Montserrat',
                fontStyle: 'bold',
                marginBottom: '0rem',
                fontWeight: 'bold',
              }}
            >
              Start From
            </label>

            <input
              className="p-0"
              type="date"
              style={{ color: '#3A3A49' }}
              onChange={(e) => onStartDateChange(e)}
              max={moment().format('YYYY-MM-DD')}
            ></input>
          </div>
          <div className=" mt-3">
            <ArrowRightLeft />
          </div>

          <div
            className="border-2 p-1"
            style={{
              border: '2px solid #FFFFFF',
              borderRadius: '10px',
              backgroundColor: '#FFFFFF',
              height: '3rem',
            }}
          >
            <label
              className="block p-0"
              style={{
                color: '#714FFF',
                fontSize: '12px',
                fontFamily: 'Montserrat',
                fontStyle: 'bold',
                marginBottom: '0rem',
                fontWeight: 'bold',
              }}
            >
              End on
            </label>

            <input
              className="p-0"
              type="date"
              style={{ color: '#3A3A49' }}
              onChange={onEndDateChange}
              max={moment().format('YYYY-MM-DD')}
            ></input>
          </div>
        </div>
        <div className="mt-4 mr-4">
          <button
            className="btn btn flex"
            onClick={downloadFile}
            style={{
              width: '178px',
              borderRadius: '10px',
              backgroundColor: '#fff',
              height: '40px',
            }}
          >
            <DownloadIcon />
            Download CSV
          </button>
        </div>
      </div>

      <div className="pt-2" style={{}}>
        <ReactTable
          columns={columns()}
          data={sortedArr && sortedArr}
          useFlexLayout={true}
          showPageJump={false}
          // className="-striped -highlight"
          style={styleObj}
          defaultSorted={
            [
              // {
              //   id: 'id',
              //   desc: true,
              // },
            ]
          }
          sortMethod={sortDate}
          getTrProps={getTrProps}
          pageSizeOptions={[10]}
        />
      </div>
    </div>
  );
}
