import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaAngleRight, FaAngleLeft, FaRegFile } from 'react-icons/fa';
import { getDocumentDeficientQueryHistory } from '../../services/reimbursementClaims';

// Tag

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  border: 1px solid #f5f3f8;
  border-radius: 8px;
  max-height: 600px; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const MessagesContainer = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;
const MessageBubble = styled.div`
  background-color: ${(props) => (props.isUser ? '#EAE7FF' : '#714fff')};
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  align-self: ${(props) => (props.isUser ? 'flex-start' : 'flex-end')};
  max-width: 60%;
  text-align: left;
  color: ${(props) => (props.isUser ? '' : '#ffff')};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isUser ? 'flex-start' : 'flex-start')};
`;

const DocumentMessage = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 10px;
  background: #f1f1f1;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
`;

const BackIcon = styled(FaAngleLeft)`
  color: #714fff;
  size: 1.5rem;
`;
const DocumentLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin-right: 16px;
  margin-bottom: 8px;
`;

const DocumentIcon = styled(FaRegFile)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: ${(props) => (props.isUser ? '#714fff' : '#714fff')};
`;

const RightArrow = styled(FaAngleRight)`
  font-size: 1.5rem;
  color: #714fff;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const QueryTitle = styled.h3`
  margin: 2px;
  font-weight: bold;
  font-family: 'Montserrat';
  color: #714fff;
`;
const CardContainer = styled.div`
  border: 1px solid #ffffff;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 16px;
  background-color: #ffffff;
  width: auto;
`;

const CardBody = styled.div`
  margin: 8px 0;
`;

const CardText = styled.div`
  margin-bottom: 8px;
  font-family: 'Montserrat';
`;
const CardTextPara = styled.p`
  margin-bottom: 8px;
  font-family: 'Montserrat';
`;

const Button = styled.button`
  display: flex;
  margin-left: auto;
  border: none;
  background: none;
  cursor: pointer;
`;

const Container = styled.div`
  max-height: 600px; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f5f3f8;
`;
const Timestamp = styled.div`
  font-size: 12px;
  color: ${(props) => (props.isUser ? '' : '#ffff')};
  margin-top: 8px;
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
`;
const StatusLabel = styled.span`
  color: #714fff;
   color: ${(props) => (props.actionTaken ? '#714fff' : '#FF754C')}
  font-size: 15px;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.actionTaken ? '#714fff' : '#FF754C')} ;
  padding: 4px;
  background-color:  ${(props) =>
    props.actionTaken ? '#cfc8ff80' : '#FCEAEA'};
`;
const DocumentLinkText = styled.p`
  font-weight: 500;
  color: #212529;
  margin: 0;

  &:hover {
    color: #007bff; /* Replace with your desired link color */
    cursor: pointer;
  }
`;

function DocumentDeficient({ reimbursementDetail, docDeficientQueryData }) {
  const [documentDeficientHistory, setDocumentDeficientHistory] = useState([]);
  const [chatComponent, setChatComponent] = useState(false);

  const fetchDocumentDeficientHistory = async (deficientId) => {
    await getDocumentDeficientQueryHistory(deficientId)
      .then((response) => {
        setDocumentDeficientHistory(response);
        setChatComponent(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doctorMsgrender = (message) => {
    return (
      <>
        <b>DD Reason : </b>
        {message.doctorRemark}
        <br />
        <b>Comment : </b> {message.comment}
      </>
    );
  };
  const userMsgRender = (message) => {
    return (
      <>
        <b>Remark : </b> {message}
      </>
    );
  };

  const filteredMessages = documentDeficientHistory.filter(
    (message) => !message.isUser || (message.isUser && message.actionTaken)
  );

  const underlineReason = (remark) => {
    const parts = remark.split(/(Reason \d+:)/); // Split based on "Reason X:"
    return parts.map((part, index) =>
      part.startsWith('Reason') ? (
        <span key={index}>
          <u>{part}</u>
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  return (
    <>
      {!chatComponent ? (
        <Container>
          {docDeficientQueryData.length === 0 && (
            <p className="m-2 align-center" style={{ fontWeight: '600' }}>
              No records found
            </p>
          )}
          {docDeficientQueryData.map((item) => (
            <CardContainer key={item.deficientId}>
              <CardHeader>
                <QueryTitle>{item.queryTitle}</QueryTitle>
                <Button
                  onClick={() =>
                    fetchDocumentDeficientHistory(item.deficientId)
                  }
                >
                  <RightArrow />
                </Button>
              </CardHeader>
              <CardBody>
                <CardText style={{ fontWeight: 'bold' }}>
                  Document Type : {item.documentType.split(',').join(', ')}
                </CardText>

                <CardText
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>DD Remark:</span>
                  <span style={{ fontWeight: '600', marginLeft: '4px' }}>
                    {underlineReason(item.remark)}
                  </span>
                </CardText>

                <CardText style={{ display: 'flex', flexDirection: 'row' }}>
                  <span style={{ fontWeight: 'bold' }}>DD Comment :</span>
                  <span style={{ fontWeight: '500', marginLeft: '4px' }}>
                    {item.doctorComment}
                  </span>
                </CardText>
                <CardText style={{ fontWeight: '600' }}>
                  <StatusLabel actionTaken={item.actionTaken}>
                    {item.actionTaken
                      ? 'Deficiency submitted'
                      : 'Deficiency Pending'}
                  </StatusLabel>
                </CardText>
              </CardBody>
            </CardContainer>
          ))}
        </Container>
      ) : (
        <>
          <div
            style={{
              borderRadius: '2px',
              border: '2px solid #FFFF',
              boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;',
            }}
          >
            <button
              className="flex flex-row btn"
              onClick={() => setChatComponent(false)}
            >
              <BackIcon className="mt-1 mr-1" />
              <strong style={{ fontFamily: 'Montserrat' }}>Back</strong>
            </button>
          </div>
          <ChatContainer>
            <MessagesContainer>
              {filteredMessages &&
                filteredMessages.length > 0 &&
                filteredMessages.map((message, index) => (
                  <MessageBubble key={index} isUser={message.isUser}>
                    <div>
                      {message.isUser
                        ? userMsgRender(message.userRemark)
                        : doctorMsgrender(message)}
                    </div>

                    {message.isUser &&
                      message.documentUrl &&
                      message.documentUrl.length > 0 && (
                        <DocumentMessage>
                          {message.documentUrl.map((url, idx) => (
                            <DocumentLink
                              key={idx}
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <DocumentIcon isUser={message.isUser} />
                              <DocumentLinkText>
                                {'Document_' + (idx + 1)}
                              </DocumentLinkText>
                            </DocumentLink>
                          ))}
                        </DocumentMessage>
                      )}
                    <Timestamp isUser={message.isUser}>
                      {message.createdAt}
                    </Timestamp>
                  </MessageBubble>
                ))}
            </MessagesContainer>
          </ChatContainer>
        </>
      )}
    </>
  );
}

export default DocumentDeficient;
