import React from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';
const SubHeading = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  color: #3a3a4a;
  font-weight: 500;
`;
export default function EditDocModal({
  isOpen,
  onClose,
  sponsorData,
  sponsorMultiSelect,
  statusOptions,
  onStatusSelected,
  updateDoctorInList,
  caseCountChange,
}) {
  const header = () => (
    <h2
      style={{
        color: '#714FFF',
        fontFamily: 'Montserrat',
        fontSize: '18px',
      }}
    >
      Manage Doctor
    </h2>
  );
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '2px solid #B9A7FE',
      width: '350px',
      borderColor: state.isFocused ? '#B9A7FE' : '##B9A7FE', // change border color based on focus state
      '&:hover': { borderColor: '#B9A7FE' }, // change border color on hover
    }),
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      size="lg"
      style={{ maxWidth: '550px' }}
    >
      <ModalHeader toggle={onClose}>{header()}</ModalHeader>
      <ModalBody>
        <div className="flex flex-row mt-2">
          <div className="basis-1/4 mt-1">
            <SubHeading>Select Sponsor</SubHeading>
          </div>
          <div className="basis-1/2">
            <Select
              className="mb-2 basic-multi-select"
              closeMenuOnSelect={false}
              isMulti
              styles={customStyles}
              placeholder="Select Sponsor"
              options={sponsorData}
              classNamePrefix="select"
              onChange={sponsorMultiSelect}
            />
          </div>
        </div>
        {/* <div className="flex flex-row mt-2">
          <div className="basis-1/4 mt-1">
            <SubHeading>Select Status</SubHeading>
          </div>
          <div className="basis-1/2">
            <Select
              className="mb-2"
              styles={customStyles}
              options={statusOptions}
              placeholder="Select Status"
              onChange={onStatusSelected}
            />
          </div>
        </div> */}
        <div className="flex flex-row mt-2">
          <div className="basis-1/4 mt-1">
            <SubHeading> Case Limit</SubHeading>
          </div>
          <div className="basis-1/2">
            <input
              placeholder=" Enter limit"
              type="number"
              pattern="[0-9]*"
              className="manage-doctor-input-box"
              style={{
                border: '2px solid #B9A7FE ',
                width: '350px',
                height: '38px',
                borderRadius: '4px',
              }}
              onChange={caseCountChange}
            ></input>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn"
          style={{ backgroundColor: '#714FFF', color: '#fff' }}
          onClick={updateDoctorInList}
        >
          Edit Sponsor
        </button>
      </ModalFooter>
    </Modal>
  );
}
