import React, { useCallback, useState } from 'react';
import { Select, Spin } from 'antd';
import {
  CommonHelpText,
  SelectionGrid,
  SelectionItem,
  SimpleColumn,
} from '../styledCommonComponents';
import { IoClose } from 'react-icons/io5';
import { debounce } from '../../../utils/debounce';

const MultipleSelectionSearchDropdown = ({
  helpText = '',
  placeholderText = '',
  required = false,
  apiCallback = () => {},
  debounceTimeout = 500,
  onAdd = () => {},
  onRemove = () => {},
  selections = [],
  optionJSX = (option) => (
    <div style={{ fontWeight: '600', color: '#555' }}>{option.label}</div>
  ),
  disabled = false,
  isAdditionDisabled = false,
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);

  const loadOptions = useCallback(
    (value) => {
      if (!value) return [];
      setFetching(true);

      apiCallback(value)
        .then(setOptions)
        .finally(() => {
          setFetching(false);
        });
    },
    [apiCallback]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchOptions = useCallback(
    debounce(loadOptions, debounceTimeout),
    []
  );

  const handleSearch = (value) => {
    debouncedFetchOptions(value);
  };

  return (
    <SimpleColumn style={{ marginBottom: '20px' }}>
      {helpText ? (
        <CommonHelpText>
          {helpText}
          {required ? (
            <span style={{ color: '#f50', marginLeft: '2px' }}>*</span>
          ) : (
            <></>
          )}
        </CommonHelpText>
      ) : (
        <></>
      )}

      {selections.length ? (
        <SelectionGrid>
          {selections.map((selection) => (
            <SelectionItem key={selection.value}>
              {selection.label}
              {!disabled ? (
                <IoClose
                  onClick={() => onRemove(selection)}
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                />
              ) : (
                <></>
              )}
            </SelectionItem>
          ))}
        </SelectionGrid>
      ) : (
        <></>
      )}

      <Select
        style={{ width: '100%' }}
        showSearch
        placeholder={placeholderText || helpText}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
        labelInValue
        onSearch={handleSearch}
        loading={fetching}
        filterOption={false}
        onBlur={() => setOptions([])}
        onChange={onAdd}
        value={null}
        optionRender={optionJSX}
        disabled={disabled || isAdditionDisabled}
      >
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </SimpleColumn>
  );
};

export default MultipleSelectionSearchDropdown;
