import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { DatePicker, Space } from 'antd';
import { components } from 'react-select';
import moment from 'moment';
import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { IoCloseCircleSharp } from 'react-icons/io5';
import {
  getDiseases,
  getDoctors,
  getCenters,
  reimbursementMarkDigitized,
  getConsultations,
  fetchAddressByPincode,
  getVerticals,
  fetchConsultationsReq,
  fetchPreviousDigitized,
} from '../../services/reimbursementClaims';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

import { debounce } from '../../utils/debounce';
import RxImg from '../../assets/images/rxImg.svg';
import { FaEye, FaCheck } from 'react-icons/fa';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { CommonHelpText } from '../../flows/CommonDigitisation/styledCommonComponents';
import { DEFAULT_COMMON_DIGITISATION_STATE } from '../../flows/CommonDigitisation/state';
import CommonFirstStep from '../../flows/CommonDigitisation/CommonFirstStep';
import { generateRandomId } from '../../utils';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { FaExclamationCircle } from 'react-icons/fa';
import CommonDigitisation from '../../flows/CommonDigitisation/CommonDigitisation';
import DocumentViewer from '../../containers/common/DocumentViewer';

const Title = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #714fff;
`;
const SubHeading = styled.label`
  color: #3a3a4a;
  font-weight: 500;
  font-size: 14px;
  font-family: Montserrat;
`;
const ModalHeaderHeading = styled.h2`
  color: #714fff;
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 600;
`;

const InputBox = styled.input`
  padding: 8px;
  border: 2px solid #b9a7fe;
  border-radius: 4px;
  width: 100%;
  font-size: 15px;
  color: #555;
  outline: none;
  &:focus {
    border-color: #b9a7fe;
  }
`;

const BreadcrumbChip = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  height: 75%;
  border-radius: 7.5px;
  padding: 0 10px;
  font-weight: 600;
  border: ${({ primary, myColor }) =>
    primary
      ? `1.5px solid ${myColor || '#32AD54'}`
      : `1.5px solid ${myColor || '#ff3f4c'}`};
  color: ${({ primary, myColor }) =>
    primary ? `${myColor || '#32AD54'}` : `${myColor || '#ff3f4c'}`};
`;

const CheckBox = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  border-radius: 5px;
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff; /* Checkbox border color */
  cursor: pointer;
`;
const CheckBoxIcon = styled(FaCheck)`
  color: #333;
  font-size: 14px; /* Icon size */
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
`;

const StyledDocViewer = styled(DocViewer)`
  background-color: white;
  border-right: 2px solid #714fff; /* Adding the right border */
`;
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '2px solid #714FFF',
    width: '97%',
    borderRadius: '6px',
    borderColor: state.isFocused ? '#714FFF' : '#714FFF', // change border color based on focus state
    '&:hover': { borderColor: '#714FFF' }, // change border color on hover
  }),
};
const customStylesForCentre = {
  control: (provided, state) => ({
    ...provided,
    border: '2px solid #714FFF',
    width: 'full',
    borderRadius: '6px',
    borderColor: state.isFocused ? '#714FFF' : '#714FFF', // change border color based on focus state
    '&:hover': { borderColor: '#714FFF' }, // change border color on hover
  }),
};
const Text = styled.p`
  margin: 0;
  font-family: Montserrat;
  font-size: 0.75rem;
`;
const BoldText = styled(Text)`
  font-family: Montserrat;
  font-size: 1rem;
`;

const LogoContainer = styled.img`
  width: 25px;
  height: 25px;
`;

// Styled components for custom option
const OptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column; /* Stack image and text vertically */
  align-items: center;
  width: 80px; /* Adjust width as needed */
  margin: 2px; /* Reduced margin */
`;

const dummyImageUrl = 'https://via.placeholder.com/90x110.png?text=Rx';
const OptionImage = styled.div`
  width: 90px; /* Adjust size as needed */
  height: 110px; /* Adjust size as needed */
  background-color: #ddd; /* Placeholder background color */
  border-radius: 4px;
  margin-bottom: 5px; /* Space between image and filename */
  position: relative;
  background-image: url(${dummyImageUrl}); /* Dummy image URL */
  background-size: cover; /* Ensure image covers the area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent repeating the image */

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
    border-radius: 4px;
    pointer-events: none; /* Allow clicks to pass through */
  }
`;

const OptionText = styled.div`
  flex: 1;
`;
const DropdownMenu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
`;

const EyeIcon = styled(FaEye)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; /* Icon color */
  font-size: 23px; /* Icon size */
  cursor: pointer;
`;

const Filename = styled.div`
  font-size: 12px; /* Adjust font size as needed */
  color: #333; /* Text color */
`;
const MenuContainer = styled.div`
  position: relative; // Make sure the container is relative for absolute positioning
  background-color: white;
  border-radius: 3px;
  border: 1.3px solid #2885ff;
`;
export default function ReimbursementDigitization({
  reimbursementDetail,
  digitizationDetails,
  updateIsDigitizedState,
  reimbursementTypeName,
  consultationIdFetch,
  consultationId,
  claimData,
  onFetchCodiId,
  markRejected,
  reimbursementForDependent,
  reimbursementFor,
}) {
  const [diagnosis, setDiagnosis] = useState('');
  const [icdCode, setIcdCode] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');
  const [centre, setCentre] = useState('');
  const [centreAddress, setCentreAddress] = useState('');
  const [registrationNo, setRegistrationNo] = useState('');
  const [centreId, setCentreId] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [markIsDisabled, setMarkIsDisabled] = useState(false);
  const [diagnosisModal, setDiagnosisModal] = useState(false);
  const [diagnosisValue, setDiagnosisValue] = useState([]);
  const [doctorModal, setDoctorModal] = useState(false);
  const [doctorValue, setDoctorValue] = useState(null);
  const [centreValue, setCentreValue] = useState(null);
  const [centreModal, setCentreModal] = useState(false);
  const [consultationModal, setConsultationModal] = useState(false);
  const [onChangeDiagnosisValue, setOnChangeDiagnosisValue] = useState(null);
  const { reimbursementId } = reimbursementDetail;
  const { userId, relativeId } = reimbursementDetail;
  const [isConsultation, setIsConsultation] = useState(false);
  let [consultationData, setConsultationData] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [verticalsValue, setVerticalValue] = useState(null);
  const [verticalId, setVerticalId] = useState('');
  const [nonEmpanelCenterId, setNonEmpanelCenterId] = useState('');
  const [nonEmpanelDoctorId, setNonEmpanelDoctorId] = useState('');
  const [consultations, setConsultations] = useState([]);
  const [consultationRequestId, setConsultationRequestId] = useState('');
  const [consultationIdSecond, setConsultationId] = useState('');
  const [medPrescriptionId, setMedPrescriptionId] = useState('');
  const [disableFields, setDisableFields] = useState(false);
  const [isConsultationSelected, setIsConsultationSelected] = useState(false);
  const [isRegistrationNumber, setIsRegistrationNumber] = useState(false);
  const [isDiagnosis, setIsDiagnosis] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [documentViewModal, setDocumentViewModal] = useState(false);
  const [documentsRenderForBlocker, setDocumentsRenderBlocker] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [docs, setSetDocs] = useState([]);
  const [formattedPrescriptionArr, setFormattedPrescriptionArr] = useState([]);
  const [prescriptionValues, setPrescriptionValues] = useState([]);
  const [isLoaded, setLoaderValue] = useState(false);
  const [selectedPrescriptIds, setSelectedPrescriptIds] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState([]);
  const [prescriptionSelectionKey, setPrescriptionSelectionKey] = useState(0);
  const [existingPrescriptionKey, setExistingPrescriptionKey] = useState(0);
  const [previousDigitisationReq, setPreviousDigitisationReq] = useState([
    false,
  ]);
  const [blockerModal, setBlockerModal] = useState(false);
  const [isBlocker, setIsBlocker] = useState(false);
  const [blockerInfo, setBlockerInfo] = useState({});
  const [validateChip, setValidateChip] = useState(false);
  const [addNewPrescriptionCheck, setAddNewPrescriptionCheck] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [digitisedButtonDisabled, setDigitisedButtonDisabled] = useState(false);
  const [selectPrescriptionDisable, setSelectPrescriptionDisable] =
    useState(false);
  const [offlineConsultationReq, setOfflineConsultationReq] = useState('');
  const [isCashlessConsultation, setIsCashlessConsultation] = useState(false);

  // making this the main state that'll govern digitisation, basically it will be a map where keys will be the
  // id of reim_doc table and value can either be the id of codi table or an object (if codi does not exist for the doc)
  // and that value doc will contain all digitisation info about the doc, needed to create a codi
  // if this object contains no key/value, means the mark digitised button should be disabled
  const [documentsToCodiMapping, setDocumentsToCodiMapping] = useState({});
  const [commonDigitisationState, setCommonDigitisationState] = useState(
    DEFAULT_COMMON_DIGITISATION_STATE
  );

  const [compareDocumentView, setCompareDocumentView] = useState(false);
  const [existingPrescriptionDocument, setExistingPrescriptionDocument] =
    useState([]);
  const [getAllExitingCodiDetail, setGetAllExitingCodiDetail] = useState(null);
  const onPrescriptionModalClose = () => {
    setDocumentViewModal(false);
  };
  const [menuIsOpen, setMenuIsOpen] = useState(true);
  const [isDropdownOpen, setDropdownOpen] = useState(true);

  const selectBlocker = () => {
    let documentArr = [];

    claimData?.reimbursementDocuments
      //.split(',')
      .forEach((data, idx) => {
        if (data.type === 'prescription') {
          documentArr.push({ uri: data.url });
          setDocumentsRenderBlocker(documentArr);
        }
      });
  };

  useEffect(() => {
    const addedDocumentIds = Object.keys(documentsToCodiMapping).map(Number);
    const prescriptionArr = claimData?.reimbursementDocuments?.filter(
      (data) =>
        data.type === 'prescription' &&
        !addedDocumentIds.includes(data.documentId)
    );

    if (prescriptionArr.length > 0) {
      const prescriptions = prescriptionArr.map((data, indx) => {
        return {
          value: data.documentId,
          label: `Prescription ${indx + 1}`,
          url: data.url,
          codiId: data.documentId,
          reimbursementDocumentId: data.documentId,
        };
      });
      setFormattedPrescriptionArr(prescriptions);
    } else {
      setFormattedPrescriptionArr([]);
    }
    selectBlocker();
  }, [claimData?.reimbursementDocuments, documentsToCodiMapping]);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  // const handleCheckboxChange = (e) => {
  //   const { value, checked } = e.target;
  //   setSelectedImages((prevSelected) =>
  //     checked
  //       ? [...prevSelected, value]
  //       : prevSelected.filter((image) => image !== value)
  //   );
  // };

  const closeBlockerModal = () => {
    //consultationOnSelect();
    setBlockerModal(false);
  };
  const blockerSaveButton = () => {
    consultationOnSelect(getAllExitingCodiDetail);
    setValidateChip(true);
    setBlockerModal(false);
    setSelectPrescriptionDisable(true);
  };
  const customStylesForImage = {
    menu: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      padding: '2px',
      width: '50%',

      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      backgroundColor: state.isFocused ? '#EAE7FF' : 'white', // Change background color on hover
      padding: 20,
      // marginLeft: state.isFocused ? '10px' : '0', // Add left margin on hover
    }),
  };

  const getCodiIdsFromMapping = () => {
    const codiIds = [];
    const offlineRequestIds = [];

    Object.keys(documentsToCodiMapping).forEach((key) => {
      if (typeof documentsToCodiMapping[key] === 'number') {
        codiIds.push(documentsToCodiMapping[key]);
      } else if (documentsToCodiMapping[key]?.offlineRequestId) {
        offlineRequestIds.push(documentsToCodiMapping[key].offlineRequestId);
      }
    });

    return { codiIds, offlineRequestIds };
  };

  // Correctly destructure the returned object
  const { codiIds, offlineRequestIds } = getCodiIdsFromMapping();

  // Combine codiIds and offlineRequestIds into a single list of values
  const allRequestValues = [
    ...codiIds, // codiIds are numbers
    ...offlineRequestIds, // offlineRequestIds are strings
  ];

  // Filter previousDigitisationReq based on whether the values are included in allRequestValues
  const filteredOptions = previousDigitisationReq.filter(
    (option) =>
      !allRequestValues.includes(
        option?.data?.commonDigitisationRequest ||
          option?.data?.offlineRequestId
      )
  );

  const CustomMenu = (props) => {
    const { children, innerProps } = props;

    const handleClose = () => {
      // Logic to close the dropdown
      // For example, update the state that controls the dropdown visibility
      // setDropdownOpen(false);
      setMenuIsOpen(false);
    };

    return (
      <MenuContainer {...innerProps}>
        <IoCloseCircleSharp
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            fontSize: '25px',
            color: '#714FFF',
          }}
          onClick={handleClose}
        />
        {children}
      </MenuContainer>
    );
  };

  const CustomOptionForHorizontal = (props) => {
    const { selectOption, data, isSelected } = props;

    const handleCheckboxClick = (event) => {
      event.stopPropagation(); // Prevent the dropdown from closing
      selectOption(data);
    };

    const handleView = (event) => {
      event.stopPropagation();
      openModal(data);
    };

    const openModal = (data) => {
      setSetDocs([{ uri: data.url, value: data.value, label: data.label }]);
      setDocumentViewModal(true);
    };

    return (
      <components.Option {...props}>
        <OptionContainer>
          <OptionImage>
            {/* Render checkbox only if codiId is not available */}
            <CheckBox onClick={handleCheckboxClick}>
              <CheckBoxIcon checked={isSelected} />
            </CheckBox>
            <EyeIcon onClick={handleView} />
          </OptionImage>
          <Filename>{data.label}</Filename>
        </OptionContainer>
      </components.Option>
    );
  };

  const getDisease = (input) => {
    return getDiseases(input)
      .then((result) => {
        console.log({ result }, 'getDisease result');
        result.forEach((dis) => {
          dis.label = `${dis.name} - ${dis.code}`;
          dis.value = dis.code;
          dis.isDisabled = true;
        });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  useEffect(() => {
    if (digitizationDetails.length > 0) {
      // setDisableFields(false);
      // setIsRegistrationNumber(false);
      // setIsDiagnosis(false);
      // const diagnosisValue =
      //   digitizationDetails.diagnosis
      //     ?.split(',')
      //     .map((d) => ({ label: d, value: d, code: null })) || [];
      // setDoctorValue({
      //   value: digitizationDetails.doctorName,
      //   label: digitizationDetails.doctorName,
      // });
      // setDiagnosisValue(diagnosisValue);
      // setCentreValue({
      //   value: digitizationDetails.centreName,
      //   label: digitizationDetails.centreName,
      // });
      // setVerticalValue({
      //   value: digitizationDetails.verticalName,
      //   label: digitizationDetails.verticalName,
      // });
      // setRegistrationNo(digitizationDetails.registrationNo);
      // setCentreAddress(digitizationDetails.centreAddress);
      // setAppointmentDate(
      //   moment(digitizationDetails.appointmentDate, 'DD-MM-YYYY')
      // );
    }
  }, [digitizationDetails]);

  useEffect(() => {
    if (digitizationDetails) {
      if (
        reimbursementTypeName
          ?.toLowerCase()
          .includes('Consultation'.toLowerCase())
      ) {
        setIsConsultation(true);
      }
    }
  }, [reimbursementTypeName]);
  const promiseDiseaseOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getDisease(input.toLowerCase()));
      }, 500);
    });
  };
  const onSelectDisease = (options) => {
    let selectedOption = [];
    if (options) {
      if (options.length === 0) {
        setDiagnosis('');
        setDiagnosisValue([]);
        return;
      }
      if (options.find((e) => e.label === 'other')) {
        setDiagnosisModal(true);
        return;
      }
      options.map((data) => {
        return selectedOption.push({
          label: `${data.label}`,
          value: data.value,
        });
      });

      setDiagnosis(
        selectedOption
          .map((e) => {
            return e.label.slice(',');
          })
          .join(',')
      );
      setIcdCode(
        selectedOption
          .map((e) => {
            return e.value.slice(',');
          })
          .join(',')
      );
      setDiagnosisValue(selectedOption);
    }
  };
  const getDoctorsNames = (input) => {
    return getDoctors(input, centreId, verticalId, nonEmpanelCenterId)
      .then((result) => {
        console.log({ result }, 'getDoctors result');
        result.forEach((doc) => {
          doc.label = doc.doctorName;
          doc.value = doc.doctorId;
        });
        result.push({
          label: 'Enter Doctor Name',
          value: 'other',
        });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };
  const debouncedGetDoctorsNames = debounce((input, resolve) => {
    getDoctorsNames(input).then(resolve);
  }, 500);
  // const promiseDoctorOptions = (input) => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve(getDoctorsNames(input.toLowerCase()));
  //     }, 500);
  //   });
  // };
  const promiseDoctorOptions = (input) => {
    return new Promise((resolve) => {
      debouncedGetDoctorsNames(input.toLowerCase(), resolve);
    });
  };
  // const onSelectDate = (e) => {
  //   const date = moment(new Date()).format('YYYY-MM-DD');
  //   const selectedDate = moment(e.target.value).format('YYYY-MM-DD');
  //   if (selectedDate <= date) {
  //     setAppointmentDate(selectedDate);
  //   } else {
  //     window.alert('Appointment date can not be in the future');
  //   }
  // };

  const getCentersInfo = (input) => {
    const isReimbursement = true;
    return getCenters(input, isReimbursement)
      .then((result) => {
        console.log({ result }, 'getDoctors result');
        result.forEach((cen) => {
          cen.label = cen.title;
          cen.data = {
            address: cen.address,
          };
          cen.value = cen.centerId;
        });
        result.push({
          label: 'Enter Center Details',
          value: 'other',
          data: {
            address: '',
          },
        });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };
  const promiseCenterOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getCentersInfo(input.toLowerCase()));
      }, 500);
    });
  };
  const markDigitized = async () => {
    console.log(documentsToCodiMapping);

    const digitisationData = groupMappingByCodiId().map((item) => {
      return {
        userId,
        reimbursementId,
        documentIds: item.documentIds,
        commonDigitisationId: item.codiId,
        offlineRequestId: item.offlineRequestId,
        digitisationDetails: !item.codiId
          ? {
              appointmentDate: item.batchDetails?.appointmentDate?.value
                ? moment(
                    item.batchDetails.appointmentDate.value,
                    'DD-MM-YYYY'
                  ).format('YYYY-MM-DD')
                : null,
              offlineRequestId: item.offlineRequestId,
              googlePlace: item.batchDetails?.googlePlace,
              doctorName: item.batchDetails?.networkDoctor?.label,
              doctorId:
                parseInt(item.batchDetails?.networkDoctor?.value) || null,
              centerName: item.batchDetails?.networkCenter?.label,
              centerId:
                parseInt(item.batchDetails?.networkCenter?.value) || null,
              practiceId: item.batchDetails?.networkPractice?.value,
              verticalId: item.batchDetails?.networkDoctor?.verticalId,
              centerAddress: item.batchDetails?.networkCenter?.address,
              diagnosis: item.batchDetails?.primaryDiagnosis[0].label,
              icdCode: item.batchDetails?.primaryDiagnosis[0].value,
              secondaryDiagnosis:
                item.batchDetails?.secondaryDiagnosis[0]?.label,
              secondaryDiagnosisIcdCode:
                item.batchDetails?.secondaryDiagnosis[0]?.value,
              blockerConsultationId: item.offlineRequestId,
              relativeId: reimbursementForDependent ? reimbursementFor : null,
            }
          : null,
      };
    });

    console.log({ digitisationData });

    //return;

    if (digitizationDetails?.length) {
      return;
    }

    const body = {
      diagnosis,
      appointmentDate,
      centre,
      centreAddress,
      doctorName,
      reimbursementId,
      userId,
      icdCode,
      doctorId,
      centreId,
      verticalId,
      registrationNo,
      nonEmpanelCenterId,
      nonEmpanelDoctorId,
      medPrescriptionId,
      consultationId: consultationIdSecond,
      consultationRequestId,
      selectedPrescriptIds,
      relativeId,
    };
    setDigitisedButtonDisabled(true);
    await reimbursementMarkDigitized(digitisationData)
      .then((result) => {
        onFetchCodiId();
        setIsCashlessConsultation(result.result);
        toast.success('Digitisation Marked Complete', { theme: 'dark' });
        updateIsDigitizedState();
        setDigitisedButtonDisabled(false);
        setMarkIsDisabled(true);
        setDisableFields(true);
        setIsRegistrationNumber(true);
        setIsConsultationSelected(true);
        setDocumentsToCodiMapping({});
        setIsDiagnosis(true);
        if (reimbursementTypeName?.includes('Consultation')) {
          setIsConsultation(true);
        }
      })
      .catch((err) => {
        const error = err?.response?.data;
        toast.error(error.errorMessage || 'Something went wrong.', {
          theme: 'dark',
        });
      });
  };
  const fetchConsultations = async () => {
    await getConsultations(userId, moment(appointmentDate).format('YYYY-MM-DD'))
      .then((result) => {
        setConsultationData(result);
        setConsultationModal(true);
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong ');
      });
  };

  const renderPrescriptionDocument = (e, url) => {
    e.stopPropagation();
    window.open(url, '_blank');
  };

  const beautifyText = (d, indx) => {
    return (
      <>
        <div className="flex flex-wrap">
          <h2 style={{ fontWeight: 'bold' }}>
            S.No - {indx + 1} |
            {d.isBlocker ? d.offlineRequestId : d.commonDigitisationRequest} |
            Patient - {d.patientName}
          </h2>
          <h2 className="ml-1" style={{}}>
            | {d.prescriptionDate || d.appointmentDate || d.invoiceDate} |{' '}
            {d?.doctorName}
          </h2>
          <p style={{ fontSize: '10px' }} className="text-muted">
            ({d.verticalName})
          </p>
          {d.isBlocker ? (
            <h3 style={{ paddingLeft: '10px' }}>
              {' '}
              <label
                style={{
                  color: 'red',
                  border: '1px solid red',
                  borderRadius: '6px',
                  fontSize: '15px',
                  paddingLeft: '20px',
                  padding: '2px',
                }}
              >
                B
              </label>
            </h3>
          ) : (
            // <BreadcrumbChip
            //   style={{ fontSize: '10px', height: '27.5px' }}
            //   myColor="#f50"
            // >

            // </BreadcrumbChip>
            <button
              className="ml-2"
              onClick={(e) => renderPrescriptionDocument(e, d.mergedUrl)}
            >
              <LogoContainer src={RxImg} alt="show Icon" />
            </button>
          )}
        </div>
        {d.diagnosis ? (
          <h2 className="ml-1" style={{}}>
            Diagnosis - {d.diagnosis} {d.icdCode ? `(${d.icdCode})` : ''}
          </h2>
        ) : (
          ''
        )}
      </>
    );
  };
  const fetchConsultationReqDetail = async () => {
    await fetchConsultationsReq(userId).then((result) => {
      const options = result.map((item, indx) => ({
        value: item.consultationId,
        data: item,
        label: (
          <div>
            {beautifyText(item, indx)}
            {item.centerName} {item.centerAddress}
          </div>
        ),
        doctorName: item.doctorName.toLowerCase(),
      }));
      setConsultations(options);
    });
  };

  const fetchPreviousDigitisation = async () => {
    await fetchPreviousDigitized(userId).then((result) => {
      const sortedResult = result.sort((a, b) => {
        // First sort by offlineRequestId if it exists
        if (a.offlineRequestId && b.offlineRequestId) {
          return b.offlineRequestId - a.offlineRequestId; // Sort by offlineRequestId in descending order
        }

        // If only one of them has offlineRequestId, prioritize that one
        if (a.offlineRequestId) return -1; // a comes first
        if (b.offlineRequestId) return 1; // b comes first

        // If neither has offlineRequestId, sort by commonDigitisationRequest
        return b.commonDigitisationRequest - a.commonDigitisationRequest;
      });
      const options = sortedResult.map((item, indx) => ({
        value: indx + 1,
        data: item,
        label: (
          <div>
            {beautifyText(item, indx)}
            {item.centerName} {item.centerAddress}
          </div>
        ),
        doctorName: item?.doctorName?.toLowerCase(),
      }));
      setPreviousDigitisationReq(options);
    });
  };

  const consultationOnSelect = (option) => {
    if (option) {
      const { data } = option;

      if (data.isBlocker) {
        setBlockerInfo(data);
        setIsBlocker(true);
        setOfflineConsultationReq(data?.offlineRequestId);
      }
      setExistingPrescriptionDocument([
        {
          uri: data.mergedUrl,
          offlineRequestId: data.offlineRequestId,
          codiId: data.commonDigitisationRequest,
          data,
        },
      ]);
      const prescriptionDate = moment(data?.prescriptionDate).format(
        'DD-MM-YYYY'
      );
      const appointmentDate = moment(data?.appointmentDate).format(
        'DD-MM-YYYY'
      );
      setDisableFields(true);
      if (data?.type === 'Offline') {
        setConsultationRequestId(data?.offlineRequestId);
        setConsultationId('');
        setMedPrescriptionId(data?.medPrescriptionId || '');
      }
      if (data.type === 'Online') {
        setConsultationId(data?.consultationId);
        setConsultationRequestId('');
        setMedPrescriptionId(data?.medPrescriptionId);
      }
      setCentreId(data?.centerId);
      setRegistrationNo(
        data?.registrationNumber ? data?.registrationNumber : ''
      );

      setVerticalId(data?.verticalId);
      setVerticalValue({ label: data.verticalName, value: data.verticalName });
      setCentre(data?.centerName);
      setDoctorValue({
        value: data?.doctorName,
        label: data?.doctorName,
      });
      setCentreValue({
        value: data?.centerName,
        label: data?.centerName,
      });

      if (data?.diagnosis && data?.icdCode) {
        setIcdCode(data?.icdCode);
        setDiagnosisValue({ value: data?.diagnosis, label: data?.diagnosis });
        setDiagnosis(data?.diagnosis);
        setIsDiagnosis(true);
      }

      setCentreAddress(data?.centerAddress);
      setDoctorName(data?.doctorName);
      setDoctorId(data?.doctorId);
      setAppointmentDate(
        data?.type === 'Online'
          ? moment(prescriptionDate, 'DD-MM-YYYY')
          : moment(appointmentDate, 'DD-MM-YYYY')
      );
      setIsRegistrationNumber(data?.registrationNumber ? true : false);
      data.appointmentDate =
        data.appointmentDate || data.prescriptionDate || data.invoiceDate;
      setCommonDigitisationState({
        commonDocumentDetails: data,
        consultationDetails: null,
        patientDetails: {
          relativeId: reimbursementDetail.relativeId || -1,
          label: reimbursementDetail.relative || reimbursementDetail.userName,
        },
        primaryDiagnosis: data?.offlineRequestId
          ? []
          : [{ value: data.icdCode, label: data.diagnosis }],
        secondaryDiagnosis: [],
        networkCenter: {
          value: data.centerId,
          label: data.centerName,
          address: data.centerAddress,
        },
        networkDoctor: {
          value: data.doctorId,
          label: data.doctorName,
          verticalId: data.verticalId,
          verticalName: data.verticalName,
        },
        networkPractice: {
          value: data.practiceId,
          label: `${data.doctorName}, ${
            data.verticalName ? `[${data.verticalName}]` : ''
          }, ${data.centerName || ''}, ${data.centerAddress || ''}`,
        },
        googlePlace: null,
        appointmentDate: {
          value:
            moment(data.appointmentDate).isValid() && data.appointmentDate
              ? moment(data.appointmentDate).format('DD-MM-YYYY')
              : null,
          label:
            moment(data.appointmentDate).isValid() && data.appointmentDate
              ? moment(data.appointmentDate).format('DD-MM-YYYY')
              : null,
        },
        prescriptionValidUptoDate: null,
        currentStep: 1,
        exceptionalAdditionReason: null,
        isFullDisabled:
          data.commonDigitisationRequest && !data.offlineRequestId,
        isPartialDisabled: true,
      });
    } else {
      setRegistrationNo('');
      setVerticalId('');
      setVerticalValue(null);
      setCentre('');
      setDoctorValue(null);
      setCentreValue(null);
      setConsultationId('');
      setConsultationRequestId('');
      setMedPrescriptionId('');
      setCentreAddress('');
      setDoctorName('');
      setDoctorId('');
      setAppointmentDate('');
      setCentreId('');
      setDisableFields(false);
      setDiagnosis('');
      setIcdCode('');
      setDiagnosisValue([]);
      setIsRegistrationNumber(false);
      setExistingPrescriptionDocument([]);
      setSelectedPrescription([]);
      setPrescriptionSelectionKey((p) => !p);
      setExistingPrescriptionKey((p) => !p);
      setCommonDigitisationState(DEFAULT_COMMON_DIGITISATION_STATE);
      setIsBlocker(false);
      setBlockerInfo('');
      setSelectPrescriptionDisable(false);
      setValidateChip(false);
    }
  };
  const handleOnChangePreviousDigitised = (option) => {
    // const newSelectedOptions = option
    //   ? [...selectedOption, option?.data]
    //   : selectedOption;
    //setSelectedOption(newSelectedOptions);
    //consultationOnSelect(option);
    if (option) {
      const { data } = option;
      if (data.isBlocker) {
        setBlockerInfo(data);
        setIsBlocker(true);
        setOfflineConsultationReq(data?.offlineRequestId);
      }
      setExistingPrescriptionDocument([
        {
          uri: data?.mergedUrl,
          offlineRequestId: data?.offlineRequestId,
          codiId: data?.commonDigitisationRequest,
          data,
        },
      ]);

      setGetAllExitingCodiDetail(option);
    } else {
      setGetAllExitingCodiDetail(null);
      setExistingPrescriptionDocument([]);
      setSelectedPrescriptIds([]);
      setSelectedPrescription([]);
      consultationOnSelect();
    }
  };

  const selectedOptionValues = selectedOption.map(
    (option) => option.commonDigitisationRequest || option.offlineRequestId
  );
  // const filteredOptions = previousDigitisationReq.filter(
  //   (option) =>
  //     !selectedOptionValues.includes(
  //       option?.data?.commonDigitisationRequest ||
  //         option?.data?.offlineRequestId
  //     )
  // );

  // const filteredOptions = selectedOption
  //   ? previousDigitisationReq.filter(
  //       (option) =>
  //         option?.data?.commonDigitisationRequest !==
  //         selectedOption.commonDigitisationRequest
  //     )
  //   : previousDigitisationReq;
  // console.log('Selected Option:', selectedOption);
  // console.log('Previous Digitisation Requests:', previousDigitisationReq);
  // console.log('Filtered Options:', filteredOptions);
  useEffect(() => {
    if (
      diagnosis &&
      appointmentDate &&
      centre &&
      doctorName &&
      reimbursementId &&
      userId &&
      registrationNo &&
      verticalId
    ) {
      setMarkIsDisabled(false);
      return;
    }
    return setMarkIsDisabled(true);
  }, [
    appointmentDate,
    centre,
    diagnosis,
    doctorName,
    reimbursementId,
    userId,
    registrationNo,
    verticalId,
  ]);

  // useEffect(() => {
  //   if (consultationId) {
  //     setConsultationModal(false);
  //   }
  // });

  const fetchVerticals = async () => {
    await getVerticals()
      .then((result) => {
        const verticals =
          result &&
          result.map((data) => {
            return {
              value: data.id,
              label: data.name,
              logo: data.logo,
            };
          });
        setVerticals(verticals);
      })
      .catch((err) => {
        console.log('Error', err);
      });
  };

  useEffect(() => {
    fetchVerticals();
    //fetchConsultationReqDetail();
    fetchPreviousDigitisation();
  }, []);

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div className=" justify-content-between">
          <BoldText fontSize="10px" lineHeight="10px" color="#413958">
            {props.label}
          </BoldText>
          <Text fontSize="10px" lineHeight="10px" color="#6A57F5">
            {props.data.address}
          </Text>
        </div>
      </components.Option>
    );
  };
  const onSelectCentre = (option) => {
    if (option.value === 'other') {
      setCentreModal(true);
      setCentreId(null);
      setCentre(null);
      setCentreAddress(null);
      return;
    }
    setCentre(option.title);
    setCentreAddress(option.address);
    setCentreId(option.centerId);
    setCentreValue({ value: option.title, label: option.title });
    if (option?.isNonEmpanelCenter) {
      setNonEmpanelCenterId(option.centerId);
      setCentreId(null);
    }
  };
  const onSelectDoctor = (option) => {
    if (option.value === 'other') {
      setDoctorModal(true);
      return;
    }
    setDoctorName(option.doctorName);
    setDoctorId(option.doctorId);
    setDoctorValue({ label: option.doctorName, value: option.doctorName });
    if (option?.isNonEmpanelDoctor) {
      setNonEmpanelDoctorId(option.doctorId);
      setDoctorId(null);
    }
  };

  const onSelectVertical = (option) => {
    setVerticalId(option.value);
    setVerticalValue({ label: option.label, value: option.value });
  };
  const OnChangeDiagnosis = (e) => {
    setDiagnosis(e.target.value);
    const value = e.target.value;
    setOnChangeDiagnosisValue(value);
  };
  const OnChangeDoctor = (e) => {
    setDoctorName(e.target.value);
    setDoctorValue({ value: e.target.value, label: e.target.value });
  };
  const onCentreChange = (e) => {
    setCentre(e.target.value);
    setCentreValue({ value: e.target.value, label: e.target.value });
  };
  const fetchAddress = async (e) => {
    const pinRegex = /^\d{6}$/;
    const validatePinCode = pinRegex.test(e.target.value);
    if (validatePinCode) {
      await fetchAddressByPincode(e.target.value)
        .then((res) => {
          const finalAddress = `${res.state},${res.city},${res.pincode}`;
          setCentreAddress(finalAddress);
        })
        .catch((err) => {
          toast.error(err.errorMessage);
        });
    }
  };
  const closeDiagnosisModal = (value) => {
    if (diagnosisValue.length < 5) {
      onSelectDisease([...diagnosisValue, { label: value, value: value }]);
      setDiagnosisModal(false);
    } else {
      window.alert('You can not select more than 5 options');
      return;
    }
  };
  const onChangeAppointmentDate = (dateString) => {
    const formattedDate = moment(dateString).format('D MMMM YYYY');
    setAppointmentDate(formattedDate);
  };
  const groupMappingByCodiId = () => {
    const res = [];
    Object.keys(documentsToCodiMapping).forEach((docId) => {
      docId = Number(docId);
      const myPrescription = claimData?.reimbursementDocuments?.find(
        (item) => item.documentId === docId
      );
      const codiId = documentsToCodiMapping[docId];

      const codiIndex = res.findIndex(
        (i) => typeof codiId === 'number' && i.codiId === codiId
      );
      if (typeof codiId === 'number' && codiIndex === -1) {
        console.log({ previousDigitisationReq }, '[prev requests]');
        const codiDetails = previousDigitisationReq.find(
          (item) => item?.data?.commonDigitisationRequest === codiId
        )?.data;
        res.push({
          codiId,
          prescriptions: [myPrescription?.url],
          documentIds: [myPrescription?.documentId],
          codiDetails,
          batchDetails: null,
          batchId: null,
          offlineRequestId: null,
        });
      } else if (codiIndex > -1) {
        res[codiIndex].prescriptions.push(myPrescription?.url);
        res[codiIndex].documentIds.push(myPrescription?.documentId);
      } else if (typeof codiId === 'object' && codiId['batchId']) {
        const batchDetails = codiId;
        console.log(
          { batchDetails, batchId: batchDetails['batchId'] },
          '[doc-to-codi][setting]'
        );

        const batchIndex = res.findIndex(
          (i) => i['batchId'] === batchDetails['batchId']
        );
        if (batchIndex === -1) {
          res.push({
            codiId: null,
            batchId: batchDetails['batchId'],
            prescriptions: [myPrescription?.url],
            documentIds: [myPrescription?.documentId],
            batchDetails,
            codiDetails: null,
            offlineRequestId: batchDetails?.offlineRequestId || null,
          });
        } else {
          res[batchIndex].prescriptions.push(myPrescription?.url);
          res[batchIndex].documentIds.push(myPrescription?.documentId);
        }
      }
    });

    return res;
  };

  const CustomOptionWithImg = (props) => {
    return (
      <div
        {...props.innerProps}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          cursor: 'pointer',
          borderRadius: '4px',
          backgroundColor: props.isFocused ? '#EAE7FF' : 'transparent',
        }}
      >
        <img
          src={
            props.data.logo ||
            'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='
          }
          alt={props.data.label}
          style={{
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            marginRight: '10px',
          }}
        />
        <span>{props.data.label}</span>
      </div>
    );
  };

  const consultationColumn = () => [
    {
      Header: 'consultation ID ',
      accessor: 'consultationId',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Patient Name ',
      accessor: 'patientName',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Consultation Type ',
      accessor: 'type',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Appointment Date ',
      accessor: 'appointmentDate',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Hospital Name',
      accessor: 'hospitalName',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Doctor Name ',
      accessor: 'doctorName',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Charges ',
      accessor: 'charges',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Payable Amount ',
      accessor: 'payableAmount',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Wallet Deduction ',
      accessor: 'walletDeduction',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    // {
    //   Header: 'Actions ',

    //   width: 170,
    //   style: { textAlign: 'center', fontWeight: '600' },
    //   Cell: (row) => {
    //     return <button className='btn bg-teal-400'>Checked</button>>
    //   },
    // },

    {
      Header: 'Actions ',
      width: 150,
      style: { textAlign: 'center', fontWeight: '600' },

      Cell: (row) => {
        return (
          <button
            className="btn"
            style={{
              marginLeft: 'auto',
              backgroundColor: '#714FFF',
              color: '#fff',
            }}
            onClick={() => consultationIdFetch(row?.original?.consultationId)}
            disabled={consultationId}
          >
            {consultationId ? 'Checked' : 'Check'}
          </button>
        );
      },
    },
  ];

  const checkIsDisabled = () => {
    //const value = centreAddress === 0 || !verticalsValue;
    return disableFields || digitizationDetails;
  };

  const filterOptions = (option, inputValue) => {
    if (!inputValue) return true; // Allow all options if input is empty
    const lowerCaseInput = inputValue.trim().toLowerCase();
    const doctorName = option?.data?.doctorName?.toLowerCase() || '';
    const centerName = option?.data?.centerName?.toLowerCase() || '';
    return (
      doctorName.includes(lowerCaseInput) || centerName.includes(lowerCaseInput)
    );
  };

  const RegistrationNumberValueSet = (e) => {
    const value = e.target.value;
    setRegistrationNo(value);
  };

  const onPrescriptionSelection = (option) => {
    let selectedPrescriptionIds = [];
    let selectedPrescriptions = [];

    if (option.length > 0) {
      option.map((d) => {
        return (
          selectedPrescriptionIds.push(d.value),
          selectedPrescriptions.push({
            uri: d.url,
            reimbursementDocumentId: d.reimbursementDocumentId,
          })
        );
      });

      setSelectedPrescriptIds(selectedPrescriptionIds);
      setSelectedPrescription(selectedPrescriptions);
      // if (option.length > 0) {
      //   setSelectedPrescription(prev => [...prev, ...option.map(item => ({ url: item.url, reimbursementDocumentId: item.reimbursementDocumentId }))]);
      // } else {
      //   setSelectedPrescription([]);
      // }
    } else {
      setSelectedPrescriptIds([]);
      setSelectedPrescription([]);
    }
  };

  const selectPrescription = (docs) => {
    // onPrescriptionSelection(docs);
    setDocumentViewModal(false);
  };
  const openCompareDocumentView = () => {
    if (isBlocker) {
      return setBlockerModal(true);
    }
    return setCompareDocumentView(true);
  };
  const removeSelectedDocuments = () => {
    consultationOnSelect();
    setCompareDocumentView(false);
  };
  const getFileExtension = (url) => {
    const parts = url?.split('.');
    return parts?.length > 1 ? parts?.pop()?.toLowerCase() : '';
  };
  const fileExtension = getFileExtension(docs[0]?.uri);

  const addNewPrescription = async () => {
    if (
      commonDigitisationState?.commonDocumentDetails?.commonDigitisationRequest
    ) {
      const currentDocumentMap = {};
      selectedPrescription.forEach(
        (item) =>
          (currentDocumentMap[item.reimbursementDocumentId] =
            commonDigitisationState?.commonDocumentDetails?.commonDigitisationRequest)
      );
      console.log({ currentDocumentMap }, '[handleMarkingPrescriptions-3]');
      setDocumentsToCodiMapping((prev) => ({ ...prev, ...currentDocumentMap }));
      removeSelectedDocuments();
      setCommonDigitisationState(DEFAULT_COMMON_DIGITISATION_STATE);
      toast.success('Prescription tagged successfully', { theme: 'dark' });
      setAddNewPrescriptionCheck(false);
      setValidateChip(false);
      setSelectPrescriptionDisable(false);
      return;
    }
    const batchId = generateRandomId(8);
    console.log({ commonDigitisationState, selectedPrescription, batchId });
    const currentDocumentMap = {};
    selectedPrescription.forEach((item) => {
      const data = Object.assign(
        {},
        commonDigitisationState,
        { batchId },
        {
          offlineRequestId:
            commonDigitisationState?.commonDocumentDetails?.offlineRequestId,
        }
      );

      console.log(
        data.hasOwnProperty('batchId'),
        data['batchId'],
        '[doc-to-codi][much-before]'
      );
      currentDocumentMap[item.reimbursementDocumentId] = data;
    });
    setDocumentsToCodiMapping((prev) => ({ ...prev, ...currentDocumentMap }));
    removeSelectedDocuments();
    setCommonDigitisationState(DEFAULT_COMMON_DIGITISATION_STATE);
    toast.success('Prescription tagged successfully', { theme: 'dark' });
    setAddNewPrescriptionCheck(false);
    setValidateChip(false);
    setSelectPrescriptionDisable(false);
  };

  useEffect(() => {
    console.log({ documentsToCodiMapping }, '[doc-to-codi]');
  }, [documentsToCodiMapping]);

  const handleMarkingPrescriptions = () => {
    console.log(
      { existingPrescriptionDocument },
      '[handleMarkingPrescriptions-1]'
    );
    console.log({ selectedPrescription }, '[handleMarkingPrescriptions-2]');
    const currentDocumentMap = {};
    selectedPrescription.forEach(
      (item) =>
        (currentDocumentMap[item.reimbursementDocumentId] =
          existingPrescriptionDocument[0].codiId)
    );
    console.log({ currentDocumentMap }, '[handleMarkingPrescriptions-3]');
    setDocumentsToCodiMapping((prev) => ({ ...prev, ...currentDocumentMap }));
    removeSelectedDocuments();
    setBlockerModal(false);
    setValidateChip(false);
    setAddNewPrescriptionCheck(false);
    setCommonDigitisationState(DEFAULT_COMMON_DIGITISATION_STATE);
    toast.success('Prescription tagged successfully', { theme: 'dark' });
  };

  const completeCompareFlow = () => {
    consultationOnSelect(getAllExitingCodiDetail);
    //removeSelectedDocuments();
    setBlockerModal(false);
    setValidateChip(false);
    setCompareDocumentView(false);
    setValidateChip(true);
    setSelectPrescriptionDisable(true);
    //setAddNewPrescriptionCheck(false);
    //setCommonDigitisationState(DEFAULT_COMMON_DIGITISATION_STATE);
  };

  const formatDate = (dateString) => {
    // Parse the dateString with the given format
    const formattedDate = moment(dateString, 'DD-MM-YYYY').format('D MMM YYYY');
    return formattedDate;
  };
  const passConsultationId = async () => {
    await markRejected(offlineConsultationReq);
    setIsConsultation(false);
  };
  return (
    <>
      <div
        className="bg-white rounded m-2 ml-0"
        style={{
          padding: '1rem',
          paddingBottom: '2px',
          width: 'fixed',
          margin: '2px',
        }}
      >
        <div className="m-2 flex flex-row">
          <Title>Prescription Details</Title>
          {/* {isConsultation && false && (
            <button
              className="btn "
              style={{
                marginLeft: 'auto',
                backgroundColor: '#714FFF',
                color: '#fff',
              }}
              // onClick={fetchConsultations}
            >
              Show Consultations
            </button>
          )} */}
        </div>

        {digitizationDetails && digitizationDetails.length > 0 ? (
          digitizationDetails.map((d, indx) => (
            <div
              key={d.codiId} // Adding a key to help React identify elements uniquely
              style={{
                border: '1px solid #714FFF',
                margin: '2px',
                borderRadius: '6px',
                marginBottom: '6px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '5px',
                  backgroundColor: '#EBE7FF',
                }}
              >
                <h3 style={{ margin: '2px' }}>Prescription Details</h3>
                {/* <button
                  onClick={() =>
                    setSelectedDocument((p) =>
                      p?.codiId === d.codiId ? null : d
                    )
                  }
                  style={{
                    color: '#714FFF',
                    border: 'none',
                    padding: '6px 12px',
                    cursor: 'pointer',
                  }}
                >
                  {selectedDocument?.codiId === d.codiId ? '-' : '+'}
                </button> */}
              </div>
              <div className="flex flex-wrap">
                <h2 style={{ fontWeight: 'bold', margin: '2px' }}>
                  {d.codiId} | Patient - {d.patientName}
                </h2>
                <h2 style={{ marginLeft: '1px' }}>
                  | {d.prescriptionDate} | {d.doctorName}
                </h2>
                <p style={{ fontSize: '10px', color: 'gray' }}>
                  ({d.verticalName})
                </p>
                {d.isBlocker ? (
                  <h3>B</h3>
                ) : (
                  <button
                    style={{ marginLeft: '2px' }}
                    onClick={(e) => renderPrescriptionDocument(e, d.mergedUrl)}
                  >
                    <LogoContainer src={RxImg} alt="show Icon" />
                  </button>
                )}
              </div>
              {d.diagnosis && (
                <h2 style={{ marginLeft: '1px' }}>
                  Diagnosis - {d.diagnosis} {d.icdCode ? `(${d.icdCode})` : ''}
                </h2>
              )}
              {d.centerName && (
                <h2 style={{ marginLeft: '1px' }}>
                  Center Name - {d.centerName}{' '}
                </h2>
              )}

              {d.centreAddress && (
                <h2 style={{ marginLeft: '1px' }}>
                  Center Address - {d.centreAddress}{' '}
                </h2>
              )}

              {/* {selectedDocument?.codiId === d.codiId ? <div className='my-3'>
                test
              </div> : <></>} */}
            </div>
          ))
        ) : (
          <></>
        )}

        {groupMappingByCodiId().length > 0 ? (
          groupMappingByCodiId().map((item, indx) => {
            const d = item.codiDetails;
            const b = item.batchDetails;
            console.log({ d, item, b }, '[render added mapping]');

            if (!d && b) {
              return (
                <div
                  key={b.batchId} // Adding a key to help React identify elements uniquely
                  style={{
                    border: '1px solid #714FFF',
                    margin: '2px',
                    borderRadius: '6px',
                    marginBottom: '6px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '5px',
                      backgroundColor: '#EBE7FF',
                    }}
                  >
                    <h3 style={{ margin: '2px' }}>Prescription Details</h3>
                    <button
                      onClick={() =>
                        setSelectedDocument((p) =>
                          p === item.batchId ? null : item.batchId
                        )
                      }
                      style={{
                        color: '#714FFF',
                        border: 'none',
                        padding: '6px 12px',
                        cursor: 'pointer',
                      }}
                    >
                      {selectedDocument === item.batchId ? '-' : '+'}
                    </button>
                  </div>
                  <div className="flex flex-wrap">
                    <h2 style={{ fontWeight: 'bold', margin: '2px' }}>
                      Patient -{' '}
                      {reimbursementDetail.relative ||
                        reimbursementDetail.userName}
                    </h2>
                    <h2 style={{ marginLeft: '1px' }}>
                      | {formatDate(b.appointmentDate.value)} |{' '}
                      {b.networkDoctor.label}
                    </h2>
                    <p style={{ fontSize: '10px', color: 'gray' }}>
                      ({b.networkDoctor?.verticalName || ''})
                    </p>
                  </div>
                  {b.primaryDiagnosis[0].label && (
                    <h2 style={{ marginLeft: '1px' }}>
                      Diagnosis - {b.primaryDiagnosis[0].label}{' '}
                      {b.primaryDiagnosis[0].value
                        ? `(${b.primaryDiagnosis[0].value})`
                        : ''}
                    </h2>
                  )}

                  {b.networkCenter.label && (
                    <h2 style={{ marginLeft: '1px' }}>
                      Center Name - {b.networkCenter.label}{' '}
                    </h2>
                  )}

                  {b.networkCenter.address && (
                    <h2 style={{ marginLeft: '1px' }}>
                      Center Name - {b.networkCenter.address}{' '}
                    </h2>
                  )}

                  {selectedDocument === item.batchId ? (
                    <div
                      style={{ columnGap: '10px' }}
                      className="m-3 flex flex-row align-middle"
                    >
                      {item.prescriptions.map((url, idx) => {
                        return (
                          <div key={`${url}_${idx}`}>
                            <OptionContainer>
                              <OptionImage>
                                {/* Render checkbox only if codiId is not available */}

                                <EyeIcon
                                  onClick={() => {
                                    setSetDocs([
                                      {
                                        uri: url,
                                        value: idx,
                                        label: `Prescription - ${idx}`,
                                        disableAction: true,
                                      },
                                    ]);
                                    setDocumentViewModal(true);
                                  }}
                                />
                              </OptionImage>
                              <Filename>S.No - {idx + 1}</Filename>
                            </OptionContainer>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            }

            return (
              <div
                key={d.codiId} // Adding a key to help React identify elements uniquely
                style={{
                  border: '1px solid #714FFF',
                  margin: '2px',
                  borderRadius: '6px',
                  marginBottom: '6px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px',
                    backgroundColor: '#EBE7FF',
                  }}
                >
                  <h3 style={{ margin: '2px' }}>Prescription Details</h3>
                  <button
                    onClick={() =>
                      setSelectedDocument((p) =>
                        p === item.codiId ? null : item.codiId
                      )
                    }
                    style={{
                      color: '#714FFF',
                      border: 'none',
                      padding: '6px 12px',
                      cursor: 'pointer',
                    }}
                  >
                    {selectedDocument === item.codiId ? '-' : '+'}
                  </button>
                </div>
                <div className="flex flex-wrap">
                  <h2 style={{ fontWeight: 'bold', margin: '2px' }}>
                    {d.codiId || d.commonDigitisationRequest} | Patient -{' '}
                    {d.patientName}
                  </h2>
                  <h2 style={{ marginLeft: '1px' }}>
                    | {formatDate(d.prescriptionDate)} | {d.doctorName}
                  </h2>
                  <p style={{ fontSize: '10px', color: 'gray' }}>
                    ({d.verticalName})
                  </p>
                  {d.isBlocker ? (
                    <h3>B</h3>
                  ) : (
                    <button
                      style={{ marginLeft: '2px' }}
                      onClick={(e) =>
                        renderPrescriptionDocument(e, d.mergedUrl)
                      }
                    >
                      <LogoContainer src={RxImg} alt="show Icon" />
                    </button>
                  )}
                </div>
                {d.diagnosis && (
                  <h2 style={{ marginLeft: '1px' }}>
                    Diagnosis - {d.diagnosis}{' '}
                    {d.icdCode ? `(${d.icdCode})` : ''}
                  </h2>
                )}

                {d.centerName && (
                  <h2 style={{ marginLeft: '1px' }}>
                    Center Name - {d.centerName}{' '}
                  </h2>
                )}

                {d.centerAddress && (
                  <h2 style={{ marginLeft: '1px' }}>
                    Center Address - {d.centerAddress}{' '}
                  </h2>
                )}

                {selectedDocument === item.codiId ? (
                  <div
                    style={{ columnGap: '10px' }}
                    className="m-3 flex flex-row align-middle"
                  >
                    {item.prescriptions.map((url, idx) => {
                      return (
                        <div key={`${url}_${idx}`}>
                          <OptionContainer>
                            <OptionImage>
                              {/* Render checkbox only if codiId is not available */}

                              <EyeIcon
                                onClick={() => {
                                  setSetDocs([
                                    {
                                      uri: url,
                                      value: idx,
                                      label: `Prescription - ${idx}`,
                                      disableAction: true,
                                    },
                                  ]);
                                  setDocumentViewModal(true);
                                }}
                              />
                            </OptionImage>
                            <Filename>S.No - {idx + 1}</Filename>
                          </OptionContainer>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })
        ) : (
          <></>
        )}

        {/* <div class="flex flex-wrap"> */}
        {/* <div style={{ width: '100%' }} className="mb-4">
          <SubHeading>Select Consultation</SubHeading>
          <Select
            className=""
            closeMenuOnSelect={true}
            styles={customStyles}
            isClearable={true}
            // value={}
            placeholder="Select Consultation"
            options={consultations}
            filterOption={filterOptions}
            isDisabled={isConsultationSelected || digitizationDetails}
            onChange={(option) => consultationOnSelect(option)}
          />
        </div> */}
        {!digitizationDetails?.length && addNewPrescriptionCheck ? (
          <div
            style={{
              border: '1.5px solid #714FFF',
              padding: '15px',
              borderRadius: '5px',
            }}
          >
            <div style={{ width: '100%' }} className="mb-4">
              <CommonHelpText>
                Select Existing Prescription or Consultation of the Patient
              </CommonHelpText>
              <Select
                //closeMenuOnSelect={true}
                className="full-select"
                styles={customStyles}
                isClearable={true}
                //value={selectedOption}
                placeholder="Select Requests"
                options={filteredOptions}
                filterOption={filterOptions}
                key={existingPrescriptionKey}
                onChange={(option) => handleOnChangePreviousDigitised(option)}
              />
            </div>

            <div style={{ width: '100%' }} className="mb-4">
              <CommonHelpText style={{ display: 'flex', flexDirection: 'row' }}>
                <span>
                  Select Prescription Document matching to the
                  prescription/consultation details
                </span>
                {validateChip ? (
                  <label
                    style={{
                      color: '#fff',
                      backgroundColor: '#22bb33',
                      borderRadius: '6px ',
                      border: '1px solid #22bb33',
                      padding: '1px',
                      marginLeft: '2px',
                    }}
                  >
                    validated
                  </label>
                ) : (
                  <></>
                )}
              </CommonHelpText>

              {/* <Select
            className=""
            closeMenuOnSelect={true}
            styles={customStyles}
            isClearable={true}
            // value={}
            placeholder="Select Consultation"
            options={consultations}
            filterOption={filterOptions}
            isDisabled={isConsultationSelected || digitizationDetails}
            onChange={(option) => consultationOnSelect(option)}
          /> */}

              <div
                className="d-flex flex-row"
                style={{ columnGap: '15px', position: 'relative' }}
              >
                {/* <BreadcrumbChip
                  style={{
                    fontSize: '10px',
                    height: '27.5px',
                    right: '150px',
                    zIndex: 1232,
                  }}
                >
                  <IoMdCheckmarkCircle size={15} style={{ marginRight: '5px' }} />
                  Validated---
                </BreadcrumbChip> */}
                <Select
                  options={formattedPrescriptionArr}
                  components={{
                    Menu: (props) => (
                      <CustomMenu
                        {...props}
                        isDropdownOpen={isDropdownOpen}
                        setDropdownOpen={setDropdownOpen}
                      />
                    ),
                    Option: CustomOptionForHorizontal,
                  }}
                  styles={customStylesForImage}
                  isMulti
                  className="full-flex"
                  menuPortalTarget={document.body} // Ensure dropdown renders in body to avoid overflow issues
                  onChange={(option) => onPrescriptionSelection(option)}
                  key={prescriptionSelectionKey}
                  isDisabled={selectPrescriptionDisable}
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={() => setMenuIsOpen(true)} // Optional if you want to manage open state
                  // onMenuClose={() => setMenuIsOpen(false)} // Optional if you want to manage close state
                  onBlur={() => setMenuIsOpen(false)} // Close dropdown on blur
                />
                {getAllExitingCodiDetail ? (
                  <button
                    className="btn btn float-right"
                    onClick={() => openCompareDocumentView()}
                    style={{ color: '#fff', backgroundColor: '#714FFF' }}
                    disabled={
                      !selectedPrescription?.length ||
                      !existingPrescriptionDocument?.length ||
                      selectPrescriptionDisable ||
                      (!existingPrescriptionDocument[0].codiId &&
                        !existingPrescriptionDocument[0].offlineRequestId)
                    }
                  >
                    Compare
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <CommonFirstStep
              commonDigitisationState={commonDigitisationState}
              setCommonDigitisationState={setCommonDigitisationState}
            />

            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <button
                style={{
                  backgroundColor: '#714FFF',
                  color: '#fff',
                  marginRight: 'auto !important',
                }}
                className="btn mt-2 mb-2"
                disabled={
                  !selectedPrescription?.length ||
                  !commonDigitisationState.primaryDiagnosis?.length ||
                  !commonDigitisationState.primaryDiagnosis[0].value ||
                  !commonDigitisationState.primaryDiagnosis[0].label ||
                  !commonDigitisationState.networkDoctor?.label ||
                  !commonDigitisationState.networkCenter?.label ||
                  !commonDigitisationState.appointmentDate?.value
                }
                onClick={addNewPrescription}
                // disabled={!Object.keys(documentsToCodiMapping).length}
              >
                Save Prescription
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}

        {digitizationDetails?.length ? (
          <></>
        ) : (
          <div className="flex justify-end">
            <button
              style={{ backgroundColor: '#714FFF', color: '#fff' }}
              className="btn mt-2 mb-2 flex flex-row align-middle"
              disabled={addNewPrescriptionCheck}
              onClick={() => setAddNewPrescriptionCheck(true)}
            >
              Add New Prescription
            </button>
          </div>
        )}

        {groupMappingByCodiId().length > 0 && (
          <div className="flex justify-end">
            <button
              style={{ backgroundColor: '#714FFF', color: '#fff' }}
              className="btn mt-2 mb-2 flex flex-row align-middle"
              onClick={markDigitized}
              disabled={
                digitisedButtonDisabled ||
                (!Object.keys(documentsToCodiMapping).length &&
                  !digitizationDetails?.length)
              }
            >
              {digitizationDetails?.length ? (
                <>
                  <IoMdCheckmarkCircle
                    style={{ marginRight: '5px' }}
                    size={20}
                    color="#32AD54"
                  />{' '}
                </>
              ) : null}
              {digitizationDetails?.length ? '' : 'Mark'} Digitized
            </button>
          </div>
        )}

        {/* add  diagnosis Modal */}
        <Modal isOpen={diagnosisModal} toggle={() => setDiagnosisModal(false)}>
          <ModalHeader toggle={() => setDiagnosisModal(false)}>
            <ModalHeaderHeading>Add Diagnosis</ModalHeaderHeading>
          </ModalHeader>
          <ModalBody>
            <InputBox
              placeholder=" Enter Diagnosis"
              type="text"
              onChange={(e) => OnChangeDiagnosis(e)}
            ></InputBox>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn mt-2 mb-2"
              style={{ backgroundColor: '#714FFF', color: '#fff' }}
              //disabled={diagnosis ? false : true}
              onClick={() => closeDiagnosisModal(onChangeDiagnosisValue)}
            >
              Save
            </button>
          </ModalFooter>
        </Modal>
        {/* add doctor Modal */}
        <Modal isOpen={doctorModal} toggle={() => setDoctorModal(false)}>
          <ModalHeader toggle={() => setDoctorModal(false)}>
            <ModalHeaderHeading>Add Doctor</ModalHeaderHeading>
          </ModalHeader>
          <ModalBody>
            <InputBox
              placeholder=" Enter Doctor"
              type="text"
              onChange={(e) => OnChangeDoctor(e)}
            ></InputBox>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn mt-2 mb-2"
              style={{ backgroundColor: '#714FFF', color: '#fff' }}
              disabled={doctorName ? false : true}
              onClick={() => setDoctorModal(false)}
            >
              Save
            </button>
          </ModalFooter>
        </Modal>
        {/* add centre Modal */}
        <Modal isOpen={centreModal} toggle={() => setCentreModal(false)}>
          <ModalHeader toggle={() => setCentreModal(false)}>
            <ModalHeaderHeading>Add Centre & Address</ModalHeaderHeading>
          </ModalHeader>
          <ModalBody>
            <InputBox
              placeholder=" Enter Centre Name"
              type="text"
              onChange={(e) => onCentreChange(e)}
            ></InputBox>
            {/* <textarea
              className="form-control mt-2"
              style={{
                backgroundColor: '#F3F0FF',
                border: '2px solid #b9a7fe',
                borderRadius: '6px',
              }}
              placeholder=" Enter Address "
              type="text"
              onChange={(e) => setCentreAddress(e.target.value)}
            ></textarea> */}
            <InputBox
              className="mt-2"
              placeholder=" Enter Pincode"
              type="text"
              onChange={(e) => fetchAddress(e)}
            ></InputBox>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn mt-2 mb-2"
              style={{ backgroundColor: '#714FFF', color: '#fff' }}
              disabled={centre && centreAddress ? false : true}
              onClick={() => setCentreModal(false)}
            >
              Save
            </button>
          </ModalFooter>
        </Modal>
        {/* Add Consultation Modal */}
        <Modal
          isOpen={consultationModal}
          toggle={() => setConsultationModal(false)}
          size="lg"
          style={{ maxWidth: '1200px', width: '100%', borderRadius: '6px' }}
        >
          <ModalHeader toggle={() => setConsultationModal(false)}>
            <ModalHeaderHeading>Consultation Requests</ModalHeaderHeading>
          </ModalHeader>
          <ModalBody>
            <ReactTable
              data={consultationData && consultationData}
              columns={consultationColumn()}
            ></ReactTable>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={documentViewModal}
          toggle={() => onPrescriptionModalClose()}
          size="lg"
          //style={{ maxWidth: '1200px', width: '100%', borderRadius: '6px' }}
        >
          <ModalHeader toggle={() => onPrescriptionModalClose()}>
            <ModalHeaderHeading>View Prescription</ModalHeaderHeading>
          </ModalHeader>
          <ModalBody>
            <DocumentViewer documents={docs} />
            {docs.length > 0 ? (
              <div
                style={{
                  width: '97%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {!docs[0].disableAction ? (
                  <button
                    style={{ backgroundColor: '#714FFF', color: '#fff' }}
                    className="btn mt-2 mb-2"
                    onClick={() => selectPrescription(docs)}
                  >
                    Close
                  </button>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              ''
            )}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={compareDocumentView}
          toggle={() => setCompareDocumentView(false)}
          size="lg"
          style={{ maxWidth: '1000px', width: '100%', borderRadius: '6px' }}
        >
          <ModalHeader toggle={() => setCompareDocumentView(false)}>
            <ModalHeaderHeading>Verify both prescriptions</ModalHeaderHeading>
          </ModalHeader>
          <ModalBody
            style={{
              overflow: 'hidden',
              padding: 0,
              maxHeight: '80vh', // Adjust as needed
              display: 'flex',
              flexDirection: 'column', // Ensure the button is positioned correctly
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  flex: 1,
                  overflowY: 'auto',
                  borderRight: '2px solid #714FFF',
                  padding: '8px',
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3 className="mb-2" style={{ fontWeight: 'bold' }}>
                  Selected Existing Prescription
                </h3>

                <iframe
                  title="existingPrescriptionDocument"
                  src={existingPrescriptionDocument[0]?.uri}
                  style={{
                    flex: 1,
                    width: '100%',
                    border: 'none',
                  }}
                />

                <div
                  style={{
                    position: 'sticky',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderTop: '1px solid #ddd',
                    boxSizing: 'border-box',
                  }}
                >
                  <button
                    onClick={completeCompareFlow}
                    style={{
                      backgroundColor: '#714FFF',
                      color: '#fff',
                      padding: '10px 20px', // Optional: padding to make the button look better
                      border: 'none', // Optional: remove default border
                      borderRadius: '4px', // Optional: rounded corners
                      cursor: 'pointer', // Optional: pointer cursor on hover
                      width: '100%',
                    }}
                    className="btn btn"
                  >
                    Yes, both are same prescription
                  </button>
                </div>
              </div>

              <div
                style={{
                  flex: 1,
                  overflowY: 'auto',
                  marginLeft: '8px',
                  padding: '8px',
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3 className="mb-2" style={{ fontWeight: 'bold' }}>
                  Prescription uploaded in reimbursement
                </h3>
                {/* Use appropriate component for rendering documents */}
                <DocumentViewer
                  documents={selectedPrescription}
                  style={{
                    flex: 1,
                    width: '100%',
                    border: 'none',
                  }}
                />

                <div
                  style={{
                    position: 'sticky',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderTop: '1px solid #ddd',
                    boxSizing: 'border-box',
                  }}
                >
                  <button
                    style={{
                      backgroundColor: '#714FFF',
                      color: '#fff',
                      padding: '10px 20px', // Optional: padding to make the button look better
                      border: 'none', // Optional: remove default border
                      borderRadius: '4px', // Optional: rounded corners
                      cursor: 'pointer', // Optional: pointer cursor on hover
                      width: '100%',
                    }}
                    className="btn btn"
                    onClick={removeSelectedDocuments}
                  >
                    No, both are different prescription
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* blocker Consultation Modal */}

        <Modal
          isOpen={blockerModal}
          style={{ maxWidth: '900px', width: '100%', borderRadius: '6px' }}
          toggle={() => closeBlockerModal()}
        >
          <ModalHeader toggle={() => closeBlockerModal()}>
            <h3 style={{ fontWeight: 'bolder' }}>Verify Prescription</h3>
          </ModalHeader>
          <ModalBody
            style={{
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '80vh', // Adjust height as needed
              overflow: 'hidden',
            }}
          >
            <div style={{ flex: 1, overflowY: 'auto' }}>
              <h3
                style={{
                  padding: '4px',
                  backgroundColor: '#E3DCFF',
                  borderRadius: '6px',
                }}
              >
                Selected Consultation Details | Consult ID :
                {blockerInfo.offlineRequestId}
              </h3>

              {/* Headings Row */}
              <div className="flex flex-row" style={{ marginBottom: '5px' }}>
                <h3 style={{ flex: 1, fontWeight: 'bold' }}>Patient Name</h3>
                <h3 style={{ flex: 1, fontWeight: 'bold' }}>Doctor Name</h3>
                <h3 style={{ flex: 1, fontWeight: 'bold' }}>
                  Consultation Date
                </h3>
              </div>

              {/* Details Row */}
              <div className="flex flex-row" style={{ marginBottom: '10px' }}>
                <h3 style={{ flex: 1 }}>{blockerInfo.patientName}</h3>
                <h3 style={{ flex: 1 }}>{blockerInfo.doctorName}</h3>
                <h3 style={{ flex: 1 }}>{blockerInfo.appointmentDate}</h3>
              </div>

              {/* Additional Information */}
              <div className="flex flex-row">
                <h3 style={{ fontWeight: 'bold' }}>Centre Name</h3>
                {/* If you want to add more details here, you can add them in another flex row or align them as needed */}
              </div>
              <div className="flex flex-row" style={{ marginBottom: '10px' }}>
                <h3
                  style={{ flex: 1 }}
                >{`${blockerInfo.centerName}, ${blockerInfo.centerAddress}`}</h3>
              </div>

              <div
                style={{
                  maxHeight: 'calc(80vh - 60px)', // Adjust height for the button
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  padding: '10px',
                  boxSizing: 'border-box',
                }}
              >
                <h2
                  className="mb-2"
                  style={{
                    padding: '4px',
                    backgroundColor: '#E3DCFF',
                    borderRadius: '6px',
                  }}
                >
                  Selected Reimbursement Document
                </h2>

                <DocumentViewer documents={selectedPrescription} />
              </div>
            </div>

            {/* Fixed Bottom Button */}
            <div
              style={{
                padding: '10px',
                borderTop: '1px solid #714FFF',
                backgroundColor: '#fff',
                position: 'sticky',
                bottom: 0,
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                onClick={() => blockerSaveButton()}
                style={{
                  backgroundColor: '#714FFF',
                  color: '#fff',
                  padding: '10px 20px', // Optional: padding to make the button look better
                  border: 'none', // Optional: remove default border
                  borderRadius: '4px', // Optional: rounded corners
                  cursor: 'pointer', // Optional: pointer cursor on hover
                }}
                className="btn btn"
              >
                Yes, consultation and prescription details are same
              </button>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={isConsultation && isCashlessConsultation}
          style={{ maxWidth: '600px', borderRadius: '6px' }}
          toggle={() => setIsConsultation(false)}
        >
          <ModalHeader
            style={{ backgroundColor: '#FCDCCF' }}
            toggle={() => setIsConsultation(false)}
          >
            <div className="flex flex-row">
              <FaExclamationCircle
                style={{
                  color: '#FF865C',
                  fontSize: '2rem',
                  marginRight: '4px',
                }}
              />
              <h3
                style={{
                  fontSize: '16px',
                  fontWeight: 'bolder',
                  marginTop: '2px',
                }}
              >
                Already Availed
              </h3>
            </div>
          </ModalHeader>
          <ModalBody>
            <h3 className="mt-2" style={{ fontWeight: '18px' }}>
              This consultation reimbursement request is already availed under
              the cashless Consultation ID: {offlineConsultationReq}
            </h3>
            <h3 className="mt-4">
              Rejection Reason:{' '}
              <strong>Cashless benefit availed for the claim</strong>
            </h3>
          </ModalBody>
          <ModalFooter>
            <button
              style={{
                color: '#fff',
                backgroundColor: '#714FFF',
                padding: '12px, 24px, 12px, 24px',
                borderRadius: '8px',
                width: '203px',
              }}
              className="btn btn"
              onClick={() => passConsultationId()}
            >
              Yes, reject it
            </button>

            <button
              style={{
                border: '1px solid #714FFF',
                backgroundColor: '#fff',
                padding: '12px, 24px, 12px, 24px',
                borderRadius: '8px',
                width: '203px',
              }}
              className="btn btn"
              onClick={() => setIsConsultation(false)}
            >
              No, continue
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}
