import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import styled from 'styled-components';
import moment from 'moment';
import { Pagination, DatePicker, Space } from 'antd';
//import 'antd/dist/antd.css';
import { Table, Switch } from 'antd';
import { FaTimes } from 'react-icons/fa';
import { useEffect } from 'react';
const { RangePicker } = DatePicker;
const customStylesModal = {
  content: {
    left: '40%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '80%',
    height: '70%',
    transform: 'translate(-40%, -10%)',
    position: 'absolute',
    top: '30%',
    backgroundColor: 'white',
    padding: '10px',
  },
};
const CrossIcon = styled(FaTimes)`
  font-size: 1.75rem;
  color: white;
`;
const Text = styled.h3`
  color: #714fff;
  font-weight: 600;
  font-size: 15px;
`;

const pastClaimColumns = [
  {
    title: 'RID',
    dataIndex: 'rId',
    render: (value, row, index) => {
      return (
        <a
          href={`/reimburse-claim/${value}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#1890ff' }}
        >
          {value}
        </a>
      );
    },
  },
  {
    title: 'Request Date',
    dataIndex: 'requestDate',
    render: (value, row, index) => {
      return (
        <p>
          {value ? moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD') : 'N.A'}
        </p>
      );
    },
  },
  {
    title: 'Reimbursement Type',
    dataIndex: 'reimbursementType',
    render: (value, row, index) => {
      return <p>{value ? value : 'N.A'}</p>;
    },
  },
  {
    title: 'Approved Amount',
    dataIndex: 'approvedAmount',
    render: (value, row, index) => {
      return <p>{value ? value : 0}</p>;
    },
  },

  {
    title: 'Claim Amount',
    dataIndex: 'reimbursementAmount',
    render: (value, row, index) => {
      return <p>{value ? value : 0}</p>;
    },
  },
  {
    title: 'Chief complaint/Probale diagnosis',
    dataIndex: 'probableDiagnosis',
    render: (value, row, index) => {
      return <p>{value ? value : 'N.A'}</p>;
    },
  },
  {
    title: 'Invoice date',
    dataIndex: 'invoiceDate',
    render: (value, row, index) => {
      return (
        <p>
          {value ? moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD') : 'N.A'}
        </p>
      );
    },
  },
  {
    title: 'Claim status',
    dataIndex: 'claimStatus',
    render: (value, row, index) => {
      return <p>{value ? value : 'N.A'}</p>;
    },
  },
  {
    title: 'Rejection reason(if any)',
    dataIndex: 'rejectionReason',
    render: (value, row, index) => {
      return <p>{value ? value : 'N.A'}</p>;
    },
  },
  {
    title: 'Documents',
    dataIndex: 'links',
    render: (value, row, index) => {
      console.log(row);
      const links = [];
      let invoicesCount = 0;
      let presciptionCount = 0;
      let labReportCount = 0;
      const invoiceLink = row.invoiceLink?.split(',').map((e) => {
        invoicesCount += 1;
        const body = {
          type: 'invoice',
          url: e,
          count: invoicesCount,
        };
        links.push(body);
        return body;
      });
      const prescriptionLink = row.prescriptionLink?.split(',').map((e) => {
        presciptionCount += 1;
        const body = {
          type: 'prescription',
          url: e,
          count: presciptionCount,
        };
        links.push(body);
        return body;
      });
      const labReportLink = row.labReportLink?.split(',').map((e) => {
        labReportCount += 1;
        const body = {
          type: 'labReport',
          url: e,
          count: labReportCount,
        };
        links.push(body);
        return body;
      });
      console.log(links);
      return links.map((e, idx) => {
        return (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={e.url}
            style={{ color: '#1890ff' }}
          >
            {` ${e.type} ${e.count}`}
          </a>
        );
      });
    },
  },
];

export default function PastClaims({
  isOpen,
  dataSource,
  onClosePastClaimModal,
  selectedDate,
}) {
  const [tableData, setTableData] = useState(dataSource?.activePolicyClaims);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (toggle) {
      return setTableData(dataSource?.oldPolicyClaims);
    }
    return setTableData(dataSource?.activePolicyClaims);
  }, [
    dataSource.activePolicyClaims,
    dataSource.oldPolicyClaims,
    onClosePastClaimModal,
    toggle,
  ]);
  useEffect(() => {
    if (!isOpen) {
      setToggle(false);
      setTableData(dataSource?.activePolicyClaims);
    }
  }, [dataSource.activePolicyClaims, isOpen, onClosePastClaimModal, toggle]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClosePastClaimModal}
      size="lg"
      style={{ maxWidth: '1200px', width: '100%' }}
    >
      <ModalHeader
        toggle={onClosePastClaimModal}
        style={{ backgroundColor: '#714FFF' }}
      >
        <div className="row">
          <div className="col-5 col-md-5 col-lg-5">
            <h3
              style={{
                fontWeight: 'bold',
                fontFamily: 'Montserrat',
                fontSize: '20px',
                color: '#ffff',
              }}
            >
              Past Claims
            </h3>
          </div>
          <div className="col-3">
            <p className="" style={{ color: '#FFFF', fontSize: '15px' }}>
              Date range-{' '}
            </p>
          </div>

          <div className="col-4 m-0">
            <Space direction="vertical" size={12}>
              <RangePicker onChange={selectedDate} />
            </Space>
          </div>
        </div>
      </ModalHeader>
      <ModalBody style={{ maxHeight: '600px', overflow: 'scroll' }}>
        <div
          className="flex flex-row mt-2 pl-2 pr-2 float-right mb-4"
          style={{
            border: '2px solid #714FFF',
            borderRadius: '8px',
            height: '3rem',
            padding: '4px',
          }}
        >
          <Text className="mt-2 mr-2" toggle={toggle}>
            New Policy
          </Text>
          <Switch
            className="mt-2"
            onChange={(e) => setToggle(e)}
            style={{
              backgroundColor: toggle ? '#714FFF' : '#D6CCFF',
              width: '40px',
            }}
          ></Switch>
          <Text className="ml-2 mt-2">Old Policy</Text>
        </div>
        <div className="mt-2">
          <Table
            columns={pastClaimColumns}
            dataSource={tableData || []}
            pagination={true}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}
