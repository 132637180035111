import React from 'react';
import { connect } from 'react-redux';
import { CommonHelpText, SimpleColumn } from '../styledCommonComponents';
import { useEffect } from 'react';
import { resetArrowDown } from '../utils';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DateSelectionInput = ({
    helpText = '',
    required = false,
    placeholderText = '',
    selection = null,
    onChange = () => { },
    disabledDate = () => { },
    disabled = false,
}) => {

    useEffect(resetArrowDown, []);

    return <SimpleColumn style={{ marginBottom: '20px', width: '100%' }}>
        {helpText ? <CommonHelpText>
            {helpText}{required ? <span style={{ color: '#f50', marginLeft: '2px' }}>*</span> : <></>}
        </CommonHelpText> : <></>}

        <ReactDatePicker
            selected={selection}
            onChange={onChange}
            dateFormat={'dd-MM-yyyy'}
            placeholder={placeholderText || helpText}
            disabledDate={disabledDate}
            disabled={disabled}
            placeholderText={placeholderText}
            maxDate={new Date()}
            className='react-date-picker'
        />

    </SimpleColumn>
};

const matchStateToProps = (state) => ({
    authToken: state.app.authToken
});

export default connect(matchStateToProps)(DateSelectionInput);
