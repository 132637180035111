import React, { useCallback, useState, useEffect } from 'react';
import { Empty, Select, Spin } from 'antd';
import { AltOption, AltOptionHeading, AltOptionsContainer, CommonHelpText, SimpleColumn } from '../styledCommonComponents';
import { debounce } from '../../../utils/debounce';

const SingleSelectionSearchDropdown = ({
    helpText = '',
    placeholderText = '',
    required = false,
    apiCallback = () => { },
    debounceTimeout = 500,
    onChange = () => { },
    selection = null,
    optionJSX = (option) => option.label,
    fetchInitially = false,
    showAltOptions = false,
    altOptionsHelpText = '',
    onAltChoose = () => { },
    altOptionLabelMaker = () => { },
    resetKey = false,
    emptyStateText = '',
    style = {},
    disabled = false,
}) => {

    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const [altOptions, setAltOptions] = useState([]);

    const loadOptions = useCallback((value) => {
        if (!value) return [];
        if (value === 'initial') value = '';
        setFetching(true);

        apiCallback(value).then(response => {
            if (Array.isArray(response)) {
                setOptions(response);
            } else if (response?.hasOwnProperty('main')) {
                setOptions(response.main);
                setAltOptions(response.alt);
            } else {
                setOptions([]);
                setAltOptions([]);
            }
        }).finally(() => {
            setFetching(false);
        });
    }, [apiCallback]);

    useEffect(() => {
        setOptions([]);
        setAltOptions([]);
    }, [resetKey]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchOptions = useCallback(debounce(loadOptions, debounceTimeout), []);

    const handleSearch = (value) => {
        debouncedFetchOptions(value);
    }

    const handleDropdownChange = useCallback((isOpen) => {
        if (isOpen && fetchInitially) {
            loadOptions('initial');
        }
    }, [fetchInitially, loadOptions]);

    return <SimpleColumn style={{ marginBottom: '20px', ...style }}>
        {helpText ? <CommonHelpText>
            {helpText}{required ? <span style={{ color: '#f50', marginLeft: '2px' }}>*</span> : <></>}
        </CommonHelpText> : <></>}

        <Select
            style={{ width: '100%' }}
            showSearch
            placeholder={placeholderText || helpText}
            notFoundContent={fetching ? <Spin size="small" /> : <Empty description={emptyStateText || 'No data'} />}
            options={options}
            labelInValue
            onSearch={handleSearch}
            loading={fetching}
            filterOption={false}
            onChange={onChange}
            disabled={disabled}
            value={selection}
            optionRender={optionJSX}
            onBlur={() => { setAltOptions([]); setOptions([]); }}
            onDropdownVisibleChange={handleDropdownChange}
        >
            {options.map(option => (
                <Select.Option key={option.value} value={option.value}>
                    {optionJSX(option)}
                </Select.Option>
            ))}
        </Select>

        {(showAltOptions && !options.length && altOptions.length) ? <>
            <AltOptionHeading>{altOptionsHelpText}</AltOptionHeading>
            <AltOptionsContainer>
                {altOptions.map(option => <AltOption key={option.value} onClick={() => { onAltChoose(option); setOptions([]); setAltOptions([]); }}>
                    <span style={{ fontSize: '15px', fontWeight: '600' }}>{option.label}</span>
                    {altOptionLabelMaker(option) ? <span style={{ fontSize: '12.5px', color: '#999' }}>{altOptionLabelMaker(option)}</span> : <></>}
                </AltOption>)}
            </AltOptionsContainer>
        </> : <></>}

    </SimpleColumn>
};

export default SingleSelectionSearchDropdown;
