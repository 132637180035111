import React, { useCallback, useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { CommonHelpText, SimpleColumn } from '../styledCommonComponents';

const SingleCommonSelectionDropdown = ({
    helpText = '',
    placeholderText = '',
    required = false,
    onChange = () => {},
    selection = null,
    optionJSX = (option) => <div style = {{ fontWeight: '600', color: '#555' }}>{option.label}</div>,
    defaultOptions = [],
    style = {},
    disabled = false,
    onFocus = () => {},
    showLoading = false,
}) => {

    const [ options, setOptions ] = useState(defaultOptions);
    useEffect(() => setOptions(defaultOptions), [ defaultOptions ]);

    const handleSearch = useCallback((value) => {
        setOptions(defaultOptions.filter(item => item.label.toLowerCase().includes((value || '').toLowerCase())));
    }, [ defaultOptions ]);

    return <SimpleColumn style={{ marginBottom: '20px', ...style }}>
        {helpText ? <CommonHelpText>
            {helpText}{required ? <span style={{ color: '#f50', marginLeft: '2px' }}>*</span> : <></>}
        </CommonHelpText> : <></>}
        
        <Select
            style = {{ width: '100%' }}
            showSearch
            notFoundContent = {(showLoading && !options.length) ? <Spin size="small" /> : null}
            placeholder = {placeholderText || helpText}
            options = {options}
            labelInValue
            onSearch = {handleSearch}
            filterOption = {false}
            onChange = {onChange}
            value = {selection}
            optionRender = {optionJSX}
            disabled = {disabled}
            onFocus = {onFocus}
        >
            {options.map(option => (
                <Select.Option key={option.value} value={option.value}>
                    {optionJSX(option)}
                </Select.Option>
            ))}
        </Select>

    </SimpleColumn>
};

export default SingleCommonSelectionDropdown;
