import { useMutation, useQuery } from 'react-query';
import {
  getReimbursementdata,
  fetchRequestHistory,
  getRejectionReason,
  getClaim,
  placeCall,
  copyClaim,
  deleteClaim,
  uploadInvoice,
  uploadPrescription,
  uploadMerFile,
  deleteInvoice,
  deletePrescription,
  deleteInvoiceItem,
  getSponsorList,
  submitPrescriptionsForDigitization,
  getReimbursementProcedures,
  updateClaim,
  processClaimRequest,
  viewCommentRequest,
  getReimbursementDeficiencyRemarks,
  updateClaimToDocumentsDeficient,
  updateDoctorRegistration,
  getReimbursementPrePostReq,
  addClarificationReason,
  addClarificationSolution,
  pastClaimData,
  getRemarkForDoc,
  updateAcknowledgeBy,
  getPolicyRemarks,
  getPolicyFiles,
  fetchCopyClaimsList,
  updateIsCheckedForDoctor,
  getOpsIsChecked,
  getNotifications,
  markNotificationRead,
  markIsOnBreak,
  getDailyReportsData,
  getAllDailyReportsData,
  markUserClaimSuspicious,
  getConsultations,
} from '../services/reimbursementClaims';

const useCallUserRequest = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return placeCall(body);
    },
    { onSuccess, onError }
  );
};

const useCopyClaimRequest = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return copyClaim(body);
    },
    { onSuccess, onError }
  );
};
const useMarkSuspiciousRequest = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return markUserClaimSuspicious(body);
    },
    { onSuccess, onError }
  );
};

const useDeleteClaim = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return deleteClaim(body);
    },
    { onSuccess, onError }
  );
};

const useUploadPrescription = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return uploadPrescription(body);
    },
    { onSuccess, onError }
  );
};
const useUploadInvoice = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return uploadInvoice(body);
    },
    { onSuccess, onError }
  );
};

const useUploadMerFile = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return uploadMerFile(body);
    },
    { onSuccess, onError }
  );
};
const useAcknowledged = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return updateAcknowledgeBy(body);
    },
    { onSuccess, onError }
  );
};

const useDeleteInvoice = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return deleteInvoice(body);
    },
    { onSuccess, onError }
  );
};
const useDeletePrescription = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return deletePrescription(body);
    },
    { onSuccess, onError }
  );
};

const useDeleteInvoiceItem = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return deleteInvoiceItem(body);
    },
    { onSuccess, onError }
  );
};

const useSubmitPrescriptionsForDigitization = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return submitPrescriptionsForDigitization(body);
    },
    { onSuccess, onError }
  );
};

const useUpdateClaim = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return updateClaim(body);
    },
    { onSuccess, onError }
  );
};

const useClaimRequest = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return processClaimRequest(body);
    },
    { onSuccess, onError }
  );
};

const useUpdateClaimToDocumentsDeficient = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return updateClaimToDocumentsDeficient(body);
    },
    { onSuccess, onError }
  );
};
const useAddClarificationReason = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return addClarificationReason(body);
    },
    { onSuccess, onError }
  );
};

const useClarificationSolution = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return addClarificationSolution(body);
    },
    { onSuccess, onError }
  );
};

const useUpdateDoctorRegistration = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return updateDoctorRegistration(body);
    },
    { onSuccess, onError }
  );
};

const useFetchReimbursementData = (
  page,
  pageSize,
  sortBy,
  sortOrder,
  myCasesFilter,
  claimBy,
  startDate,
  endDate,
  reimbursementId,
  sponsorName,
  q,
  status,
  claimStatus,
  reimbursementType,
  deficientTab,
  vendorName
) => {
  console.log(
    'K-data ',
    claimBy,
    reimbursementId,
    page,
    pageSize,
    q,
    deficientTab,
    reimbursementType
  );
  const params = {
    page,
    size: pageSize,
    claimBy,
    startDate,
    endDate,
    reimbursementId,
    sponsorName,
    q: q ? q : '',
    status,
    claimStatus,
    reimbursementType: reimbursementType ? reimbursementType : '',
    deficientTab: deficientTab ? deficientTab : '',
    vendorName: vendorName ? vendorName : '',
  };
  return useQuery(
    ['getReimbursementData', { params, sortBy, sortOrder }],
    () => {
      return getReimbursementdata(params, sortBy, sortOrder);
    }
  );
};

const useGetRemarks = (
  page,
  pageSize,
  reimbursementId,
  intialDocName,
  qcDocName
) => {
  const params = {
    page,
    pageSize,
    reimbursementId,
    intialDocName,
    qcDocName,
  };
  return useQuery(['getRemarks', {}], () => {
    return getRemarkForDoc(params);
  });
};

const useHistory = (requestId) => {
  return useQuery(['getHistory', { requestId }], () => {
    return fetchRequestHistory(requestId);
  });
};
const usePastClaims = (claimId, pastClaimStdate, pastClaimEdDate) => {
  return useQuery(['getPastClaims', { claimId }], () => {
    return pastClaimData(claimId, pastClaimStdate, pastClaimEdDate);
  });
};

const useReimbursementProcedures = (userId, dateOfInvoice, relativeId) => {
  return useQuery(
    'getReimbursementProcedures',

    () => getReimbursementProcedures(userId, dateOfInvoice, relativeId),

    {
      // The query will not execute until the userId exists
      enabled: !!userId,
    }
  );
};
const useFetchPolicyRemarks = (sponsorId) => {
  return useQuery('fetchPolicyRemarks', () => getPolicyRemarks(sponsorId), {
    // The query will not execute until the sponsorId exists
    enabled: !!sponsorId,
  });
};

const useFetchPolicyFiles = (sponsorId) => {
  return useQuery('fetchPolicyFilesAndFaqs', () => getPolicyFiles(sponsorId), {
    // The query will not execute until the sponsorId exists
    enabled: !!sponsorId,
  });
};
const useFetchCopyClaims = (claimId) => {
  return useQuery('fetchCopyClaims', () => fetchCopyClaimsList(claimId), {
    enabled: !!claimId,
  });
};

const useFetchCommentByOrderId = (body) => {
  const { reimbursementId } = body;
  return useQuery(
    'fetchComments',

    () => viewCommentRequest(reimbursementId),

    {
      // The query will not execute until the reimbursementId exists
      enabled: !!reimbursementId,
    }
  );
};

const useDeficienyRemarks = () => {
  return useQuery(
    'fetchDeficiencyRemarks',

    () => getReimbursementDeficiencyRemarks(),
    {
      select: (res) => {
        return res;
      },
    }
  );
};

const useReimburseRejectionReason = () => {
  return useQuery(
    'getRejectionReasonList',

    () => getRejectionReason(),

    {
      select: (res) => {
        const rejectReasonList = res.map((elem) => ({
          value: elem.value,
          label: `${elem.title}`,
        }));
        return rejectReasonList;
      },
    }
  );
};

const useClaim = (reimbursementId) => {
  return useQuery(
    ['getHistory', { reimbursementId }],
    () => getClaim(reimbursementId),
    {
      select: (res) => {
        return res;
      },
    }
  );
};
const useReimbursementPrePostReq = (reimbursementId) => {
  return useQuery(
    ['getReimbursementPrePost', { reimbursementId }],
    () => getReimbursementPrePostReq(reimbursementId),
    {
      select: (res) => {
        return res;
      },
    }
  );
};

//sponsor get query

const useFetchAllSponsors = () => {
  return useQuery(
    'getSponsorList',

    () => getSponsorList(),

    {
      select: (res) => {
        const sponsor = res.map((elem) => ({
          value: elem.sponsorId,
          label: `${elem.name} ( ${elem.sponsorId} )`,
        }));
        return sponsor;
      },
    }
  );
};
const useUpdateDoctorIsCheckedIn = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return updateIsCheckedForDoctor(body);
    },
    { onSuccess, onError }
  );
};

const useGetDoctorIsCheckedIn = (authToken) => {
  return useQuery(
    'fetchCheckIn',

    () => getOpsIsChecked(authToken),
    {
      // The query will not execute until the authToken exists
      enabled: !!authToken,
    }
  );
};

const useFetchNotifications = (AdminUser) => {
  return useQuery('notifications', () => getNotifications(AdminUser), {
    // The query will not execute until the AdminUser exists
    enabled: !!AdminUser,
  });
};
const useMarkNotificationRead = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return markNotificationRead(body);
    },
    { onSuccess, onError }
  );
};

const useUpdateIsOnBreak = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return markIsOnBreak(body);
    },
    { onSuccess, onError }
  );
};

const useGetDailyReport = (starDate, endDate, fileDownload) => {
  return useQuery(
    ['fetchDailyDoctorReport', starDate, endDate, fileDownload],

    () => getDailyReportsData(starDate, endDate, fileDownload)
  );
};
const useAllGetDailyReport = (reqDate, isDailyReport, endDate) => {
  return useQuery(
    ['fetchAllDailyDoctorReport', reqDate, isDailyReport, endDate],

    () => getAllDailyReportsData(reqDate, isDailyReport, endDate)
  );
};

// const fetchConsultations = (userId, appointmentId) => {
//   return useQuery(
//     ['getConsultation', userId, appointmentId],

//     () => getConsultations(userId, appointmentId)
//   );
// };
export {
  useFetchReimbursementData,
  useHistory,
  useReimburseRejectionReason,
  useClaim,
  useCallUserRequest,
  useCopyClaimRequest,
  useDeleteClaim,
  useUploadInvoice,
  useUploadPrescription,
  useUploadMerFile,
  useDeleteInvoice,
  useDeletePrescription,
  useDeleteInvoiceItem,
  useFetchAllSponsors,
  useSubmitPrescriptionsForDigitization,
  useReimbursementProcedures,
  useUpdateClaim,
  useClaimRequest,
  useFetchCommentByOrderId,
  useDeficienyRemarks,
  useUpdateClaimToDocumentsDeficient,
  useUpdateDoctorRegistration,
  useReimbursementPrePostReq,
  useAddClarificationReason,
  useClarificationSolution,
  usePastClaims,
  useGetRemarks,
  useAcknowledged,
  useFetchPolicyRemarks,
  useFetchPolicyFiles,
  useFetchCopyClaims,
  useUpdateDoctorIsCheckedIn,
  useGetDoctorIsCheckedIn,
  useFetchNotifications,
  useMarkNotificationRead,
  useUpdateIsOnBreak,
  useGetDailyReport,
  useAllGetDailyReport,
  useMarkSuspiciousRequest,
  //fetchConsultations,
};
