
import React, { useRef, useCallback, memo } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import ReactTable from 'react-table';



export default function DoctorVerifyTableModal(
    {
        doctorTableModal,
        closeDoctorTableModal,
        registrationDoctorArr,
        selectedDoctor,
        setSelectedDoctor,
        updateDoctorRegistrationInfo,
        verifyAgain }) {


    const columns = [
        {
            Headers: '',
            accessor: 'doctorId',
            Cell: (data, record) => {
                return (
                    <input
                        type={'radio'}
                        checked={
                            selectedDoctor &&
                            selectedDoctor.doctorId === data.original.doctorId
                        }
                        onChange={() => {
                            setSelectedDoctor(data.original)
                        }}
                    />
                );
            },
            filterable: false,
        },
        {
            Header: 'Registration No',
            style: { whiteSpace: 'unset' },
            accessor: 'registrationNo',
            render: (text) => {
                console.log(text);
                return <p>{text.registrationNo}</p>;
            },
            filterable: true,
        },
        {
            Header: 'Doctor Name',
            style: { whiteSpace: 'unset' },
            accessor: 'firstName',
            render: (text) => {
                console.log(text);
                return <p>{text.firstName}</p>;
            },
            filterable: true,
            filterMethod: (filter, row) => {
                return row[filter.id].includes(filter.value);
            },
        },
        {
            Header: 'Registration Date',
            style: { whiteSpace: 'unset' },
            accessor: 'regDate',
            render: (text) => {
                return <p>{text.regDate}</p>;
            },
            filterable: false,
        },
    ];

    return (
        < Modal
            isOpen={doctorTableModal}
            style={{ borderRadius: '6px' }}
            toggle={closeDoctorTableModal}
            scrollable={true}
        >
            <ModalHeader
                toggle={closeDoctorTableModal}
                style={{ backgroundColor: '#714FFF', }}
            >

                <h1 className='text-white text-center' style={{ fontSize: '18px' }}>
                    Doctors Detail
                </h1>
            </ModalHeader>

            <ModalBody

            >
                <div
                    style={{
                        height: '85%',
                        overflow: 'scroll',
                        boxShadow: '0px 1px 2px 0px #b4adad',
                    }}
                >
                    <Row className="m-2">

                        <Col xs={12} md={12}>
                            <ReactTable
                                filterable
                                sortable={false}
                                style={{ flex: 1 }}
                                data={registrationDoctorArr}
                                collapseOnDataChange={false}
                                columns={columns}
                                manual={false}
                                className="-striped -highlight"
                            />
                        </Col>
                    </Row>
                </div>

                <div className=""
                    style={{
                        height: '14%',
                        marginTop: '12px',
                    }}
                >


                    <div className='row mt-2'>
                        <div className='col-6  text-right'>
                            <button
                                className="btn btn-success"
                                onClick={updateDoctorRegistrationInfo}
                                disabled={!selectedDoctor}
                            >
                                Verify Doctor
                            </button>
                        </div>
                        <div className='col-6'>
                            <button className="btn btn"
                                style={{ backgroundColor: '#714FFF', color: 'white' }}
                                onClick={verifyAgain}
                            >
                                Find Doctor again
                            </button>
                        </div>
                    </div>

                </div>

            </ModalBody>
        </Modal >
    )
}