import React, { useState, useCallback, useEffect } from 'react';
import VerificationInput from './widgets/VerificationInput';
import { connect } from 'react-redux';
import AppointmentDateUpdateModal from './modals/AppointmentDateUpdateModal';
import { showError } from '../../utils';
import moment from 'moment';
import DateSelectionInput from './widgets/DateSelectionInput';

const AppointmentDateSelectionInput = ({
    helpText = '',
    required = true,
    onChange = () => { },
    appointmentDate = null,
    disabled = false,
}) => {

    const handleDateUpdate = useCallback((value) => {
        value = moment(value).format('DD-MM-YYYY');
        onChange(prevAppointmentDate => {
            if (prevAppointmentDate?.isUpdated && !value) {
                return prevAppointmentDate;
            }
            if (value) {
                return { ...prevAppointmentDate, value, isUpdated: true, isValidated: false };
            } else if (!value && !prevAppointmentDate?.value) {
                return showError({ data: { errorMessage: 'Cannot validate date as it is missing.' } });
            }

            return { ...prevAppointmentDate, isUpdated: false, isValidated: true };

        });
    }, [onChange]);

    return <DateSelectionInput
        helpText='Select appointment date'
        placeholderText='Appointment Date'
        selection={moment(appointmentDate?.value, 'DD-MM-YYYY').isValid() && appointmentDate?.value ? new Date(moment(appointmentDate?.value, 'DD-MM-YYYY').toISOString()) : null}
        onChange={handleDateUpdate}
        disabled={disabled}
        disabledDate={current => current && current > moment().endOf('day')}
    />
};

const matchStateToProps = (state) => ({
    authToken: state.app.authToken
});

export default connect(matchStateToProps)(AppointmentDateSelectionInput);
