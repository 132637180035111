
import React from "react";

export default function Comment(props) {
    const { name, message, time } = props.comment;

    return (
        <div className="media mb-1">

            <div className="media-body p-4 shadow-sm rounded bg-light ml-4 mr-4 "
                style={{
                    border: '1px solid #714FFF',

                }}>
                <small className="float-right text-muted">{time}</small>
                <h6 className="mt-0 mb-3 text-[#3A3A4A]">{name}</h6>
                <hr style={{
                    backgroundColor: '#714FFF',
                    margin: '30px - 20px 20px',
                    width: '100%'
                }}></hr>
                <h6 className="mt-3">
                    {message}
                </h6>

            </div>
        </div>
    );
}
