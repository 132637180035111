import { Divider, Modal } from "antd";
import React, { useCallback, useState } from 'react';
import CommonButton from "../widgets/CommonButton";
import SingleSelectionSearchDropdown from "../widgets/SingleSelectionSearchDropdown";
import { connect } from "react-redux";
import { getCenters } from "../../../services";
import { CommonHyperlink, NetworkCenterOption, SimpleColumn, SimpleRow } from "../styledCommonComponents";
import CommonMapModal from "./CommonMapModal";
import CommonPlainInput from "../widgets/CommonPlainInput";
import { showError } from "../../../utils";
import SingleCommonSelectionDropdown from "../widgets/SingleCommonSelectionDropdown";

const NetworkDoctorUpdateModal = ({
    open = false,
    onClose = () => { },
    authToken,
    onChange = () => { },
    verticals = [],
}) => {

    const [isMapOpen, setIsMapOpen] = useState(false);
    const [localDoctorPractice, setLocalDoctorPractice] = useState({ doctor: null, practice: null, center: null });

    const getCenterFromServer = useCallback((query) => {
        return getCenters(authToken, query)
            .then(result => {
                return result.map(item => ({ ...item, data: item, label: `${item.title}, ${item.address}`, value: item.centerId }));
            });
    }, [authToken]);

    const handleDoctorCenterUpdate = useCallback(({ doctor = null, center = null, practice = null }) => {
        setLocalDoctorPractice(prevState => ({
            doctor: doctor || prevState.doctor,
            center: center || prevState.center,
            practice: practice || prevState.practice,
        }));
    }, []);

    const handleUpdateClick = useCallback(() => {
        if (!localDoctorPractice.doctor.value || !localDoctorPractice.center.value) {
            return showError({ data: { errorMessage: 'Please select center and doctor to proceed' } });
        }
        onChange(
            { value: null, label: `${localDoctorPractice.doctor?.label}, ${localDoctorPractice.center?.label}` },
            localDoctorPractice.doctor, localDoctorPractice.center, null
        );
        onClose();
    }, [onChange, onClose, localDoctorPractice]);

    return <Modal
        open={open}
        onClose={onClose}
        title='Update Center and Doctor'
        footer={null}
        onCancel={onClose}
    >
        <div>
            <SingleSelectionSearchDropdown
                helpText='Select Network Center'
                required
                emptyStateText='Search network centers'
                placeholderText='Search Center'
                apiCallback={getCenterFromServer}
                onChange={(_, center) => handleDoctorCenterUpdate({ center: { ...center, ..._, isUpdated: true } })}
                selection={localDoctorPractice.center}
                optionJSX={option => <NetworkCenterOption>
                    <span style={{ fontWeight: '600', fontSize: '12.5px' }}>{option.data.title}</span>
                    <span style={{ fontWeight: '500', fontSize: '10px', color: '#555' }}>{option.data.address}</span>
                </NetworkCenterOption>}
            />
            <SimpleRow style={{ columnGap: '10px', alignItems: 'center', width: '100%' }}>
                <CommonPlainInput
                    helpText='Add Doctor' style={{ flex: 1 }}
                    selection={localDoctorPractice.doctor?.label} disabled={!localDoctorPractice.center?.value}
                    onChange={value => setLocalDoctorPractice(prev => ({ ...prev, doctor: { ...prev.doctor, label: value, value, isUpdated: true } }))}
                />
                <SingleCommonSelectionDropdown
                    defaultOptions={verticals.map(item => ({ value: item.verticalId, label: item.verticalName, ...item }))}
                    style={{ flex: 1 }} helpText='Select Vertical' required
                    selection={localDoctorPractice.doctor?.verticalId || null} disabled={!localDoctorPractice.center?.value}
                    onChange={(_, data) => setLocalDoctorPractice(prev => ({ ...prev, doctor: { ...prev.doctor, verticalId: data.verticalId, verticalName: data.verticalName, isUpdated: true } }))}
                />
            </SimpleRow>

            <SimpleColumn style={{ marginTop: '20px', rowGap: '10px', alignItems: 'center' }}>
                <CommonButton onClick={handleUpdateClick} text='Update' />
                <CommonHyperlink onClick={() => setIsMapOpen(true)}>
                    Center still not found? Locate on map
                </CommonHyperlink>
            </SimpleColumn>

            <CommonMapModal
                open={isMapOpen} onClose={() => setIsMapOpen(false)}
                onChange={onChange} onAllClose={onClose}
            />

        </div>
    </Modal>
};

const matchStateToProps = (state) => ({
    authToken: state.app.authToken,
    verticals: state?.app?.verticals || [],
});

export default connect(matchStateToProps)(NetworkDoctorUpdateModal);
