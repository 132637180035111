import React, { useRef, useCallback, memo } from 'react';
import moment from 'moment';
import Select from 'react-select';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
import { Input } from 'reactstrap';

export default function DependentsContainer({
  policyDetail,
  reimbursementForDependent,
  reimbursementFor,
  selectReimbursementForHolder,
  policyDependents,
  renderDependent,
  policyMembers,
  policyId,
  fetchHolderValue,
  isWalletLoading,
}) {
  console.log(policyMembers, policyId, 'policyMembers');

  const HolderSelection = (
    reimbursementForDependent,
    reimbursementFor,
    policyMembers,
    policyId,
    p
  ) => {
    const value =
      !reimbursementForDependent &&
      reimbursementFor === policyMembers.holder.holderId &&
      (policyMembers.newPolicy === false || policyId === p.policyId);
    fetchHolderValue(value);
    return value;
  };

  return (
    <div className="bg-white rounded p-3 m-1">
      <div className="col-12">
        <h2
          className="m-1"
          style={{ color: '#714FFF', fontSize: '17px', fontWeight: 'bold' }}
        >
          Policy Holders and Dependents
        </h2>
        {isWalletLoading ? (
          <ClipLoader
            color={'#714FFF'}
            loading={isWalletLoading}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <>
            {policyMembers &&
            (policyMembers.holder || policyMembers.dependents.length > 0) ? (
              <div className="">
                <table style={{ width: '95%' }}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Date of Birth</th>
                      <th>Policy Name</th>
                      <th>Policy Start Date</th>
                      <th>Policy End Date</th>
                      {policyMembers?.holder?.showDentalPastClaimed && (
                        <th>Available Balance</th>
                      )}
                      <th>Reimbursement for</th>
                    </tr>
                  </thead>
                  <tbody>
                    {policyMembers.holder
                      ? policyMembers.holder.policies.map((p, idx) => {
                          return (
                            <tr key={idx}>
                              {idx === 0 ? (
                                <>
                                  <td
                                    rowSpan={
                                      policyMembers.holder.policies.length
                                    }
                                  >
                                    {policyMembers.holder.name}
                                  </td>
                                  <td
                                    rowSpan={
                                      policyMembers.holder.policies.length
                                    }
                                  >
                                    {policyMembers.holder.dob}
                                  </td>
                                </>
                              ) : (
                                ''
                              )}
                              <td>{p.policyName}</td>
                              <td>{p.policyStartDate}</td>
                              <td>{p.policyEndDate}</td>
                              {policyMembers?.holder?.showDentalPastClaimed && (
                                <td>{p.remainingAmount === null ? 'N/A' : p.remainingAmount}</td>
                              )}
                              <td>
                                <div className="flex custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="checkmark"
                                    style={{ height: '13px', width: '18px' }}
                                    checked={HolderSelection(
                                      reimbursementForDependent,
                                      reimbursementFor,
                                      policyMembers,
                                      policyId,
                                      p
                                    )}
                                    onChange={(e) =>
                                      selectReimbursementForHolder(
                                        policyMembers.holder.holderId,
                                        p.policyId,
                                        e
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : ''}
                    {policyMembers &&
                      policyMembers.dependents
                        .filter((e) => e.dependentId !== null)
                        .map(renderDependent)}
                  </tbody>
                </table>
              </div>
            ) : (
              <h2
                className="m-1"
                style={{ color: '#714FFF', fontSize: '14px' }}
              >
                {' '}
                No policy details found
                {policyMembers?.msg ? (
                  <>
                    <h4
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'Montserrat',
                        padding: '12px',
                        background: '#FFF2EE',
                        borderRadius: '12px',
                        marginBottom: '12px',
                      }}
                    >
                      {policyMembers.msg}.
                    </h4>
                  </>
                ) : (
                  ''
                )}
              </h2>
            )}
          </>
        )}
      </div>
    </div>
  );
}
