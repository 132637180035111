import React from 'react';
import { NewDigitisationButton, Spinner } from '../styledCommonComponents';

const CommonButton = ({
    text = 'Submit',
    onClick = () => {},
    primary = true,
    loading = false,
    disabled = false,
    ...props
}) => {

    return <NewDigitisationButton 
        {...props} loading = {loading} disabled = {disabled} primary = {primary} 
        onClick = {(!disabled && !loading) ? onClick : () => {}}
    >
        {loading ? <Spinner primary = {primary} /> : text}
    </NewDigitisationButton>
};

export default CommonButton;
