import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import '../../../src/output.css';
import { FaUserCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useOpsLogin } from '../../../src/queries/loginQuery';
import VisitLogo from '../../assets/images/VisitLogo.png';
import { useDispatch } from 'react-redux';
//import { setAuthToken } from '../../actions';
import { setAuthToken, setOpsUser, setReimbursement, setVerticals } from '../../actions';
import ClipLoader from 'react-spinners/ClipLoader';
export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLogging, setIsLogging] = useState(false);
  // const notify = () => toast("Wow so easy!");
  const OnError = (error) => {
    setIsLogging(false);
    toast.error('Incorrect username or password provided.', {
      icon: '🤯',
      theme: 'dark',
    });
  };
  const OnSuccess = (res) => {
    setIsLogging(false);
    dispatch(setAuthToken(res.authToken));
    dispatch(setVerticals(res.verticals));
    dispatch(setOpsUser(res.result));
    navigate('/reimbursement-claims', {
      state: { opsUserName: res.result.name },
    });
    toast.success(`Welcome ${res.result.name}`, { icon: '✅', theme: 'dark' });
    if (!res.result.checkedIn) {
      setTimeout(() => {
        toast.info('Check-in to assign the cases to yourself', {
          theme: 'dark',
        });
      }, 5000);
    }
  };

  const { mutate: opsUserLogin } = useOpsLogin(OnSuccess, OnError);

  const handleSubmit = () => {
    setIsLogging(true);
    opsUserLogin({ userName, password });
  };

  useEffect(() => {
    dispatch(setAuthToken(''));
    //dispatch(setOpsUser(null));
    //dispatch(setReimbursement(null));
  }, []);

  return (
    <div className="w-100 h-100 flex flex-row" style={{ height: '100vh' }}>
      <div
        className="w-50 h-100 flex flex-col justify-center"
        style={{ backgroundColor: '#F3F5F9' }}
      >
        <div
          className="flex flex-row"
          style={{
            margin: '25% 10% 25% 10%',
            backgroundColor: '#FFFFFF',
            height: '100%',
          }}
        >
          <div className="m-auto">
            <img src={VisitLogo} />
          </div>
        </div>
      </div>
      <div
        className="w-50 h-100 flex flex-col justify-evenly overflow-hidden"
        style={{
          padding: '10%',
          backgroundColor: '#714FFF',
          position: 'relative',
        }}
      >
        <div
          className=""
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.06)',
            height: '380px',
            width: '380px',
            marginLeft: '-50px',
            marginTop: '-400px',
            display: 'inline',
            borderRadius: '50%',
          }}
        ></div>
        <div
          className=""
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.06)',
            height: '800px',
            width: '800px',
            marginBottom: '-500px',
            marginLeft: '200px',
            display: 'inline',
            borderRadius: '50%',
          }}
        ></div>
        <div
          style={{
            width: '450px',
            height: '285px',
            position: 'absolute',
            top: '150px',
          }}
        >
          <p
            className=""
            style={{
              color: '#fff',
              fontSize: '45px',
              fontWeight: '600',
              marginBottom: '6px',
            }}
          >
            Welcome Back!
          </p>

          <div
            className="col-md-4"
            style={{
              maxWidth: '100%',
              flex: '0',
              paddingLeft: '0px',
              paddingRight: '0px',
            }}
          >
            <div className="">
              <label
                for="email"
                className="block text-xs mb-1"
                style={{ color: '#fff', fontSize: '16px' }}
              >
                Username or Email
              </label>
              <input
                class="w-full border rounded p-2 outline-none focus:shadow-outline"
                type="text"
                name="username"
                id="username"
                placeholder="Enter Username"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="mt-2">
              <label
                for="password"
                className="block text-xs mb-1"
                style={{ color: '#ffff', fontSize: '16px' }}
              >
                Password
              </label>
              <input
                class="w-full border rounded p-2 outline-none focus:shadow-outline"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mt-5">
              <button
                className="btn btn"
                style={{ backgroundColor: '#ffff', width: '450px' }}
                type="submit"
                onClick={handleSubmit}
              >
                {!isLogging ? (
                  'Login'
                ) : (
                  <ClipLoader
                    color={'#714FFF'}
                    loading={isLogging}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
