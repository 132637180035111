import { useMutation, useQuery } from 'react-query';
import { sendLogoutRequest } from '../../src/services/logOut';
const useLogOut = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return sendLogoutRequest(body);
    },
    { onSuccess, onError }
  );
};
export { useLogOut };
