import PubNub from 'pubnub';
import config from '../config';

class LiveDataClient {
  constructor(pubnub) {
    this.pubnub = pubnub;
  }

  subscribe(channelId, callbacks) {
    this.pubnub.subscribe({
      channels: [channelId],
      // channelGroups: [channelId],
    });

    console.log('subscribed to channel', { channelId });
    this.messageListener = this.addMessageListener((payload, channelName) => {
      if (channelName === channelId) {
        if (callbacks && callbacks.dataChat) {
          callbacks.dataChat(payload);
        }

        // if (callbackMethod) {
        //   callbackMethod(payload);
        // }
      }
    });
  }

  unsubscribe(channelId) {
    this.pubnub.unsubscribe({
      channels: [channelId],
    });
    this.removeListener(this.messageListener);
    console.log('unsubscribed to channel', { channelId });
  }

  addMessageListener(fnc) {
    const listener = {
      message: (m) => {
        const channelName = m.channel; // The channel for which the message belongs

        const payload = m.message; // The Payload

        fnc(payload, channelName, '', '');
        return;
      },
    };

    this.pubnub.addListener(listener);
    console.log(this.pubnub);
    return listener;
  }

  removeListener(fnc) {
    this.pubnub.removeListener(fnc);
  }

  publish(sessionId) {
    console.log(sessionId);

    this.pubnub.publish(
      {
        message: {
          name: 'user_joined',
          sessionId: sessionId,
        },
        channel: sessionId,
      },
      (status, response) => {
        if (status.error) {
          // Handle error
          console.log(status);
          return;
        }
        console.log(status, response);
      }
    );
  }
}

let client;

const initializeLiveDataClient = (user) => {
  if (client) {
    return client;
  } else {
    const pubnubParams = {
      subscribeKey: config.pubnubSubscribeKey,
      publishKey: config.pubnubPublishKey,
      authKey: user.id,
      uuid: `browser::${user.id}`,
    };

    const pubnub = new PubNub(pubnubParams);

    client = new LiveDataClient(pubnub);
    return client;
  }
};

export { initializeLiveDataClient };
