import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LoginPage from './pages/Login/Login';
import ReimbursementClaims from './pages/ReimbursementClaims/ReimbursementClaims';
import ReimburseClaimRequest from './pages/ReimbursementClaims/ReimburseClaimRequest';
import './App.css';
import ReimbursementNavbar from './components/ReimbursementNavbar/ReimbursementNavbar';
import SideBar from './components/ReimbursementNavbar/SideBar';
import Admin from './pages/AdminReimbursement/Admin';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import LoginHeader from './components/LoginHeader/LoginHeader';
import { ReactQueryDevtools } from 'react-query/devtools';
import styled from 'styled-components';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QcReimbursementClaimsTable from './pages/QCReimbursementClaims/QcReimbursementClaimsTable';
import QcRemarks from './pages/QCReimbursementClaims/QcRemarks';
import ManageDoctor from './pages/AdminReimbursement/ManageDoctor';
import { useDispatch } from 'react-redux';
import AllDoctorDailyReport from './pages/ReimbursementClaims/AllDoctorDailyReport';
import SuspiciousUser from './pages/AdminReimbursement/SuspiciousUser';
import {
  setSearchStringAction,
  setNotificationModalClose,
  setOnBreakModalClose,
  setSponsorDataWitCount,
} from './actions/index';
import DoctorDailyReport from './pages/ReimbursementClaims/DoctorDailyReport';

const OuterComponent = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  align-self: stretch;
  justify-content: stretch;
`;
const BodyContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex: 9;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App({ authToken, isOpen, showSideBar, userName, brokerId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchStringAction(''));
    dispatch(setSponsorDataWitCount([]));
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <OuterComponent>
        <BrowserRouter>
          <ReimbursementNavbar
            opsName={userName}
            isOpen={isOpen}
            authToken={authToken}
          />
          <BodyContainer
            style={{ height: authToken ? 'calc(100vh - 76px)' : '100vh' }}
          >
            {isOpen && authToken ? (
              <>
                {brokerId && brokerId ? (
                  ''
                ) : (
                  <SideBar style={{ border: '1px solid red' }} />
                )}
              </>
            ) : (
              ''
            )}
            <div
              style={{
                width: isOpen && authToken && !brokerId ? '82%' : '100%',
              }}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <Navigate
                      to={authToken ? '/reimbursement-claims' : '/login'}
                    />
                  }
                />
                <Route
                  path="/reimbursement-claims"
                  name="reimbursementTable"
                  element={<ReimbursementClaims />}
                />
                <Route
                  path="/login"
                  element={
                    <LoginPage authToken={authToken} opsName={userName} />
                  }
                />
                <Route
                  path="/reimburse-claim/:claimId"
                  name="ConsultReimbursement"
                  element={<ReimburseClaimRequest />}
                />
                <Route
                  path="/qc-reimbursement"
                  element={<QcReimbursementClaimsTable />}
                ></Route>
                <Route path="/qc-remark" element={<QcRemarks />}></Route>
                <Route path="/reimbursement-admin" element={<Admin />}></Route>

                <Route
                  path="/reimbursement-doctor"
                  element={<ManageDoctor />}
                ></Route>
                <Route
                  path="/doctor-daily-report"
                  name="DoctorDailyReport"
                  element={<DoctorDailyReport />}
                />
                <Route
                  path="/all-doctor-daily-report"
                  name="AllDoctorDailyReport"
                  element={<AllDoctorDailyReport />}
                />
                <Route
                  path="/suspicious-users"
                  name="suspiciousUsers"
                  element={<SuspiciousUser />}
                />
              </Routes>
            </div>
          </BodyContainer>
        </BrowserRouter>
      </OuterComponent>

      <ToastContainer hideProgressBar />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}
const mapStateToProps = (state) => {
  const isOpen = state.app.showSideBar;
  const authToken = state.app.authToken;
  const userName = state.app.opsUser;
  const showSideBar = state.app.showSideBar;
  const brokerId = state.app.opsUser?.brokerId;
  return { authToken, showSideBar, isOpen, userName, brokerId };
};
export default connect(mapStateToProps)(App);
