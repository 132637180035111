import React, { Component } from 'react';
import ReactSelect, { components } from 'react-select';

import { getVendorList } from '../../services/reimbursementClaims';
import { SearchIcon } from './svgFiles';

export default class VendorFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
    };
  }

  handleChange = (event) => {
    //const value = event.value;
    this.props.onChangeValue(event ? event : '');
  };

  getVendors = () => {
    return getVendorList()
      .then((response) => {
        console.log(response, 'Response from server');
        this.setState({
          vendors: response,
        });
      })
      .catch(() => {
        this.setState({
          vendors: [],
        });
      });
  };

  componentDidMount() {
    this.getVendors();
  }

  render() {
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <SearchIcon />
          </components.DropdownIndicator>
        )
      );
    };
    const customStyles = {
      control: (base) => ({
        ...base,
        flexDirection: 'row-reverse',
      }),
    };
    const { vendors } = this.state;
    return (
      <div>
        <ReactSelect
          placeholder="Search.."
          onChange={this.handleChange}
          isClearable={true}
          theme={(theme) => ({
            ...theme,
            borderRadius: '10px',
            borderColor: 'red',
            colors: {
              ...theme.colors,
              primary50: '#d5d5d5',
              primary25: '#f5f5f5',
              primary: '#D5C9FF',
            },
          })}
          value={this.props.value.value}
          options={vendors.map((e) => {
            return {
              value: e.id,
              label: e.insurerName,
            };
          })}
          components={{
            DropdownIndicator,
          }}
          styles={customStyles}
          menuPortalTarget={document.body}
        />
      </div>
    );
  }
}
