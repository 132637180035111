import React, { Component } from 'react';
import ReactSelect, { components } from 'react-select';
import { useReimbursementDocList } from '../../queries/reimbursementAdminQuery';
import { SearchIcon } from '../../components/common/svgFiles';
import { getReimbursementDoctorList } from '../../services/reimbursementAdmin';

// export default function DoctorsFilter({ onChangeValue }) {
//   const { error: ErrorInDoctorList, data: opsDoctorList } =
//     useReimbursementDocList();
//   if (opsDoctorList) {
//     opsDoctorList.push({
//       value: 'unassigned',
//       label: 'unassigned',
//     });
//   }
//   const handleChange = (event) => {
//     //const value = event.value;
//     onChangeValue(event ? event : '');
//   };
//   const DropdownIndicator = (props) => {
//     return (
//       components.DropdownIndicator && (
//         <components.DropdownIndicator {...props}>
//           <SearchIcon />
//         </components.DropdownIndicator>
//       )
//     );
//   };
//   const customStyles = {
//     control: (base) => ({
//       ...base,
//       flexDirection: 'row-reverse',
//     }),
//   };

// }
export default class DoctorsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorList: [
        {
          value: 'unassigned',
          label: 'unassigned',
        },
      ],
    };
  }

  handleChange = (event) => {
    //const value = event.value;
    this.props.onChangeValue(event ? event : '');
  };
  getDoctorList = () => {
    return getReimbursementDoctorList()
      .then((response) => {
        const doctorList = response.map((d) => ({
          value: d.opsId,
          label: `${d.name}`,
        }));
        if (doctorList) {
          doctorList.push({
            value: 'unassigned',
            label: 'unassigned',
          });
        }
        this.setState({
          doctorList,
        });
      })
      .catch(() => {
        this.setState({
          doctorList: [],
        });
      });
  };

  componentDidMount() {
    if (this.state.doctorList.length === 1) this.getDoctorList();
  }
  render() {
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <SearchIcon />
          </components.DropdownIndicator>
        )
      );
    };
    const customStyles = {
      control: (base) => ({
        ...base,
        flexDirection: 'row-reverse',
      }),
    };
    // const { error: ErrorInDoctorList, data: opsDoctorList } =
    //   useReimbursementDocList();

    return (
      <ReactSelect
        onChange={this.handleChange}
        isClearable={true}
        value={this.props.value.value}
        options={this.state.doctorList}
        theme={(theme) => ({
          ...theme,
          borderRadius: '10px',
          borderColor: 'red',
          colors: {
            ...theme.colors,
            primary50: '#d5d5d5',
            primary25: '#f5f5f5',
            primary: '#D5C9FF',
          },
        })}
        styles={customStyles}
        components={{
          DropdownIndicator,
        }}
        menuPortalTarget={document.body}
      />
    );
  }
}
