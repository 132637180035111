import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { Radio } from 'antd';
import { useState } from 'react';
import Password from 'antd/lib/input/Password';
import { useEffect } from 'react';
import { CreateDoctorAccount } from '../../services/reimbursementAdmin';
import { toast } from 'react-toastify';
const Heading = styled.h3`
  color: #714fff;
font-family: Montserrat
font-style: SemiBold
font-size: 18px
line-height: 22px
line-height: 100%
align: Left
vertical-align: Center
letter-spacing: -2%
`;
const SubHeading = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  color: #3a3a4a;
  font-weight: 500;
`;
const PasswordView = styled(AiOutlineEye)`
  font-size: 1.75rem;
  font-weight: normal;
  color: #ebe4ff;
`;
const InputBox = styled.input`
  border: 2px solid #ebe4ff;
  width: 478px;
  height: 52px;
  border-radius: 6px;
  &:focus {
    outline: #ebe4ff;
    border-color: #ebe4ff;
  }
  &::placeholder {
    color: #69686a;
    font-size: 16px;
    font-weight: 500;
    font-family: Montserrat;
  }
`;
const StyledRadio = styled(Radio)`
font-size: 16px;
 line-height: 24px
height: 24px
 font-weight: 600;
font-family:Montserrat;        
`;

const InputBoxPassword = styled.input`
  border: 2px solid #ebe4ff;
  width: 478px;
  height: 52px;
  border-radius: 6px;
  &:focus {
    outline: #ebe4ff;
    border-color: #ebe4ff;
  }
  &::placeholder {
    color: #69686a;
    font-size: 16px;
    font-weight: 500;
    font-family: Montserrat;
  }

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    text-align: center;
    font-size: 1.2em;
    line-height: 1.2;
    color: #ccc;
    pointer-events: none;
  }
`;

export default function CreateCredModal({ isOpen, Close }) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [passwordType, setPasswordType] = useState('password');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [permissionReimbursement, setPermissionReimbursement] = useState(false);
  const [qcPermission, setQcPermission] = useState(false);
  const [adminPermission, setAdminPermission] = useState(false);
  const [emailValidationCheck, setEmailValidationCheck] = useState(false);
  const [phoneVerify, setPhoneVerify] = useState(false);
  const checkIsDisabled = () => {
    if (name && email && userName && password && phone) {
      if (permissionReimbursement || qcPermission || adminPermission) {
        return setIsDisabled(false);
      }
      return setIsDisabled(true);
    }
    return setIsDisabled(true);
  };
  useEffect(() => {
    checkIsDisabled();
  });

  const createUser = async () => {
    const body = {
      name,
      phone,
      email,
      password,
      userName,
      showQc: qcPermission,
      showReimbursementAdmin: adminPermission,
      showClaims: permissionReimbursement,
    };
    await CreateDoctorAccount(body)
      .then((res) => {
        toast.success('User Created Successfully.', { theme: 'dark' });
        Close();
      })
      .catch((err) => {
        toast.error(err.errorMessage || 'something went wrong.', {
          theme: 'dark',
        });
      });
  };

  const viewPassword = () => {
    if (passwordType === 'text') {
      return setPasswordType('password');
    }
    return setPasswordType('text');
  };
  const onChangeReimbursement = (e) => {
    console.log('radio checked', e.target.value);
    setPermissionReimbursement(e.target.value);
  };
  const onChangeQcPermission = (e) => {
    setQcPermission(e.target.value);
  };
  const OnChangeAdminPermission = (e) => {
    setAdminPermission(e.target.value);
  };
  const onChangeEmail = (e) => {
    const regex =
      /^([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$/;
    setEmailValidationCheck(true);
    if (regex.test(e.target.value)) {
      setEmail(e.target.value);
      setEmailValidationCheck(false);
    }
  };
  const OnChangePhone = (e) => {
    const regexExp = /^[6-9]\d{9}$/gi;
    setPhoneVerify(true);
    if (regexExp.test(e.target.value)) {
      setPhone(e.target.value);
      setPhoneVerify(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      style={{ maxWidth: '673px' }}
      toggle={Close}
    >
      <ModalHeader toggle={Close}>
        <Heading>Create Credentials</Heading>
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-row">
          <div className="basis-1/4 mt-2">
            <SubHeading>Name</SubHeading>
          </div>
          <div className="basis-1/2 ">
            <InputBox
              placeholder="  Please enter name"
              onChange={(e) => setName(e.target.value)}
            ></InputBox>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="basis-1/4 mt-4">
            <SubHeading>Email</SubHeading>
          </div>
          <div className="basis-1/2 mt-3">
            <InputBox
              placeholder="  Please enter email"
              type="email"
              pattern="[^ @]*@[^ @]*"
              required
              onChange={(e) => onChangeEmail(e)}
            ></InputBox>
            {emailValidationCheck ? (
              <label className="text-danger">Email Address is invalid !</label>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="basis-1/4 mt-4">
            <SubHeading>Phone no</SubHeading>
          </div>
          <div className="basis-1/2 mt-3">
            <InputBox
              placeholder="  Please enter mobile number"
              onChange={(e) => OnChangePhone(e)}
            ></InputBox>
            {phoneVerify ? (
              <label className="text-danger">Phone is invalid !</label>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="basis-1/4 mt-4">
            <SubHeading>Username</SubHeading>
          </div>
          <div className="basis-1/2 mt-3">
            <InputBox
              placeholder="  Please enter  username"
              onChange={(e) => setUserName(e.target.value)}
            ></InputBox>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="basis-1/4 mt-4">
            <SubHeading>Password</SubHeading>
          </div>
          <div className="basis-1/2 mt-3" style={{ position: 'relative' }}>
            <InputBoxPassword
              onChange={(e) => setPassword(e.target.value)}
              type={passwordType}
              placeholder="  Please enter  password"
            ></InputBoxPassword>
            {password.length > 0 ? (
              <button onClick={viewPassword}>
                <PasswordView
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '15px',
                    transform: 'translateY(-50%)',
                  }}
                />
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="basis-1/4 mt-4">
            <SubHeading>Permissions</SubHeading>
          </div>
          <div className="basis-1/2 mt-4">
            <SubHeading> Reimbursement claims - </SubHeading>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="basis-1/4 mt-1"></div>
          <div className="basis-1/2 mt-1">
            <Radio.Group
              onChange={onChangeReimbursement}
              value={permissionReimbursement}
            >
              <StyledRadio value={true} style={{}}>
                Yes
              </StyledRadio>
              <StyledRadio value={false}>No</StyledRadio>
            </Radio.Group>
          </div>
        </div>
        {/* <div className="flex flex-row">
          <div className="basis-1/4 mt-1"></div>
          <div className="basis-1/2 mt-1">
            <SubHeading> QC remarks - </SubHeading>
            <Radio.Group
            //onChange={onChange} value={value}
            >
              <StyledRadio value={true} style={{}}>
                Yes
              </StyledRadio>
              <StyledRadio value={false}>No</StyledRadio>
            </Radio.Group>
          </div>
        </div> */}
        <div className="flex flex-row">
          <div className="basis-1/4 mt-1"></div>
          <div className="basis-1/2 mt-1">
            <SubHeading> QC tab - </SubHeading>
            <Radio.Group onChange={onChangeQcPermission} value={qcPermission}>
              <StyledRadio value={true} style={{}}>
                Yes
              </StyledRadio>
              <StyledRadio value={false}>No</StyledRadio>
            </Radio.Group>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="basis-1/4 mt-1"></div>
          <div className="basis-1/2 mt-1">
            <SubHeading> Reimbursement Admin - </SubHeading>
            <Radio.Group
              onChange={OnChangeAdminPermission}
              value={adminPermission}
            >
              <StyledRadio value={true} style={{}}>
                Yes
              </StyledRadio>
              <StyledRadio value={false}>No</StyledRadio>
            </Radio.Group>
          </div>
        </div>
        <hr className="mt-4"></hr>
        <div className="flex mt-2" onClick={createUser}>
          <button
            className=" mx-auto"
            //style={{ color: isDisabled ? '#9F9F9F' : '#fff' }}
            style={{
              width: '633px',
              height: '39px',
              borderRadius: '6px',
              backgroundColor: isDisabled ? '#EDEDED' : '#714fff',
              border: `2px solid ${isDisabled ? '#EDEDED' : '#714fff'}`,
              padding: '3px',
              color: isDisabled ? '#929292' : '#fff',
              fontFamily: 'Montserrat',
              fontWeight: 600,
              fontSize: '15px',
            }}
            disabled={isDisabled}
          >
            Create
          </button>
        </div>
      </ModalBody>

      {/* <ModalFooter>
        <div className="flex">
          <button>Create</button>
        </div>
      </ModalFooter> */}
    </Modal>
  );
}
