import moment from 'moment';

export const DEFAULT_COMMON_DIGITISATION_STATE = {
  commonDocumentDetails: null,
  consultationDetails: null,
  patientDetails: null,
  primaryDiagnosis: [],
  secondaryDiagnosis: [],
  networkCenter: null,
  networkDoctor: null,
  networkPractice: null,
  googlePlace: null,
  appointmentDate: null,
  prescriptionValidUptoDate: null,
  currentStep: 1,
  exceptionalAdditionReason: null,
  isFullDisabled: false,
  isPartialDisabled: false,
};

export const buildDigitisationStateForOfflineConsultation = (
  offlineConsultation,
  userName
) => {
  if (offlineConsultation?.consultationId) {
    const preFilledState = {
      ...DEFAULT_COMMON_DIGITISATION_STATE,
      consultationDetails: { value: offlineConsultation.consultationId },
    };

    if (offlineConsultation.centerId) {
      preFilledState.networkCenter = {
        value: offlineConsultation.centerId,
        label: offlineConsultation.centerName,
        address: offlineConsultation.centerAddress,
      };
    }
    preFilledState.patientDetails = {
      value: offlineConsultation.relativeId || -1,
      label:
        !offlineConsultation.relativeId || offlineConsultation.relativeId === -1
          ? userName
          : offlineConsultation.relativeName,
      isUpdated: false,
      isValidated: false,
    };
    if (offlineConsultation.doctorId) {
      preFilledState.networkDoctor = {
        value: offlineConsultation.doctorId,
        label: offlineConsultation.doctorName,
        isValidated: false,
        isUpdated: false,
        verticalId: offlineConsultation.verticalId,
      };
    }
    if (offlineConsultation.practiceId || preFilledState.networkDoctor) {
      preFilledState.networkPractice = {
        value: offlineConsultation?.practiceId,
        label: offlineConsultation?.practiceId,
      };
      if (preFilledState.networkCenter && preFilledState.networkDoctor) {
        preFilledState.networkPractice.label = `${preFilledState.networkDoctor.label} [${offlineConsultation.verticalName}], ${preFilledState.networkCenter.label}`;
      }
    }
    if (offlineConsultation.appointmentTimeStamp) {
      preFilledState.appointmentDate = {
        value: moment
          .unix(offlineConsultation.appointmentTimeStamp)
          .format('YYYY-MM-DD'),
        isValidated: false,
        isUpdated: false,
      };
    }

    return preFilledState;
  }

  return DEFAULT_COMMON_DIGITISATION_STATE;
};

export const buildDigitisationStateForCommonDigitisation = (
  commonDigitisation,
  prevState
) => {
  if (
    commonDigitisation?.commonDigitisationRequest ||
    commonDigitisation?.offlineRequestId
  ) {
    const preFilledState = {
      ...DEFAULT_COMMON_DIGITISATION_STATE,
      commonDocumentDetails: prevState.commonDocumentDetails,
    };

    if (commonDigitisation.centerId || commonDigitisation.centerName) {
      preFilledState.networkCenter = {
        value: commonDigitisation.centerId,
        label: commonDigitisation.centerName,
        address: commonDigitisation.centerAddress,
      };
    }
    if (commonDigitisation.doctorId || commonDigitisation.doctorName) {
      preFilledState.networkDoctor = {
        value: commonDigitisation.doctorId,
        label: commonDigitisation.doctorName,
        isValidated: true,
        isUpdated: false,
        verticalId: commonDigitisation.verticalId,
      };
    }
    preFilledState.patientDetails = {
      value: commonDigitisation.relativeId || -1,
      label: commonDigitisation.patientName,
      isUpdated: false,
      isValidated: false,
    };
    if (commonDigitisation.practiceId || commonDigitisation.doctorName) {
      preFilledState.networkPractice = {
        value: commonDigitisation.practiceId,
        label: commonDigitisation.practiceId,
      };
      preFilledState.networkPractice.label = `${
        commonDigitisation.doctorName
      } [${commonDigitisation.verticalName}], ${
        commonDigitisation.centerName || ''
      }${
        commonDigitisation.centerAddress
          ? `, ${commonDigitisation.centerAddress}`
          : ''
      }`;
    }
    if (
      commonDigitisation.appointmentDate ||
      commonDigitisation.prescriptionDate
    ) {
      preFilledState.appointmentDate = {
        value: moment(
          commonDigitisation.appointmentDate ||
            commonDigitisation.prescriptionDate
        ).format('YYYY-MM-DD'),
        isValidated: false,
        isUpdated: false,
      };
    }
    if (commonDigitisation.diagnosis && commonDigitisation.icdCode) {
      preFilledState.primaryDiagnosis = [
        {
          value: commonDigitisation.icdCode,
          label: commonDigitisation.diagnosis,
        },
      ];
    }

    console.log(preFilledState);
    return preFilledState;
  }

  return prevState;
};
