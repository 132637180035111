import React, { useState } from 'react';
import { SearchIcon } from '../../components/common/svgFiles';
import Select from 'react-select';
import { Col, Row, FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';
import { addPolicySubRemarks } from '../../services/reimbursementClaims';
import { toast } from 'react-toastify';
import { store } from '../../store/';
const careVendor = [11, 19, 8];
export const TimeContainer = styled.div`
  flex-grow: 1;
  font-size: x-small;
  float: right;
  padding-top: 5px;
  padding-right: 5px;
`;

export default function RejectionReasons({
  searchRejectionReasons,
  rejectionReasonList,
  onValidationCheckChange,
  OnInputAlreadyInvoiceClaimId,
  rejectionReasons,
  onSubmitAlreadyInvoiceClaimId,
  disabledSubmit,
  policyRemarks,
  onPolicyRemarkSelected,
  reimbursementDetail,
  refetchPolicyRemarks,
  policyDetail,
}) {
  const [subRemark, setSubRemark] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const state = store.getState();
  const checkIsAdmin = state.app.opsUser.showReimbursementAdmin;
  const isQcAccess = state.app.opsUser.showQcTab;
  const onChangeSubRemark = (e) => {
    setSubRemark(e.target.value);
    setIsDisabled(false);
  };
  const addRemark = async () => {
    const { sponsorId } = reimbursementDetail;
    await addPolicySubRemarks(subRemark, sponsorId)
      .then((response) => {
        if (response.data.message === 'success') {
          toast.success('Added.');
          setSubRemark('');
          setIsDisabled(true);
          refetchPolicyRemarks();
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  const withoutOneReason = rejectionReasonList.filter(
    (d) => d.value !== 'blackListedHospitalOrDiagnostic'
  );
  const vendorId = policyDetail && policyDetail.vendorId;
  const finalRejectionList =
    vendorId && careVendor.includes(vendorId)
      ? rejectionReasonList
      : withoutOneReason;
  return (
    <div className="felx bg-white rounded  p-6 m-1">
      <div className="flex">
        <div className="basis-1/2">
          <h2
            className="text-[#714FFF] mb-2"
            style={{ color: '#714FFF', fontSize: '16px', fontWeight: 'bold' }}
          >
            Do any of these Validation Checks Fail?
          </h2>
        </div>
        <div
          className="basis-1/2  flex justify-end ..."
          style={{ width: '100%', display: 'flex', alignItems: 'right' }}
        >
          <label className="relative block ">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              {/* <LogoContainer src={searchIcon} alt='search-logo' /> */}
              <SearchIcon />
            </span>

            <input
              className="  placeholder-[#714FFF] bg-white placeholder:font-semibold h-8
                                                    border-solid border-2  rounded-lg py-2 pl-10 pr-2 focus:outline-none w-[300px]"
              placeholder="Search for validation checks"
              style={{ border: '2px solid #714FFF' }}
              type="text"
              onChange={searchRejectionReasons}
            />
          </label>
        </div>
      </div>
      <br></br>

      <Row>
        {finalRejectionList &&
          finalRejectionList.map((r, index) => {
            return (
              <Col xs={6} md={6} lg={6} className="mb-2">
                <div className="flex custom-checkbox">
                  <input
                    type="checkbox"
                    className="checkmark"
                    onChange={onValidationCheckChange(r.value)}
                  />

                  <p
                    className="mb-1"
                    style={{
                      fontSize: '16px',
                      marginLeft: '16px',
                      fontWeight: '500',
                    }}
                  >
                    {r.label}
                  </p>
                </div>
                {rejectionReasons.invoiceInAnotherClaim &&
                r.value === 'invoiceInAnotherClaim' ? (
                  <div className="flex mb-2">
                    <Row
                      className="flex"
                      style={{
                        width: '400px',
                        height: '120px',
                      }}
                    >
                      <FormGroup check>
                        <Label for="" className="mr-2">
                          Enter the RID under which the invoice is already
                          claimed
                          <p
                            style={{
                              fontWeight: 'lighter',
                              fontSize: 'small',
                              marginBottom: '0rem',
                            }}
                          >
                            {' '}
                            <mark>
                              (The submitted RID will be shared with the user).
                            </mark>{' '}
                          </p>
                        </Label>
                        <div className="flex">
                          <input
                            style={{
                              backgroundColor: '#F3F0FF',
                              border: '1px solid #714FFF',
                              borderRadius: '6px',
                            }}
                            onChange={(e) => OnInputAlreadyInvoiceClaimId(e)}
                            placeholder="Enter ClaimId.."
                            className="form-control  flex"
                          />
                          <button
                            className="btn ml-1"
                            style={{
                              backgroundColor: '#714FFF',
                              color: 'white',
                            }}
                            onClick={onSubmitAlreadyInvoiceClaimId}
                            disabled={disabledSubmit}
                          >
                            submit
                          </button>
                        </div>
                      </FormGroup>
                    </Row>
                  </div>
                ) : (
                  ''
                )}
                {rejectionReasons.benefitNotCovered &&
                r.value === 'benefitNotCovered' ? (
                  <div>
                    <div
                      className=" flex flex-row mt-1  ml- mb-1"
                      style={{
                        width: '600px',
                        height: '40px',
                      }}
                    >
                      <Label for="" className="mr-1 flex ">
                        <mark> Select Remark* </mark>
                      </Label>
                      {checkIsAdmin ? (
                        <>
                          <input
                            className="ml-2"
                            style={{
                              backgroundColor: '#F3F0FF',
                              border: '1px solid #714FFF',
                              borderRadius: '6px',
                              minWidth: '220px',
                            }}
                            value={subRemark}
                            placeholder=" Enter Remark "
                            onChange={onChangeSubRemark}
                          ></input>
                          <button
                            className="btn btn-success ml-2"
                            onClick={addRemark}
                            disabled={isDisabled}
                            style={{ backgroundColor: '#714FFF' }}
                          >
                            {' '}
                            Add Remark
                          </button>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    <Select
                      options={
                        policyRemarks &&
                        policyRemarks.map((r, index) => {
                          return {
                            value: index,
                            label: r.remark,
                          };
                        })
                      }
                      placeholder="Select Remark"
                      onChange={(e) => onPolicyRemarkSelected(e)}
                    ></Select>
                  </div>
                ) : (
                  ''
                )}
                <hr></hr>
              </Col>
            );
          })}
      </Row>
    </div>
  );
}
