import React from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Button,
} from 'reactstrap';
import Select from 'react-select';
export default function BulkTransferModal({
  isOpen,
  opsDoctorList,
  transferCasesDoctorId,
  handleChangeTransferDoctorId,
  onClose,
  bulkTransferCase,
  isTransfer,
}) {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={onClose}>
        <h3
          style={{
            fontWeight: '500',
            fontSize: '18px',
            color: '#714fff',
          }}
        >
          Transfer Cases
        </h3>
      </ModalHeader>
      <ModalBody>
        <Select
          placeholder="Select Doctor"
          options={opsDoctorList}
          onChange={handleChangeTransferDoctorId}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ backgroundColor: '#714fff' }}
          disabled={transferCasesDoctorId === -1}
          onClick={bulkTransferCase}
        >
          {isTransfer ? (
            <div className="flex">
              Transferring..
              <ClipLoader
                className="flex"
                color={'#fff'}
                loading={isTransfer}
                //cssOverride={override}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            'Transfer'
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
