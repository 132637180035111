import { Select } from 'antd';
import React, { Component } from 'react'
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, FormGroup, Label } from 'reactstrap';
import { addRemarkForDoc } from '../../services/reimbursementClaims';
import { RightIcon } from '../../components/common/svgFiles';
import { actions } from 'react-table';

const remarksArr = [

    {
        value: 'NA',
        label: 'NA'
    },
    { value: "digitisation-prescription-not-submitted", label: 'Prescription not submitted for digitisation' },
    {
        value: "Incorrect-remark-document-deficient",
        label: 'Incorrect remark of Document deficient selected'
    },
    {
        value: 'claims-not-covered',
        label: 'BHMS/BAMS/BPT/IPD claims are not covered',
    },
    {
        value: "checkup-not-covered",
        label: 'Cosmetic/Dental/Vision/Physiotherapy are not covered'
    },
    {
        value: "scan-not-covered",
        label: 'CT scan, MRI, PET Scan, Biopsy, Bone Densitometry are not covered'
    },
    {
        value: 'wrong-bill',
        label: ' Wrong bill number updated'
    },
    {
        value: 'incorrect-merchant',
        label: ' Incorrect merchant name updated'
    },
    {
        value: 'wrong-date',
        label: ' Date selected is wrong'
    },
    {
        value: 'partial-transcription',
        label: ' Partial transcription is done'
    },
    {
        value: 'internal-comment-history',
        label: 'No update in internal comment history'
    },
    {
        value: 'incorrect-rejection',
        label: 'Incorrect rejection reason'
    },
    {
        value: 'medicine-vaccines-together',
        label: ' Medicines and vaccines approved together'
    },
    {
        value: 'reimbursement-type-wrong',
        label: ' Reimbursement type selected wrong'
    },
    {
        value: 'procedure-not-covered',
        label: 'Procedures are not covered',

    }, {
        value: 'incorrect-amount',
        label: 'Incorrect amount is approved'
    },
    {
        value: 'invoice-date-prescription',
        label: 'Invoice date before prescription date is not valid'
    },
    {
        value: 'apr-with-proper-document',
        label: 'Approved without proper documents'

    },
    {
        value: 'critical-illness',
        label: 'Claim had to be submitted under Critical illness'
    },
    {
        value: 'diagnosis-chief-complaint',
        label: 'Approved without any diagnosis/chief complaints'
    },
    {
        value: 'one-invoice-multiple',
        label: ' Only one invoice approved out of multiple'
    },
    {
        value: 'cap-charge-approved',
        label: 'Capping items/charges are approved'
    },
    {
        value: 'invalid-prescription-invoice',
        label: 'Invalid prescription/invoice is approved'
    },
    {
        value: 'claim-rejected-already-submitted-another-rid',
        label: 'Claim is to be rejected as already submitted in another RID',
    },
    {
        value: 'not-a-ci-case',
        label: ' Not a CI case'
    },
    {
        value: 'unnecessarily-clicked-on-ci-labs',
        label: 'Unnecessarily clicked on CI (for labs/consultation claim)'
    }
]
const { Option } = Select;
class RemarksConfirmModal extends Component {
    constructor(props) {
        super(props)
        console.log(props, '[qxremark]')
        this.state = {
            remark: '',
            selectedReason: [],
            selectDisabled: false,
            remarksOptions: remarksArr
        }
    }



    addRemark = () => {
        const opsUserId = this.props.opsUserId
        let { remark, selectedReason } = this.state
        selectedReason = selectedReason && selectedReason.slice(',')
            .map((e) => {
                return e;
            }).join(',')
        const reimburseRequest = this.props.reimbursementDetails
        console.log(opsUserId, remark, reimburseRequest.userId, selectedReason, '[opsuserid]')
        if (opsUserId && reimburseRequest.userId && selectedReason) {
            addRemarkForDoc(opsUserId, remark, reimburseRequest.userId, reimburseRequest.reimbursementId, selectedReason)
                .then((response) => {
                    if (response.message === 'success') {
                        toast.success('Remark Added Successfully')
                        this.props.onSubmit()
                        this.props.onClose()
                    }
                }).catch((err) => {
                    console.log({ err }, '[err]')
                    toast.error('Remarks Not added');
                })
        }
        else {
            window.alert('Seems like information is missing')
        }
    }

    handleChange = (e) => {
        const isNaSelected = e.find(r => r === 'NA');
        if (isNaSelected) {
            return this.setState({ selectedReason: [isNaSelected] }, () => {
            })
        }

        this.setState({
            selectedReason: e
        }, () => {
            const message = e.length > 4 ? <h1 className='' style={{ color: '#9F9F9F' }}>You can not select more than 5 options</h1> : <h1 style={{ color: '#9F9F9F' }}>You can not choose other options with NA</h1>

            this.removeOption(e.length > 4 || isNaSelected, message)
            console.log('selectedReason', this.state.selectedReason);
        })


    }
    header = () => {
        return (
            <h2 style={{
                color: '#714FFF', fontFamily: 'Montserrat', fontWeight: '18px'
            }}>
                Update Remarks

            </h2>
        )
    }

    removeOption = (value, msg) => {
        if (value) {
            this.setState({ remarksOptions: [{ value: '', label: msg, disabled: true }], })
        } else {

            this.setState({ remarksOptions: remarksArr })
        }
    }


    disabledRemarks = (items) => {
        const { selectedReason } = this.state;

        if (selectedReason && selectedReason.includes('NA')) {
            if (selectedReason && selectedReason.includes(items.label)) {
                return false
            }
            return true;
        }
        if (selectedReason && selectedReason.length > 4) {
            if (selectedReason && selectedReason.includes(items.label)) {
                return false
            }
            return true
        }
        else {
            return false
        }

    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen}
                toggle={this.props.onClose}
                size="lg" style={{ maxWidth: '700px', width: '100%', borderRadius: '6px' }}
            >
                <ModalHeader
                    style={{ backgroundColor: 'transparent' }}
                    toggle={this.props.onClose}
                >
                    {this.header()}
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="col">
                            <FormGroup check>
                                <div className='flex'>
                                    <Label className="col-3 ">Select Issue </Label>
                                    <Select className='col-9'
                                        mode="multiple"
                                        min={1} max={5}
                                        onChange={this.handleChange}
                                        placeholder="Select Remark"
                                        value={this.state.selectedReason}

                                    >
                                        {this.state.remarksOptions.map(item => {
                                            return (
                                                <Option
                                                    disabled={this.disabledRemarks(item)}
                                                    key={item.label}
                                                >
                                                    {item.label}
                                                </Option>
                                            );
                                        })}

                                    </Select>

                                </div>


                                {/* </select> */}
                            </FormGroup>
                            <FormGroup check>
                                {/* <Label>Remark Comments:</Label> */}
                                <textarea
                                    style={{ backgroundColor: '#F3F0FF', border: '1px solid #714FFF', borderRadius: '6px' }}
                                    onChange={(e) => this.setState({ remark: e.target.value })}
                                    placeholder="Post a comment"
                                    className="form-control mt-2"
                                />
                            </FormGroup>
                        </div>
                    </div>
                </ModalBody >
                <ModalFooter>
                    <div className="">
                        <button
                            className=" btn text-white font-semibold bg-[#714FFF]
                                                py-2 px-2 border-2 border-[#714FFF] hover:bg-[#714FFF]
                                                 rounded flex items-center disabled:opacity-75"
                            onClick={this.addRemark}
                        >

                            {this.props.loading ? 'Loading...' : 'Update'}

                            <RightIcon />

                        </button>
                    </div>
                    {/* {!this.props.loading && (
                        <Button
                            color="secondary"
                            onClick={this.props.onClose}
                            disabled={this.props.loading}
                        >
                            Cancel
                        </Button>
                    )} */}
                </ModalFooter>
            </Modal >
        );
    }
}


export default RemarksConfirmModal