import React, { useState } from 'react';
import styled from 'styled-components';
import { SearchIcon } from '../../components/common/svgFiles';
import { Switch } from 'antd';
import ShowIcon from '../../assets/images/show.svg';
const Title = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #714fff;
`;
const Heading = styled.h2`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 22px;
  line-height: 37px;
  margin-top:8px
  color: #714fff;
`;
const SubText = styled.p`
  font-weight: bold;
  color: ${(props) => (!props.toggle ? '#714fff' : '#69686A')};
  font-size: 14px;
  font-family: Montserrat;
`;
const ManageText = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  color: ${(props) => (props.toggle ? '#714fff' : '#69686A')};
  font-size: 14px;
`;
const ResultsContainer = styled.div`
  background-color: #ffffff;
  border: 1.5px solid #714fff;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
`;
const ResultItem = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #eae7ff;
  }
`;

const ResultHeading = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
  color: #1e1e1e;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 700;
  opacity: 0.800000011920929;
  letter-spacing: -0.28px;
  padding: 20px;
`;
const LogoContainer = styled.img`
  width: 23px;
  height: 23px;
`;
const HeadingRow = styled.div`
display: flex;
  justify-content: space-between
  align-items: center;
  margin-bottom: 4px;
background-color:#EAE7FF
`;
const ResultRow = styled.div`
display: flex;
  justify-content: space-between
  align-items: center;
  margin-bottom: 4px;
`;
const SearchResult = styled.div`
  margin-right: 8px;
  color: #3a3a4a;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: -0.32px;
`;
const SubResult = styled.div`
  margin-right: 8px;
  color: #3a3a4a;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  letter-spacing: -0.24px;
`;
const BlackListedHospitalUrl = `https://visit-public.s3.ap-south-1.amazonaws.com/assets/claims/ABHI+Non+Preferred+Hospitals.pdf`;
const BlackListedDiagnosticsUrl = `https://visit-public.s3.ap-south-1.amazonaws.com/assets/claims/Copy+of+Negative+Diagnostic+Center.xlsx+-+Negative+Diagnostic+Centre+Mast.pdf`;
export default function BlackListHospitalVerify({
  searchBlacklistHospital,
  blacklistedHospital,
  handleItemClick,
  blackListHospitalValue,
  blackListToggle,
  isDiagnostic,
}) {
  const SearchResults = ({ results, onItemClick }) => {
    return (
      <ResultsContainer>
        <HeadingRow>
          <ResultHeading>Hospital Name</ResultHeading>
          <ResultHeading>Address</ResultHeading>
          <ResultHeading>City</ResultHeading>
        </HeadingRow>
        {results.map((result) => (
          <ResultItem key={result.id} onClick={() => onItemClick(result)}>
            <ResultRow>
              <SearchResult>{result.name}</SearchResult>
              <SubResult>{result.address}</SubResult>
              <SearchResult>{result.city}</SearchResult>
            </ResultRow>
          </ResultItem>
        ))}
      </ResultsContainer>
    );
  };
  const renderDoc = (link) => {
    window.open(link, '_blank');
  };

  return (
    <div
      className="bg-white rounded m-2 ml-0"
      style={{ padding: '1rem', paddingBottom: '1px', width: 'fixed' }}
    >
      {' '}
      <div className="flex flex-row mb-2">
        <div className="m-2">
          <Title>Blacklisted Hospitals/Diagnostic Centres</Title>
        </div>

        <div class="ml-auto">
          <div className=" basis-1/3 flex flex-row  ">
            <div
              className="flex "
              style={{
                border: '2px solid #714FFF',
                borderRadius: '8px',
                height: '3rem',
                padding: '4px',
              }}
            >
              <SubText className="mt-2" toggle={isDiagnostic}>
                Hospitals
              </SubText>
              <Switch
                className="ml-2 mt-2"
                onChange={(e) => blackListToggle(e)}
                style={{
                  width: '40px',
                  backgroundColor: isDiagnostic ? '#714FFF' : '#D6CCFF',
                }}
              ></Switch>
              <ManageText className="ml-2 mt-2 mr-2" toggle={isDiagnostic}>
                Diagnostic Centres
              </ManageText>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-1">
        <div className="basis-1/2 " style={{ width: '100%', display: 'flex' }}>
          <label className="relative block ">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              {/* <LogoContainer src={searchIcon} alt='search-logo' /> */}
              <SearchIcon />
            </span>

            <input
              className="  placeholder-[#714FFF] bg-white placeholder:font-semibold h-8
                                                    border-solid border-2  rounded-lg py-2 pl-10 pr-2 focus:outline-none w-[300px]"
              placeholder={`Search blacklisted ${
                isDiagnostic ? 'diagnostic center' : 'hospital'
              }`}
              style={{
                border: '2px solid #714FFF',
                height: '36px',
                widows: '537px',
              }}
              type="text"
              value={blackListHospitalValue}
              onChange={searchBlacklistHospital}
            />
          </label>
        </div>
        <div className="ml-auto">
          <div
            className=" m-2 pl-1.5 flex border-2 rounded  border-[#714FFF]"
            style={{ width: '280px' }}
          >
            <a
              className="flex ml-2"
              style={{ color: 'black' }}
              rel="noopener noreferrer"
              target="_blank"
              href={
                isDiagnostic
                  ? BlackListedDiagnosticsUrl
                  : BlackListedHospitalUrl
              }
            >
              {isDiagnostic
                ? 'blacklisted_diagnostics.pdf'
                : 'blacklisted_hospitals.pdf'}
            </a>

            <button
              className=" flex ml-auto mr-1"
              onClick={() =>
                renderDoc(
                  isDiagnostic
                    ? BlackListedDiagnosticsUrl
                    : BlackListedHospitalUrl
                )
              }
            >
              <LogoContainer src={ShowIcon} alt="show Icon" />
            </button>
          </div>
        </div>
      </div>
      {blacklistedHospital.length > 0 ? (
        <SearchResults
          results={blacklistedHospital || []}
          onItemClick={handleItemClick}
        />
      ) : (
        ''
      )}
    </div>
  );
}
