import React, { useState } from 'react';
import moment from 'moment';
import { UpArrow, EyeIcon, UserIcon } from '../../components/common/svgFiles';
import { Link } from 'react-router-dom';
import ReClaimIcon from '../../assets/images/re-claim.png';
import ViewClaim from '../../assets/images/EyeIcon.png';
import ProcessClaim from '../../assets/images/processClaim.png';
import ClaimRequest from '../../assets/images/claimReq.png';
import { format } from 'date-fns';
import styled from 'styled-components';
import ReactTableInputFilter from '../../../src/components/common/ColumnFilter';
const LogoContainer = styled.img`
  max-width: 1.8rem;
`;
const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getReimbursementClaimsReqColumn = (lastWorkedDate) => [
  {
    Header: () => (
      <div className="flex flex-row">
        {' '}
        Payment Settlement Date <UpArrow />
      </div>
    ),
    accessor: 'paymentSettlementDate',
    width: 230,
    Cell: (row) => (
      <span>
        {row.original.paymentSettlementDate
          ? moment(row.original.paymentSettlementDate).format(
              'DD/MM/YYYY HH:mm'
            )
          : null}
      </span>
    ),
  },
  {
    Header: () => (
      <div className="flex flex-row ">
        {' '}
        Last worked date <UpArrow />
      </div>
    ),
    accessor: 'lastWorkedDate',
    width: 200,
    filterable: false,
    Cell: (row) => (
      <span>
        {lastWorkedDate
          ? moment(lastWorkedDate).format('DD/MM/YYYY HH:mm')
          : null}
      </span>
    ),
  },
  {
    Header: 'Invoices',
    //width: 190,
    sortable: false,
    Cell: (row) =>
      row.original.invoiceLink &&
      row.original.invoiceLink.split(',').map((link, idx) => (
        <div>
          <a
            style={{ color: '#714FFF' }}
            key={idx}
            rel="noopener noreferrer"
            target="_blank"
            href={link}
          >{`Invoice ${idx + 1}`}</a>
        </div>
      )),
  },
  {
    Header: 'Prescription/Proofs',
    width: 200,
    sortable: false,
    Cell: (row) =>
      row.original.prescriptionLink &&
      row.original.prescriptionLink.split(',').map((link, idx) => (
        <div>
          <a
            style={{ color: '#714FFF' }}
            key={idx}
            rel="noopener noreferrer"
            target="_blank"
            href={link}
          >{`Prescription ${idx + 1}`}</a>
        </div>
      )),
  },
];
let reimbursementId = '';
const onChangeValue = (e) => {
  reimbursementId = e.target.value;
};
const reimbursementColumn = () => [
  {
    Header: () => (
      <div className="flex flex-row">
        Reimbursement ID
        <UpArrow />
      </div>
    ),
    accessor: 'reimbursementId',
    filterable: true,
    Filter: ({ filter, onChange }) => {
      return (
        <input
          type="text"
          placeholder="Search..."
          value={reimbursementId}
          onChange={onChangeValue}
        />
      );
    },
    Cell: (row) => {
      const { reimbursementId } = row.original;
      return (
        <Link
          title={reimbursementId}
          to={`/reimburse-claim/${reimbursementId}`}
        >
          {reimbursementId}
        </Link>
      );
    },
  },
  {
    Header: () => (
      <div className="flex flex-row">
        {' '}
        Claimed By <UpArrow />
      </div>
    ),
    accessor: 'claimBy',
    filterable: true,
  },
  {
    Header: 'Sponsor Name ',
    accessor: 'sponsorName',
  },
  {
    Header: () => (
      <div className="flex flex-row">
        Patient Name
        <UpArrow />
      </div>
    ),
    accessor: 'relative',
  },

  {
    Header: () => (
      <div className="flex flex-row">
        Reimbursement Type
        <UpArrow />
      </div>
    ),
    accessor: 'reimbursementType',
  },
  {
    Header: () => (
      <div className="flex flex-row">
        Status
        <UpArrow />
      </div>
    ),
    accessor: 'requestStatus',
    Cell: (row) => {
      if (row.original.deletionDate) {
        return <h5 className="text-[#D53815] font-bold">Deleted</h5>;
      }
      switch (row.original.requestStatus.toUpperCase()) {
        case 'DOCUMENTS-UPLOADED':
          return (
            <h5 className="text-[#714FFF] font-bold">
              {capitalizeFirst(row.original.requestStatus)}
            </h5>
          );
        case 'DOCUMENTS-VERIFIED':
          return (
            <h5 className="text-[#3899D3] font-bold">
              {capitalizeFirst(row.original.requestStatus)}
            </h5>
          );
        case 'APPROVED':
          return (
            <h5 className="text-[#72BE11] font-bold">
              {capitalizeFirst(row.original.requestStatus)}
            </h5>
          );
        case 'REJECTED':
          return (
            <h5 className="text-[#D53815] font-bold">
              {capitalizeFirst(row.original.requestStatus)}
            </h5>
          );
        default:
          return (
            <h5 className="text-[#008B8B] font-bold">
              {capitalizeFirst(row.original.requestStatus)}
            </h5>
          );
      }
    },
  },
  {
    Header: () => (
      <div className="flex flex-row">
        Reimbursement Type
        <UpArrow />
      </div>
    ),
    accessor: 'reimbursementType',
  },
  {
    Header: () => (
      <div className="flex flex-row">
        Insurer Status
        <UpArrow />
      </div>
    ),
    accessor: 'claimStatus',
    Cell: (row) => {
      // eslint-disable-next-line default-case
      switch (row.original.claimStatus.toUpperCase()) {
        case 'PENDING':
          return (
            <div className=" m-4 flex flex-column border-2 border-[#EC9719]  rounded">
              <span className="text-center"> Insurer</span>
              <button
                type="button"
                className="py-2 px-3 text-xs font-medium  dark:bg-[#EC9719]  dark:border-[#EC9719] text-white"
              >
                {capitalizeFirst(row.original.claimStatus)}
              </button>
            </div>
          );

        case 'REJECTED':
        case 'FAILURE':
        case 'REVERSED':
          return (
            <div className="flex flex-column border-2 border-[#D53815]  rounded">
              <span className="text-center"> Insurer</span>
              <button
                type="button"
                className="py-2 px-3 text-xs font-medium  dark:bg-[#D53815]  dark:border-[#D53815] text-white"
              >
                {capitalizeFirst(row.original.claimStatus)}
              </button>
            </div>
          );
        case 'APPROVED':
          return (
            <div className="flex flex-column border-2 border-[#72BE11]  rounded">
              <span className="text-center"> Insurer</span>
              <button
                type="button"
                className="py-2 px-3 text-xs font-medium  dark:bg-[#72BE11]  dark:border-[#72BE11] text-white"
              >
                {capitalizeFirst(row.original.claimStatus)}
              </button>
            </div>
          );

        case 'SETTLED':
          return (
            <div className="flex flex-column border-2 border-[#3899D3]  rounded">
              <span className="text-center"> Insurer</span>
              <button
                type="button"
                className="py-2 px-3 text-xs font-medium  dark:bg-[#3899D3]  dark:border-[#3899D3] text-white"
              >
                {capitalizeFirst(row.original.claimStatus)}
              </button>
            </div>
          );
      }
    },
  },

  {
    Header: () => (
      <div className="flex flex-row">
        Invoice Date
        <UpArrow />
      </div>
    ),
    accessor: 'invoiceDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'dd/MM/yyyy');
    },
  },
  {
    Header: 'Claimed Amount',
    accessor: 'approvedAmount',
  },
  {
    Header: 'Actions',
    width: 200,
    Cell: () => {
      return (
        <div className="flex flex-row cursor-pointer">
          <LogoContainer className="mr-1" src={ViewClaim} alt="view claim" />
          <LogoContainer
            className="mr-1"
            src={ReClaimIcon}
            alt="Reclaim Logo"
          />
          <LogoContainer
            className="mr-1"
            src={ProcessClaim}
            alt="Process Cliam"
          />
          <LogoContainer src={ClaimRequest} alt="claim Request" />
        </div>
      );
    },
  },
];

export { getReimbursementClaimsReqColumn, reimbursementColumn };
