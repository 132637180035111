const REQUEST_CHAT = {
  INTERNAL: 'internal',
  NETWORK: 'network',
};
const LABS = {
  labsOrder: 'labsOrder',
};
const REIMBURSEMENT = {
  reimbursement: 'reimbursement',
};
const config = {
  REQUEST_CHAT,
  LABS,
  REIMBURSEMENT,
};

export default config;
