import React, { useState } from 'react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import styled from 'styled-components';
import { GrDocumentMissing } from 'react-icons/gr';

const RightButton = styled(FaArrowAltCircleRight)`
  color: ${({ disabled }) =>
    disabled ? '#E3DCFF' : '#714fff'}; /* Icon color */
  font-size: 30px; /* Icon size */
  cursor: ${({ disabled }) =>
    disabled ? 'not-allowed' : 'pointer'}; /* Cursor style for disabled state */
`;
const LeftButton = styled(FaArrowAltCircleLeft)`
  color: ${({ disabled }) =>
    disabled ? '#E3DCFF' : '#714fff'}; /* Icon color */
  font-size: 30px; /* Icon size */
  cursor: ${({ disabled }) =>
    disabled ? 'not-allowed' : 'pointer'}; /* Cursor style for disabled state */
`;
const NoDocs = styled(GrDocumentMissing)`
  font-size: 50px;
  color: #714fff;
`;

const iframeStyle = {
  height: '100%',
  width: '100%',
  minHeight: '100vh',
};

const imageStyle = {
  width: '100%',
  maxWidth: '100%',
  maxHeight: '90vh',
};

const buttonContainerStyle = {
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'right',
  gap: '10px',
};

const DocumentViewer = ({ documents }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // Handle navigation
  const goToNextDocument = () => {
    if (currentIndex < documents.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goToPreviousDocument = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const renderDocument = (doc) => {
    const fileExtension = doc.uri.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
      return (
        <iframe
          key={doc.uri}
          src={doc.uri}
          style={iframeStyle}
          title={`PDF Document`}
          frameBorder="0"
        />
      );
    } else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
      return (
        <img
          key={doc.uri}
          src={doc.uri}
          alt={`Document Image`}
          style={imageStyle}
        />
      );
    }
    return <p key={doc.uri}>Unsupported file type.</p>;
  };

  const getCapitalizedDocumentType = () => {
    // Get the documentType from the documents array
    const documentType = documents[currentIndex]?.type || 'Doc';

    // Capitalize the first letter and make the rest lowercase
    const capitalizedDocumentType =
      documentType.charAt(0).toUpperCase() +
      documentType.slice(1).toLowerCase();

    return capitalizedDocumentType;
  };
  return (
    <div style={{ padding: '5px', textAlign: 'center' }}>
      {documents.length > 0 ? (
        <div style={{ position: 'relative', minHeight: '90vh' }}>
          {documents.length > 1 ? (
            <div style={buttonContainerStyle}>
              <h3
                className="mt-2"
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                }}
              >{`${getCapitalizedDocumentType()} ${currentIndex + 1} of ${
                documents.length
              }`}</h3>
              <LeftButton
                onClick={goToPreviousDocument}
                disabled={currentIndex === 0}
              />

              <RightButton
                onClick={goToNextDocument}
                disabled={currentIndex === documents.length - 1}
              />
            </div>
          ) : (
            <></>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
              fontSize: '16px',
            }}
          ></div>
          <div style={{ marginTop: '20px' }}>
            {renderDocument(documents[currentIndex])}
          </div>
        </div>
      ) : (
        <p
          className="flex flex-row"
          style={{ color: '#714fff', fontWeight: 'bolder', fontSize: '15px' }}
        >
          No documents available. <NoDocs />
        </p>
      )}
    </div>
  );
};

export default DocumentViewer;
