import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';

export default class ConfirmModal extends React.Component {
  submitModal() {
    const { onSubmit } = this.props;
    this.setState({ modal: false }, onSubmit);
  }

  render() {
    const className = 'bg-info';
    const { modalHeader, children, onSubmit, isOpen, onClose, loading } =
      this.props;
    return (
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader
          style={{ backgroundColor: '#714FFF', color: 'white' }}
          toggle={onClose}
        >
          {modalHeader}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button
            style={{ color: 'white', backgroundColor: '	#198754' }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Confirm'}
          </Button>
          {!loading && (
            <Button
              style={{ color: 'white', backgroundColor: '	#DC3545' }}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
