import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { BsPlus } from 'react-icons/bs';
import { Switch } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
  useDoctorSponsorList,
  useReimbursementDocList,
  useSponsorList,
  useAddDoctorSponsor,
  useRemoveDoctorSponsor,
  useUpdateDoctorSponsor,
} from '../../queries/reimbursementAdminQuery';
import ReactTable from 'react-table';
import { RemoveIcon, ModifyIcon } from '../../components/common/svgFiles';
import CreateCredModal from './CreateCredModal';
import AddDoctorModal from './AddDoctorModal';
import { toast } from 'react-toastify';
import EditDocModal from './EditDoctorModal';
import { PlusIcon } from '../../components/common/svgFiles';
const colorMap = {
  0: '#D53815',
  1: '#006BE8',
  2: '#71BB13',
  3: '#EC9719',
  4: '#714FFF',
};
const SubText = styled.p`
  font-weight: bold;
  color: ${(props) => (props.toggle ? '' : '#714fff')};
  font-size: 14px;
  font-family: Montserrat;
`;
const ManageText = styled.p`
  font-family: Montserrat;
  font-style: SemiBold;
  font-size: 14px;
`;

const Title = styled.p`
  font-size: 24px;
  line-height: 37px;
  color: #714fff;
  font-family: Montserrat;
  font-weight: bold;
`;
const Plus = styled(BsPlus)`
  color: white;
  margin-top: 0.5px;
  font-size: 1.5rem;
`;
const styleObj = {
  'font-size': '15px',
  overflow: 'hidden',
  'background-color': '#FFF',
  'font-weight': '500',
  'font-family': 'Montserrat',
};
const statusOptions = [
  {
    value: 'documents-deficient',
    label: 'documents-deficient',
  },
];
export default function ManageDoctor() {
  const [toggle, setToggle] = useState(false);
  const [addDoctorModal, setAddDoctorModal] = useState(false);
  const [doctorId, setDoctorId] = useState('');
  const [sponsorsArr, setSponsorsArr] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [updateDoctorId, setUpdateDoctorId] = useState('');
  const [createCredModal, setCreateCredModal] = useState(false);
  const [caseLimit, setCaseLimit] = useState(0);
  const navigate = useNavigate();
  let statusArray;
  const {
    data: sponsorDoctorTable,
    isLoading: isLoadingSponsorTable,
    isFetching: isFetchingSponsorTable,
    refetch: refetchSponsorDoctor,
  } = useDoctorSponsorList();
  const {
    isLoading,
    error: ErrorInDoctorList,
    data: opsDoctorList,
    isFetching,
  } = useReimbursementDocList();
  const OnSuccessDoctorAdd = (success) => {
    toast.success(`${success}`, { theme: 'dark' });
    setAddDoctorModal(false);
    refetchSponsorDoctor();
  };
  const OnErrorDoctorAdd = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      {
        icon: '❌',
        theme: 'dark',
      }
    );
  };
  const OnSuccessRemoveDoctor = (success) => {
    toast.success(`${success}`, { theme: 'dark' });
    refetchSponsorDoctor();
  };
  const OnErrorRemoveDoctor = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      {
        icon: '❌',
        theme: 'dark',
      }
    );
  };

  const OnSuccessEditDoctor = (success) => {
    toast.success(`Sponsor Modify Successfully`, { theme: 'dark' });
    refetchSponsorDoctor();
    setOpenEditModal(false);
  };
  const onErrorEditDoctor = (error) => {
    toast.error('Sponsor is already added', {
      icon: '❌',
      theme: 'dark',
    });
  };
  const { mutate: addDoctorSponsor } = useAddDoctorSponsor(
    OnSuccessDoctorAdd,
    OnErrorDoctorAdd
  );
  const { mutate: removeDoctorSponsor } = useRemoveDoctorSponsor(
    OnSuccessRemoveDoctor,
    OnErrorRemoveDoctor
  );
  const { mutate: updateDoctorSponsor } = useUpdateDoctorSponsor(
    OnSuccessEditDoctor,
    onErrorEditDoctor
  );

  const { data: sponsorData, error: errorInSponsor } = useSponsorList();

  const columns = [
    {
      Header: 'DoctorId',
      accessor: 'doctorId',
      style: { textAlign: 'center', fontWeight: '700' },
    },
    {
      Header: 'Doctor Name ',
      accessor: 'name',
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Case Limit',
      accessor: 'caseLimit',
      style: { textAlign: 'center', fontWeight: '700' },
    },
    {
      Header: 'Sponsor',
      accessor: 'sponsorName',
      width: 900,
      style: { textAlign: 'center', fontWeight: '600' },
      Cell: (row) => {
        return (
          <h1 className="mt-2">
            {row.original.sponsorName
              .toUpperCase()
              .split(',')
              .map((m, index) => {
                return (
                  <span
                    className="mr-2"
                    style={{
                      border: `3px solid ${colorMap[index % 4]}`,
                      borderRadius: '20px',
                      padding: '5px',
                      backgroundColor: '#F6F6F6',
                      fontFamily: 'Montserrat',
                      fontWeight: '14px',
                    }}
                  >
                    {m}
                  </span>
                );
              })}
          </h1>
        );
      },
    },
    {
      Header: 'Actions',
      numeric: false,
      disablePadding: false,
      style: {
        whiteSpace: 'unset',
        position: 'absolute',
        right: 0,
        background: 'white',
        height: '1110px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Montserrat',
        width: 300,
      },
      headerStyle: {
        overflow: 'visible',
        position: 'absolute',
        right: 0,
        background: '#FFFFFF',
        textAlign: 'center',
      },

      Cell: (row) => {
        return (
          <div className="flex flex-row cursor-pointer mr-3">
            <ReactTooltip id="modify" place="top" effect="float" type="info">
              Modify
            </ReactTooltip>
            <button
              className="mr-2"
              data-tip
              data-for="modify"
              onClick={showEditModal(row.original.doctorId)}
            >
              <ModifyIcon />
            </button>

            <ReactTooltip
              id="removeDoctor"
              place="top"
              effect="float"
              type="warning"
            >
              Remove Doctor
            </ReactTooltip>
            <button
              data-tip
              data-for="removeDoctor"
              onClick={removeDoctor(row.original.doctorId)}
            >
              <RemoveIcon className="" />
            </button>
          </div>
        );
      },
    },
  ];
  if (toggle) {
    navigate('/reimbursement-admin');
  }
  const showEditModal = (docId) => () => {
    setUpdateDoctorId(docId);
    setOpenEditModal(true);
  };
  const removeDoctor = (docId) => () => {
    const removeDoctorId = docId;
    removeDoctorSponsor(removeDoctorId);
  };
  const onDoctorSelected = (e) => {
    setDoctorId(e.value);
  };
  const onStatusSelected = (e) => {
    statusArray = [e.value];
  };
  const sponsorMultiSelect = (e) => {
    setSponsorsArr(e.map((l) => l.value));
  };
  const addDoctorInList = () => {
    addDoctorSponsor({
      doctorId: doctorId,
      sponsors: sponsorsArr,
      status: statusArray,
      caseLimit,
    });
  };
  const disabledAddDoctor = () => {
    const checkIsTrue = doctorId && sponsorsArr.length > 0;
    if (checkIsTrue) {
      return false;
    }
    return true;
  };

  const updateDoctorInList = () => {
    updateDoctorSponsor({
      doctorId: updateDoctorId,
      sponsors: sponsorsArr,
      status: statusArray,
      caseLimit,
    });
  };
  const caseCountChange = (e) => {
    setCaseLimit(e.target.value);
  };
  return (
    <div
      className="container-fluid w-full "
      style={{ backgroundColor: '#F5F3F8' }}
    >
      <div className="flex flex-row justify-end">
        <div className=" basis-1/4 mt-3">
          <Title>Manage Doctors</Title>
        </div>
        <div
          className=" basis-1/8 flex mt-3 pl-2 pr-2"
          style={{
            border: '2px solid #714FFF',
            borderRadius: '8px',
            height: '3rem',
            padding: '4px',
          }}
        >
          <SubText className="mt-2" toggle={toggle}>
            Manage Doctor
          </SubText>
          <Switch
            className="ml-2 mt-2"
            onChange={(e) => setToggle(e)}
            //toggle={toggle}
            style={{
              backgroundColor: toggle ? '#714FFF' : '#D6CCFF',
              width: '40px',
            }}
          ></Switch>
          <ManageText className="ml-2 mt-2 mr-2">
            Reimbursement Admin
          </ManageText>
        </div>
        <div className="ml-auto mt-4">
          <button
            className="btn flex flex-row"
            style={{ backgroundColor: '#714fff', color: '#fff' }}
            onClick={() => setCreateCredModal(true)}
          >
            <Plus /> Create Credentials
          </button>
        </div>
      </div>
      <div className="mt-2">
        <button
          className=" btn "
          style={{ backgroundColor: '#714fff', color: '#fff' }}
          onClick={() => setAddDoctorModal(true)}
        >
          Add Doctor
        </button>
      </div>
      <div className="mt-3">
        <ReactTable
          data={sponsorDoctorTable}
          columns={columns}
          style={styleObj}
          loading={isLoadingSponsorTable || isFetchingSponsorTable}
        />
      </div>
      <AddDoctorModal
        isOpen={addDoctorModal}
        onClose={() => setAddDoctorModal(false)}
        opsDoctorList={opsDoctorList}
        onDoctorSelected={onDoctorSelected}
        sponsorData={sponsorData}
        sponsorMultiSelect={sponsorMultiSelect}
        onStatusSelected={onStatusSelected}
        statusOptions={statusOptions}
        addDoctorInList={addDoctorInList}
        disabledAddDoctor={disabledAddDoctor}
        caseCountChange={caseCountChange}
      ></AddDoctorModal>

      <EditDocModal
        isOpen={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        sponsorData={sponsorData}
        sponsorMultiSelect={sponsorMultiSelect}
        statusOptions={statusOptions}
        onStatusSelected={onStatusSelected}
        updateDoctorInList={updateDoctorInList}
        caseCountChange={caseCountChange}
      ></EditDocModal>
      <CreateCredModal
        isOpen={createCredModal}
        Close={() => setCreateCredModal(false)}
      ></CreateCredModal>
    </div>
  );
}
