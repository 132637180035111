import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { UpArrow } from '../../components/common/svgFiles';
import ViewClaim from '../../assets/images/EyeIcon.png';
import GreenTick from '../../assets/images/greenTick.png';
import ReactTable from 'react-table';
import { SearchIcon } from '../../components/common/svgFiles';
import { Input } from 'antd';
import { useFetchSuspiciousClaimData } from '../../queries/reimbursementAdminQuery';
import { toast } from 'react-toastify';
import {
  fetchAllSuspiciousUserClaim,
  deleteSuspiciousUser,
} from '../../services/reimbursementAdmin';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const styleObj = {
  'font-size': '15px',
  overflow: 'hidden',
  'background-color': '#FFF',
  'font-weight': '500',
  'font-family': 'Montserrat',
};
const Title = styled.p`
  font-size: 24px;
  line-height: 37px;
  color: #714fff;
  font-family: Montserrat;
  font-weight: bold;
`;
const LogoContainer = styled.img`
  max-width: 2rem;
`;
const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export default function SuspiciousUser() {
  const { brokerId } = useSelector((state) => state.app.opsUser);
  const [allSuspiciousClaims, setAllSuspiciousClaims] = useState([]);
  const [modalIsTrue, setModalIsTrue] = useState(false);
  const {
    isLoading,
    isFetching,
    error,
    refetch: refreshSuspiciousClaimTable,
    data: suspiciousUserData,
  } = useFetchSuspiciousClaimData();
  if (error) {
    const errorMessage = error.response.data.message;
    toast.error(
      errorMessage || 'Something went wrong. Please try again later',
      {
        icon: '❌',
        theme: 'dark',
      }
    );
  }
  const globalFilter = ({ filter, onChange }) => (
    <Input
      style={{
        border: '2px solid rgba(113, 79, 255, 0.3)',
        borderRadius: '10px',
      }}
      placeholder="Search..."
      prefix={<SearchIcon className="site-form-item-icon" />}
      onChange={(event) => onChange(event.target.value)}
      value={filter ? filter.value : ''}
    />
  );

  const viewAllSuspiciousClaim = async (userId) => {
    await fetchAllSuspiciousUserClaim(userId)
      .then((result) => {
        setAllSuspiciousClaims(result);
        setModalIsTrue(true);
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong ');
      });
  };

  const markDeleteSuspiciousUser = async (userId) => {
    await deleteSuspiciousUser(userId)
      .then((result) => {
        refreshSuspiciousClaimTable();
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong ');
      });
  };

  let column = [
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          Reimbursement ID
          <UpArrow />
        </div>
      ),
      accessor: 'sourceId',
      filterable: true,
      Filter: globalFilter,
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },

    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          User Name
          <UpArrow />
        </div>
      ),
      accessor: 'name',
      filterable: true,
      //Filter: ColumnFilter,
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          Patient Name
          <UpArrow />
        </div>
      ),
      filterable: true,
      //Filter: ColumnFilter,
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
      Cell: (row) => (
        <span>{`${row.original.relativeName
            ? row.original.relativeName
            : row.original.name
          }`}</span>
      ),
    },
    {
      Header: 'Sponsor Name ',
      accessor: 'sponsorName',
      width: 170,
      // filterable: brokerId ? false : true,
      style: { textAlign: 'center', fontWeight: '600' },
      // Filter: ({ filter, onChange }) => (
      //   <SponsorFilter onChangeValue={onChange} value={filter ? filter : ''} />
      // ),
    },

    {
      Header: 'Reason ',
      accessor: 'reason',
      width: 260,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          Total Suspicious Claims
          <UpArrow />
        </div>
      ),
      accessor: 'totalClaimCount',
      //Filter: ColumnFilter,
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
      Cell: (row) => {
        return (
          <h2
            onClick={() => viewAllSuspiciousClaim(row.original.userId)}
            style={{
              textAlign: 'center',
              fontWeight: '600',
              color: '#0000EE',
              cursor: 'pointer',
            }}
          >
            {row.original.totalClaimCount}
          </h2>
        );
      },
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          Marked At
          <UpArrow />
        </div>
      ),
      accessor: 'createdAt',
      //Filter: ColumnFilter,
      width: 200,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Actions',
      width: 110,
      numeric: false,
      disablePadding: false,
      style: {
        whiteSpace: 'unset',
        position: 'absolute',
        right: 0,
        background: 'white',
        height: '1110px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Montserrat',
      },
      headerStyle: {
        overflow: 'visible',
        position: 'absolute',
        right: 0,
        background: '#FFFFFF',
        textAlign: 'center',
      },
      Cell: (row) => {
        return (
          <div className="flex flex-row cursor-pointer ml-3">
            <ReactTooltip id="claimView" place="top" effect="float" type="info">
              View Claim
            </ReactTooltip>
            <Link
              to={`/reimburse-claim/${row.original.sourceId}`}
              target="_blank"
            >
              <LogoContainer
                data-tip
                data-for="claimView"
                className="mr-2"
                src={ViewClaim}
                alt="view claim"
              />
            </Link>
            {!row.original.deletedAt ? (
              <>
                {brokerId && brokerId ? (
                  ''
                ) : (
                  <ReactTooltip
                    id="markUnsuspiciousUser"
                    place="top"
                    effect="float"
                    type="success"
                  >
                    Mark Unsuspicious User
                  </ReactTooltip>
                )}
              </>
            ) : (
              ''
            )}
            {!row.original.deletedAt ? (
              <>
                {brokerId && brokerId ? (
                  ''
                ) : (
                  <LogoContainer
                    data-tip
                    data-for="markUnsuspiciousUser"
                    className="mr-1"
                    src={GreenTick}
                    alt="markUnsuspiciousUser Logo"
                    onClick={() =>
                      markDeleteSuspiciousUser(row.original.userId)
                    }
                  />
                )}
              </>
            ) : (
              ''
            )}
          </div>
        );
      },
    },
  ];

  const suspiciousClaimsColumn = [
    {
      Header: 'Reimbursement ID',
      accessor: 'id',
      filterable: true,
      width: 170,
      Filter: globalFilter,

      style: { textAlign: 'center', fontWeight: '600' },
      Cell: (row) => {
        return (
          <a
            href={`/reimburse-claim/${row.original.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#1890ff' }}
          >
            {row.original.id}
          </a>
        );
      },
    },
    {
      Header: 'Patient Name',
      accessor: 'relativeName',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
      Cell: (row) =>
        row.original.relativeName
          ? row.original.relativeName
          : row.original.name,
    },
    {
      Header: 'Reimbursement type',
      accessor: 'procedureName',
      width: 300,
      style: { textAlign: 'center', fontWeight: '600' },
      Cell: (row) => row.original.procedureName,
    },
    {
      Header: 'Status',
      accessor: 'requestStatus',
      width: 300,
      style: { textAlign: 'center', fontWeight: '600' },
      Cell: (row) => {
        switch (row.original.requestStatus.toUpperCase()) {
          case 'DOCUMENTS-UPLOADED':
            if (row.original.documentDefUpdatedOn) {
              return (
                <h5 className="text-[#714FFF] font-bold">
                  {capitalizeFirst('Deficiency-submitted')}
                  <br></br>
                  {row.original.subStatus ? (
                    <span className="badge badge-secondary">
                      {row.original.subStatus.toUpperCase()}
                    </span>
                  ) : null}
                </h5>
              );
            } else {
              return (
                <h5 className="text-[#714FFF] font-bold">
                  {capitalizeFirst(row.original.requestStatus)}
                  <br></br>
                  {row.original.subStatus ? (
                    <span className="badge badge-secondary">
                      {row.original.subStatus.toUpperCase()}
                    </span>
                  ) : null}
                </h5>
              );
            }
          case 'DOCUMENTS-VERIFIED':
            return (
              <h5 className="text-[#3899D3] font-bold">
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          case 'APPROVED':
            return (
              <h5 className="text-[#72BE11] font-bold">
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          case 'REJECTED':
            return (
              <h5 className="text-[#D53815] font-bold">
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          default:
            return (
              <h5 className="text-[#008B8B] font-bold">
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
        }
      },
    },
  ];
  const closeModal = () => {
    setModalIsTrue(false);
  };
  const getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          minHeight: '70px',
        },
      };
    }
    return {};
  };

  return (
    <div
      className="container-fluid w-full"
      style={{ backgroundColor: '#F5F3F8' }}
    >
      <div className="flex flex-row mt-2">
        <Title>Suspicious Users</Title>
      </div>
      <ReactTable
        columns={column}
        data={suspiciousUserData && suspiciousUserData}
        style={styleObj}
        getTrProps={getTrProps}
        loading={isLoading || isFetching}
      />

      <Modal
        isOpen={modalIsTrue}
        size="lg"
        toggle={closeModal}
        style={{ maxWidth: '1000px', width: '100%' }}
      >
        <ModalHeader style={{ backgroundColor: '#714FFF' }} toggle={closeModal}>
          <div className="row m-2">
            <div className="flex flex-row">
              <h3
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat',
                  fontSize: '20px',
                  color: '#ffff',
                }}
              >
                Suspicious Claims
              </h3>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <ReactTable
            columns={suspiciousClaimsColumn}
            data={allSuspiciousClaims || []}
            pagination={true}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
