import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  CardFooter,
} from 'reactstrap';
import { FaGlobe, FaUser } from 'react-icons/fa';
import {
  DashBoardIcon,
  QcTabIcon,
  QcRemarksIcon,
  DoctorReportIcon,
  AdminUserIcon,
  DoctorDailyReportIcon,
} from '../common/svgFiles';
import { setSponsorDataWitCount } from '../../actions';

const OuterComponent = styled(ListGroup)`
  height: 70%;
  width: 18%;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
`;

const TitleText = styled(ListGroupItemHeading)`
  background-color: #8852cc;
  color: white;
  text-align: center;
  padding: 1rem 0.25rem;
  margin: 0rem;
  font-size: 1.3rem;
  align-items: stretch;
  align-self: stretch;
`;

const BodyContainer = styled(ListGroup)`
  display: flex;
  flex: 1;
  padding: 1rem 0rem;
  background-color: #ffffff;
  overflow-y: scroll;
`;

const LinkContainer = styled(ListGroupItem)`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0.25rem 0.825rem;
  color: white;
  margin: 0.25rem 0.5rem
  width: 15rem;
  margin-bottom: 3.5px;
  font-size: 14px;
  cursor: pointer;
  :hover {
		background-color: #F6F4FF;
		cursor: pointer;
        color:green;
	}
    
`;
const FooterContainer = styled(CardFooter)`
  background-color: #525963;
  align-items: right;
`;

const ListObject = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0.25rem 0.5rem;
  background-color: rgba(135, 135, 135, 0.9);
  margin: 0.25rem 0.1rem 0.15rem 0.5rem;
  border-radius: 0.25rem;
`;

function SideBar({ isOpen, user, opsName }) {
  const [showQcTab, setShowQcTab] = useState(false);
  const [showReimbursement, setShowReimbursement] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const opsUserName = user?.name;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(isOpen, 'sideBar');

  useEffect(() => {
    if (user?.showQcTab) {
      setShowQcTab(true);
    }
    if (user?.showReimbursement) {
      setShowReimbursement(true);
    }
    if (user?.showReimbursementAdmin) {
      setShowAdmin(true);
    }
  }, [user.showQcTab, user.showReimbursement, user.showReimbursementAdmin]);

  const fetchAdminTab = () => {
    dispatch(setSponsorDataWitCount([]));
    navigate(`/reimbursement-admin`);
  };
  const fetchReimbursementTab = () => {
    dispatch(setSponsorDataWitCount([]));
    navigate(`/reimbursement-claims`, {
      state: {
        opsUserName,
      },
    });
  };

  // if (!isOpen) return <></>;
  return (
    <>
      {isOpen ? (
        <OuterComponent style={{ height: '72%' }}>
          <BodyContainer>
            {showReimbursement && (
              <div onClick={() => fetchReimbursementTab()}>
                <LinkContainer style={{ color: '#5F6165' }}>
                  <DashBoardIcon />
                  &nbsp; Reimbursement Claims
                </LinkContainer>
              </div>
            )}
            {showQcTab && (
              <div onClick={() => navigate(`/qc-reimbursement`)}>
                <LinkContainer style={{ color: '#5F6165' }}>
                  <QcTabIcon />
                  &nbsp; QC Tab
                </LinkContainer>
              </div>
            )}
            {showReimbursement && (
              <div onClick={() => navigate(`/qc-remark`)}>
                <LinkContainer style={{ color: '#5F6165' }}>
                  <QcRemarksIcon />
                  &nbsp; QC Remarks
                </LinkContainer>
              </div>
            )}

            {showAdmin && (
              <div onClick={() => fetchAdminTab()}>
                <LinkContainer style={{ color: '#5F6165' }}>
                  <AdminUserIcon />
                  &nbsp; Reimbursement Admin
                </LinkContainer>
              </div>
            )}
            {showReimbursement && (
              <div onClick={() => navigate(`/doctor-daily-report`)}>
                <LinkContainer style={{ color: '#5F6165' }}>
                  <DoctorReportIcon />
                  &nbsp; Daily Report
                </LinkContainer>
              </div>
            )}
            {showAdmin && (
              <div onClick={() => navigate(`/all-doctor-daily-report`)}>
                <LinkContainer style={{ color: '#5F6165' }}>
                  <DoctorDailyReportIcon />
                  &nbsp; Doctor Daily Report
                </LinkContainer>
              </div>
            )}
            {showAdmin && (
              <div onClick={() => navigate(`/suspicious-users`)}>
                <LinkContainer style={{ color: '#5F6165' }}>
                  <QcRemarksIcon />
                  &nbsp; Suspicious User
                </LinkContainer>
              </div>
            )}
          </BodyContainer>
        </OuterComponent>
      ) : (
        ''
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  const isOpen = state.app.showSideBar;
  const user = state.app.opsUser;
  return { isOpen, user };
};

export default connect(mapStateToProps)(SideBar);
