export const defaultState = {
  authToken: '',
  opsUser: null,
  showSideBar: false,
  searchString: '',
  verticals: [],
};

export default function app(state = defaultState, action) {
  switch (action.type) {
    case 'SET_AUTH_TOKEN':
      return Object.assign({}, state, {
        authToken: action.payload,
      });
    case 'SET_VERTICALS':
      return Object.assign({}, state, {
        verticals: action.payload,
      });
    case 'SET_OPS_USER':
      return Object.assign({}, state, {
        opsUser: action.payload,
      });
    case 'SET_REIMBURSEMENT': {
      return Object.assign({}, state, {
        reimbursementData: action.payload,
      });
    }
    case 'TOGGLE_SIDEBAR': {
      console.log(
        'action',
        action.value?.current,
        'state-showSideBar',
        state.showSideBar
      );
      return Object.assign({}, state, {
        showSideBar:
          action.value?.current !== undefined
            ? action.value.current
            : !state.showSideBar,
      });
    }
    case 'SET_SEARCH_STRING': {
      return Object.assign({}, state, {
        searchString: action.payload,
      });
    }
    case 'SET_IS_ON_BREAK': {
      return Object.assign({}, state, {
        isOnBreak: action.payload,
      });
    }
    case 'SET_BELL_CLOSE': {
      return Object.assign({}, state, {
        bellModalClose: action.payload,
      });
    }
    case 'SET_ON_BREAK_CLOSE': {
      return Object.assign({}, state, {
        onBreakModalClose: action.payload,
      });
    }
    case 'SET_SPONSOR_DATA_WITH_COUNT': {
      return Object.assign({}, state, {
        sponsorDataWithCount: action.payload,
      });
    }

    default:
      return state;
  }
}
