import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect, { components } from 'react-select';
import { getSponsorList } from '../../services/reimbursementClaims';
import { getSponsorList as getSponsorListWithCount } from '../../services/reimbursementAdmin';
import { SearchIcon } from './svgFiles';
import { setSponsorDataWitCount } from '../../actions';

const SponsorFilter = memo(({ onChangeValue, value, isAdmin }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const sponsorsValue = useSelector((state) => state.app.sponsorDataWithCount);
  const handleChange = (selectedOption) => {
    onChangeValue(selectedOption || '');
  };

  useEffect(() => {
    if (sponsorsValue.length === 0) {
      getSponsors();
    }
  }, []); // Dependency array ensures getSponsors runs only once

  const getSponsors = () => {
    setIsLoading(true);
    const apiCall = isAdmin ? getSponsorListWithCount : getSponsorList;
    apiCall()
      .then((response) => {
        const fetchedSponsors = isAdmin ? response : response.data;
        dispatch(setSponsorDataWitCount(fetchedSponsors));
      })
      .catch((error) => {
        console.error('Error fetching sponsors:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <SearchIcon />
        </components.DropdownIndicator>
      )
    );
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      flexDirection: 'row-reverse',
    }),
  };

  return (
    <div>
      <ReactSelect
        placeholder="Search.."
        onChange={handleChange}
        isClearable={true}
        isLoading={isLoading}
        theme={(theme) => ({
          ...theme,
          borderRadius: '10px',
          borderColor: 'red',
          colors: {
            ...theme.colors,
            primary50: '#d5d5d5',
            primary25: '#f5f5f5',
            primary: '#D5C9FF',
          },
        })}
        value={value.value}
        options={sponsorsValue.map((sponsor) => ({
          value: sponsor.sponsorId,
          label: isAdmin ? sponsor.name : sponsor.sponsorName,
        }))}
        components={{
          DropdownIndicator,
        }}
        styles={customStyles}
        menuPortalTarget={document.body}
      />
    </div>
  );
});

export default SponsorFilter;
