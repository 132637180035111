import visitLogo from '../../assets/images/visit-logo-white.svg';
import {
  SearchIcon,
  PlusIcon,
  BellIcon,
  RightIcon,
} from '../../../src/components/common/svgFiles';
import CreateClaim from '../../pages/ReimbursementClaims/CreateClaim';
import {
  toggleSidebarAction,
  setAuthToken,
  setSearchStringAction,
  setOnBreak,
  setNotificationModalClose,
  setOnBreakModalClose,
} from '../../actions/index';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLogOut } from '../../queries/logOutQuery';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation, Router } from 'react-router-dom';
import { FaAlignJustify, FaTimes } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {
  useUpdateDoctorIsCheckedIn,
  useGetDoctorIsCheckedIn,
  useFetchNotifications,
  useMarkNotificationRead,
  useUpdateIsOnBreak,
} from '../../queries/reimbursementQuery';
import { toast } from 'react-toastify';
import { initializeLiveDataClient } from '../../services/liveClient';
import { message } from 'antd';
import moment from 'moment';
const HeaderContainer = styled.div`
  z-index: 10;
  align-self: stretch;
  display: flex;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #714fff;
  border-radius: 0px 0px 25px 25px;
  padding: 0.875rem;
`;
const LogoContainer = styled.img`
  max-width: 9.8rem;
`;

const DropdownWrapper = styled.div`
  position: relative;
  width: 20%;
`;
const DropdownButtonBellIcon = styled.button`
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 10px;
  width: 40px;
  height: 40px;

  b &:hover {
    background-color: #f2f2f2;
  }
`;
const DropdownButton = styled.button`
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 10px;
  width: 40px;
  height: 40px;

  b &:hover {
    background-color: #f2f2f2;
  }
`;
const DropdownMenu = styled.ul`
  position: absolute;
  top: 48px;
  left: -108px;
  z-index: 999;
  right: -40px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  list-style: none;
`;
const DropdownMenuForNotifications = styled.ul`
  position: absolute;
  z-index: 999;
  top: 48px;
  left: -408px;
  right: -40px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 5px;
  margin: 0;
  list-style: none;
  max-height: 387px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 #ffffff;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ddd6fc;
    border-radius: 4px;
  }
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  color: #3a3a4a;
  font-weight: 600;
  font-family:
Montserrat
  &:hover {
    background-color: #ddd6fc;
  }
`;
const ThreeDotIcon = styled(BsThreeDotsVertical)`
  font-size: 1.7rem;
  padding-left: 2px;
  margin-left: 4px;
  color: #714fff;
`;
const Avatar = styled.div`
  background-color: ${(props) => props.color};
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 18px;
  margin-right: 2px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CountLabel = styled.span`
  position: absolute;
  right: -23px;

  background-color: #fd4848;
  color: #fff;
  font-size: 0.8rem;
`;

function ReimbursementNavbar({ opsName, isOpen, authToken, brokerId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { bellModalClose } = useSelector((state) => state.app);
  const { onBreakModalClose } = useSelector((state) => state.app);
  const [createClaimModalVisible, setCreateClaimModalVisible] = useState(false);
  const [checkedIn, setCheckedIn] = useState(false);
  //const [openBellIcon, setOpenBellIcon] = useState(bellModalClose);
  const searchKeyword = useSelector((state) => state.app.searchString);
  const caseLimit = useSelector((state) => state.app.opsUser?.caseLimit);
  const AdminUser = useSelector((state) => state.app.opsUser);
  const [notification, setNotification] = useState(0);
  const [isOnBreak, setIsOnBreak] = useState(0);
  const [notificationOpenCounter, setNotificationOpenCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const createClaim = () => {
    setCreateClaimModalVisible(true);
  };
  const onSuccessMarkRead = (success) => {
    setNotification(0);
  };
  const onErrorMarkRead = () => {};
  const route = location.pathname;
  const updateModalVisible = (modalVisible) => {
    setCreateClaimModalVisible(modalVisible);
  };
  const { data: notifications, refetch: fetchNotifications } =
    useFetchNotifications(AdminUser?.showReimbursementAdmin);

  const dataChat = (payload) => {
    fetchNotifications();
  };
  useEffect(() => {
    if (AdminUser?.showReimbursementAdmin) {
      const liveData = initializeLiveDataClient(AdminUser);
      const channelId = `maori-admin-user`;
      liveData.subscribe(channelId, { dataChat });
    }
  });

  const onSuccess = (success) => {
    dispatch(setAuthToken(''));
    dispatch(setSearchStringAction(''));
    dispatch(toggleSidebarAction(false));
    navigate('/login');
  };
  const toggleSideBar = (e) => {
    if (route !== '/' && route !== '/login') {
      dispatch(toggleSidebarAction(true));
    }
  };
  let onMarkOnBreakSuccess = (success) => {
    fetchOpsResult();
    const msg = isOnBreak ? 'Off' : 'On';
    toast.success(`${msg} Break`, { theme: 'dark' });
  };
  let onMarkOnBreakError = (error) => {
    toast.error('Error.', { theme: 'dark' });
    fetchOpsResult();
  };
  const onError = (error) => {};
  const { mutate: logOut } = useLogOut(onSuccess, onError);
  const { mutate: markOnBreak } = useUpdateIsOnBreak(
    onMarkOnBreakSuccess,
    onMarkOnBreakError
  );
  const onClickOnBreak = (value) => {
    markOnBreak(value);
  };
  const onClickLogOut = () => {
    logOut();
  };

  const ToggleIcon = styled(FaAlignJustify)`
    color: white;
  `;
  const ToggleClose = styled(FaTimes)`
    color: white;
  `;
  const onSearch = (e) => {
    dispatch(setSearchStringAction(e.target.value));
  };
  const toggleDropdown = () => {
    const value = onBreakModalClose ? false : true;
    dispatch(setNotificationModalClose(false));
    dispatch(setOnBreakModalClose(value));
  };
  const onSuccessIsChecked = (success) => {
    if (success) {
      const type = success.type === 'check-in' ? 'assigned' : 'unassigned';
      toast.success(
        `${success.count} pending case${
          success === 1 ? ' is' : 's are '
        }${type} `,
        { theme: 'dark' }
      );
      fetchOpsResult();
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
    fetchOpsResult();
  };
  const onErrorIsChecked = (err) => {
    toast.error(err, { theme: 'dark' });
    setIsLoading(false);
  };
  const { mutate: updateIsChecked } = useUpdateDoctorIsCheckedIn(
    onSuccessIsChecked,
    onErrorIsChecked
  );

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      const count = notifications.filter((d) => d.readed === 0) || [];
      setNotification(count.length);
    }
  }, [notifications]);

  const { data: opsResult, refetch: fetchOpsResult } =
    useGetDoctorIsCheckedIn(authToken);

  useEffect(() => {
    if (opsResult) {
      setCheckedIn(opsResult?.checkedIn);
      setIsOnBreak(opsResult?.IsOnBreak);
      dispatch(setOnBreak(opsResult?.IsOnBreak));
    }
  }, [checkedIn, dispatch, opsResult]);

  const OnChangeIsChecked = (value) => {
    const isChecked = value;
    setIsLoading(true);
    updateIsChecked({ isChecked, caseLimit });
  };

  const { mutate: markNotificationAsRead } = useMarkNotificationRead(
    onSuccessMarkRead,
    onErrorMarkRead
  );
  const onChangeBellState = () => {
    const value = bellModalClose ? false : true;
    //setOpenBellIcon(value);
    dispatch(setNotificationModalClose(value));
    dispatch(setOnBreakModalClose(false));
    const notificationIds =
      notifications &&
      notifications.filter((d) => d.readed === 0).map((d) => d.msgId);
    if (value && notificationIds.length > 0 && notificationOpenCounter === 0) {
      markNotificationAsRead({ notificationIds });
      setNotificationOpenCounter(+1);
    }
  };
  const handleBlur = () => {
    dispatch(setNotificationModalClose(false));
  };
  const breakHandleBlur = () => {
    dispatch(setOnBreakModalClose(false));
  };
  const getRandomColor = () => {
    const colors = [
      '#714EFF',
      '#ff5722',
      '#8bc34a',
      '#e91e63',
      '#9c27b0',
      '#009688',
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const NameAvatar = ({ name, color }) => {
    const initials = name
      .split(' ')
      .map((word) => word[0])
      .join('');
    return <Avatar color={color}>{initials}</Avatar>;
  };
  const notificationMap = (notifications) => {
    const notificationArr = notifications.filter((d) => d.readed === 0) || [];
    const renderNotification = () => {
      return notificationArr.map((d, index) => (
        <>
          <DropdownItem key={index}>
            <div className="flex flex-row">
              <NameAvatar name={d.name} color={getRandomColor()} />
              <div>
                <h2 className="mt-2 ml-1">{d.message}</h2>
                <small
                  className="ml-2"
                  style={{ color: '##9F9F9F', fontFamily: 'Montserrat' }}
                >
                  {moment(d.notificationDate).format('Do MMM, hh:mm a')}
                </small>
                {index !== d.length - 1}
              </div>
            </div>
          </DropdownItem>
          <hr></hr>
        </>
      ));
    };
    const noNotification = () => <DropdownItem>No Notifications</DropdownItem>;

    return notificationArr.length > 0 ? renderNotification() : noNotification();
  };

  return authToken ? (
    <div>
      <HeaderContainer>
        <div className="flex" style={{ width: '100%' }}>
          {brokerId && brokerId ? (
            ''
          ) : (
            <div className="pl-1 pr-4 pt-2">
              {isOpen ? (
                <ToggleClose onClick={toggleSideBar}></ToggleClose>
              ) : (
                <ToggleIcon onClick={toggleSideBar} />
              )}
            </div>
          )}
          <div className="basis-1/3 pr-10">
            <Link to="/reimbursement-claims" state={{ state: opsName }}>
              <LogoContainer src={visitLogo} alt="Visit Logo" />
            </Link>
          </div>
          {route.indexOf('/reimburse-claim/') !== 0 &&
            route.indexOf('/reimbursement-doctor') !== 0 &&
            route.indexOf('/doctor-daily-report') !== 0 &&
            route.indexOf('/all-doctor-daily-report') !== 0 && (
              <>
                {brokerId && brokerId && false ? (
                  ''
                ) : (
                  <div className="basis-1/3 pr-10">
                    <label className="relative block ">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        {/* <LogoContainer src={searchIcon} alt='search-logo' /> */}
                        <SearchIcon />
                      </span>

                      <input
                        className=" placeholder-[#714FFF] bg-white placeholder:font-semibold 
                        border border-slate-300 rounded-lg py-2 pl-10 pr-4 focus:outline-none"
                        placeholder="Search by User Name/ Patient Name/ Mobile Number..."
                        type="text"
                        style={{
                          width: '600px',
                        }}
                        value={searchKeyword ? searchKeyword : ''}
                        onChange={(e) => onSearch(e)}
                      />
                    </label>
                  </div>
                )}
              </>
            )}

          {route === '/reimbursement-claims' ? (
            <>
              {brokerId && brokerId ? (
                ''
              ) : (
                <>
                  <div className="basis-1/3">
                    <button
                      className=" font-semibold
                         hover:text-white py-2 px-4 border
                            flex items-center w-[160px]"
                      onClick={createClaim}
                      style={{
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        color: '#714FFF',
                      }}
                    >
                      <PlusIcon
                      //style={{ backgroudColor: 'red' }}
                      />{' '}
                      Add a claim
                    </button>
                  </div>

                  <div className="basis-1/6 pr-3">
                    <button
                      className=" btn mr-auto
                        font-semibold hover:text-black py-2 px-4 border border-white-500 
                        hover:border-transparent flex items-center w-[150px]"
                      style={{
                        borderRadius: '10px',
                        backgroundColor: '#ffff',
                        color: checkedIn ? 'red' : 'green',
                      }}
                      onClick={() =>
                        OnChangeIsChecked(checkedIn ? false : true)
                      }
                      disabled={isLoading}
                    >
                      {checkedIn ? 'check-out' : 'check-in'}
                      <IoIosArrowForward
                        style={{
                          color: checkedIn ? 'red' : 'green',
                          fontSize: '1rem',
                        }}
                      />
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            ''
          )}

          <div className="ml-auto">
            <DropdownWrapper>
              <DropdownButtonBellIcon
                tabIndex="1"
                onBlur={handleBlur}
                onClick={onChangeBellState}
                className="btn bg-[white]"
                style={{
                  backgroudColor: 'white',
                  borderRadius: '12px',
                  marginRight: '20px',
                  padding: '6px',
                  top: '1px',
                }}
              >
                <CountLabel
                  style={{
                    borderRadius: '50%',
                    width: '18px',
                    height: '18px',
                    top: '2px',
                  }}
                >
                  {notification > 9 ? '9+' : notification}
                </CountLabel>
                <BellIcon />
              </DropdownButtonBellIcon>
              {bellModalClose && (
                <DropdownMenuForNotifications>
                  {notificationMap(notifications || [])}
                </DropdownMenuForNotifications>
              )}
            </DropdownWrapper>
          </div>

          {/* <div className="basis-1/3 pr-10">
            <h2 style={{ color: 'white', fontWeight: 'bold' }}>
              {opsName && opsName}
            </h2>
          </div> */}
          <div className="">
            <DropdownWrapper>
              <DropdownButton
                onClick={toggleDropdown}
                tabIndex="2"
                onBlur={breakHandleBlur}
              >
                <ThreeDotIcon />
              </DropdownButton>
              {onBreakModalClose && (
                <DropdownMenu>
                  <DropdownItem
                    onMouseDown={() => onClickOnBreak(isOnBreak ? false : true)}
                  >
                    Mark {isOnBreak ? 'Off' : 'On'} Break
                  </DropdownItem>
                  <DropdownItem onMouseDown={onClickLogOut}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              )}
            </DropdownWrapper>
          </div>
        </div>
      </HeaderContainer>
      <CreateClaim
        updateModalVisible={updateModalVisible}
        modalVisible={createClaimModalVisible}
      />
    </div>
  ) : (
    <>
      {/* <HeaderContainer>
        <LogoContainer src={visitLogo} alt="Visit Logo" />
      </HeaderContainer> */}
    </>
  );
}

const mapStateToProps = (state) => {
  const userName = state.app.opsUser?.name;
  const searchString = state.app.searchString;
  const brokerId = state.app.opsUser?.brokerId;
  return { userName, searchString, brokerId };
};

export default connect(mapStateToProps)(ReimbursementNavbar);
