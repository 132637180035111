import React from 'react';
import { useState, useEffect } from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import { useAllGetDailyReport } from '../../queries/reimbursementQuery';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { BiDownload } from 'react-icons/bi';
import { UpArrow, ArrowRightLeft } from '../../components/common/svgFiles';
import { getDailyReportsDataDownload } from '../../services/reimbursementClaims';
import moment from 'moment';
const Heading = styled.h2`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 22px;
  line-height: 37px;
  margin-top:8px
  color: #714fff;
`;
const styleObj = {
  'font-size': '15px',
  overflow: 'scroll',
  height: '100vh',
  'background-color': '#FFF',
  'font-weight': 'semi-bold',
  'font-family': 'Montserrat',
  'vertical-align': 'middle',
};
const ManageText = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  color: ${(props) => (props.toggle ? '#714fff' : '#69686A')};
  font-size: 14px;
`;
const CountLabel = styled.label`
  position: relative;
  right: -2px;
  background-color: #714fff;
  color: #fff;
  font-size: 0.8rem;
`;
const SubText = styled.p`
  font-weight: bold;
  color: ${(props) => (!props.toggle ? '#714fff' : '#69686A')};
  font-size: 14px;
  font-family: Montserrat;
`;
const getTrProps = (state, rowInfo, instance) => {
  if (rowInfo) {
    return {
      //className: 'center',
      style: {
        minHeight: '70px',
        verticalAlign: 'center',
      },
    };
  }
  return {};
};
const DownloadIcon = styled(BiDownload)`
  color: #714fff;
  font-size: 1.5rem;
  margin-right: 4px;
`;

const Dot = styled.label`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left:4px
  background-color: ${(props) => props.isActiveDoc};
`;

export default function AllDoctorDailyReport() {
  const [reqDate, setReqDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [weeklyStartDate, setStartWeeklyDate] = useState('');
  const [weeklyEndDate, setWeeklyEndDate] = useState('');
  const [toggle, setToggle] = useState(false);
  const [isDailyReport, setIsDailyReport] = useState(true);
  const onDailyReportDateChange = (e) => {
    setReqDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    setWeeklyEndDate(e.target.value);
  };
  const onWeeklyReportDateChange = (e) => {
    setStartWeeklyDate(e.target.value);
  };
  useEffect(() => {
    if (weeklyStartDate && weeklyEndDate) {
      setReqDate(weeklyStartDate);
      setEndDate(weeklyEndDate);
    }
  });

  //   const totalCompleted = (data) => {
  //     const totalCount =
  //       Number(data.reject) +
  //       Number(data.underCheck) +
  //       Number(data.documentDef) +
  //       Number(data.qcCheck);

  //     return totalCount;
  //   };
  const pendingCases = (data) => {
    const pendingCount =
      Number(data.assignedCasesCount) - Number(data.totalCount);
    return pendingCount < 0 ? 0 : pendingCount;
  };

  const renderColorCode = (active, isBreak, checkedIn) => {
    if (active && checkedIn && !isBreak) {
      return '#5cb85c';
    } else if (isBreak) {
      return '#FEC006';
    } else if (!checkedIn) {
      return '#FFA500';
    } else {
      return '#DD3445';
    }
  };
  const dailyReportColumn = () => [
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{
            backgroundColor: '',
            marginLeft: '40px',
            textAlign: 'center',
            paddingBottom: '5px',
          }}
        >
          Name
          <UpArrow />
        </div>
      ),
      width: 180,
      accessor: 'opsUser',
      Cell: (row) => (
        <div className="ml-3 font-semibold w-full h-full flex items-center ">
          {row.original.opsUser}
          <Dot
            isActiveDoc={renderColorCode(
              row?.original?.isActiveDoc,
              row?.original?.onBreak,
              row?.original?.checkedIn
            )}
          ></Dot>
        </div>
      ),
    },
    {
      Header: 'Check-In-Time',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'checkedInAt',
      Cell: (row) => (
        <div className="font-semibold w-full h-full flex items-center justify-center ">
          {row.original.checkedInAt ? (
            <>
              {moment(row.original.checkedInAt).format('hh:mm A')}{' '}
              <CountLabel
                style={{
                  borderRadius: '50%',
                  width: '18px',
                  height: '18px',
                  top: '-5px',
                }}
              >
                {row.original.noOfCheckIn}
              </CountLabel>
            </>
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      Header: 'Check-Out-Time',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'checkedOutAt',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center">
          {row.original.checkedOutAt
            ? moment(row.original.checkedOutAt).format('hh:mm A')
            : '-'}
        </span>
      ),
    },
    {
      Header: 'Copy Claim',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'copyCount',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center">
          {row.original.copyCount}
        </span>
      ),
    },

    {
      Header: 'Assigned Case',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'checkedOutAt',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center">
          {row.original.assignedCasesCount}
        </span>
      ),
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px', textAlign: 'center' }}
        >
          Cases Completed
          <UpArrow />
        </div>
      ),
      width: 160,
      style: { textAlign: 'center' },
      accessor: 'totalCount',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center ">
          {row.original.totalCount}
        </span>
      ),
    },
    {
      Header: 'Pending Cases',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'checkedOutAt',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center">
          {pendingCases(row.original)}
        </span>
      ),
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px', textAlign: 'center' }}
        >
          Avg. Claim Processing TAT
          <UpArrow />
        </div>
      ),
      width: 230,
      style: { textAlign: 'center' },
      accessor: 'avgTat',
      Cell: (row) => (
        <span className="font-semibold s w-full h-full flex items-center justify-center ">
          {row.original.avgTat ? row.original.avgTat : '-'}
        </span>
      ),
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px', textAlign: 'center' }}
        >
          Productive Hours
          <UpArrow />
        </div>
      ),
      width: 160,
      style: { textAlign: 'center' },
      accessor: 'totalHours',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center ">
          {row.original.totalHours ? row.original.totalHours : '-'}
        </span>
      ),
    },
  ];

  const weeklyReport = () => [
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{
            backgroundColor: '',
            marginLeft: '40px',
            textAlign: 'center',
            paddingBottom: '5px',
          }}
        >
          Name
          <UpArrow />
        </div>
      ),
      width: 180,
      accessor: 'opsUser',
      Cell: (row) => (
        <div className="ml-3 font-semibold w-full h-full flex items-center ">
          {row.original.opsUser}
        </div>
      ),
    },
    {
      Header: 'Copy Claim',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'copyCount',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center">
          {row.original.copyCount}
        </span>
      ),
    },

    {
      Header: 'Assigned Case',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'assignedCasesCount',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center">
          {row.original.assignedCasesCount}
        </span>
      ),
    },

    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px', textAlign: 'center' }}
        >
          Cases Completed
          <UpArrow />
        </div>
      ),
      width: 160,
      style: { textAlign: 'center' },
      accessor: 'totalCount',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center ">
          {row.original.totalCount}
        </span>
      ),
    },
    {
      Header: 'Pending Cases',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'checkedOutAt',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center">
          {pendingCases(row.original)}
        </span>
      ),
    },
    {
      Header: 'Claims Updated',
      width: 500,
      Cell: (row) => {
        return (
          <div className="font-semibold w-full h-full flex items-center justify-center ">
            <h1
              className="font-semibold"
              style={{
                border: `2px solid #F7D3C8`,
                borderRadius: '20px',
                padding: '5px',
                backgroundColor: '#FFF8F7',
                fontFamily: 'Montserrat',
                fontWeight: '14px',
              }}
            >
              {row.original.reject} Rejected
            </h1>
            <h1
              className="ml-1 font-semibold"
              style={{
                border: `2px solid #C2E0FC`,
                borderRadius: '20px',
                padding: '5px',
                backgroundColor: '#F5FAFE',
                fontFamily: 'Montserrat',
                fontWeight: '14px',
              }}
            >
              {row.original.underCheck} Document Verified
            </h1>
            <h1
              className="ml-1 font-semibold"
              style={{
                border: `2px solid #DFD9FE`,
                borderRadius: '20px',
                padding: '5px',
                backgroundColor: '#F8F9FF',
                fontFamily: 'Montserrat',
                fontWeight: '14px',
              }}
            >
              {row.original.documentDef} DD Case
            </h1>
            <h1
              className="ml-1 font-semibold"
              style={{
                border: `2px solid #D9F1BD`,
                borderRadius: '20px',
                padding: '5px',
                backgroundColor: '#F9FEEF',
                fontFamily: 'Montserrat',
                fontWeight: '14px',
              }}
            >
              {row.original.qcCheck} QC Check
            </h1>
          </div>
        );
      },
    },

    {
      Header: 'Productive Days',
      style: { textAlign: 'center' },
      width: 150,
      accessor: 'checkedOutAt',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center">
          {row.original.workingDay ? `${row.original.workingDay} Days` : '-'}
        </span>
      ),
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px', textAlign: 'center' }}
        >
          Avg. Claim Processing TAT
          <UpArrow />
        </div>
      ),
      width: 150,
      style: { textAlign: 'center' },
      accessor: 'avgTat',
      Cell: (row) => (
        <span className="font-semibold s w-full h-full flex items-center justify-center ">
          {row.original.avgTat ? row.original.avgTat : '-'}
        </span>
      ),
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px', textAlign: 'center' }}
        >
          Productive Hours
          <UpArrow />
        </div>
      ),
      width: 160,
      style: { textAlign: 'center' },
      accessor: 'totalHours',
      Cell: (row) => (
        <span className="font-semibold w-full h-full flex items-center justify-center ">
          {row.original.totalHours ? row.original.totalHours : '-'}
        </span>
      ),
    },
  ];
  const {
    isLoading,
    isFetching,
    data: responseDate,
    error: errorInGetReport,
    refetch: refetchDailyReport,
  } = useAllGetDailyReport(reqDate, isDailyReport, endDate);

  //   const sortDate = (a, b, id) => {
  //     const dateA = new Date(a[id]);
  //     const dateB = new Date(b[id]);

  //     if (dateA < dateB) {
  //       return -1;
  //     } else if (dateA > dateB) {
  //       return 1;
  //     } else {
  //       return 0;
  //     }
  //   };

  //   const sortedArr =
  //     responseDate &&
  //     responseDate
  //       .map((obj) => ({ key: new Date(obj.claimDate), value: obj }))
  //       .sort((a, b) => b.key - a.key)
  //       .map((obj) => obj.value);

  const toggleAction = (e) => {
    setToggle(e);
    const value = isDailyReport ? false : true;
    setIsDailyReport(value);
    if (value) {
      setReqDate('');
      setEndDate('');
      setWeeklyEndDate('');
      setStartWeeklyDate('');
    } else {
      setReqDate(moment().subtract(10, 'days').format('YYYY-MM-DD'));
      setEndDate(moment().format('YYYY-MM-DD'));
      setWeeklyEndDate('');
      setStartWeeklyDate('');
    }
  };
  function downloadArrayOfObjectsAsCSV(data, filename) {
    const headers = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map((row) => Object.values(row).join(',')).join('\n');
    const csvData = headers + rows;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    if (navigator.msSaveBlob) {
      // For IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    }
  }

  return (
    <div
      className="container-fluid w-full px-0  flex flex-col primary-bg m-0 p-0"
      style={{ backgroundColor: '#F5F3F8' }}
    >
      <div className="flex flex-row justify-end">
        <div className="basis-1/4 m-4 ">
          <Heading> Doctor Daily Report</Heading>
        </div>
        <div className=" basis-1/3 flex flex-row mt-4 ">
          <div
            className="flex "
            style={{
              border: '2px solid #714FFF',
              borderRadius: '8px',
              height: '3rem',
              padding: '4px',
            }}
          >
            <SubText className="mt-2" toggle={toggle}>
              Daily Report
            </SubText>
            <Switch
              className="ml-2 mt-2"
              onChange={(e) => toggleAction(e)}
              style={{
                width: '40px',
                backgroundColor: toggle ? '#714FFF' : '#D6CCFF',
              }}
            ></Switch>
            <ManageText className="ml-2 mt-2 mr-2" toggle={toggle}>
              Time Report
            </ManageText>
          </div>
        </div>
        <div className="ml-auto flex mt-4 mr-4">
          {isDailyReport ? (
            <div className="flex mr-3 mt-1 ">
              <div
                className="mt-2 m-2 p-1"
                style={{
                  border: '2px solid #714fff',
                  borderRadius: '10px',
                  backgroundColor: '#FFFFFF',
                  height: '35px',
                }}
              >
                <input
                  className="p-0"
                  type="date"
                  style={{ color: '#3A3A49' }}
                  onChange={onDailyReportDateChange}
                  value={reqDate}
                  max={moment().format('YYYY-MM-DD')}
                  disabled={toggle}
                ></input>
              </div>
            </div>
          ) : (
            <div className=" basis-1/2 flex mr-3 m-1 ">
              <div
                className="border-2 p-1"
                style={{
                  border: '2px solid #FFFFFF',
                  borderRadius: '10px',
                  backgroundColor: '#FFFFFF',
                  paddingBottom: '28px',
                  height: '3rem',
                }}
              >
                <label
                  className="block p-0"
                  style={{
                    color: '#714FFF',
                    fontSize: '12px',
                    fontFamily: 'Montserrat',
                    fontStyle: 'bold',
                    marginBottom: '0rem',
                    fontWeight: 'bold',
                  }}
                >
                  Start From
                </label>

                <input
                  className="p-0"
                  type="date"
                  style={{ color: '#3A3A49' }}
                  value={reqDate}
                  onChange={(e) => onWeeklyReportDateChange(e)}
                  max={moment().format('YYYY-MM-DD')}
                ></input>
              </div>
              <div className=" mt-3">
                <ArrowRightLeft />
              </div>

              <div
                className="border-2 p-1"
                style={{
                  border: '2px solid #FFFFFF',
                  borderRadius: '10px',
                  backgroundColor: '#FFFFFF',
                  height: '3rem',
                }}
              >
                <label
                  className="block p-0"
                  style={{
                    color: '#714FFF',
                    fontSize: '12px',
                    fontFamily: 'Montserrat',
                    fontStyle: 'bold',
                    marginBottom: '0rem',
                    fontWeight: 'bold',
                  }}
                >
                  End on
                </label>

                <input
                  className="p-0"
                  type="date"
                  style={{ color: '#3A3A49' }}
                  value={endDate}
                  onChange={(e) => onEndDateChange(e)}
                  max={moment().format('YYYY-MM-DD')}
                ></input>
              </div>
            </div>
          )}

          <button
            className="btn btn flex"
            onClick={() =>
              downloadArrayOfObjectsAsCSV(
                (responseDate && responseDate) || [],
                `all-Doctor-DailyReport-${
                  isDailyReport ? 'daily-Report' : 'weekly-Report'
                }`
              )
            }
            style={{
              width: '178px',
              borderRadius: '10px',
              backgroundColor: '#fff',
              height: '40px',
              marginTop: '7px',
            }}
          >
            <DownloadIcon />
            Download CSV
          </button>
        </div>
      </div>

      <div className="pt-2" style={{}}>
        <ReactTable
          columns={isDailyReport ? dailyReportColumn() : weeklyReport()}
          data={responseDate && responseDate}
          useFlexLayout={true}
          showPageJump={false}
          // className="-striped -highlight"
          style={styleObj}
          defaultSorted={
            [
              // {
              //   id: 'id',
              //   desc: true,
              // },
            ]
          }
          loading={isLoading || isFetching}
          getTrProps={getTrProps}
          pageSizeOptions={[10]}
        />
      </div>
    </div>
  );
}
