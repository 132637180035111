import { Modal } from 'antd';
import React, { useCallback } from 'react';
import MapWidget from '../widgets/MapWidget';

const CommonMapModal = ({
    open,
    onClose = () => { },
    onChange = () => { },
    onAllClose = () => { },
}) => {

    const handleClose = useCallback(() => {
        onClose();
        setTimeout(onAllClose, 100);
    }, [onClose, onAllClose]);

    return <Modal
        title='Choose hospital on map'
        open={open}
        onClose={onClose}
        onCancel={onClose}
        footer={null}
        style={{ minWidth: '75vw', height: 'fit-content', marginTop: '-10vh', }}
    >
        <MapWidget onClose={handleClose} onChange={onChange} mapStyles={{ height: '65vh' }} />
    </Modal>
};

export default CommonMapModal;
