import React, { useRef, useCallback, memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'antd/dist/antd.css';
import { Alert, Space, Spin } from 'antd';
import 'react-tabs/style/react-tabs.css';
//import Modal from 'react-modal';
import CommentForm from './comments/CommentForm';
import CommentList from './comments/CommentList';
import ReimbursementNavbar from '../../components/ReimbursementNavbar/ReimbursementNavbar';
import UserClaimDetail from './UserClaimDetail';
import ReimbursementUser from './ReimbursementUser';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import appConfig from '../../config/appConfig';
import RejectionReasons from './RejectionReasons';
import ReimbursementInvoiceDetail from './ReimbursementInvoiceDetail';
import AddItemsContainer from './AddItemsContainer';
import DependentsContainer from './DependentsContainer';
import '../../styling/table.css';
import PastClaims from './PastClaims';
import PolicyRemarksModal from './PolicyRemarksModal';
import RemarksConfirmModal from './RemarksConfirmModal';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import ReimbursementDigitization from './ReimbursementDigitization';
import BlackListHospitalVerify from './BlackListHospitalVerify';
import DocumentDeficient from './DocumentDeficient';
import { debounce } from '../../utils/debounce';
import ShowIcon from '../../assets/images/show.svg';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import ReimbursementPrePost from './ReimbursementPrePost';
import {
  getWalletBalanceByReimbursementType,
  getDoctorNameSuggestion,
  verifyDoctorRegistration,
  getPolicyDependents,
  getBlacklistHospital,
  checkMerchantNameIsBlacklisted,
  getDocumentDeficientQueryCount,
  getLabTests,
  getMedicines,
  fetchDigitisationDocumentsId,
  fetchDigitisedItems,
  changeDocumentTypeReq,
} from '../../services/reimbursementClaims';
import { setNotificationModalClose, setOnBreakModalClose } from '../../actions';
import DeleteIcon from '../../assets/images/delete.svg';
import { store } from '../../store/';
import {
  useReimburseRejectionReason,
  useClaim,
  useCallUserRequest,
  useCopyClaimRequest,
  useDeleteClaim,
  useUploadInvoice,
  useUploadPrescription,
  useUploadMerFile,
  useDeleteInvoice,
  useDeletePrescription,
  useDeleteInvoiceItem,
  useSubmitPrescriptionsForDigitization,
  useReimbursementProcedures,
  useUpdateClaim,
  useFetchCommentByOrderId,
  useDeficienyRemarks,
  useUpdateClaimToDocumentsDeficient,
  useUpdateDoctorRegistration,
  useReimbursementPrePostReq,
  useAddClarificationReason,
  useClarificationSolution,
  usePastClaims,
  useFetchPolicyRemarks,
  useFetchPolicyFiles,
  useFetchCopyClaims,
  useMarkSuspiciousRequest,
} from '../../queries/reimbursementQuery';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react';
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from 'react-icons/bs';
import ConfirmModal from '../../containers/common/ConfirmModal';
import {
  RupeeIcon,
  EditIcon,
  RightIcon,
} from '../../components/common/svgFiles';
import { useEffect } from 'react';
import DoctorVerifyTableModal from './DoctorVerifyTableModal';
import DoctorVerifyModal from './DoctorVerifyModal';
import EditAmountModal from './EditAmountModal';
import ActionsContainer from './ActionsContainer';
import { FaEye, FaPlus } from 'react-icons/fa';
import CopyClaims from './CopyClaims';
import { connect, useSelector, useDispatch } from 'react-redux';

const MainHeading = styled.p`
  font-family: 'Montserrat';
  font-size: 20px;
  color: ${(props) => (props.isSuspicious ? '#FF6262' : '#714fff')};
  font-weight: 600;
  letter-spacing: -0.6px;
`;
const InputItem = styled.input`
  border: 1.5px solid #714fff;
  border-radius: 6px;
  width: 200px;
  height: 38px;
  line-height: normal;
`;

const InputMed = styled.input`
  border: 2px solid #cccccc;
  border-radius: 6px;
  width: 800px;
  height: 2.7rem;
  line-height: normal;
`;

const InputAmount = styled.input`
  border: 1.5px solid #714fff;
  border-radius: 6px;
  width: 90px;
  height: 38px;
  line-height: normal;
  &:disabled {
    background-color: #f8f8fe; /* Light grey background for disabled state */
    cursor: not-allowed; /* Change cursor to indicate disabled state */
  }
`;
const Title = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #714fff;
`;

const RightArrow = styled(BsFillArrowRightCircleFill)`
  font-size: 1.5rem;
  font-weight: normal;
  color: #714fff;
  padding-left: 2px;
`;

const LeftArrow = styled(BsFillArrowLeftCircleFill)`
  font-size: 1.5rem;
  font-weight: normal;
  color: #714fff;
  padding-right: 2px;
`;

const Button = styled.button`
  background: #ffffff;
  border: 1px solid #714fff;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
`;
const Plus = styled(FaPlus)`
font-size: 1.2rem;
  font-weight: normal;
  color : #714FFF;
  padding-left 1px;
`;
const ViewButton = styled(FaEye)`
  font-size: 1.2rem;
  font-weight: normal;
 
  padding-left 1px;
`;

const ApprovedText = styled.span`
  color: ${(props) => props.color};
  font-size: 15px;
  font-weight: 800;
`;

const LogoContainer = styled.img`
  width: 23px;
  height: 23px;
`;
const DeleteLogoContainer = styled.img`
  width: 30px;
  height: 30px;
  padding: 2px;
  border: 2px solid #714fff;
  border-radius: 3px;
`;

const clarificationOptions = [
  {
    label: 'Maternity Related Claim',
    value: 'Maternity Related Claim',
  },
  {
    label: 'Doctor details not found on Google',
    value: 'Doctor details not found on Google',
  },
  {
    label: 'Diagnosis is mandatory',
    value: 'Diagnosis is mandatory',
  },
  {
    label: 'AYUSH claims on hold',
    value: 'AYUSH claims on hold',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
const itemRemarks = [
  // {
  //   label: ' Capping Applicable',
  //   value: 'cappingApplicable',
  // },
  {
    label: 'Approved as per wallet balance',
    value: 'approvedWalletBalance',
  },
  {
    label: ' Unprescribed medicines',
    value: 'unprescribedMedicines',
  },
  {
    label: 'Unprescribed test',
    value: 'unprescribedTest',
  },
  // {
  //   label: 'Other',
  //   value: 'Other',
  // },
  {
    label: 'Not covered',
    value: 'notCovered',
  },
  {
    label: 'OTC Product',
    value: 'otcProduct',
  },
];
const documentsDeficientOption = [
  {
    label: 'Prescription',
    value: 'prescription',
  },
  {
    label: 'Invoice',
    value: 'invoice',
  },
  {
    label: 'Labs Report',
    value: 'lab-report',
  },
];

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Text = styled.p`
  color: #714fff;
  font-weight: bold;
  position: relative;
  display: inline-block;
`;

const Badge = styled.span`
  position: absolute;
  top: -7px;
  right: -14px;
`;

const ButtonPrescription = styled.button`
  background: #ffffff;
  border: 1px solid #714fff;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
`;

function ReimburseClaimRequest() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = store.getState();
  const OpsUserDetail = state.app.opsUser;
  const { isOnBreak } = useSelector((state) => state.app);
  const { reimbursementData } = state.app;
  const idsArray = [];
  reimbursementData &&
    reimbursementData.forEach((d, index) => {
      idsArray.push(d.reimbursementId);
    });
  const [reimbursementInfo, setReimbursementInfo] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [prescriptionModalIsOpen, setPrescriptionModalIsOpen] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [deficiencyRemarksArray, setDeficiencyRemarksArray] = useState([]);
  const [invoiceId, setInvoiceId] = useState(null);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    header: '',
    body: '',
    onSubmit: null,
    requestId: null,
    onClose: null,
  });
  const [invoiceName, setInvoiceName] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [invoiceDateInputFocused, setInvoiceDateInputFocused] = useState(false);
  const [invoiceTeleNumber, setInvoiceTeleNumber] = useState('');
  const [doctorVerifyByGoogleSearch, setDoctorVerifyByGoogleSearch] =
    useState(false);
  const [prescriptions, setPrescriptions] = useState([]);
  const [selectedPrescriptionFiles, setSelectedPrescriptionFiles] = useState(
    []
  );
  const [comments, setComments] = useState([]);
  const [digitizing, setDigitizing] = useState(false);
  const [isCriticalIllness, setIsCriticalIllness] = useState(false);
  const [isMaternity, setIsMaternity] = useState(false);
  const [walletBalanceAmount, setWalletBalanceAmount] = useState(null);
  const [finalApprovalStatus, setFinalApprovalStatus] = useState('pending');
  const [editingFinalApprovedPrice, setEditingFinalApprovedPrice] =
    useState(false);
  const [updating, setUpdating] = useState(false);
  const [docs, setDocs] = useState([]);
  const [rejectionReasonList, setRejectionReasonList] = useState([]);
  const [allRejectionList, setAllRejectionList] = useState([]);
  const [firstEffectToggel, setFirstEffectToggel] = useState(false);
  const [oracleCiScan, setOracleCiScan] = useState(false);
  const [doctorRegModal, setDoctorRegModal] = useState(false);
  const [doctorTableModal, setDoctorTableModal] = useState(false);
  const [doctorNameSuggestions, setDoctorNameSuggestions] = useState([]);
  const [doctorName, setDoctorName] = useState('');
  const [doctorRegId, setDoctorRegId] = useState('');
  const [registrationDoctorArr, setRegistrationDoctorArr] = useState([]);
  const [oracleDentalCheck, setOracleDentalCheck] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [prePostModal, setPrePostModal] = useState(false);
  const [reimbursementForDependent, setReimbursementForDependent] =
    useState(false);
  const [reimbursementFor, setReimbursementFor] = useState(-1);
  const { claimId } = useParams();
  const toastTheme = 'dark';
  const [qcRemarkModal, setQcRemarkModal] = useState(false);
  const [pastClaimModal, setPastClaimModal] = useState(false);
  const [ciWalletAmount, setCiWalletAmount] = useState(null);
  const procedureSelectionRef = useRef();
  const [pastClaimStdate, setPastClaimStdate] = useState('');
  const [pastClaimEdDate, setPastClaimEdDate] = useState('');
  const [procedureId, setProcedureId] = useState(null);
  // const [policyModal, setPolicyModal] = useState(false);
  const [policyRemark, setPolicyRemark] = useState('');
  const [policyFile, setPolicyFile] = useState([]);
  const [faqFile, setFaqFile] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [selectedPolicyId, setSelectedPolicyId] = useState(null);
  const [isDigitized, setIsDigitized] = useState(false);
  const [isWalletLoading, setIsWalletLoading] = useState(true);
  const [consultationId, setConsultationId] = useState('');
  const [docDeficientQueryData, setDocDeficientQueryData] = useState([]);
  const [empanelLabPartnerId, setEmpanelLabPartnerId] = useState('');
  const [showMerchantSelectOption, setShowMerchantSelectOption] =
    useState(false);
  const [searchType, setSearchType] = useState('');
  const [merchantValue, setMerchantValue] = useState(null);
  const [
    blackListedHospitalOrDiagnosticId,
    setBlackListedHospitalOrDiagnosticId,
  ] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [labLists, setLabList] = useState([]);
  const [selectedLabsList, setSelectedLabList] = useState([]);
  const [isLabModal, setIsLabModal] = useState(false);
  const [isMedicineModal, setIsMedicineModal] = useState(false);
  const [dosageView, setDosageView] = useState(false);
  const [timeOfDay, setTimeOfDay] = useState('');
  const [dosageValue, setDosageValue] = useState('');
  const [duration, setDuration] = useState('');
  const [durationPeriod, setDurationPeriod] = useState('');
  const [selectedCodiId, setSelectedCodiId] = useState(null);
  const [enabledRows, setEnabledRows] = useState({});
  const [multipleCodiOption, setMultipleCodiOption] = useState([]);
  const [multipleCodiOptionValue, setMultipleCodiOptionValue] = useState([]);
  const [manualAddMedsCheck, setManualAddMedsCheck] = useState(false);
  const [digitisedCodiId, setDigitisedCodiIds] = useState([]);
  //const [dosageOption, setDosageOption] = useState([]);
  const [rejectionReasons, setRejectionReasons] = useState({
    prescriptionInvalid: false,
    invoiceDateDifferent: false,
    detailsMismatch: false,
    invalidStamp: false,
    invalidDoctorName: false,
    invalidPatientName: false,
    blurredPicture: false,
    genuinePrescription: false,
    signatureMissing: false,
    inappropriatePicture: false,
    invalidReimbursement: false,
    patientNotCovered: false,
    invoiceBeforePolicyDate: false,
    invoiceInAnotherClaim: false,
    itemsNotPrescribed: false,
    documentsMissing: false,
    invoiceNotItemized: false,
    outOfPolicy: false,
    policyNotActive: false,
    sumInsuredExhausted: false,
    registrationCheckFailed: false,
    invoiceBeforePrescriptionDate: false,
    documentUploadedNotAsPerNorm: false,
    benefitNotCovered: false,
    AlreadyApprovedMedOrLabs: false,
    PrescriptionValidityCrossed: false,
    blackListedHospitalOrDiagnostic: false,
    SubLimitBenefitExhausted: false,
    InvalidBankDetails: false,
    CashlessBenefitAvailed: false,
    fiftyPercentageWalletAllowed: false,
  });
  const [policyMemberDetails, setPolicyMemberDetails] = useState(null);
  const [copyClaimOpen, setCopyClaimOpen] = useState(false);
  const [invoiceAlreadyClaimId, setInvoiceAlreadyClaimId] = useState(null);
  const [checkNewPolicy, setNewPolicy] = useState(false);
  const [
    checkIsDependentOrHolderSelected,
    setCheckIsDependentOrHolderSelected,
  ] = useState(false);
  const { brokerId } = state.app.opsUser;
  const [blacklistedHospital, setBlacklistedHospital] = useState([]);
  const [blackListHospitalValue, setBlackListHospitalValue] = useState('');
  const [isDiagnostic, setIsDiagnostic] = useState(false);
  const [blacklistedWarning, setBlacklistedWarning] = useState(false);
  const [reimbursementTypeName, setReimbursementTypeName] = useState('');
  const [ddModal, setDdModal] = useState(false);
  const [ddEdgeCaseRemark, setDDEdgeCaseRemark] = useState(false);
  const [documentsDeficientRemark, setDocumentsDeficientRemark] = useState([]);
  const [otherMerchantNameModal, setOtherMerchantNameModal] = useState(false);
  const [nonEmpanelLabPartnerId, setNonEmpanelLabPartnerId] = useState('');
  const validValues = ['lab', 'health', 'vaccine', 'Diagnostics', 'labs'];
  const [nonEmpanelVaccinationCenterId, setNonEmpanelVaccinationCenterId] =
    useState('');
  const [empanelVaccinationCenterId, setEmpanelVaccinationCenterId] =
    useState('');
  const [medsList, setMedsList] = useState([]);
  const [cartMedList, setCartMedList] = useState([]);
  const [selectedMedicineName, setSelectedMedicineName] = useState('');
  const [selectedMedicineNameValue, setSelectedMedicineNameValue] =
    useState('');
  const [rxValue, setRxValue] = useState();
  const [drugId, setDrugId] = useState('');
  const [masterGroupId, setMasterGroupId] = useState('');
  const [codiId, setCodiId] = useState('');
  const [multipleCodi, setMultipleCodi] = useState([]);
  const [walletApiCall, setWalletApiCall] = useState(true);
  let reimbursementDetail,
    policyDetail,
    bankDetail,
    amountClaimedForProcedure = 0,
    isJnJClaim,
    procedureDetail,
    selectedDocumentsDeficient = [],
    policyDependents = [],
    documentArr = [],
    clarificationstatus = '',
    //documentsDeficientRemark = [],
    deficiencyComment = '',
    ahcDetails = [],
    digitizationDetails;

  const {
    data: reimburseRejectReason,
    error: errorInRejectionList,
    isLoading,
  } = useReimburseRejectionReason();
  // setRejectionReasonList(reimburseRejectReason)
  useEffect(() => {
    if (reimburseRejectReason) {
      setRejectionReasonList(reimburseRejectReason);
      setAllRejectionList(reimburseRejectReason);
    }
  }, [reimburseRejectReason]);

  const {
    data: claimData,
    error: errorInClaimData,
    refetch: getClaim,
  } = useClaim(claimId);
  let nextId = null;
  const findNextAndPreviousValue = (array, target) => {
    let nextId = null;
    let previousId = null;
    for (let i = 0; i < array.length; i++) {
      if (i === array.length - 1) {
        nextId = array[i - 1];
        break;
      }
      if (i === 0 && array[i] === target) {
        previousId = array[i + 1];
      }
      if (array[i] === target) {
        previousId = array[i + 1];
        nextId = array[i - 1];
        break;
      }
    }
    return { nextId, previousId };
  };

  const nextclaim = () => {
    const { nextId } = findNextAndPreviousValue(idsArray, parseInt(claimId));
    if (nextId) {
      navigate(`/reimburse-claim/${nextId}`, {
        state: { tableData: reimbursementData && reimbursementData },
      });
    }
  };
  const updateIsDigitizedState = () => {
    setIsDigitized(true);
  };

  const onDoctorVerifyCheck = () => (event) => {
    setDoctorVerifyByGoogleSearch(event.target.checked);
  };
  const previousClaim = () => {
    const { previousId } = findNextAndPreviousValue(
      idsArray,
      parseInt(claimId)
    );
    if (previousId) {
      navigate(`/reimburse-claim/${previousId}`, {
        state: { tableData: reimbursementData && reimbursementData },
      });
    }
  };
  if (errorInClaimData) {
    const errorMessage =
      errorInClaimData.errorMessage || 'Something went wrong';
    toast.error(errorMessage, { icon: '❌', theme: toastTheme });
  }
  const OnSuccessReqCall = (success) => {
    setConfirmModal({ isOpen: false });
    toast.success(success, { icon: '☎️', theme: toastTheme });
  };
  const OnErrorReqCall = (error) => {
    toast.error('Error .', { icon: '❌', theme: toastTheme });
  };
  const OnSuccessCopyClaim = (success) => {
    setConfirmModal({ isOpen: false });
    const { claimId } = success;
    toast.success('Claim Copied', { icon: '✅', theme: toastTheme });
    const anchorElement = document.createElement('a');
    anchorElement.setAttribute('href', `/reimburse-claim/${claimId}`);
    anchorElement.setAttribute('target', '_blank');
    anchorElement.click();
  };
  const OnSuccessClaimSuspicious = (success) => {
    setConfirmModal({ isOpen: false });
    getClaim();
    toast.success('Marked Suspicious', { icon: '✅', theme: toastTheme });
  };
  const OnErrorClaimSuspicious = (error) => {
    toast.error(error, { icon: '❌', theme: toastTheme });
  };
  const OnErrorCopyClaim = (error) => {
    setConfirmModal({ isOpen: false });
    toast.error('Error creating claim copy', { icon: '❌', theme: toastTheme });
  };
  const OnSuccessDeleteClaim = (success) => {
    setConfirmModal({ isOpen: false });
    getClaim();
    toast.success('Claim Deleted', { icon: '🚮', theme: toastTheme });
  };
  const OnErrorDeleteClaim = (error) => {
    setConfirmModal({ isOpen: false });
    toast.error('Error deleting claim', { icon: '❌', theme: toastTheme });
  };
  const OnSuccessUploadInvoice = (success) => {
    getClaim();
    toast.success('Upload Complete', { icon: '✅', theme: toastTheme });
  };
  const OnErrorUploadInvoice = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      { icon: '❌', theme: toastTheme }
    );
  };
  const OnSuccessDeleteInvoice = () => {
    getClaim();
    toast.success('File Deleted', { icon: '✅', theme: toastTheme });
  };
  const OnErrorDeleteInvoice = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      { icon: '❌', theme: toastTheme }
    );
  };
  const onSuccessSubmitPrescriptions = (success) => {
    getClaim();
    toast.success('Submitted For Digitization', {
      icon: '✅',
      theme: toastTheme,
    });
  };
  const OnErrorSubmitPrescription = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      { icon: '❌', theme: toastTheme }
    );
  };
  const onSuccessClaimUpdate = (success) => {
    toast.success('Claim updated!', { icon: '✅', theme: toastTheme });
    getClaim();
  };
  const OnErrorClaimUpdate = (error) => {
    const versionError = error?.response?.data;
    toast.error(
      versionError?.errorMessage ||
        error.errorMessage ||
        error.message ||
        'Something went wrong. Please try again later',
      { icon: '❌', theme: toastTheme, autoClose: 7000 }
    );
    setUpdating(false);
  };
  const onSuccessDocumentsDeficient = (success) => {
    setDdModal(false);
    toast.success('Claim updated!', { icon: '✅', theme: toastTheme });
    getClaim();
  };
  const OnErrorDocumentDeficient = (error) => {
    const errMsg = error;
    toast.error(errMsg || 'Something went wrong. Please try again later', {
      icon: '❌',
      theme: toastTheme,
    });
  };
  const onSuccessUpdateDocReg = (success) => {
    setConfirmModal({ isOpen: false });
    toast.success('Doctor verified!', { icon: '✅', theme: toastTheme });
  };

  const onErrorDocReg = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      { icon: '❌', theme: toastTheme }
    );
  };
  const OnSuccessAddReason = (success) => {
    setConfirmModal({ isOpen: false });
    getClaim();
    toast.success('Clarification Added', { icon: '✅', theme: toastTheme });
  };
  const OnErrorAddReason = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      { icon: '❌', theme: toastTheme }
    );
  };
  const onSuccessSolution = (success) => {
    setConfirmModal({ isOpen: false });
    getClaim();
    toast.success('Clarification Solution  Added', {
      icon: '✅',
      theme: toastTheme,
    });
  };
  const onErrorSolution = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      { icon: '❌', theme: toastTheme }
    );
  };

  const { mutate: callUserReq } = useCallUserRequest(
    OnSuccessReqCall,
    OnErrorReqCall
  );
  const { mutate: copyUserClaim } = useCopyClaimRequest(
    OnSuccessCopyClaim,
    OnErrorCopyClaim
  );
  const { mutate: markUserClaimSuspicious } = useMarkSuspiciousRequest(
    OnSuccessClaimSuspicious,
    OnErrorClaimSuspicious
  );
  const { mutate: deleteClaim } = useDeleteClaim(
    OnSuccessDeleteClaim,
    OnErrorDeleteClaim
  );

  const { mutate: uploadInvoiceFile } = useUploadInvoice(
    OnSuccessUploadInvoice,
    OnErrorUploadInvoice
  );

  const { mutate: uploadPrescriptionFile } = useUploadPrescription(
    OnSuccessUploadInvoice,
    OnErrorUploadInvoice
  );

  const { mutate: uploadMerFile } = useUploadMerFile(
    OnSuccessUploadInvoice,
    OnErrorUploadInvoice
  );

  const { mutate: deleteInvoiceUrl } = useDeleteInvoice(
    OnSuccessDeleteInvoice,
    OnErrorDeleteInvoice
  );

  const { mutate: deletePrescriptionUrl } = useDeletePrescription(
    OnSuccessDeleteInvoice,
    OnErrorUploadInvoice
  );

  const { mutate: deleteInvoiceItemList } = useDeleteInvoiceItem(
    OnSuccessDeleteInvoice,
    OnErrorUploadInvoice
  );
  const { mutate: submitPrescriptionsForDigitization } =
    useSubmitPrescriptionsForDigitization(
      onSuccessSubmitPrescriptions,
      OnErrorSubmitPrescription
    );
  const { mutate: updateClaimReq } = useUpdateClaim(
    onSuccessClaimUpdate,
    OnErrorClaimUpdate
  );
  const { mutate: clarificationReason } = useAddClarificationReason(
    OnSuccessAddReason,
    OnErrorAddReason
  );
  const { mutate: clarificationSolution } = useClarificationSolution(
    onSuccessSolution,
    onErrorSolution
  );

  const { mutate: updateClaimToDocumentsDeficient } =
    useUpdateClaimToDocumentsDeficient(
      onSuccessDocumentsDeficient,
      OnErrorDocumentDeficient
    );
  const { mutate: updateDoctorRegistration } = useUpdateDoctorRegistration(
    onSuccessUpdateDocReg,
    onErrorDocReg
  );
  const {
    data: reimbursementPrePostRequestsData,
    refetch: getReimbursementPrePostData,
  } = useReimbursementPrePostReq(claimId);

  if (claimData && claimData.reimbursementDetails) {
    reimbursementDetail = claimData.reimbursementDetails;
    policyDetail = claimData.policyDetails;
    bankDetail = claimData.bankDetails;
    procedureDetail = claimData.procedureDetails;
    isJnJClaim = claimData.isJnJClaim;
    policyDependents = claimData.policyDependents;
    amountClaimedForProcedure = claimData.amountClaimedForProcedure;
    digitizationDetails = claimData.digitizationDetails;
    ahcDetails = claimData.previousAhc || [];
  }
  useEffect(() => {
    if (claimData && claimData.reimbursementInvoiceItems) {
      //selectedRx: { value: option.value, label: option.label },

      const inputCheck =
        claimData?.reimbursementDetails?.requestStatus === 'qc-check'
          ? false
          : true;
      const transformedArray =
        digitizationDetails &&
        digitizationDetails.map((item, index) => ({
          value: item.codiId,
          label: `Rx${index + 1}`,
        }));

      const allSelectedOptions = [];

      const updatedInvoiceItems = claimData.reimbursementInvoiceItems.map(
        (item) => {
          // Find the transformed item matching the codiId
          const match = transformedArray.find(
            (transformedItem) => transformedItem.value === item.codiId
          );

          // If a match is found, add the rxValue key
          if (match) {
            allSelectedOptions.push({ value: match.value, label: match.label });

            return {
              ...item,
              selectedRx: { value: match.value, label: match.label },
              isChecked: true,
            };
          } else {
            return {
              ...item,
              selectedRx: { value: '', label: 'Invoice' },
              isChecked: true,
            };
          }

          // Otherwise, return the item as is
          return item;
        }
      );
      setInvoiceItems(updatedInvoiceItems);

      // setMultipleCodiOption(allSelectedOptions);
    }
    if (claimData && claimData.prescriptionDetails) {
      const prescriptionFiles = reimbursementDetail.prescriptionLink
        ? reimbursementDetail.prescriptionLink.split(',')
        : [];
      const invoiceFiles = reimbursementDetail.invoiceLink
        ? reimbursementDetail.invoiceLink.split(',')
        : [];
      const prescriptionsInfo = claimData.prescriptionDetails.map((ele) => ({
        files: ele.files.split(',').map((link, idx) => {
          if (prescriptionFiles) {
            const findIndex = prescriptionFiles.findIndex(
              (file) => file === link
            );
            if (findIndex !== -1) {
              return {
                label: `Prescription File ${findIndex + 1}`,
                value: link,
              };
            }
            if (invoiceFiles.length > 0) {
              const findIndex = invoiceFiles.findIndex((file) => file === link);
              if (findIndex !== -1) {
                return {
                  label: `Invoice File ${findIndex + 1}`,
                  value: link,
                };
              } else {
                return {
                  label: 'Invoice File',
                  value: link,
                };
              }
            } else {
              return {
                label: 'Prescription File',
                value: link,
              };
            }
          }
        }),
        status: ele.digitizationStatus,
      }));

      setPrescriptions(prescriptionsInfo);
    }
    if (claimData && claimData.reimbursementInvoice) {
      const { reimbursementInvoice } = claimData;
      // if (reimbursementInvoice && reimbursementInvoice.invoiceDate) {
      //     setInvoiceDate(moment(reimbursementInvoice.invoiceDate, 'DD/MM/YYYY'))
      // }
      setInvoiceId(reimbursementInvoice.invoiceId);
      setInvoiceName(reimbursementInvoice.invoiceName);
      setInvoiceNumber(reimbursementInvoice.invoiceNumber);
      setInvoiceTeleNumber(reimbursementInvoice.invoiceTeleNumber);
    }
    if (reimbursementDetail) {
      const checkIsExist = validValues.some((validValue) =>
        reimbursementDetail?.reimbursementProcedureKey.includes(validValue)
      );
      const checkForVaccine =
        reimbursementDetail?.reimbursementProcedureKey.includes('vaccine');

      if (checkForVaccine) {
        setSearchType('vaccination');
      }
      if (checkIsExist) {
        setShowMerchantSelectOption(true);
      }
      if (!checkIsExist) {
        setShowMerchantSelectOption(false);
      }
      setReimbursementForDependent(reimbursementDetail.relative ? true : false);
      setReimbursementTypeName(reimbursementDetail.reimbursementType);
      if (walletApiCall) {
        setProcedureId(reimbursementDetail.procedureId);
      }

      setFinalApprovalStatus(
        reimbursementDetail.requestStatus
          ? reimbursementDetail.requestStatus
          : 'pending'
      );
      setDoctorVerifyByGoogleSearch(
        reimbursementDetail.doctorVerifyByGoogleSearch
      );
      setIsCriticalIllness(reimbursementDetail.isCriticalIllness);
      setIsMaternity(reimbursementDetail.maternityCheck);
      const reimbursementFor = reimbursementDetail.relative
        ? reimbursementDetail.relativeId || reimbursementDetail.dependentId
        : (policyDetail || {}).holderId;
      setReimbursementFor(reimbursementFor);
      setSelectedPolicyId(policyDetail?.policyId);
      setBlackListHospitalValue(
        reimbursementDetail.blacklistedHospitalName &&
          reimbursementDetail.blacklistedHospitalAddress
          ? `${reimbursementDetail.blacklistedHospitalName}-${reimbursementDetail.blacklistedHospitalAddress}`
          : ''
      );
    }
    if (reimbursementDetail && reimbursementDetail.invoiceDate) {
      setInvoiceDate(moment(reimbursementDetail.invoiceDate, 'DD/MM/YYYY'));
    }
    if (
      reimbursementDetail &&
      reimbursementDetail.CategoryName === 'gym-fitness-membership'
    ) {
      setIsDigitized(true);
    }
  }, [claimData]);

  //api call for document deficient

  const callUser = () => {
    setConfirmModal({
      isOpen: true,
      header: `Call ${reimbursementDetail.userName}`,
      body: `Are you sure you want to place call to ${
        reimbursementDetail.userName
      } regarding claim made for ${
        reimbursementDetail.relative || reimbursementDetail.userName
      } ${
        reimbursementDetail.relative
          ? '(' + reimbursementDetail.relation + ')'
          : ''
      }`,
      onSubmit: () =>
        callUserReq({
          eventId: reimbursementDetail.reimbursementId,
          eventType: 'claim-reimburse',
        }),
      onClose: () => {
        setConfirmModal({ isOpen: false });
      },
    });
  };

  const userId = reimbursementDetail?.userId;
  const reimbursementId = reimbursementDetail?.reimbursementId;
  const policyId = policyDetail?.policyId;
  const sponsorId = reimbursementDetail?.sponsorId;
  const relativeValue = reimbursementDetail?.relativeId;
  const dateOfInvoice =
    reimbursementDetail && reimbursementDetail.invoiceDate
      ? moment(reimbursementDetail.invoiceDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )
      : '';

  const {
    data: availableProceduresData,
    error: errorInReimbursementProcedures,
  } = useReimbursementProcedures(userId, dateOfInvoice, relativeValue);
  const { data: policyRemarks, refetch: refetchPolicyRemarks } =
    useFetchPolicyRemarks(sponsorId);
  const { data: policyFileAndFaq } = useFetchPolicyFiles(sponsorId);
  const { data: deficiencyRemarks, error: errorInFetchingDeficiencyRemarks } =
    useDeficienyRemarks();
  const {
    data: pastClaimsArr,
    error: errorInFetchingPastClaimsArr,
    refetch: refetchPastClaimReq,
  } = usePastClaims(claimId, pastClaimStdate, pastClaimEdDate);
  const { data: copyClaimList } = useFetchCopyClaims(claimId);
  const setDeficiencyRemark =
    deficiencyRemarks &&
    deficiencyRemarks.data.map((data) => {
      return {
        value: data.id,
        label: data.remark,
      };
    });
  useEffect(() => {
    if (deficiencyRemarks) {
      setDeficiencyRemarksArray(setDeficiencyRemark);
    }
  }, [deficiencyRemarks]);

  const OnInvoiceDateChange = (date) => {
    setInvoiceDate(date);
  };

  const { data: fetchCommentByOrderId, error: errorFetchCommentByOrderId } =
    useFetchCommentByOrderId({
      reimbursementId,
      appConfig: appConfig.REQUEST_CHAT.INTERNAL,
    });

  const availableProcedures =
    availableProceduresData &&
    availableProceduresData.result.map((d) => ({
      value: d.procedureId,
      label: d.title,
      metaData: d,
    }));

  const fetchData = () => {
    const commentArr = [];
    fetchCommentByOrderId &&
      fetchCommentByOrderId.forEach((c) => {
        commentArr.push({
          name: c.userName,
          message: c.text,
          time: c.createdAt,
        });
      });

    return setComments(...comments, commentArr);
  };

  const changeDocumentType = async (type, documentId) => {
    await changeDocumentTypeReq({ documentId, documentType: type })
      .then((result) => {
        getClaim();
        toast.success('Document Updated', { icon: '✅', theme: toastTheme });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const dosageOptions = [
    { value: '1-0-0', label: 'once a day in the morning' },
    { value: '0-1-0', label: 'once a day in afternoon' },
    { value: '0-0-1', label: 'once a day in the evening' },
    { value: '1-0-1', label: 'twice a day once in morning and evening' },
    {
      value: '1-1-1',
      label: 'three times a day morning, afternoon and evening',
    },
    { value: '1-1-1-1', label: 'four times a day' },
    { value: '1-1-1-1-1', label: 'five times a day' },
    { value: '1-0-0-0-0-0-0', label: 'once a week' },
    { value: '1-0-0-0-0-0-1', label: 'twice a week' },
    { value: '1-0-1-0-1-0-1', label: 'alternate day' },
    { value: 'Other', label: 'Other' },
    { value: 'SOS', label: 'SOS' },
  ];

  const handleCheckboxChange = (idx, ele) => {
    setEnabledRows((prev) => {
      // Toggle enabled state for the row
      const newEnabledState = !prev[idx];

      // Update the corresponding invoice item values if the checkbox is checked
      if (newEnabledState) {
        // If enabling the row, set values to default
        onInvoiceItemChanged(idx, 'itemPrice')({ target: { value: '0.00' } });
        onInvoiceItemChanged(
          idx,
          'approvedAmount'
        )({ target: { value: '0.00' } });
        onInvoiceItemChanged(idx, 'itemCount')({ target: { value: 'N.A' } });
      }
      if (!newEnabledState) {
        // If enabling the row, set values to default
        onInvoiceItemChanged(idx, 'itemPrice')({ target: { value: '0.00' } });
        onInvoiceItemChanged(
          idx,
          'approvedAmount'
        )({ target: { value: '0.00' } });
        onInvoiceItemChanged(idx, 'itemCount')({ target: { value: 'N.A' } });
      }

      return {
        ...prev,
        [idx]: newEnabledState, // Toggle enabled state for the row
      };
    });
  };

  useEffect(() => {
    if (fetchCommentByOrderId) {
      fetchData();
    }
  }, [fetchCommentByOrderId]);

  useEffect(() => {
    if (digitizationDetails && digitizationDetails.length > 0) {
      fetchCodiIds(reimbursementId);
    }
  }, [digitizationDetails]);

  const addComment = (comment) => {
    console.log('addComment', { comment });
    setComments([...comments, comment]);
  };
  const renderPolicyFileAndFaq = () => {
    let policyArr = [],
      faqArr = [];

    if (policyFileAndFaq && policyFileAndFaq.length > 0) {
      policyFileAndFaq.forEach((data, index) => {
        policyArr.push({ uri: data.policyStructureUrl });
        faqArr.push({ uri: data.faqsUrl });
      });
      setPolicyFile(policyArr);
      setFaqFile(faqArr);
    }
  };

  useEffect(() => {
    if (policyFileAndFaq && policyFileAndFaq.length > 0) {
      renderPolicyFileAndFaq();
    }
  }, [policyFileAndFaq]);

  const oracleChecks = (index) => (event) => {
    if (index === 'oracleCiScan') {
      setOracleCiScan(event.target.checked);
    }
    if (index === 'oracleDentalCheck') {
      setOracleDentalCheck(event.target.checked);
    }
  };

  const editTotalApprovedAmount = () => {
    setEditingFinalApprovedPrice(true);
    setIsOpen(true);
  };

  const openLabModal = () => {
    setIsLabModal(true);
  };
  const openMedicineModal = () => {
    setIsMedicineModal(true);
  };

  const fetchDocDeficient = async () => {
    await getDocumentDeficientQueryCount(reimbursementId)
      .then((response) => {
        setDocDeficientQueryData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (reimbursementId) {
      fetchDocDeficient();
    }
  }, [reimbursementId]);

  const requestCopyClaim = () => {
    setConfirmModal({
      isOpen: true,
      header: `Copy ${reimbursementDetail.reimbursementId}`,
      body: `Are you sure you want to copy this claim? This will create a new claim for the user.`,
      onSubmit: () =>
        copyUserClaim({
          claimId: reimbursementDetail.reimbursementId,
        }),
      onClose: () => {
        setConfirmModal({ isOpen: false });
      },
    });
  };

  const fetchCodiIds = async () => {
    const { reimbursementId } = reimbursementDetail;
    await fetchDigitisationDocumentsId(reimbursementId)
      .then(async (response) => {
        const codiOption = response.map((d, indx) => ({
          value: d.codiId,
          label: `Rx ${indx + 1}`,
          mergedUrl: d.mergedUrl,
          codiId: d.codiId,
        }));

        setMultipleCodi(codiOption);
        setMultipleCodiOption((prevOptions) => [
          { value: '', label: 'Invoice' }, // Add default value
          ...codiOption,
        ]);
        if (
          reimbursementDetail.requestStatus !== 'documents-verified' &&
          reimbursementDetail.requestStatus !== 'approved' &&
          reimbursementDetail.requestStatus !== 'qc-check' &&
          reimbursementDetail.requestStatus !== 'rejected'
        ) {
          await fetchDigitisedItemsList(codiOption);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDigitisedItemsList = async (codiOption) => {
    const codiIds = codiOption.map((item) => item.codiId);
    const codiLookup = codiOption.reduce((acc, item) => {
      acc[item.codiId] = item.label;
      return acc;
    }, {});

    if (claimData && claimData?.reimbursementInvoiceItems.length === 0) {
      if (codiIds.length > 0) {
        try {
          const response = await fetchDigitisedItems(codiIds);

          // Initialize an empty array to hold all items
          let allItems = [];

          if (response.drugsCartItems.length > 0) {
            const medData = response.drugsCartItems.map((d) => ({
              itemName: d.genericName,
              codiId: d.codiId,
              type: 'medicine',
              isBlock: true,
              selectedRx: {
                value: d.codiId,
                label: codiLookup[d.codiId] || '',
              },
              drugId: d.dimDrugId,
              masterGroupId: d.masterGroupId,
              sourceType: d.sourceType,
            }));
            allItems = allItems.concat(medData);
          }

          if (response.labsCartItems.length > 0) {
            const labData = response.labsCartItems.map((d) => ({
              itemName: d.testName,
              codiId: d.codiId,
              type: 'labs',
              isBlock: true,
              selectedRx: {
                value: d.codiId,
                label: codiLookup[d.codiId] || '',
              },
              labId: d.testId,
              sourceType: d.sourceType,
            }));
            allItems = allItems.concat(labData);
          }

          if (response.otherItems.length > 0) {
            const otherData = response.otherItems.map((d) => ({
              itemName: d.name,
              codiId: d.codiId,
              isBlock: true,
              selectedRx: {
                value: d.codiId,
                label: codiLookup[d.codiId] || '',
              },
              otherId: d.cdOthersId,
              sourceType: d.sourceType,
            }));
            allItems = allItems.concat(otherData);
          }

          // Set the combined items to the state
          setInvoiceItems(allItems);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const renderDocs = () => {
    if (claimData && claimData.reimbursementDocuments.length > 0) {
      claimData.reimbursementDocuments
        //.split(',')
        .forEach((data, idx) => {
          //if (data.type === 'prescription') {
          documentArr.push({ uri: data.url, type: data.type });
          setDocs(documentArr);
          // }
        });
    }

    // reimbursementDetail.invoiceLink && reimbursementDetail.invoiceLink
    //     .split(',')
    //     .forEach((link, idx) =>
    //         documentArr.push({ uri: link })
    //     )
    // setDocs(documentArr)
    // reimbursementDetail.merLink && reimbursementDetail.merLink
    //     .split(',')
    //     .forEach((link, id) =>
    //         documentArr.push({ uri: link })

    //     )
    // setDocs(documentArr)
    // reimbursementDetail.labReportLink && reimbursementDetail.labReportLink
    //     .split(',')
    //     .forEach((link, id) =>
    //         documentArr.push({ uri: link })

    //     )
    // setDocs(documentArr)
    //  }
  };

  useEffect(() => {
    if (claimData) {
      renderDocs();
    }
  }, [claimData]);

  const deleteUserClaim = () => {
    setConfirmModal({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    const reason = document.querySelector('#deleteReason').value;
    if (!reason) {
      toast.error('Please specify a valid reason to delete this claim');
      setConfirmModal({ isOpen: false });
      return;
    }
    return deleteClaim({
      reason,
      claimId: reimbursementDetail.reimbursementId,
    });
  };
  const markClaimSuspiciousBody = (claimId) => {
    const { reimbursementId, userId } = reimbursementDetail;
    setConfirmModal({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    const reason = document.querySelector('#suspiciousReason').value;
    if (!reason) {
      toast.error(
        'Please specify a valid reason to mark this claim suspicious'
      );
      setConfirmModal({ isOpen: false });
      return;
    }
    return markUserClaimSuspicious({
      sourceId: reimbursementId,
      reason,
      userId,
    });
  };
  const addInvoiceFile = () => {
    document.querySelector('#add-invoice-input').click();
  };
  const requestDeleteClaim = () => {
    setConfirmModal({
      isOpen: true,
      header: `Delete ${reimbursementDetail.reimbursementId}`,
      body: (
        <div className="container">
          <div className="col-12">
            <FormGroup check>
              <Label for="deleteReason">
                Specify a reason to delete this claim
              </Label>
              <input
                type="text"
                name="deleteReason"
                id="deleteReason"
                class="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-3 dark:placeholder-gray-400"
                placeholder="Enter Reason"
                required
              />
            </FormGroup>
          </div>
        </div>
      ),
      onSubmit: () => deleteUserClaim(reimbursementDetail.reimbursementId),
      requestId: reimbursementDetail.reimbursementId,
      onClose: () => {
        setConfirmModal({ isOpen: false });
      },
    });
  };

  const onInvoiceFileAdded = (ev) => {
    const reimbursementId = reimbursementDetail.reimbursementId;
    const file = document.querySelector('#add-invoice-input').files[0];
    const toastId = toast('Uploading', {
      position: 'top-right',
      closeButton: false,
      closeOnClick: false,
      hideProgressBar: false,
      type: toast.TYPE.INFO,
      progress: 0,
      theme: toastTheme,
    });
    const onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const { loaded, total } = progressEvent;
        toast.update(toastId, {
          progress: loaded / total,
        });
      }
    };
    if (file) {
      uploadInvoiceFile({ reimbursementId, file, onUploadProgress });
    }
  };
  const addPrescriptionFile = () => {
    document.querySelector('#add-prescription-input').click();
  };
  const onPrescriptionFileAdded = (ev) => {
    const reimbursementId = reimbursementDetail.reimbursementId;
    const file = document.querySelector('#add-prescription-input').files[0];
    const toastId = toast('Uploading', {
      position: 'top-right',
      closeButton: false,
      closeOnClick: false,
      hideProgressBar: false,
      type: toast.TYPE.INFO,
      progress: 0,
    });
    const onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const { loaded, total } = progressEvent;
        toast.update(toastId, {
          progress: loaded / total,
        });
      }
    };
    if (file) {
      uploadPrescriptionFile({ reimbursementId, file, onUploadProgress });
    }
  };

  const addMerFile = () => {
    document.querySelector('#add-mer-input').click();
  };
  const onMerFileAdded = (ev) => {
    const reimbursementId = reimbursementDetail.reimbursementId;
    const file = document.querySelector('#add-mer-input').files[0];
    const toastId = toast('Uploading', {
      position: 'top-right',
      closeButton: false,
      closeOnClick: false,
      hideProgressBar: false,
      type: toast.TYPE.INFO,
      progress: 0,
    });
    const onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const { loaded, total } = progressEvent;
        toast.update(toastId, {
          progress: loaded / total,
        });
      }
    };
    if (file) {
      uploadMerFile({ reimbursementId, file, onUploadProgress });
    }
  };

  let data = null;
  const onHandleDocumentsDeficientChanged = (e) => {
    selectedDocumentsDeficient = e;
  };

  const prescriptionAndInvoiceArr = () => {
    let arr = [];
    if (reimbursementDetail && reimbursementDetail.prescriptionLink) {
      reimbursementDetail &&
        reimbursementDetail.prescriptionLink.split(',').map((link, idx) =>
          arr.push({
            label: `Prescription File ${idx + 1}`,
            value: link,
          })
        );
    }
    if (reimbursementDetail && reimbursementDetail.invoiceLink) {
      reimbursementDetail &&
        reimbursementDetail.invoiceLink.split(',').map((link, idx) =>
          arr.push({
            label: `Invoice File ${idx + 1}`,
            value: link,
          })
        );
    }
    return arr;
  };

  const documentsDeficient = (reimbursementId) => {
    let documentsDeficient = '';
    if (selectedDocumentsDeficient.length > 0) {
      selectedDocumentsDeficient.forEach((element) => {
        documentsDeficient =
          documentsDeficient.length > 0
            ? `${documentsDeficient},${element.value}`
            : element.value;
      });
    }
    // const remarksData = documentsDeficientRemark
    //   .slice(',')
    //   .map((e) => {
    //     return e.label;
    //   })
    //   .join(',');

    const formatRemarksData = (documentsDeficientRemark) => {
      return documentsDeficientRemark
        .map((remark, index) => `Reason ${index + 1}: ${remark.label}`)
        .join('. ');
    };
    const remarksData = formatRemarksData(documentsDeficientRemark);

    const remarkIds = documentsDeficientRemark
      .slice(',')
      .map((e) => {
        return parseInt(e.value);
      })
      .join(',')
      .split(',') // Split by commas to get an array of strings
      .map((e) => parseInt(e.trim(), 10));
    if (!remarksData || !remarkIds.length) {
      return toast.error('Please select the Remark');
    }
    if (!deficiencyComment || deficiencyComment.length === 1) {
      return toast.error('Please add comments for deficiency');
    }

    const body = {
      reimbursementId,
      remark: remarksData,
      documentsDeficient,
      procedureId,
      deficiencyComment,
      remarkIds,
      ddEdgeCaseRemark,
    };
    updateClaimToDocumentsDeficient(body);
  };
  const requestDocumentsDeficient = () => {
    setDdModal(true);
    // setConfirmModal({
    //   isOpen: true,
    //   header: `Documents deficient ${reimbursementId}`,
    //   body: (
    //     <div className="container">
    //       <div className="col-12">
    //         <FormGroup check>
    //           <Label for="DocumentsDeficient" className="mr-2">
    //             Remark:
    //           </Label>
    //           {/* <input
    //               type="text"
    //               name="DocumentsDeficient"
    //               id="DocumentsDeficient"
    //               onChange={this.onHandleRemarkChanged}
    //             /> */}
    //           <Select
    //             theme={(theme) => ({
    //               ...theme,
    //               borderRadius: 0,
    //               colors: {
    //                 ...theme.colors,
    //                 primary25: '#F3F0FF',
    //               },
    //             })}
    //             styles={{ backgroundColor: 'green' }}
    //             isMulti
    //             placeholder="Select Remark"
    //             onChange={onHandleRemarkChanged}
    //             options={deficiencyRemarksArray}
    //           />
    //         </FormGroup>
    //         <p>value : {IsDdRemarkEdgeCase ? 'yes' : 'no'}</p>
    //         {confirmModal.uniqueId ? (
    //           <FormGroup check>
    //             <Label for="DocumentsDeficient">
    //               Select documents deficient:
    //             </Label>
    //             <Select
    //               styles={{ color: 'red' }}
    //               isMulti
    //               options={documentsDeficientOption}
    //               onChange={onHandleDocumentsDeficientChanged}
    //             />
    //           </FormGroup>
    //         ) : (
    //           ''
    //         )}
    //         <FormGroup check>
    //           <Label for="DocumentsDeficient" className="mr-2">
    //             Comment :
    //           </Label>
    //           <textarea
    //             style={{
    //               backgroundColor: '#F3F0FF',
    //               border: '1px solid #714FFF',
    //               borderRadius: '6px',
    //             }}
    //             onChange={(e) => OnChangeDeficiencyComment(e)}
    //             placeholder="Post a comment"
    //             className="form-control mt-2"
    //           />
    //         </FormGroup>
    //       </div>
    //     </div>
    //   ),
    //   onSubmit: () => documentsDeficient(reimbursementId),
    //   onClose: () => {
    //     setConfirmModal({ isOpen: false });
    //   },
    // });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
      fontSize: 16,
      textAlign: 'left',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      // padding: 10,
      fontSize: 12,
      textAlign: 'left',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      // padding: 10,
      fontSize: 14,
      textAlign: 'left',
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      padding: 10,
      fontSize: 14,
      textAlign: 'left',
    }),
  };
  const remarkCustomStyles = {
    container: (provided) => ({
      ...provided,
      height: '2rem', // Adjust the height of the container
    }),
    control: (provided) => ({
      ...provided,
      height: '2rem', // Adjust the height of the control element
      minHeight: '2rem',
      border: '2px solid #714FFF',
      borderRadius: '6px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '2rem', // Ensure value container fits within the specified height
      padding: '0 8px', // Adjust padding as needed
    }),
    input: (provided) => ({
      ...provided,
      margin: '0', // Remove default margin
      height: '2rem', // Ensure input height fits within container
    }),
    placeholder: (provided) => ({
      ...provided,
      height: '2rem', // Ensure placeholder height fits within container
      lineHeight: '2rem', // Vertically center the placeholder text
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none', // Hide the indicator separator if not needed
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      height: '2rem', // Adjust dropdown indicator height
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0, // Remove margin if needed
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0, // Remove padding to align items properly
    }),
  };
  const getLabs = (input) => {
    return getLabTests(input)
      .then((result) => {
        console.log({ result }, 'getLabs result');
        result.forEach((lab) => {
          lab.label = lab.name;
          lab.value = lab.name;
        });
        setLabList(result);
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  const getMedicinesInfo = (input) => {
    return getMedicines(input)
      .then((result) => {
        console.log({ result }, 'meds result');
        result.forEach((med) => {
          //med.label = `${med.brand_name} (${med.generic_name})`;
          med.label = med.name;
          med.value = med.dimDrugMasterId;
        });
        setMedsList(result);
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  // const promiseLabOptions = (input) => {
  //   return new Promise((resolve) => {
  //     debouncedGetLabTests(input.toLowerCase(), resolve);
  //   });
  // };

  const promiseLabOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getLabs(input.toLowerCase()));
      }, 500);
    });
  };

  const debouncedGetMedicines = debounce((input, resolve) => {
    getMedicinesInfo(input).then(resolve);
  }, 500);

  const promiseMedicinesOptions = (input) => {
    return new Promise((resolve) => {
      debouncedGetMedicines(input.toLowerCase(), resolve);
    });
  };

  const MedicineSelection = (option) => {
    if (option) {
      setSelectedMedicineName(option.label);
      setSelectedMedicineNameValue({
        value: option.label,
        label: option.label,
      });
      setDrugId(option.value);
      setMasterGroupId(option.masterGroupItemCode);
      setDosageView(true);
      return;
    }
    setSelectedMedicineNameValue({ value: '', label: '' });
    setDosageView(false);
  };
  const LabList = () => {
    return (
      <div>
        <h3 className="mt-2" style={{ fontWeight: 'bolder' }}>
          Your List
        </h3>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {multipleCodi.map((prescription, idx) => {
            return (
              <div key={prescription.codiId}>
                {selectedLabsList
                  .filter((item) => item.selectedCodiId === prescription.codiId)
                  .map((d, labIndex) => {
                    return (
                      <React.Fragment key={d.id}>
                        {labIndex === 0 ? (
                          <p className="mt-2" style={{ fontWeight: 'bold' }}>
                            Prescription - {idx + 1}
                          </p>
                        ) : (
                          <></>
                        )}
                        <li
                          style={{
                            padding: '10px 15px',
                            marginBottom: '10px',
                            backgroundColor: '#f9f9f9',
                            borderRadius: '8px',
                            border: '1px solid #ddd',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            fontSize: '16px',
                            color: '#333',
                          }}
                        >
                          {d.label}
                        </li>
                      </React.Fragment>
                    );
                  })}
              </div>
            );
          })}
        </ul>
      </div>
    );
  };

  const MedicineList = () => {
    return (
      <div>
        <h3 className="mt-2" style={{ fontWeight: 'bolder' }}>
          Drugs List
        </h3>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {multipleCodi.map((prescription, idx) => {
            return (
              <div key={prescription.codiId}>
                {cartMedList
                  .filter((item) => item.selectedCodiId === prescription.codiId)
                  .map((d, medIndex) => {
                    return (
                      <React.Fragment key={d.id}>
                        {medIndex === 0 ? (
                          <p className="mt-2" style={{ fontWeight: 'bold' }}>
                            Prescription - {idx + 1}
                          </p>
                        ) : (
                          <></>
                        )}

                        <li
                          key={d.id}
                          style={{
                            padding: '10px 15px',
                            marginBottom: '10px',
                            backgroundColor: '#f9f9f9',
                            borderRadius: '8px',
                            border: '1px solid #ddd',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            fontSize: '16px',
                            color: '#333',
                          }}
                        >
                          {d.medicineName}
                          {d.dosageValue}
                          {d.timeOfDay} {d.duration}
                          {d.durationPeriod}
                        </li>
                      </React.Fragment>
                    );
                  })}
              </div>
            );
          })}
        </ul>
      </div>
    );
  };

  const onLabSelect = (option, cb) => {
    // Use a callback function to ensure we get the latest state
    if (option) {
      setSelectedLabList((prevLabList) => {
        // Check if the option already exists in the list
        const isAlreadySelected = prevLabList.some(
          (lab) => lab.id === option.id
        );

        if (!isAlreadySelected) {
          // Add the new selection only if it doesn't already exist in the list
          const updatedLabList = [
            ...prevLabList,
            {
              id: option.id,
              label: option.label,
              selectedCodiId,
              selectedRx: multipleCodiOptionValue,
            },
          ];
          console.log('Updated Lab List:', updatedLabList);
          return updatedLabList;
        } else {
          // Return the existing list if the lab is already selected
          return prevLabList;
        }
      });
    } else {
      return;
    }
  };

  const onHandleRemarkChanged = (selectedOptions) => {
    const ddEdgeCaseIds = [246, 247, 251];
    if (selectedOptions.length > 0) {
      const hasEdgeCase =
        selectedOptions.find((option) =>
          ddEdgeCaseIds.includes(option.value)
        ) !== undefined;
      setDDEdgeCaseRemark(hasEdgeCase);
      setDocumentsDeficientRemark(selectedOptions);
    } else {
      setDDEdgeCaseRemark(false);
      return;
    }
  };
  const deleteFile = (type, invoiceUrl) => () => {
    const { reimbursementId } = reimbursementDetail;
    setConfirmModal(Object.assign({}, confirmModal, { buttonLoading: true }));
    if (type === 'invoice') {
      return deleteInvoiceUrl({ reimbursementId, invoiceUrl });
    } else {
      const prescriptionUrl = invoiceUrl;
      return deletePrescriptionUrl({ reimbursementId, prescriptionUrl });
    }
  };

  const confirmDeleteFile = (fileName, type, url) => () => {
    const { reimbursementId } = reimbursementDetail;
    setConfirmModal({
      isOpen: true,
      header: `Delete ${fileName}`,
      body: 'Are you sure you want to delete this file?',
      requestId: reimbursementId,
      onSubmit: deleteFile(type, url),

      onClose: () => {
        setConfirmModal({ isOpen: false });
      },
      buttonLoading: false,
    });
  };
  const deleteInvoiceItem = (itemId) => () => {
    setConfirmModal(Object.assign({}, confirmModal, { buttonLoading: true }));
    const { reimbursementId, requestStatus } = reimbursementDetail;
    if (
      requestStatus === 'documents-verified' ||
      requestStatus === 'approved'
    ) {
      return window.alert(
        `You can not delete an already ${requestStatus} claim`
      );
    }
    return deleteInvoiceItemList({ reimbursementId, itemId, requestStatus });
  };
  const requestDeleteInvoiceItem = (e, itemId, index) => {
    e.preventDefault();
    const { reimbursementId } = reimbursementDetail;
    if (!itemId) {
      const updatedInvoiceItems = Array.from(invoiceItems);
      updatedInvoiceItems.splice(index, 1);
      setInvoiceItems(updatedInvoiceItems);
      return;
    }
    setConfirmModal({
      isOpen: true,
      header: `Delete ${reimbursementId}`,
      body: 'Are you sure you want to delete this invoice item?',
      onSubmit: deleteInvoiceItem(itemId),
      requestId: reimbursementId,
      onClose: () => {
        setConfirmModal({ isOpen: false });
      },
      buttonLoading: false,
    });
  };

  //
  const onInvoiceItemChanged = (idx, key) => (e) => {
    if (idx === -1) {
      if (key === 'invoiceName') {
        const value = e.target.value;
        const formattedValue = value
          .split(' ')
          .reduce(
            (acc, ele, idx) =>
              `${acc}${idx === 0 ? '' : ' '}${ele
                .substring(0, 1)
                .toUpperCase()}${ele.substring(1)}`,
            ''
          );

        setInvoiceName(formattedValue);

        checkMerchantNameBlacklisted(encodeURIComponent(formattedValue));
        return;
      }
      if (key === 'invoiceTeleNumber') {
        setInvoiceTeleNumber(e.target.value);
        return;
      }
      setInvoiceNumber(e.target.value);
      return;
    }
    const updatedInvoiceItems = Array.from(invoiceItems);
    if (key === 'itemPrice') {
      // Update both item price and approvedAmount
      updatedInvoiceItems[idx] = Object.assign(updatedInvoiceItems[idx], {
        itemPrice: e.target.value,
        approvedAmount: e.target.value,
        doctorApprovedAmount: e.target.value,
      });
    } else {
      if (key === 'remarks') {
        updatedInvoiceItems[idx] = Object.assign(updatedInvoiceItems[idx], {
          [key]: e.label,
        });
      } else {
        if (key === 'approvedAmount') {
          updatedInvoiceItems[idx] = Object.assign(updatedInvoiceItems[idx], {
            doctorApprovedAmount: e.target.value,
          });
        }
        if (key === 'selectRx') {
          updatedInvoiceItems[idx] = Object.assign(updatedInvoiceItems[idx], {
            codiId: e.codiId,
            selectedRx: { value: e.value, label: e.label },
          });
        }
        if (key === 'itemCount') {
          const value = e.target.value;
          const regex = /^\d*$/;

          // if (regex.test(value)) {
          updatedInvoiceItems[idx] = Object.assign(updatedInvoiceItems[idx], {
            medItemCountValue: value,
          });
          //}
        }
        updatedInvoiceItems[idx] = Object.assign(updatedInvoiceItems[idx], {
          [key]: e?.target?.value,
        });
      }
    }
    setInvoiceItems(updatedInvoiceItems);
    updateFinalStatus();
  };
  const onMerchantNameChange = (option) => {
    if (option?.value === 'other') {
      setOtherMerchantNameModal(true);
      return;
    }
    const merchantName =
      searchType === 'vaccination' ? option?.title : option?.name || '';
    setInvoiceName(merchantName);
    checkMerchantNameBlacklisted(encodeURIComponent(merchantName));
    setMerchantValue({ value: merchantName, label: merchantName });
    if (searchType !== 'vaccination') {
      if (option?.nonEmpanelCenter) {
        setNonEmpanelLabPartnerId(option?.id);
        setEmpanelLabPartnerId(null);
      } else {
        setEmpanelLabPartnerId(option?.id);
        setNonEmpanelLabPartnerId(null);
      }
    } else {
      if (option?.isNonEmpanelCenter) {
        setNonEmpanelVaccinationCenterId(option?.centerId);
        setEmpanelVaccinationCenterId(null);
      } else {
        setEmpanelVaccinationCenterId(option?.centerId);
        setNonEmpanelVaccinationCenterId(null);
      }
    }
  };

  const onMerchantNameChangeNonEmpanel = (e) => {
    setInvoiceName(e.target.value);
    checkMerchantNameBlacklisted(encodeURIComponent(e.target.value));
    setMerchantValue({ value: e.target.value, label: e.target.value });
  };
  const otherModalDisabled = () => {
    setOtherMerchantNameModal(false);
  };
  const OnSetNonEmpanelCenterId = (value) => {
    if (searchType === 'vaccination') {
      setNonEmpanelVaccinationCenterId(value);
      setEmpanelVaccinationCenterId(null);
    } else {
      setNonEmpanelLabPartnerId(value);
      setEmpanelLabPartnerId(null);
    }
  };
  const onChangeCodiIdForItem = (option, idx, ele) => {
    if (option && ele) {
      const updatedEle = {
        ...ele,
        codiId: option.value,
        selectedRx: { value: option.value, label: option.label },
      };
      //setInvoiceItems(updatedEle);

      console.log('Updated ele:', updatedEle);
    }
  };
  //deprecated
  // const renderInvoiceItems = (ele, idx, isHeader) => {
  //   const isDisabled = false; // Determine if the row should be disabled

  //   return (
  //     <div className={isDisabled ? 'row-disabled' : ''}>
  //       <div key={idx} className="flex flex-row justify-space-between mb-4">
  //         {/* Prescription Name */}
  //         <div style={{ opacity: isDisabled ? 0.5 : 1, flex: '1 1 100px' }}>
  //           <Label
  //             className="font-bold mb-1 heading-wrap"
  //             style={{ fontSize: '14px' }}
  //           >
  //             {isHeader ? 'Prescription' : ''}
  //             <h3>{isHeader ? 'Name' : ''}</h3>
  //           </Label>

  //           <Select
  //             name={`selectRx-${idx}`}
  //             id={`selectRx-${idx}`}
  //             style={{ border: '2px solid #714FFF', borderRadius: '6px' }}
  //             theme={(theme) => ({
  //               ...theme,
  //               width: '100px',
  //               colors: {
  //                 ...theme.colors,
  //                 primary50: '#d5d5d5',
  //                 primary25: '#f5f5f5',
  //                 primary: '#D5C9FF',
  //               },
  //             })}
  //             options={multipleCodiOption}
  //             value={
  //               ele?.selectedRx || {
  //                 label: invoiceItems[idx].selectedRx || '',
  //               }
  //             }
  //             onChange={onInvoiceItemChanged(idx, 'selectRx')}
  //             isDisabled={isDisabled} // Disable Select component if row is disabled
  //           />
  //         </div>

  //         {/* Select Item Checkbox */}
  //         <div
  //           style={{
  //             //opacity: isDisabled ? 0.5 : 1,
  //             flex: '1 1 100px',
  //             display: 'flex',
  //             flexDirection: 'column',
  //             alignItems: 'center',
  //           }}
  //         >
  //           <Label
  //             className="font-bold mb-4 heading-wrap"
  //             style={{ fontSize: '14px' }}
  //           >
  //             {isHeader ? 'Select ' : ''}
  //             <h3>{isHeader ? 'Item' : ''}</h3>
  //           </Label>
  //           <input
  //             type="checkbox"
  //             className="checkmark"
  //             style={{ height: '13px', width: '18px' }}
  //             checked={!!enabledRows[idx]}
  //             onChange={() => handleCheckboxChange(idx)}
  //             disabled={isDisabled}
  //           />
  //         </div>

  //         {/* Item Name Input */}
  //         <div style={{ opacity: isDisabled ? 0.5 : 1, flex: '1 1 200px' }}>
  //           <Label
  //             className="font-bold mb-3 heading-wrap"
  //             for={`itemName-${idx}`}
  //             style={{ fontSize: '14px' }}
  //           >
  //             {isHeader ? 'Item ' : ''}
  //             <h3>{isHeader ? 'Name' : ''}</h3>
  //           </Label>
  //           <InputItem
  //             style={{
  //               color: '#3A3A4A',
  //               fontWeight: 'bold',
  //               //marginTop: '10px',
  //             }}
  //             type="text"
  //             placeholder=" &nbsp; Please enter details"
  //             name={`itemName-${idx}`}
  //             id={`itemName-${idx}`}
  //             value={invoiceItems[idx].itemName}
  //             onChange={onInvoiceItemChanged(idx, 'itemName')}
  //             disabled={isDisabled} // Disable InputItem if row is disabled
  //           />
  //         </div>

  //         <div style={{ opacity: isDisabled ? 0.5 : 1, flex: '1 1 100px' }}>
  //           <Label
  //             className="font-bold mt-1" // Added margin-top to create space above the label
  //             for={`itemPrice-${idx}`}
  //             style={{
  //               fontSize: '14px',
  //               textAlign: 'center',
  //               wordSpacing: '50px',
  //             }} // Center the text or adjust as needed
  //           >
  //             {isHeader ? 'Item' : ''}
  //             <h3>{isHeader ? 'Amount' : ''}</h3>
  //           </Label>
  //           <InputAmount
  //             className=""
  //             style={{
  //               color: '#3A3A4A',
  //               fontWeight: 'bold',
  //               marginTop: '0px',
  //             }}
  //             type="text"
  //             name={`itemPrice-${idx}`}
  //             id={`itemPrice-${idx}`}
  //             value={invoiceItems[idx].itemPrice}
  //             onChange={onInvoiceItemChanged(idx, 'itemPrice')}
  //             disabled={isDisabled || !enabledRows[idx]} // Disable InputAmount if row is disabled
  //           />
  //         </div>

  //         {/* Approved Amount Input */}
  //         <div style={{ opacity: isDisabled ? 0.5 : 1, flex: '1 1 150px' }}>
  //           <Label
  //             className="font-bold"
  //             for={`itemApprovedAmount-${idx}`}
  //             style={{ fontSize: '14px' }}
  //           >
  //             {isHeader ? 'Approved ' : ''}
  //             <h3>{isHeader ? 'Amount' : ''}</h3>
  //           </Label>
  //           <InputAmount
  //             style={{
  //               color: '#3A3A4A',
  //               fontWeight: 'bold',
  //             }}
  //             type="text"
  //             name={`itemApprovedAmount-${idx}`}
  //             id={`itemApprovedAmount-${idx}`}
  //             value={invoiceItems[idx].doctorApprovedAmount}
  //             onChange={onInvoiceItemChanged(idx, 'approvedAmount')}
  //             disabled={isDisabled || !enabledRows[idx]} // Disable InputAmount if row is disabled
  //           />
  //         </div>
  //         {/* Remarks Select */}
  //         <div style={{ opacity: isDisabled ? 0.5 : 1, flex: '1 1 150px' }}>
  //           <Label
  //             className="mb-3 font-bold"
  //             for={`itemRemarks-${idx}`}
  //             style={{ fontSize: '14px' }}
  //           >
  //             {isHeader ? 'Select ' : ''}
  //             <h3>{isHeader ? 'Remark' : ''}</h3>
  //           </Label>
  //           <div
  //             style={{
  //               width: '150px',
  //               border: '2px solid #714FFF',
  //               borderRadius: '6px',
  //               // marginTop: '10px',
  //             }}
  //           >
  //             <Select
  //               name={`itemRemarks-${idx}`}
  //               id={`itemRemarks-${idx}`}
  //               theme={(theme) => ({
  //                 ...theme,
  //                 width: '150px',
  //                 colors: {
  //                   ...theme.colors,
  //                   primary50: '#d5d5d5',
  //                   primary25: '#f5f5f5',
  //                   primary: '#D5C9FF',
  //                 },
  //               })}
  //               options={itemRemarks}
  //               value={{ label: invoiceItems[idx].remarks || '' }}
  //               multi
  //               placeholder="Select Remark"
  //               onChange={onInvoiceItemChanged(idx, 'remarks')}
  //               isDisabled={isDisabled} // Disable Select component if row is disabled
  //             />
  //           </div>
  //         </div>

  //         {brokerId && brokerId
  //           ? ''
  //           : !ele.isBlock && (
  //               <div className="mt-4 ml-2 flex justify-end">
  //                 <button
  //                   color="danger"
  //                   outline
  //                   onClick={(e) =>
  //                     requestDeleteInvoiceItem(e, ele.itemId, idx)
  //                   }
  //                   style={{ marginTop: '12px' }}
  //                   disabled={isDisabled} // Disable button if row is disabled
  //                 >
  //                   <DeleteLogoContainer src={DeleteIcon} alt="delete logo" />
  //                 </button>
  //               </div>
  //             )}
  //       </div>
  //     </div>
  //   );
  // };

  const renderInvoiceItems = (ele, idx, isHeader) => {
    const isDisabled = false; // Determine if the row should be disabled

    return (
      <div className={isDisabled ? 'row-disabled' : ''}>
        <div
          key={idx}
          className="flex flex-row justify-between items-center mb-3 "
        >
          {/* Prescription Name */}
          <div
            style={{
              opacity: isDisabled ? 0.5 : 1,
              flex: '1 1 100px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Label className="font-bold " style={{ fontSize: '14px' }}>
              {isHeader ? 'Select ' : ''}
              <h3>{isHeader ? 'Prescription' : ''}</h3>
            </Label>
            <div
            //style={{ zIndex: 1 }}
            >
              <Select
                className="custom-dropdown"
                name={`selectRx-${idx}`}
                id={`selectRx-${idx}`}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary50: '#d5d5d5',
                    primary25: '#f5f5f5',
                    primary: '#D5C9FF',
                  },
                })}
                options={multipleCodiOption}
                value={
                  ele?.selectedRx || {
                    label: invoiceItems[idx].selectedRx || 'Invoice',
                  }
                }
                onChange={onInvoiceItemChanged(idx, 'selectRx')}
                isDisabled={isDisabled}
              />
            </div>
          </div>

          {/* Select Item Checkbox */}
          <div
            style={{
              //opacity: isDisabled ? 0.5 : 1,
              flex: '1 1 90px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Label
              className="font-bold mb-2 heading-wrap"
              style={{ fontSize: '14px' }}
            >
              {isHeader ? 'Select ' : ''}
              <h3>{isHeader ? 'Item' : ''}</h3>
            </Label>
            <div className="flex custom-checkbox">
              <input
                type="checkbox"
                className="checkmark"
                style={{ height: '13px', width: '18px' }}
                checked={!!enabledRows[idx] || ele?.isChecked}
                onChange={() => handleCheckboxChange(idx, ele)}
                disabled={ele?.isChecked}
              />
            </div>
          </div>

          {/* Item Name Input */}
          <div
            style={{
              opacity: isDisabled ? 0.5 : 1,
              flex: '1 1 200px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Label
              className="font-bold mb-1"
              htmlFor={`itemName-${idx}`}
              style={{ fontSize: '14px' }}
            >
              {isHeader ? 'Item' : ''}
              <h3>{isHeader ? 'Name' : ''}</h3>
            </Label>
            <InputItem
              className="mt-1"
              style={{ color: '#3A3A4A', fontWeight: 'bold' }}
              type="text"
              placeholder=" &nbsp; Please enter details"
              name={`itemName-${idx}`}
              id={`itemName-${idx}`}
              value={invoiceItems[idx].itemName}
              onChange={onInvoiceItemChanged(idx, 'itemName')}
              disabled={isDisabled}
            />
          </div>

          {/* Item Amount Input */}
          <div
            style={{
              opacity: isDisabled ? 0.5 : 1,
              flex: '1 1 100px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Label
              className="font-bold mb-1"
              htmlFor={`itemPrice-${idx}`}
              style={{ fontSize: '14px', textAlign: 'center' }}
            >
              {isHeader ? 'Item ' : ''}
              <h3>{isHeader ? 'Amount' : ''}</h3>
            </Label>
            <InputAmount
              style={{ color: '#3A3A4A', fontWeight: 'bold' }}
              type="text"
              name={`itemPrice-${idx}`}
              id={`itemPrice-${idx}`}
              value={invoiceItems[idx].itemPrice}
              onChange={onInvoiceItemChanged(idx, 'itemPrice')}
              disabled={!(enabledRows[idx] || ele?.isChecked)}
            />
          </div>
          {ele?.type === 'medicine' ? (
            <div
              style={{
                opacity: isDisabled ? 0.5 : 1,
                flex: '1 1 100px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Label
                className="font-bold mb-1"
                htmlFor={`itemCount-${idx}`}
                style={{ fontSize: '14px', textAlign: 'center' }}
              >
                {isHeader ? 'Item ' : ''}
                <h3>{isHeader ? 'Count' : ''}</h3>
              </Label>
              <InputAmount
                style={{ color: '#3A3A4A', fontWeight: 'bold' }}
                type="text"
                name={`itemCount-${idx}`}
                id={`itemCount-${idx}`}
                value={invoiceItems[idx].medItemCountValue}
                onChange={onInvoiceItemChanged(idx, 'itemCount')}
                disabled={!(enabledRows[idx] || ele?.isChecked)}
              />
            </div>
          ) : (
            <></>
          )}

          {/* Approved Amount Input */}
          <div
            style={{
              opacity: isDisabled ? 0.5 : 1,
              flex: '1 1 100px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Label
              className="font-bold mb-1"
              htmlFor={`itemApprovedAmount-${idx}`}
              style={{ fontSize: '14px' }}
            >
              {isHeader ? 'Approved' : ''}
              <h3>{isHeader ? 'Amount' : ''}</h3>
            </Label>
            <InputAmount
              style={{ color: '#3A3A4A', fontWeight: 'bold' }}
              type="text"
              name={`itemApprovedAmount-${idx}`}
              id={`itemApprovedAmount-${idx}`}
              value={invoiceItems[idx].doctorApprovedAmount}
              onChange={onInvoiceItemChanged(idx, 'approvedAmount')}
              disabled={!enabledRows[idx] && !ele?.isChecked}
            />
          </div>

          {/* Remarks Select */}
          <div
            style={{
              opacity: isDisabled ? 0.5 : 1,
              flex: '1 1 150px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Label
              className="font-bold "
              htmlFor={`itemRemarks-${idx}`}
              style={{ fontSize: '14px' }}
            >
              {isHeader ? 'Select ' : ''}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '200px',
                }}
              >
                <h3>{isHeader ? 'Remark' : ''}</h3>
                {ele?.sourceType === 'REIMBURSEMENTS' ? (
                  <label
                    style={{
                      marginLeft: 'auto',
                      border: '1px solid #EAE7FF',
                      borderRadius: '3px',
                      backgroundColor: '#EAE7FF',
                      padding: '2px 6px',
                      color: '#714FFF',
                      fontSize: '11px',
                    }}
                  >
                    {ele?.sourceType === 'REIMBURSEMENTS' ? 'Reimbursed' : ''}
                  </label>
                ) : (
                  ''
                )}
              </div>
            </Label>

            <Select
              className="custom-dropdown-remarks"
              name={`itemRemarks-${idx}`}
              id={`itemRemarks-${idx}`}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary50: '#d5d5d5',
                  primary25: '#f5f5f5',
                  primary: '#D5C9FF',
                },
              })}
              options={itemRemarks}
              value={{ label: invoiceItems[idx].remarks || '' }}
              multi
              placeholder="Select Remark"
              onChange={onInvoiceItemChanged(idx, 'remarks')}
              isDisabled={isDisabled} // Disable Select component if row is disabled
            />
          </div>

          {/* Delete Button */}
          {brokerId && brokerId ? null : (
            <div className=" ml-2 flex justify-end">
              <button
                style={{
                  marginTop: isHeader ? '30px' : '5px',
                  backgroundColor: ele.isBlock
                    ? '#F7F7F7'
                    : isDisabled || ele.isBlock
                    ? '#D5D5D5'
                    : '#F5F5F5',
                  opacity: isDisabled || ele.isBlock ? 0.5 : 1,
                }}
                color="danger"
                outline
                onClick={(e) => requestDeleteInvoiceItem(e, ele.itemId, idx)}
                disabled={isDisabled || ele.isBlock} // Add ele.isBlock to the disabled condition
              >
                <DeleteLogoContainer src={DeleteIcon} alt="delete logo" />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const addInvoiceItem = () => {
    const updatedInvoiceItems = Array.from(invoiceItems);
    updatedInvoiceItems.push({
      itemName: '',
      itemPrice: '',
      approvedAmount: '',
      doctorApprovedAmount: '',
      remarks: '',
      codiId: '',
      selectedRx: '',
    });

    setInvoiceItems(updatedInvoiceItems);

    // setInvoiceItems((prevItems) => {
    //   console.log({ prevItems, updatedInvoiceItems }, 'testing');
    //   return [...prevItems, ...updatedInvoiceItems];
    // });
  };
  const addPrescription = () => {
    const updatedPrescriptions = Array.from(prescriptions);
    updatedPrescriptions.push({
      files: [],
      status: 'pending',
    });
    setPrescriptions(updatedPrescriptions);
    //setPrescriptionModalIsOpen(true);
  };

  const onPrescriptionSelectedChanged = (idx) => (items, action) => {
    console.log(items, action);
    const isFileAlreadySelected = (file) => {
      console.log(selectedPrescriptionFiles, file);
      return (
        selectedPrescriptionFiles.findIndex(
          (ele) => file.value === ele.value
        ) !== -1
      );
    };
    switch (action.action) {
      case 'select-option':
      case 'set-value': {
        if (isFileAlreadySelected(action.option)) {
          window.alert(
            'You cannot select a file already added to another prescription'
          );
          return;
        }
        const updatedPrescriptions = Array.from(prescriptions);
        const updatedFileList = Array.from(updatedPrescriptions[idx].files);
        updatedFileList.push(action.option);
        updatedPrescriptions[idx] = Object.assign(
          {},
          updatedPrescriptions[idx],
          {
            files: updatedFileList,
          }
        );
        setPrescriptions(updatedPrescriptions);
        setSelectedPrescriptionFiles(
          updatedPrescriptions.reduce((acc, ele) => acc.concat(ele.files), [])
        );
        break;
      }
      case 'remove-value':
      case 'pop-value': {
        const updatedPrescriptions = Array.from(prescriptions);
        const fileList = Array.from(updatedPrescriptions[idx].files);
        const findIndex = fileList.findIndex(
          (ele) => ele.value === action.removedValue.value
        );
        if (findIndex !== -1) {
          fileList.splice(findIndex, 1);
          updatedPrescriptions[idx] = Object.assign(
            {},
            updatedPrescriptions[idx],
            {
              files: fileList,
            }
          );

          setPrescriptions(updatedPrescriptions);
          setSelectedPrescriptionFiles(
            updatedPrescriptions.reduce((acc, ele) => acc.concat(ele.files), [])
          );
        }
        break;
      }
      case 'clear': {
        const updatedPrescriptions = Array.from(prescriptions);
        updatedPrescriptions[idx] = Object.assign(
          {},
          updatedPrescriptions[idx],
          {
            files: [],
          }
        );

        setPrescriptions(updatedPrescriptions);
        setSelectedPrescriptionFiles(
          updatedPrescriptions.reduce((acc, ele) => acc.concat(ele.files), [])
        );
        break;
      }
      default:
        break;
    }
  };

  const OnChangeDeficiencyComment = (e) => {
    deficiencyComment = e.target.value;
  };
  const renderPrescription = (ele, idx) => (
    <div className="col-12 m-2 mt-4 bg-white rounded" key={idx}>
      <div className="row">
        <div
          className="col-4"
          style={{ fontWeight: '600' }}
        >{` Invoice & Prescription ${idx + 1} (Status: ${ele.status})`}</div>
        <div className="col-8">
          <Select
            isMulti
            value={ele.files}
            options={prescriptionAndInvoiceArr()}
            onChange={onPrescriptionSelectedChanged(idx)}
          />
        </div>
      </div>
    </div>
  );

  const selectReimbursementForDependent = (dependentId, policyId, e) => {
    const check = e.target.checked;
    setCheckIsDependentOrHolderSelected(check);
    setReimbursementForDependent(true);
    setNewPolicy(false);
    setReimbursementFor(dependentId);
    setSelectedPolicyId(policyId);
  };

  const fetchHolderValue = (value) => {
    if (value) {
      setCheckIsDependentOrHolderSelected(value);
      setNewPolicy(false);
    }
  };
  const submitForDigitization = () => {
    const { reimbursementId } = reimbursementDetail;
    const formattedBody = prescriptions.map((ele) => {
      const array = ele.files.reduce((acc, file) => {
        acc.push(file.value);
        return acc;
      }, []);
      return array;
    });
    setDigitizing(true);
    submitPrescriptionsForDigitization({
      reimbursementId,
      prescriptions: formattedBody,
    });
  };
  const prettyPrintPayoutStatus = (claimStatus) => {
    switch (claimStatus) {
      case 'pending':
        return (
          <h4>
            <span
              className="badge badge-warning"
              style={{
                color: 'white',
                fontFamily: 'Montserrat',
                fontSize: '14px',
              }}
            >
              Pending
            </span>
          </h4>
        );
      case 'approved':
        return (
          <h4 className="text-white">
            <span
              className="badge badge-success"
              style={{
                color: 'white',
                fontFamily: 'Montserrat',
                fontSize: '14px',
              }}
            >
              Approved
            </span>
          </h4>
        );
      case 'settled':
        return (
          <h4>
            <span
              className="badge badge-success"
              style={{
                color: 'white',
                fontFamily: 'Montserrat',
                fontSize: '14px',
              }}
            >
              Settled
            </span>
          </h4>
        );
      case 'rejected':
      case 'failure':
      case 'reversed':
        return (
          <h4>
            <span
              className="badge badge-danger"
              style={{
                color: 'white',
                fontFamily: 'Montserrat',
                fontSize: '14px',
              }}
            >
              {claimStatus}
            </span>
          </h4>
        );
      case 'reopened':
        return (
          <h4>
            <span className="badge badge-warning">Reopened</span>
          </h4>
        );
      case 'suspicious':
        return (
          <h4>
            <span
              className="badge badge-danger"
              style={{
                color: 'white',
                fontFamily: 'Montserrat',
                fontSize: '14px',
              }}
            >
              Suspicious Claim
            </span>
          </h4>
        );
      default:
        return (
          <h4>
            <span
              className="badge badge-secondary"
              style={{
                color: 'white',
                fontFamily: 'Montserrat',
                fontSize: '14px',
              }}
            >
              Pending
            </span>
          </h4>
        );
    }
  };
  const handleSelectReimbursementType = async (e) => {
    const { reimbursementId, sponsorId } = reimbursementDetail;
    const procedureId = e.value;

    setReimbursementTypeName(e.label);

    const checkIsExist = validValues.some((validValue) =>
      e?.metaData?.procedureName.includes(validValue)
    );

    const checkForVaccine = e?.metaData?.procedureName.includes('vaccine');

    if (checkForVaccine) {
      setSearchType('vaccination');
    }
    if (checkIsExist) {
      setShowMerchantSelectOption(true);
    }
    if (!checkIsExist && e.metaData) {
      setShowMerchantSelectOption(false);
    }
    const dateOfInvoice = reimbursementDetail.invoiceDate
      ? moment(reimbursementDetail.invoiceDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )
      : '';
    return Promise.all([
      getPolicyDependents(
        reimbursementId,
        procedureId,
        sponsorId,
        dateOfInvoice
      ),
      getWalletBalanceByReimbursementType(
        reimbursementId,
        procedureId,
        dateOfInvoice,
        selectedPolicyId || policyId
      ),
    ])
      .then(([dependentsResponse, walletResponse]) => {
        setPolicyMemberDetails(dependentsResponse);
        setNewPolicy(dependentsResponse.newPolicy);
        setWalletBalanceAmount(walletResponse.result);
        setCiWalletAmount(walletResponse.ciWalletBalance);
        setProcedureId(procedureId);
        setIsWalletLoading(false);
      })
      .catch((err) => {
        console.log('err', { err });
        setWalletBalanceAmount(null);
      });
  };
  const toggleIsCriticalIllness = () => {
    setIsCriticalIllness((current) => !current);
  };
  const maternityOnChange = () => {
    setIsMaternity((current) => !current);
  };
  useEffect(() => {
    if (claimData && claimData.reimbursementDetails) {
      if (walletApiCall) {
        handleSelectReimbursementType({
          value: reimbursementDetail.procedureId,
          label: reimbursementDetail.reimbursementType,
        });
      }
    }
  }, [claimData]);

  useEffect(() => {
    if (reimbursementDetail) {
      const { reimbursementId, procedureId } = reimbursementDetail;
      const dateOfInvoice = reimbursementDetail.invoiceDate
        ? moment(reimbursementDetail.invoiceDate, 'DD/MM/YYYY').format(
            'YYYY-MM-DD'
          )
        : '';
      getWalletBalanceByReimbursementType(
        reimbursementId,
        procedureId,
        dateOfInvoice,
        selectedPolicyId
      )
        .then((walletResponse) => {
          setWalletBalanceAmount(walletResponse.result);
          setCiWalletAmount(walletResponse.ciWalletBalance);
        })
        .catch(() => {
          setWalletBalanceAmount(null);
        });
    }
  }, [selectedPolicyId]);

  useEffect(() => {
    if (firstEffectToggel) {
      updateFinalStatus();
    }
  }, [firstEffectToggel]);

  const onValidationCheckChange = (index) => (event) => {
    setFirstEffectToggel(true);
    let updatedRejectionsReasons = rejectionReasons;
    updatedRejectionsReasons[index] = event.target.checked;
    // if (updatedRejectionsReasons && updatedRejectionsReasons.benefitNotCovered) {
    //     setPolicyModal(true);
    // }
    setRejectionReasons(updatedRejectionsReasons);
    setBlacklistedWarning(false);
  };
  const onPolicyRemarkSelected = (e) => {
    setPolicyRemark(e.label);
    //setPolicyModal(false);
  };
  const updateFinalStatus = () => {
    const {
      prescriptionInvalid,
      invoiceDateDifferent,
      detailsMismatch,
      invalidStamp,
      invalidDoctorName,
      invalidPatientName,
      blurredPicture,
      genuinePrescription,
      signatureMissing,
      inappropriatePicture,
      invalidReimbursement,
      patientNotCovered,
      invoiceBeforePolicyDate,
      invoiceInAnotherClaim,
      itemsNotPrescribed,
      documentsMissing,
      invoiceNotItemized,
      documentUploadedNotAsPerNorm,
      outOfPolicy,
      sumInsuredExhausted,
      policyNotActive,
      registrationCheckFailed,
      invoiceBeforePrescriptionDate,
      benefitNotCovered,
      AlreadyApprovedMedOrLabs,
      PrescriptionValidityCrossed,
      blackListedHospitalOrDiagnostic,
      SubLimitBenefitExhausted,
      InvalidBankDetails,
      CashlessBenefitAvailed,
      fiftyPercentageWalletAllowed,
    } = rejectionReasons;
    if (
      prescriptionInvalid ||
      invoiceDateDifferent ||
      detailsMismatch ||
      invalidStamp ||
      invalidDoctorName ||
      invalidPatientName ||
      blurredPicture ||
      genuinePrescription ||
      signatureMissing ||
      inappropriatePicture ||
      invalidReimbursement ||
      patientNotCovered ||
      invoiceBeforePolicyDate ||
      invoiceInAnotherClaim ||
      documentsMissing ||
      documentUploadedNotAsPerNorm ||
      itemsNotPrescribed ||
      invoiceNotItemized ||
      outOfPolicy ||
      sumInsuredExhausted ||
      policyNotActive ||
      registrationCheckFailed ||
      invoiceBeforePrescriptionDate ||
      benefitNotCovered ||
      AlreadyApprovedMedOrLabs ||
      PrescriptionValidityCrossed ||
      blackListedHospitalOrDiagnostic ||
      SubLimitBenefitExhausted ||
      InvalidBankDetails ||
      CashlessBenefitAvailed ||
      fiftyPercentageWalletAllowed
    ) {
      setFinalApprovalStatus('rejected');
      return;
    }
    if (policyDetail && policyDetail.policyStartDate && invoiceDate) {
      const policyStartDate = moment(
        policyDetail.policyStartDate,
        'DD/MM/YYYY'
      );

      if (moment(invoiceDate).isBefore(policyStartDate) && !isJnJClaim) {
        setFinalApprovalStatus('rejected');
        setRejectionReasons({
          invoiceBeforePolicyDate: true,
        });
        return;
      }
    }
    if (invoiceItems.length === 0) {
      setFinalApprovalStatus('pending');
      return;
    }
    // Compute the total approved amount
    // const totalApprovedAmount = invoiceItems.reduce(
    //   (acc, ele) => acc + Number.parseFloat(ele.approvedAmount),
    //   0
    // );
    const totalApprovedAmount = invoiceItems
      .filter(
        (item) =>
          item.hasOwnProperty('approvedAmount') &&
          !isNaN(parseFloat(item.approvedAmount))
      )
      .reduce((acc, ele) => acc + parseFloat(ele.approvedAmount), 0);
    if (reimbursementDetail.reimbursementAmount) {
      if (reimbursementDetail.reimbursementAmount === totalApprovedAmount) {
        setFinalApprovalStatus('approved');
      } else {
        setFinalApprovalStatus('partially-approved');
      }
    } else {
      // const totalInvoiceAmount = invoiceItems.reduce(
      //   (acc, ele) => acc + Number.parseFloat(ele.itemPrice),
      //   0
      // );
      const totalInvoiceAmount = invoiceItems
        .filter(
          (item) =>
            item.hasOwnProperty('itemPrice') &&
            !isNaN(parseFloat(item.itemPrice))
        )
        .reduce((acc, ele) => acc + parseFloat(ele.itemPrice), 0);
      console.log(totalInvoiceAmount, totalApprovedAmount);
      if (totalInvoiceAmount === totalApprovedAmount) {
        setFinalApprovalStatus('approved');
      } else {
        setFinalApprovalStatus('partially-approved');
      }
    }
  };
  const saveApprovedAmount = () => {
    // const { invoiceItems } = this.state;

    const finalAmount = document.querySelector('#finalApprovedPrice').value;
    const totalAmount = invoiceItems
      .filter(
        (item) =>
          item.hasOwnProperty('approvedAmount') &&
          !isNaN(parseFloat(item.approvedAmount))
      )
      .reduce((acc, ele) => acc + parseFloat(ele.approvedAmount), 0);
    // const totalAmount = invoiceItems.reduce(
    //   (acc, ele) => acc + Number.parseFloat(ele.approvedAmount),
    //   0
    // );
    const roundOffInvoiceItem = invoiceItems.findIndex((ele) => ele.roundOff);
    const discountInvoiceItem = invoiceItems.findIndex((ele) => ele.discount);
    // Check if the final amount is above the total computed amount
    const updatedInvoiceItems = Array.from(invoiceItems);
    if (finalAmount > totalAmount) {
      // It's a round up.
      // Check if another round off item already exists, and if so
      // modify that
      // Also check if a discount item exists, remove that and add the
      // discounted price to the round off
      if (roundOffInvoiceItem !== -1) {
        const item = updatedInvoiceItems[roundOffInvoiceItem];
        // Increase the price of the round off item by the difference
        item.itemPrice += finalAmount - totalAmount;
        item.approvedAmount += finalAmount - totalAmount;
      } else if (discountInvoiceItem !== -1) {
        const discountedPrice =
          updatedInvoiceItems[discountInvoiceItem].itemPrice;
        const roundOffItem = {
          itemName: 'Round Off',
          itemPrice: finalAmount - totalAmount + discountedPrice,
          approvedAmount: finalAmount - totalAmount + discountedPrice,
          roundOff: true,
          remarks: '',
        };
        if (roundOffItem.itemPrice === 0) {
          // Just remove the discount item
          updatedInvoiceItems.splice(discountInvoiceItem, 1);
        } else {
          updatedInvoiceItems.splice(discountInvoiceItem, 1, roundOffItem);
        }
      } else {
        updatedInvoiceItems.push({
          itemName: 'Round Off',
          itemPrice: finalAmount - totalAmount,
          approvedAmount: finalAmount - totalAmount,
          roundOff: true,
          remarks: '',
        });
      }
    } else {
      // It's a discount
      // Check if another discount item already exists, and if so modify that
      // Also check if a round off item exists, remove that and subtract the
      // round off price to the discount
      if (discountInvoiceItem !== -1) {
        const item = updatedInvoiceItems[discountInvoiceItem];
        item.itemPrice -= totalAmount - finalAmount;
        item.approvedAmount -= totalAmount - finalAmount;
      } else if (roundOffInvoiceItem !== -1) {
        const roundOffItemPrice =
          updatedInvoiceItems[roundOffInvoiceItem].itemPrice;
        const discountItem = {
          itemName: 'Discount',
          itemPrice: finalAmount - totalAmount + roundOffItemPrice,
          approvedAmount: finalAmount - totalAmount + roundOffItemPrice,
          discount: true,
          remarks: '',
          doctorApprovedAmount: finalAmount - totalAmount,
        };
        if (discountItem.itemPrice === 0) {
          // Just remove the round off item
          updatedInvoiceItems.splice(roundOffInvoiceItem, 1);
        } else {
          updatedInvoiceItems.splice(roundOffInvoiceItem, 1, discountItem);
        }
      } else {
        updatedInvoiceItems.push({
          itemName: 'Discount',
          itemPrice: finalAmount - totalAmount,
          approvedAmount: finalAmount - totalAmount,
          discount: true,
          remarks: '',
          doctorApprovedAmount: finalAmount - totalAmount,
        });
      }
    }
    console.log(updatedInvoiceItems);
    setInvoiceItems(updatedInvoiceItems);
    // Update enabled rows state
    setEnabledRows((prev) => {
      const newData = { ...prev };
      const lastIndex = updatedInvoiceItems.length - 1;
      // updatedInvoiceItems.forEach((info, index) => {
      //   if (!info?.type) {
      //     newData[index] = true; // Set enabled state for all items
      //   }
      // });
      // return newData;
      Object.keys(newData).forEach((key) => {
        //newData[key] = false; // Optionally reset all to false
      });

      newData[lastIndex] = true; // Enable only the last added item

      return newData; // Return updated state
    });

    setEditingFinalApprovedPrice(false);
    setIsOpen(true);
  };

  // const totalAmount = invoiceItems.reduce(
  //   (acc, ele) => acc + Number.parseFloat(ele.approvedAmount),
  //   0
  // );

  const totalAmount = invoiceItems
    .filter(
      (item) =>
        item.hasOwnProperty('approvedAmount') &&
        !isNaN(parseFloat(item.approvedAmount))
    )
    .reduce((acc, ele) => acc + parseFloat(ele.approvedAmount), 0);
  // const renderDependent = (ele, idx) => (
  //     <tr key={idx}>
  //         <td>{ele.name}</td>
  //         <td>{ele.dob}</td>
  //         <td>{ele.policyStartDate}</td>
  //         <td>{ele.policyEndDate}</td>
  //         <td>
  //             <div className="flex custom-checkbox">
  //                 <input
  //                     style={{ height: '13px', width: '18px' }}
  //                     className="checkmark"
  //                     type="checkbox"
  //                     checked={
  //                         reimbursementForDependent && reimbursementFor === ele.dependentId
  //                     }
  //                     onChange={selectReimbursementForDependent(ele.dependentId)}
  //                 />
  //             </div>
  //         </td>
  //     </tr>
  // );
  const isDependentChecked = (p, ele) => {
    const finalValue =
      reimbursementForDependent &&
      reimbursementFor === ele.dependentId &&
      (policyMemberDetails.newPolicy === false ||
        selectedPolicyId === p.policyId);
    if (finalValue) {
      setCheckIsDependentOrHolderSelected(true);
      setNewPolicy(false);
    }
    return finalValue;
  };

  const renderDependent = (ele, idx) => {
    return ele.policies.map((p, idx) => {
      return (
        <tr key={idx}>
          {idx === 0 ? (
            <>
              <td rowSpan={ele.policies.length}>{ele.name}</td>
              <td rowSpan={ele.policies.length}>{ele.dob}</td>
            </>
          ) : (
            ''
          )}
          <td>{p.policyName}</td>
          <td>{p.policyStartDate}</td>
          <td>{p.policyEndDate}</td>
          {policyMemberDetails?.holder?.showDentalPastClaimed && (
            <td>{p.remainingAmount === null ? 'N/A' : p.remainingAmount}</td>
          )}
          <td>
            <div className="flex custom-checkbox">
              <input
                type="checkbox"
                className="checkmark"
                style={{ height: '13px', width: '18px' }}
                checked={isDependentChecked(p, ele)}
                onChange={(e) =>
                  selectReimbursementForDependent(
                    ele.dependentId,
                    p.policyId,
                    e
                  )
                }
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderApprovalStatus = (finalApprovalStatus) => {
    switch (finalApprovalStatus.toLowerCase()) {
      case 'pending':
        return (
          <ApprovedText className="h4" color="#ffc53d">
            PENDING
          </ApprovedText>
        );
      case 'qc-check':
        return (
          <ApprovedText className="h4" color="">
            QC-Check
          </ApprovedText>
        );
      case 'documents-verified':
        return (
          <ApprovedText className="h4" color="#fa8c16">
            DOCUMENTS VERIFIED
          </ApprovedText>
        );
      case 'partially-approved':
        return (
          <ApprovedText className="h4" color="#95de64">
            PARTIALLY APPROVED
          </ApprovedText>
        );
      case 'approved':
        return (
          <ApprovedText className="h4" color="#237804">
            APPROVED
          </ApprovedText>
        );
      case 'rejected':
        return (
          <ApprovedText className="h4" color="#cf1322">
            REJECTED
          </ApprovedText>
        );
      default:
        return (
          <ApprovedText className="h4" color="#ffc53d">
            PENDING
          </ApprovedText>
        );
    }
  };
  const updateClaim = () => {
    if (isOnBreak) {
      toast.warning('Mark off break to process the claim', {
        theme: 'dark',
        position: 'top-center',
      });

      return;
    }
    const { reimbursementId, relative, sponsorId, userId } =
      reimbursementDetail;
    const {
      prescriptionInvalid,
      invoiceDateDifferent,
      detailsMismatch,
      invalidStamp,
      invalidDoctorName,
      invalidPatientName,
      blurredPicture,
      genuinePrescription,
      signatureMissing,
      inappropriatePicture,
      invalidReimbursement,
      patientNotCovered,
      invoiceBeforePolicyDate,
      invoiceInAnotherClaim,
      itemsNotPrescribed,
      documentsMissing,
      documentUploadedNotAsPerNorm,
      invoiceNotItemized,
      outOfPolicy,
      sumInsuredExhausted,
      policyNotActive,
      registrationCheckFailed,
      invoiceBeforePrescriptionDate,
      benefitNotCovered,
      AlreadyApprovedMedOrLabs,
      PrescriptionValidityCrossed,
      blackListedHospitalOrDiagnostic,
      SubLimitBenefitExhausted,
      InvalidBankDetails,
      CashlessBenefitAvailed,
      fiftyPercentageWalletAllowed,
    } = rejectionReasons;
    if (
      prescriptionInvalid ||
      invoiceDateDifferent ||
      detailsMismatch ||
      invalidStamp ||
      invalidDoctorName ||
      invalidPatientName ||
      blurredPicture ||
      genuinePrescription ||
      signatureMissing ||
      inappropriatePicture ||
      invalidReimbursement ||
      patientNotCovered ||
      invoiceBeforePolicyDate ||
      invoiceInAnotherClaim ||
      documentsMissing ||
      itemsNotPrescribed ||
      invoiceNotItemized ||
      outOfPolicy ||
      documentUploadedNotAsPerNorm ||
      sumInsuredExhausted ||
      policyNotActive ||
      registrationCheckFailed ||
      invoiceBeforePrescriptionDate ||
      benefitNotCovered ||
      AlreadyApprovedMedOrLabs ||
      PrescriptionValidityCrossed ||
      blackListedHospitalOrDiagnostic ||
      SubLimitBenefitExhausted ||
      InvalidBankDetails ||
      CashlessBenefitAvailed ||
      fiftyPercentageWalletAllowed
    ) {
      setUpdating(true);

      updateClaimReq({
        reimbursementId,
        dependentId: reimbursementForDependent ? reimbursementFor : null,
        prescriptionInvalid,
        invoiceDateDifferent,
        detailsMismatch,
        invalidStamp,
        invalidDoctorName,
        invalidPatientName,
        blurredPicture,
        genuinePrescription,
        signatureMissing,
        inappropriatePicture,
        invalidReimbursement,
        patientNotCovered,
        invoiceBeforePolicyDate,
        invoiceInAnotherClaim,
        itemsNotPrescribed,
        documentsMissing,
        documentUploadedNotAsPerNorm,
        invoiceNotItemized,
        registrationCheckFailed,
        invoiceBeforePrescriptionDate,
        outOfPolicy,
        sumInsuredExhausted,
        benefitNotCovered,
        AlreadyApprovedMedOrLabs,
        PrescriptionValidityCrossed,
        blackListedHospitalOrDiagnostic,
        blackListedHospitalOrDiagnosticId,
        SubLimitBenefitExhausted,
        fiftyPercentageWalletAllowed,
        isDiagnostic,
        doctorVerifyByGoogleSearch,
        policyNotActive,
        isCriticalIllness,
        isMaternity,
        oracleCiScan,
        oracleDentalCheck,
        subRemark: policyRemark,
        newPolicyId: selectedPolicyId,
        invoiceInAnotherClaimId: invoiceAlreadyClaimId,
        invoiceTeleNumber,
        InvalidBankDetails,
        CashlessBenefitAvailed,
        consultationId,
        userId,
        procedureId: procedureId ? procedureId : null,
        invoiceDate: moment(invoiceDate).format('YYYY-MM-DD'),
        amountClaimedForProcedure,
      });
      return;
    }
    // const requestedAmount = invoiceItems.reduce(
    //   (acc, ele) => acc + Number.parseFloat(ele.itemPrice),
    //   0
    // );

    // const seenLabIds = new Set();
    // const seenDrugIds = new Set();

    // const filteredItems = invoiceItems.filter((item) => {
    //   const hasRequiredProperties =
    //     item.hasOwnProperty('itemPrice') &&
    //     item.hasOwnProperty('approvedAmount') &&
    //     item.itemPrice !== '' &&
    //     parseFloat(item.itemPrice) !== 0;

    //   const hasLabId =
    //     item.hasOwnProperty('labId') &&
    //     item.labId !== null &&
    //     item.labId !== '';
    //   const hasDrugId =
    //     item.hasOwnProperty('drugId') &&
    //     item.drugId !== null &&
    //     item.drugId !== '';

    //   // Check for duplicates only if labId or drugId exist
    //   const isUniqueLabId = !hasLabId || !seenLabIds.has(item.labId);
    //   const isUniqueDrugId = !hasDrugId || !seenDrugIds.has(item.drugId);

    //   // If the item is valid and unique, or it lacks labId/drugId, keep it
    //   if (
    //     hasRequiredProperties &&
    //     ((isUniqueLabId && isUniqueDrugId) || (!hasLabId && !hasDrugId))
    //   ) {
    //     if (hasLabId) {
    //       seenLabIds.add(item.labId);
    //     }
    //     if (hasDrugId) {
    //       seenDrugIds.add(item.drugId);
    //     }
    //     return true; // Keep this item
    //   }

    //   return false; // Filter out this item
    // });

    const getMedItemCount = (value) => {
      const count = parseInt(value);
      return !isNaN(count) ? count : null; // Return null if count is NaN
    };

    function filterAndUpdateInvoiceItems(invoiceItems) {
      const seenLabIds = new Set();
      const seenDrugIds = new Set();

      // First, filter the items
      const filteredItems = invoiceItems.filter((item) => {
        // Check for required properties
        const hasRequiredProperties =
          item.hasOwnProperty('itemPrice') &&
          item.hasOwnProperty('approvedAmount') &&
          item.itemPrice !== '' &&
          parseFloat(item.itemPrice) !== 0;

        const hasLabId =
          item.hasOwnProperty('labId') &&
          item.labId !== null &&
          item.labId !== '';

        const hasDrugId =
          item.hasOwnProperty('drugId') &&
          item.drugId !== null &&
          item.drugId !== '';

        // Check for uniqueness
        const isUniqueLabId = !hasLabId || !seenLabIds.has(item.labId);
        const isUniqueDrugId = !hasDrugId || !seenDrugIds.has(item.drugId);

        // If the item is valid and unique, or it lacks labId/drugId, keep it
        if (
          hasRequiredProperties &&
          ((isUniqueLabId && isUniqueDrugId) || (!hasLabId && !hasDrugId))
        ) {
          // Update seen IDs
          if (hasLabId) {
            seenLabIds.add(item.labId);
          }
          if (hasDrugId) {
            seenDrugIds.add(item.drugId);
          }

          return true; // Keep this item
        }

        return false; // Filter out this item
      });

      // Then, map over the filtered items to update medItemCountValue
      const updatedItems = filteredItems.map((item) => ({
        ...item,
        medItemCountValue: getMedItemCount(item.medItemCountValue),
      }));

      return updatedItems; // Return the updated items
    }

    const filteredItems = filterAndUpdateInvoiceItems(invoiceItems);

    const requestedAmount = filteredItems
      .filter(
        (item) =>
          item.hasOwnProperty('itemPrice') && !isNaN(parseFloat(item.itemPrice))
      )
      .reduce((acc, ele) => acc + parseFloat(ele.itemPrice), 0);

    // const approvedAmount = invoiceItems.reduce(
    //   (acc, ele) => acc + Number.parseFloat(ele.approvedAmount),
    //   0
    // );
    const approvedAmount = filteredItems
      .filter(
        (item) =>
          item.hasOwnProperty('approvedAmount') &&
          !isNaN(parseFloat(item.approvedAmount))
      )
      .reduce((acc, ele) => acc + parseFloat(ele.approvedAmount), 0);

    setUpdating(true);
    const procedureTypeName = reimbursementDetail.CategoryName;

    // if (!isDigitized && !digitizationDetails) {
    //   toast.warning('Please complete the digitisation first', {
    //     theme: 'dark',
    //     position: 'top-center',
    //   });
    //   setUpdating(false);
    //   return;
    // }

    // const filteredItems = invoiceItems.filter((item) => {
    //   return (
    //     item.hasOwnProperty('itemPrice') &&
    //     item.hasOwnProperty('approvedAmount') &&
    //     //item.codiId &&
    //     item.itemPrice !== '' &&
    //     parseFloat(item.itemPrice) !== 0
    //   );
    // });

    updateClaimReq({
      reimbursementId,
      dependentId: reimbursementForDependent ? reimbursementFor : null,
      invoiceId: invoiceId ? invoiceId : null,
      reimbursementFor: null,
      invoiceNumber,
      doctorVerifyByGoogleSearch,
      invoiceName,
      invoiceDate: moment(invoiceDate).format('DD/MM/YYYY'),
      invoiceItems: filteredItems,
      requestedAmount,
      approvedAmount,
      isCriticalIllness,
      isMaternity,
      procedureId: procedureId ? procedureId : null,
      oracleCiScan,
      oracleDentalCheck,
      reimburseRequestStatus: reimbursementDetail.requestStatus
        ? reimbursementDetail.requestStatus
        : null,
      newPolicyId: selectedPolicyId,
      sponsorId,
      invoiceTeleNumber,
      userId,
      blackListedHospitalOrDiagnosticId,
      isDiagnostic,
      amountClaimedForProcedure,
      nonEmpanelLabPartnerId,
      empanelLabPartnerId,
      nonEmpanelVaccinationCenterId,
      empanelVaccinationCenterId,
    });
  };
  const closeAmountModal = () => {
    setIsOpen(false);
  };
  const closePrescriptionAddModal = () => {
    setPrescriptionModalIsOpen(false);
  };
  let verifyAgain = () => {
    setDoctorRegModal(true);
    setDoctorTableModal(false);
    setDoctorNameSuggestions([]);
  };

  const searchRejectionReasons = (e) => {
    const searchValue = e.target.value;
    setRejectionReasonList(
      allRejectionList.filter((value) => {
        return (
          value.label
            .toString()
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1 ||
          value.value
            .toString()
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1
        );
      })
    );
  };
  // useEffect(() => {
  //     if (invoiceDate) {
  //         updateFinalStatus();
  //     }
  // }, [invoiceDate]);
  const selectedDate = (date, dateString) => {
    setPastClaimStdate(dateString[0]);
    setPastClaimEdDate(dateString[1]);
  };
  useEffect(() => {
    if (pastClaimStdate && pastClaimEdDate) {
      refetchPastClaimReq();
    }
  }, [pastClaimStdate]);

  const openDoctorVerifyModal = () => {
    setDoctorRegModal(true);
    setDoctorNameSuggestions([]);
  };
  const closeDoctorModal = () => {
    setDoctorRegModal(false);
  };
  const closeDoctorTableModal = () => {
    setDoctorTableModal(false);
  };
  const OnChangeDoctorName = (e) => {
    setDoctorName(e);
  };
  const verifyDoctorReg = async () => {
    if (doctorRegId) {
      const body = {
        registrationNo: doctorRegId,
        doctorName,
      };
      await verifyDoctorRegistration(body)
        .then((result) => {
          if (result.length > 0) {
            setRegistrationDoctorArr(result);
            setDoctorRegModal(false);
            setDoctorTableModal(true);
          } else {
            window.alert('Doctor is not found');
          }
        })
        .catch(() => {});
    } else {
      window.alert('RegistrationId and Doctor name are required');
    }
  };
  const OnRegistrationNumber = (e) => {
    setDoctorRegId(e.target.value);
  };

  const onDoctorNameSearch = async (searchText) => {
    await getDoctorNameSuggestion(searchText)
      .then((res) => {
        const arr = [
          { value: searchText },
          ...res.result.map((e) => {
            return { value: e.name };
          }),
        ];
        setDoctorNameSuggestions(arr);
        return null;
      })
      .catch(() => {});
  };
  const searchBlacklistHospital = async (e) => {
    const searchString = e.target.value;
    setBlackListHospitalValue(searchString);
    await getBlacklistHospital(searchString, isDiagnostic)
      .then((res) => {
        setBlacklistedHospital(res);
      })
      .catch(() => {});
  };
  const handleItemClick = (result) => {
    console.log('Clicked on:', result);
    const value = `${result.name}-${result.address}`;
    setBlackListHospitalValue(value);
    setBlackListedHospitalOrDiagnosticId(result.id);
    setBlacklistedHospital([]);
  };
  const checkMerchantNameBlacklisted = async (value) => {
    const careVendor = [11, 19, 8];
    const vendorId = policyDetail && policyDetail.vendorId;
    if (vendorId && careVendor.includes(vendorId)) {
      if (value.length === 0) {
        setBlacklistedWarning(false);
      }
      if (value.length > 0) {
        await checkMerchantNameIsBlacklisted(value)
          .then((res) => {
            if (res.length > 0) {
              setBlacklistedWarning(true);
            } else {
              setBlacklistedWarning(false);
            }
          })
          .catch((err) => {
            throw err;
          });
      }
    }
  };
  const updateDoctorRegistrationInfo = () => {
    const { registrationNo, firstName, doctorId } = selectedDoctor;
    const body = {
      reimbursementId: reimbursementDetail.reimbursementId,
      doctorRegVerifyKey: 1,
      registrationNo,
      firstName,
      doctorId,
      enteredDoctorName: doctorName,
      enteredRegistrationId: doctorRegId,
    };
    updateDoctorRegistration(body);
  };
  const shiftDoc = (link) => () => {
    documentArr.unshift({ uri: link });
    setDocs(documentArr);
  };
  useEffect(() => {}, [docs]);

  const addClarificationReason = (reimbursementId) => () => {
    setConfirmModal(Object.assign({}, confirmModal, { buttonLoading: true }));
    const remark = document.querySelector('#clarificationReason').value;
    console.log('sss', clarificationstatus);
    if (clarificationstatus === 'Other') {
      if (!remark) {
        toast.error('Please update a clarification valid remark this claim', {
          icon: '❌',
          theme: toastTheme,
        });
        setConfirmModal({ isOpen: false });
        return;
      }
    }
    const body = {
      reimbursementId,
      remark,
      clarificationstatus,
    };
    return clarificationReason(body);
  };
  const selectReimbursementForHolder = (holderId, policyId, e) => {
    const check = e.target.checked;
    setReimbursementForDependent(false);
    setCheckIsDependentOrHolderSelected(check);
    setNewPolicy(false);
    setReimbursementFor(holderId);
    setSelectedPolicyId(policyId);
  };
  useEffect(() => {});
  const clarificationOnChange = (e) => {
    clarificationstatus = e.value;
  };
  const openPastClaimModal = () => {
    setPastClaimModal(true);
  };
  const openCopyClaimModal = () => {
    setCopyClaimOpen(true);
  };

  const OnMedicineAdd = () => {
    setCartMedList((prevList) => [
      ...prevList,
      {
        medicineName: selectedMedicineName,
        timeOfDay,
        dosageValue,
        duration,
        durationPeriod,
        drugId,
        selectedCodiId: selectedCodiId,
        selectedRx: multipleCodiOptionValue,
        masterGroupId,
      },
    ]);
    setSelectedMedicineNameValue({ value: '', label: '' });
    setDosageView(false);
    setSelectedMedicineName('');
    setManualAddMedsCheck(false);
    // setDrugId('');
    // setTimeOfDay('');
    setDosageValue('');
    setDurationPeriod('');
  };

  const clarificationNeeded = () => {
    setConfirmModal({
      isOpen: true,
      header: `Clarification Needed ${reimbursementDetail.reimbursementId}`,
      body: (
        <div className="container">
          <div className="col-12">
            <Select
              className="ml-3"
              placeholder="Select Clarification Status"
              options={clarificationOptions}
              onChange={clarificationOnChange}
            ></Select>
            <FormGroup check className="mt-2">
              <Label for="clarificationReason">
                Please update what clarification is needed
              </Label>
              <Input
                type="text"
                name="clarificationReason"
                id="clarificationReason"
                placeholder="Enter Clarification"
              />
            </FormGroup>
          </div>
        </div>
      ),
      onSubmit: addClarificationReason(reimbursementDetail.reimbursementId),
      onClose: () => {
        setConfirmModal({ isOpen: false });
      },
    });
  };

  const DocumentCounter = (data, tabName, colorCode) => {
    return (
      <Wrapper>
        <Text>
          {tabName}
          <Badge
            className="badge badge-success"
            style={{ backgroundColor: colorCode }}
          >
            {(data && data.length) || 0}
          </Badge>
        </Text>
      </Wrapper>
    );
  };
  const markClaimSuspicious = () => {
    setConfirmModal({
      isOpen: true,
      header: `Mark Claim Suspicious ${reimbursementDetail.reimbursementId}`,
      body: (
        <div className="container">
          <div className="col-12">
            <FormGroup check>
              <Label for="suspiciousReason">
                Specify a reason to mark this claim suspicious
              </Label>
              <input
                type="text"
                name="suspiciousReason"
                id="suspiciousReason"
                class="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-3 dark:placeholder-gray-400"
                placeholder="Enter Reason"
                required
              />
            </FormGroup>
          </div>
        </div>
      ),
      onSubmit: () => markClaimSuspiciousBody(),
      onClose: () => {
        setConfirmModal({ isOpen: false });
      },
    });
  };
  const addClarificationResolution = (reimbursementId) => () => {
    setConfirmModal(Object.assign({}, confirmModal, { buttonLoading: true }));
    const remark = document.querySelector('#clarificationResolution').value;
    if (!remark) {
      toast.error('Please update a clarification valid remark this claim', {
        icon: '❌',
        theme: toastTheme,
      });
      setConfirmModal({ isOpen: false });
      return;
    }
    const body = {
      reimbursementId,
      solution: remark,
    };
    return clarificationSolution(body);
  };
  const clarificationResolution = () => {
    setConfirmModal({
      isOpen: true,
      header: `Resolve Clarification ${reimbursementDetail.reimbursementId}`,
      body: (
        <div className="container">
          <div className="col-12">
            <h5>
              <strong>Remark by doctor: </strong>
              <span>{reimbursementDetail.clarificationRemarks}</span>
            </h5>
          </div>
          <br />
          <div className="col-12">
            <FormGroup check>
              <Label for="clarificationResolution">
                Please update clarification for above remark
              </Label>
              <Input
                type="text"
                name="clarificationResolution"
                id="clarificationResolution"
                placeholder="Please update clarification"
              />
            </FormGroup>
          </div>
        </div>
      ),
      onSubmit: addClarificationResolution(reimbursementDetail.reimbursementId),
      onClose: () => setConfirmModal({ isOpen: false }),
    });
  };
  const OnInputAlreadyInvoiceClaimId = (e) => {
    setDisabledSubmit(false);
    const value = e.target.value.replace(/\D/g, '');
    setInvoiceAlreadyClaimId(value);
  };
  const onSubmitAlreadyInvoiceClaimId = () => {
    setDisabledSubmit(true);
  };
  const blackListToggle = (e) => {
    setBlacklistedHospital([]);
    setIsDiagnostic(e);
  };
  const onFetchCodiId = () => {
    setWalletApiCall(false);
    fetchCodiIds(reimbursementId);
    getClaim();
  };

  const markRejected = (consultationId) => {
    setConsultationId(consultationId);
    setRejectionReasons((prevState) => ({
      ...prevState,
      CashlessBenefitAvailed: true,
    }));
  };

  useEffect(() => {
    if (rejectionReasons.CashlessBenefitAvailed) {
      updateClaim(); // Call updateClaim only after state update
    }
  }, [rejectionReasons]);

  const AddMedsItems = () => {
    // Create a new array by transforming `cartMedList`
    if (cartMedList.length > 0) {
      const transformedArray = cartMedList.map((d) => ({
        itemName: d.medicineName,
        type: 'medicine',
        drugId: d.drugId,
        dosage: d.dosageValue,
        duration: d.duration,
        durationType: d.durationPeriod,
        meals: d.timeOfDay.split(' ')[0],
        selectedRx: d.selectedRx,
        codiId: d.selectedCodiId,
        masterGroupId: d.masterGroupId,
        itemPrice: '0.00',
        approvedAmount: '0.00',
        doctorApprovedAmount: '0.00',
        medItemCountValue: 'N.A',
      }));
      setInvoiceItems((prevItems) => {
        console.log({ prevItems, transformedArray }, 'testing');
        setEnabledRows((prev) => {
          console.log(prev, 'check prev hrer');
          const newData = Object.assign({}, prev || {});
          for (
            let count = prevItems.length;
            count < prevItems.length + transformedArray.length;
            count++
          ) {
            newData[count] = true;
          }
          return newData;
        });
        return [...prevItems, ...transformedArray];
      });
      setCartMedList([]);
      setSelectedLabList([]);
      setSelectedMedicineName('');
      setSelectedMedicineNameValue({ value: '', label: '' });
      setIsMedicineModal(false);
      setDuration('');
      setDurationPeriod('');
    } else {
      setIsMedicineModal(false);
      setDuration('');
      setDurationPeriod('');
    }
  };

  const closeMedicineModal = () => {
    setIsMedicineModal(false);
    setDuration('');
    setDurationPeriod('');
  };

  const addLabTest = () => {
    // Create a new array by transforming `selectedLabsList`
    if (selectedLabsList.length > 0) {
      const transformedArray = selectedLabsList.map((d) => ({
        itemName: d.label,
        type: 'labs',
        labId: d.id,
        codiId: d.selectedCodiId,
        selectedPrescription: d.selectedPrescription,
        selectedRx: d.selectedRx,
        itemPrice: '0.00',
        approvedAmount: '0.00',
        doctorApprovedAmount: '0.00',
        // checked: true,
      }));

      // Merge the new items with the existing items in `invoiceItems`
      setInvoiceItems((prevItems) => {
        console.log({ prevItems, transformedArray }, 'testing');
        setEnabledRows((prev) => {
          console.log(prev, 'check prev hrer');
          const newData = Object.assign({}, prev || {});
          for (
            let count = prevItems.length;
            count < prevItems.length + transformedArray.length;
            count++
          ) {
            newData[count] = true;
          }
          return newData;
        });
        return [...prevItems, ...transformedArray];
      });
      setSelectedLabList([]);
      setCartMedList([]);
      setIsLabModal(false);
      //handleCheckboxChange();
    } else {
      setIsLabModal(false);
    }
  };

  useEffect(() => {
    console.log({ invoiceItems }, 'testing');
  }, [invoiceItems]);
  const disabledUpdateClaim = () => {
    console.log('updateDisabled', {
      checkNewPolicy,
      checkIsDependentOrHolderSelected,
      updating,
      blacklistedWarning,
    });
    if (updating) {
      return true;
    }
    // if (consultationId) {
    //   return true;
    // }
    if (blackListedHospitalOrDiagnosticId) {
      return false;
    }

    if (!checkIsDependentOrHolderSelected) {
      if (finalApprovalStatus === 'rejected') {
        return false;
      }
      return true;
    }
    if (checkNewPolicy) {
      if (reimbursementDetail?.dopePolicyId) {
        return false;
      }
      return true;
    }
    if (blacklistedWarning) {
      return true;
    }
    if (rejectionReasons.invoiceInAnotherClaim) {
      if (invoiceAlreadyClaimId) {
        return;
      }
      return true;
    }
    if (rejectionReasons.benefitNotCovered) {
      if (policyRemark) {
        return;
      }
      return true;
    }
    if (finalApprovalStatus === 'rejected') {
      return;
    }
    if (invoiceDate) {
      if (
        reimbursementDetail.sponsorId === 731 &&
        policyDetail &&
        policyDetail.vendorId === 8
      ) {
        if (oracleCiScan && oracleDentalCheck) {
          return;
        }
        return true;
      }
      return;
    }
    return true;
  };

  const handleButtonClick = (codiId, labelValue) => {
    console.log('CodiId:', codiId, labelValue);
    setSelectedCodiId(codiId);
    setMultipleCodiOptionValue({ value: codiId, label: labelValue });
    // Add functionality to view the prescription here
  };

  const handleEyeClick = (e, url) => {
    e.stopPropagation();
    window.open(url);
  };

  const rejectionReason = (reimbursementDetail) => {
    let reason = reimbursementDetail.reimbursementReason;
    if (reimbursementDetail.invoiceAlreadyClaimedIn) {
      reason =
        reimbursementDetail.reimbursementReason +
        ' - ' +
        reimbursementDetail.invoiceAlreadyClaimedIn;
    }
    return reason;
  };

  const consultationIdFetch = (value) => {
    setConsultationId(value);
  };

  const checkQcStatus = () => {
    if (OpsUserDetail.showQcTab) {
      return setQcRemarkModal(true);
    }
    return toast.warning(`You Can't Update Qc Claim`, {
      theme: 'dark',
      position: 'top-center',
    });
  };

  const addManualModal = () => {
    setManualAddMedsCheck(true);
    setSelectedMedicineName('');
    setDrugId('');
    setMasterGroupId('');
    setTimeOfDay('');
    setDosageValue('');
    setDurationPeriod('');
    setSelectedMedicineNameValue({ value: '', label: '' });
    setDosageView(false);
    //setManualAddMedsCheck(false);
  };

  const medicineModalClearState = () => {
    setSelectedMedicineName('');
    setDrugId('');
    setMasterGroupId('');
    setTimeOfDay('');
    setDosageValue('');
    setDurationPeriod('');
    setSelectedMedicineNameValue({ value: '', label: '' });
    setManualAddMedsCheck(false);
    setDosageView(false);
  };

  const medAddManual = (e) => {
    const originalString = e.target.value;
    const stringWithoutSpaces = originalString.replace(/^\s+/, '');
    setSelectedMedicineName(stringWithoutSpaces);
  };

  return (
    <>
      {claimData ? (
        <div className="container-fluid w-full px-0 h-full flex flex-col primary-bg m-0 p-0">
          <div className="">
            {/* <ReimbursementNavbar /> */}
            {reimbursementDetail.deletionDate && (
              <div className="col-12 m-2">
                <div className="alert alert-danger" role="alert">
                  Please note: This claim request has been deleted.
                </div>
              </div>
            )}
            {reimbursementDetail.claimStatus === 'reopened' && (
              <div className="col-12">
                <div className="alert alert-primary" role="alert">
                  Please note: This claim request has been reopened. Any amount
                  changes you make will be sent for approval.
                </div>
              </div>
            )}
          </div>
          <div
            className="container-fluid p-0 flex  m-1 "
            style={{ height: '100%' }}
          >
            <div
              className=" flex flex-col h-full border-black-800 mr-2"
              style={{ width: '65%' }}
            >
              <div className="flex m-1">
                <div className="basis-1/2">
                  <MainHeading isSuspicious={reimbursementDetail.isSuspicious}>
                    Reimbursement Claims for {reimbursementDetail.userName} (
                    {reimbursementDetail.reimbursementId})
                  </MainHeading>
                </div>
                <div className="mr-2">
                  <button
                    style={{ fontFamily: 'Montserrat', fontStyle: 'normal' }}
                    className="text-[#714FFF] font-semibold bg-white
                            py-2 px-4 border-2 border-[#714FFF] hover:bg-[#EBE7FF]
                             rounded flex items-center w-[200px]"
                    onClick={previousClaim}
                    //disabled={true}
                  >
                    <LeftArrow />
                    Previous Claim
                  </button>
                </div>
                <div className="ml-2">
                  <button
                    style={{ fontFamily: 'Montserrat', fontStyle: 'normal' }}
                    className="text-[#714FFF] font-semibold bg-white
                            py-2 px-4 border-2 border-[#714FFF] hover:bg-[#EBE7FF]
                         rounded flex items-center w-[160px]"
                    onClick={nextclaim}
                    //disabled={true}
                  >
                    Next Claim
                    <RightArrow />
                  </button>
                </div>
              </div>
              <div className="overflow-y-auto">
                <div className="flex p-0 ">
                  <div className="basis-1/2">
                    <ReimbursementUser
                      reimbursementDetail={reimbursementDetail}
                      policyDetail={policyDetail}
                      bankDetail={bankDetail}
                      openPastClaimModal={openPastClaimModal}
                      pastClaimsArr={pastClaimsArr && pastClaimsArr}
                      openCopyClaimModal={openCopyClaimModal}
                      copyClaimList={copyClaimList && copyClaimList}
                      prettyPrintPayoutStatus={prettyPrintPayoutStatus}
                      ahcDetails={ahcDetails}
                    ></ReimbursementUser>
                  </div>

                  <div className="basis-1/2 m-1">
                    <UserClaimDetail
                      prettyPrintPayoutStatus={prettyPrintPayoutStatus}
                      reimbursementDetail={reimbursementDetail}
                      availableProcedures={availableProcedures}
                      procedureSelectionRef={procedureSelectionRef}
                      handleSelectReimbursementType={
                        handleSelectReimbursementType
                      }
                      procedureDetail={procedureDetail}
                      policyDetail={policyDetail}
                      bankDetail={bankDetail}
                      walletBalanceAmount={walletBalanceAmount}
                      ciWalletAmount={ciWalletAmount}
                      amountClaimedForProcedure={amountClaimedForProcedure}
                      isWalletLoading={isWalletLoading}
                    ></UserClaimDetail>
                  </div>
                </div>
                {reimbursementDetail.clarificationRemarks ||
                reimbursementDetail.clarificationSolution ||
                reimbursementDetail.clarificationStatus ||
                reimbursementDetail.documentDeficientSubmittedCount ||
                reimbursementDetail.ddComments ||
                reimbursementDetail.reimbursementReason ? (
                  <div className=" bg-white rounded  p-6 m-1">
                    {reimbursementDetail.clarificationStatus ? (
                      <div className="col-12">
                        <h5>
                          <strong style={{ color: '#714FFF' }}>
                            Clarification Status :{' '}
                          </strong>
                          <span style={{ fontWeight: 'bold' }}>
                            {reimbursementDetail.clarificationStatus}
                          </span>
                        </h5>
                      </div>
                    ) : (
                      ''
                    )}
                    {reimbursementDetail.ddComments ? (
                      <div className="col-12">
                        <h5>
                          <strong style={{ color: '#714FFF' }}>
                            DD Comment :{' '}
                          </strong>
                          <span style={{ fontWeight: 'bold' }}>
                            {reimbursementDetail.ddComments}
                          </span>
                        </h5>
                      </div>
                    ) : (
                      ''
                    )}
                    {reimbursementDetail.clarificationRemarks ? (
                      <div className="col-12">
                        <h5>
                          <strong style={{ color: '#714FFF' }}>
                            Clarification Remark :{' '}
                          </strong>
                          <span style={{ fontWeight: 'bold' }}>
                            {reimbursementDetail.clarificationRemarks}
                          </span>
                        </h5>
                      </div>
                    ) : (
                      ''
                    )}
                    {reimbursementDetail.clarificationSolution ? (
                      <div className="col-12">
                        <h5>
                          <strong style={{ color: '#714FFF' }}>
                            Clarification solution :{' '}
                          </strong>
                          <span style={{ fontWeight: 'bold' }}>
                            {reimbursementDetail.clarificationSolution}
                          </span>
                        </h5>
                      </div>
                    ) : (
                      ''
                    )}
                    {reimbursementDetail.qcReason ? (
                      <div className="col-12">
                        <h5>
                          <strong style={{ color: '#714FFF' }}>
                            Qc Reason :{' '}
                          </strong>
                          <span style={{ fontWeight: 'bold' }}>
                            {reimbursementDetail.qcReason}
                          </span>
                        </h5>
                      </div>
                    ) : (
                      ''
                    )}

                    {reimbursementDetail.reimbursementReason ? (
                      <div className="col-12">
                        <h3>
                          <strong style={{ color: '#714FFF' }}>
                            {reimbursementDetail.requestStatus ===
                            'documents-verified'
                              ? 'Items Partially Approved :'
                              : reimbursementDetail.requestStatus === 'rejected'
                              ? 'Rejection Reason : '
                              : 'Reason : '}
                          </strong>
                          <span style={{ fontWeight: 'bold' }}>
                            {rejectionReason(reimbursementDetail)}
                          </span>
                        </h3>
                      </div>
                    ) : (
                      ''
                    )}
                    {reimbursementDetail.documentDeficientSubmittedCount ? (
                      <div className="col-12">
                        <h5>
                          <strong style={{ color: '#714FFF' }}>
                            Deficiency Submission Count :{' '}
                          </strong>
                          <span style={{ fontWeight: 'bold' }}>
                            {
                              reimbursementDetail.documentDeficientSubmittedCount
                            }
                          </span>
                        </h5>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
                <DependentsContainer
                  policyDetail={policyDetail}
                  reimbursementForDependent={reimbursementForDependent}
                  reimbursementFor={reimbursementFor}
                  selectReimbursementForHolder={selectReimbursementForHolder}
                  policyDependents={policyDependents}
                  renderDependent={renderDependent}
                  policyMembers={policyMemberDetails}
                  policyId={selectedPolicyId}
                  fetchHolderValue={fetchHolderValue}
                  isWalletLoading={isWalletLoading}
                ></DependentsContainer>

                {brokerId && brokerId ? (
                  ''
                ) : (
                  <ReimbursementDigitization
                    reimbursementDetail={reimbursementDetail}
                    digitizationDetails={digitizationDetails}
                    updateIsDigitizedState={updateIsDigitizedState}
                    reimbursementTypeName={reimbursementTypeName}
                    consultationIdFetch={consultationIdFetch}
                    consultationId={consultationId}
                    claimData={claimData}
                    onFetchCodiId={onFetchCodiId}
                    markRejected={markRejected}
                    reimbursementForDependent={reimbursementForDependent}
                    reimbursementFor={reimbursementFor}
                  ></ReimbursementDigitization>
                )}

                {brokerId && brokerId ? (
                  ''
                ) : (
                  <>
                    <ReimbursementInvoiceDetail
                      claimData={claimData && claimData}
                      confirmDeleteFile={confirmDeleteFile}
                      invoiceName={invoiceName}
                      onInvoiceItemChanged={onInvoiceItemChanged}
                      invoiceDate={invoiceDate}
                      invoiceDateInputFocused={invoiceDateInputFocused}
                      setInvoiceDateInputFocused={setInvoiceDateInputFocused}
                      OnInvoiceDateChange={OnInvoiceDateChange}
                      invoiceNumber={invoiceNumber}
                      invoiceTeleNumber={invoiceTeleNumber}
                      shiftDoc={shiftDoc}
                      isCriticalIllness={isCriticalIllness}
                      toggleIsCriticalIllness={toggleIsCriticalIllness}
                      maternityOnChange={maternityOnChange}
                      isMaternity={isMaternity}
                      blacklistedWarning={blacklistedWarning}
                      onMerchantNameChange={onMerchantNameChange}
                      showMerchantSelectOption={showMerchantSelectOption}
                      otherMerchantNameModal={otherMerchantNameModal}
                      OnSetNonEmpanelCenterId={OnSetNonEmpanelCenterId}
                      onMerchantNameChangeNonEmpanel={
                        onMerchantNameChangeNonEmpanel
                      }
                      otherModalDisabled={otherModalDisabled}
                      merchantValue={merchantValue}
                      searchType={searchType}
                      changeDocumentType={changeDocumentType}
                    ></ReimbursementInvoiceDetail>
                    {policyDetail && policyDetail.vendorId === 8 ? (
                      <BlackListHospitalVerify
                        searchBlacklistHospital={searchBlacklistHospital}
                        blacklistedHospital={blacklistedHospital}
                        handleItemClick={handleItemClick}
                        blackListHospitalValue={blackListHospitalValue}
                        blackListToggle={blackListToggle}
                        isDiagnostic={isDiagnostic}
                      ></BlackListHospitalVerify>
                    ) : (
                      ''
                    )}
                  </>
                )}

                <AddItemsContainer
                  invoiceItems={invoiceItems}
                  renderInvoiceItems={renderInvoiceItems}
                  addInvoiceItem={addInvoiceItem}
                  reimbursementDetail={reimbursementDetail}
                  oracleDentalCheck={oracleDentalCheck}
                  oracleChecks={oracleChecks}
                  oracleCiScan={oracleCiScan}
                  doctorVerifyByGoogleSearch={doctorVerifyByGoogleSearch}
                  onDoctorVerifyCheck={onDoctorVerifyCheck}
                  policyDetail={policyDetail}
                  finalApprovalStatus={finalApprovalStatus}
                  availableProceduresData={availableProceduresData}
                  openLabModal={openLabModal}
                  openMedicineModal={openMedicineModal}
                  digitizationDetails={digitizationDetails}
                ></AddItemsContainer>

                {brokerId && brokerId ? (
                  ''
                ) : (
                  <RejectionReasons
                    searchRejectionReasons={searchRejectionReasons}
                    rejectionReasonList={rejectionReasonList}
                    onValidationCheckChange={onValidationCheckChange}
                    OnInputAlreadyInvoiceClaimId={OnInputAlreadyInvoiceClaimId}
                    rejectionReasons={rejectionReasons}
                    onSubmitAlreadyInvoiceClaimId={
                      onSubmitAlreadyInvoiceClaimId
                    }
                    disabledSubmit={disabledSubmit}
                    policyRemarks={policyRemarks && policyRemarks}
                    onPolicyRemarkSelected={onPolicyRemarkSelected}
                    reimbursementDetail={reimbursementDetail}
                    refetchPolicyRemarks={refetchPolicyRemarks}
                    policyDetail={policyDetail}
                  ></RejectionReasons>
                )}

                {/* {brokerId && brokerId ? (
                  ''
                ) : (
                  <div
                    className="bg-white rounded m-2 ml-0"
                    style={{ padding: '1rem', paddingBottom: '1px' }}
                  >
                    <div className="m-2">
                      <Title>
                        How many Unique Prescriptions are there in this Claim?
                      </Title>

                      <Button
                        className="mt-4 flex hover:bg-[#714FFF] hover:text-white"
                        onClick={addPrescription}
                        disabled={!reimbursementDetail.prescriptionLink}
                      >
                        <Plus className="mr-2"></Plus>
                        Add Prescription
                      </Button>

                      {prescriptions.map(renderPrescription)}

                      <div className="flex items-end justify-end">
                        <button
                          className=" btn text-white font-semibold bg-[#714FFF] mr-2
                                            border-[#714FFF] 
                                            rounded flex"
                          onClick={submitForDigitization}
                          disabled={
                            selectedPrescriptionFiles.length === 0 || digitizing
                          }
                        >
                          Submit Prescriptions for digitization
                        </button>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>

              <div
                className="bg-white rounded  p-4 m-2 ml-0"
                style={{ fixedBottom: '0px', paddingBottom: '0px' }}
              >
                <div
                  className="flex flex-row"
                  style={{ justifyContent: 'space-between' }}
                >
                  <div className="flex flex-row">
                    <div
                      className=""
                      style={{
                        marginLeft: '0px',
                        borderRight: '3px solid #DADADA',
                      }}
                    >
                      <h1
                        className=""
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 'bold',
                          fontSize: '15px',
                          paddingRight: '20px',
                          color: '#3A3A4A',
                        }}
                      >
                        Total Approved Amount
                      </h1>
                      <div className="">
                        <h1
                          className="flex"
                          style={{
                            fontFamily: 'Montserrat',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: '#3A3A4A',
                            border: '1px solid #714FFF',
                            borderRadius: '4px',
                            width: '100px',
                            padding: '1px',
                          }}
                        >
                          <RupeeIcon />
                          {totalAmount}
                        </h1>
                      </div>
                    </div>

                    <div className="ml-4">
                      <h1
                        className=""
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 'bold',
                          fontSize: '15px',
                          paddingRight: '20px',
                          color: '#3A3A4A',
                        }}
                      >
                        Final Status
                      </h1>
                      <div className="">
                        <h1
                          className="flex"
                          style={{
                            fontFamily: 'Montserrat',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: '#3A3A4A',
                            width: '210px',
                            padding: '1px',
                          }}
                        >
                          {renderApprovalStatus(finalApprovalStatus)}
                        </h1>
                      </div>
                    </div>
                  </div>

                  {brokerId && brokerId ? (
                    ''
                  ) : (
                    <div className="flex flex-row">
                      <div className=" mr-3">
                        <div className="flex">
                          {editingFinalApprovedPrice ? (
                            <EditAmountModal
                              modalIsOpen={modalIsOpen}
                              closeAmountModal={closeAmountModal}
                              saveApprovedAmount={saveApprovedAmount}
                            ></EditAmountModal>
                          ) : (
                            <>
                              <div className="flex ">
                                <Button
                                  className=" hover:bg-[#714FFF] hover:text-white flex"
                                  onClick={editTotalApprovedAmount}
                                >
                                  <EditIcon />
                                  Edit
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="">
                        <button
                          className=" btn text-white font-semibold bg-[#714FFF]
                                                py-2 px-2 border-2 border-[#714FFF] hover:bg-[#714FFF]
                                                 rounded flex items-center"
                          style={{ backgroundColor: '#714FFF' }}
                          onClick={openDoctorVerifyModal}
                        >
                          Verify Doctor
                        </button>
                      </div>
                      <div className="ml-4">
                        <button
                          className=" btn text-white font-semibold bg-[#714FFF]
                                                py-2 px-2 border-2 border-[#714FFF] hover:bg-[#714FFF]
                                                 rounded flex items-center disabled:opacity-75"
                          onClick={() => {
                            if (
                              reimbursementDetail.requestStatus === 'qc-check'
                            ) {
                              checkQcStatus();
                            } else {
                              updateClaim();
                            }
                          }}
                          disabled={disabledUpdateClaim()}
                        >
                          Update Claim
                          <RightIcon />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className=" overflow-y-auto pb-3 mr-2 mt-3"
              style={{
                width: '35%',
                backgroundColor: 'white',
                borderRadius: '5px',
              }}
            >
              <Tabs>
                <TabList>
                  <Tab>
                    <p className="text-[#714FFF] font-bold">
                      Prescription Details
                    </p>
                  </Tab>
                  <Tab>
                    {DocumentCounter(
                      docDeficientQueryData,
                      'Documents Deficient',
                      '#ff754c'
                    )}
                  </Tab>
                  <Tab>{DocumentCounter(comments, 'Comments', '#14A44D')}</Tab>
                  <Tab>
                    <p className="font-bold" style={{ color: '#E18767' }}>
                      {' '}
                      Policy Structure{' '}
                    </p>
                  </Tab>
                  <Tab>
                    <p className="font-bold" style={{ color: '#C6B411' }}>
                      {' '}
                      FAQ{' '}
                    </p>
                  </Tab>
                </TabList>
                <TabPanel>
                  <ActionsContainer
                    docs={docs}
                    callUser={callUser}
                    requestCopyClaim={requestCopyClaim}
                    requestDeleteClaim={requestDeleteClaim}
                    onInvoiceFileAdded={onInvoiceFileAdded}
                    addInvoiceFile={addInvoiceFile}
                    onPrescriptionFileAdded={onPrescriptionFileAdded}
                    addPrescriptionFile={addPrescriptionFile}
                    onMerFileAdded={onMerFileAdded}
                    addMerFile={addMerFile}
                    requestDocumentsDeficient={requestDocumentsDeficient}
                    reimbursementPrePostRequestsData={
                      reimbursementPrePostRequestsData
                    }
                    isCriticalIllness={isCriticalIllness}
                    setPrePostModal={setPrePostModal}
                    toggleIsCriticalIllness={toggleIsCriticalIllness}
                    addPrescription={addPrescription}
                    reimbursementDetail={reimbursementDetail}
                    prescriptionModalIsOpen={prescriptionModalIsOpen}
                    closePrescriptionAddModal={closePrescriptionAddModal}
                    prescriptions={prescriptions}
                    renderPrescription={renderPrescription}
                    submitForDigitization={submitForDigitization}
                    selectedPrescriptionFiles={selectedPrescriptionFiles}
                    digitizing={digitizing}
                    clarificationNeeded={clarificationNeeded}
                    clarificationResolution={clarificationResolution}
                    brokerId={brokerId}
                    markClaimSuspicious={markClaimSuspicious}
                  ></ActionsContainer>
                </TabPanel>
                <TabPanel>
                  <DocumentDeficient
                    reimbursementDetail={reimbursementDetail}
                    docDeficientQueryData={docDeficientQueryData}
                  ></DocumentDeficient>
                </TabPanel>
                <TabPanel>
                  <div className="">
                    <CommentList comments={comments} />
                    <div className="">
                      <CommentForm
                        addComment={addComment}
                        reimbursementId={reimbursementDetail.reimbursementId}
                        opsUserDetail={OpsUserDetail}
                        commentType={appConfig.REIMBURSEMENT.reimbursement}
                        brokerId={brokerId}
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      minHeight: '80vh',
                    }}
                  >
                    {policyFile && policyFile[0] ? (
                      <iframe
                        title="FAQ"
                        src={policyFile[0].uri}
                        style={{
                          height: '100%',
                          width: '100%',
                          minHeight: '80vh',
                        }}
                      />
                    ) : (
                      <div className="alert text-center alert-info">
                        No results found
                      </div>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      minHeight: '80vh',
                    }}
                  >
                    {faqFile && faqFile[0] ? (
                      <iframe
                        title="FAQ"
                        src={faqFile[0].uri}
                        style={{
                          height: '100%',
                          width: '100%',
                          minHeight: '80vh',
                        }}
                      />
                    ) : (
                      <div className="alert text-center alert-info">
                        No results found
                      </div>
                    )}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
          {/* pastClaims Modal */}
          {pastClaimsArr && pastClaimsArr.activePolicyClaims ? (
            <PastClaims
              isOpen={pastClaimModal}
              dataSource={pastClaimsArr && pastClaimsArr}
              onClosePastClaimModal={() => setPastClaimModal(false)}
              selectedDate={selectedDate}
            />
          ) : (
            ''
          )}
          {/* doctor verify modal */}
          <DoctorVerifyModal
            doctorRegModal={doctorRegModal}
            closeDoctorModal={closeDoctorModal}
            OnRegistrationNumber={OnRegistrationNumber}
            doctorNameSuggestions={doctorNameSuggestions}
            OnChangeDoctorName={OnChangeDoctorName}
            onDoctorNameSearch={onDoctorNameSearch}
            verifyDoctorReg={verifyDoctorReg}
            reimbursementDetail={reimbursementDetail}
          ></DoctorVerifyModal>
          {/* doctor table modal */}
          <DoctorVerifyTableModal
            doctorTableModal={doctorTableModal}
            closeDoctorTableModal={closeDoctorTableModal}
            registrationDoctorArr={registrationDoctorArr}
            selectedDoctor={selectedDoctor}
            setSelectedDoctor={setSelectedDoctor}
            updateDoctorRegistrationInfo={updateDoctorRegistrationInfo}
            verifyAgain={verifyAgain}
          ></DoctorVerifyTableModal>
          <ReimbursementPrePost
            isOpen={prePostModal}
            prepostData={reimbursementPrePostRequestsData}
            onClose={() => setPrePostModal(false)}
          ></ReimbursementPrePost>
          {/* <PolicyRemarksModal
                        isOpen={policyModal}
                        policyRemarks={policyRemarks && policyRemarks}
                        onClose={() => setPolicyModal(false)}
                        onPolicyRemarkSelected={onPolicyRemarkSelected}
                    /> */}
          <ConfirmModal
            modalHeader={confirmModal.header}
            onClose={confirmModal.onClose}
            isOpen={confirmModal.isOpen}
            onSubmit={confirmModal.onSubmit}
            loading={confirmModal.buttonLoading}
          >
            {confirmModal.body}
          </ConfirmModal>
          <RemarksConfirmModal
            onClose={() => setQcRemarkModal(false)}
            isOpen={qcRemarkModal}
            onSubmit={updateClaim}
            opsUserId={OpsUserDetail && OpsUserDetail.userId}
            reimbursementDetails={reimbursementDetail}
          />
          <CopyClaims
            isOpen={copyClaimOpen}
            copyClaimData={copyClaimList && copyClaimList}
            onCloseCopyClaimsModal={() => setCopyClaimOpen(false)}
          ></CopyClaims>
          {/* documents deficient modal */}
          <Modal isOpen={ddModal}>
            <ModalHeader
              className="text-white"
              style={{ backgroundColor: '#714FFF' }}
              toggle={() => setDdModal(false)}
            >
              <h2 className="text-white">
                Documents deficient {reimbursementId}
              </h2>
            </ModalHeader>
            <ModalBody>
              <div className="container">
                <div className="col-12">
                  <FormGroup check>
                    <Label for="DocumentsDeficient" className="mr-2">
                      Remark:
                    </Label>
                    {/* <input
                  type="text"
                  name="DocumentsDeficient"
                  id="DocumentsDeficient"
                  onChange={this.onHandleRemarkChanged}
                /> */}
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: '#F3F0FF',
                        },
                      })}
                      styles={{ backgroundColor: 'green' }}
                      isMulti
                      placeholder="Select Remark"
                      onChange={onHandleRemarkChanged}
                      options={deficiencyRemarksArray}
                    />
                  </FormGroup>

                  {ddEdgeCaseRemark ? (
                    <FormGroup check>
                      <Label for="DocumentsDeficient">
                        Select documents deficient:
                      </Label>
                      <Select
                        styles={{ color: 'red' }}
                        isMulti
                        options={documentsDeficientOption}
                        onChange={onHandleDocumentsDeficientChanged}
                      />
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  <FormGroup check>
                    <Label for="DocumentsDeficient" className="mr-2">
                      Comment :
                    </Label>
                    <textarea
                      style={{
                        backgroundColor: '#F3F0FF',
                        border: '1px solid #714FFF',
                        borderRadius: '6px',
                      }}
                      onChange={(e) => OnChangeDeficiencyComment(e)}
                      placeholder="Post a comment"
                      className="form-control mt-2"
                    />
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn bg-success text-white float-right"
                onClick={() => documentsDeficient(reimbursementId)}
              >
                Confirm
              </button>
              <button className="btn bg-danger text-white float-right">
                Cancel
              </button>
            </ModalFooter>
          </Modal>
          {/* lab Modal */}
          <Modal
            isOpen={isLabModal}
            toggle={() => setIsLabModal(false)}
            style={{ maxWidth: '1200px', width: '100%', borderRadius: '6px' }}
          >
            <ModalHeader toggle={() => setIsLabModal(false)}>
              <div className="d-flex w-100 align-items-center justify-between">
                <h3
                  style={{ fontSize: '18px', fontWeight: 'bolder', margin: 0 }}
                  className="m-0"
                >
                  Add Lab Tests
                </h3>
                <button
                  style={{
                    backgroundColor: '#714FFF',
                    color: '#fff',
                    width: '80px',
                  }}
                  className="btn btn-primary ml-auto" // ml-auto pushes the button to the right
                  onClick={addLabTest}
                >
                  Save
                </button>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="d-flex">
                <div style={{ width: '70%', padding: '10px' }}>
                  <h3 className="mt-2" style={{ fontWeight: 'bolder' }}>
                    Select the prescription and add lab tests
                  </h3>
                  <div className="flex flex-row m-2">
                    <div className="flex flex-row m-2">
                      {multipleCodi.map((item, index) => (
                        <div className="flex flex-row" key={index}>
                          <Button
                            className="ml-2 flex flex-row"
                            style={{
                              background:
                                selectedCodiId === item.codiId ? '#714FFF' : '',
                              color:
                                selectedCodiId === item.codiId ? '#fff' : '',
                            }}
                            onClick={() =>
                              handleButtonClick(item.codiId, item.label)
                            }
                          >
                            {`Prescription_${index + 1}`}
                            <ViewButton
                              style={{
                                color:
                                  selectedCodiId === item.codiId
                                    ? '#fff'
                                    : '#714FFF',
                              }}
                              className="ml-2"
                              onClick={(e) => handleEyeClick(e, item.mergedUrl)}
                            />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Add additional content here */}
                  {selectedCodiId ? (
                    <AsyncSelect
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#d5d5d5',
                          primary25: '#f5f5f5',
                          primary: '#4F34D2',
                        },
                      })}
                      placeholder="Search Lab Test"
                      cacheOptions
                      isClearable={true}
                      //defaultOptions={}
                      loadOptions={promiseLabOptions}
                      onChange={(option, cb) => onLabSelect(option, cb)}
                    />
                  ) : (
                    <></>
                  )}
                </div>

                <div
                  style={{
                    width: '1px',
                    backgroundColor: '#ddd',
                    height: '100%',
                    margin: '0 10px',
                  }}
                ></div>

                <div style={{ width: '30%', padding: '10px' }}>
                  <LabList />
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={isMedicineModal}
            style={{ maxWidth: '1200px', width: '100%', borderRadius: '6px' }}
            toggle={() => closeMedicineModal()}
          >
            <ModalHeader toggle={() => closeMedicineModal()}>
              <div className="d-flex w-100 align-items-center justify-between">
                <h3
                  style={{ fontSize: '18px', fontWeight: 'bolder' }}
                  className="m-0"
                >
                  {' '}
                  Add Medicine
                </h3>
                <button
                  style={{
                    backgroundColor: '#714FFF',
                    color: '#fff',
                    width: '80px',
                  }}
                  className="btn btn"
                  onClick={() => AddMedsItems()}
                >
                  Save
                </button>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="d-flex justify-content-between align-items-center"></div>

              <div className="d-flex">
                <div style={{ width: '70%', padding: '10px' }}>
                  <h3 className="mt-2" style={{ fontWeight: 'bolder' }}>
                    Select the prescription and add medicines
                  </h3>
                  {/* Add additional content here */}

                  <div className="flex flex-row m-2">
                    <div className="flex flex-row m-2">
                      {multipleCodi.map((item, index) => (
                        <div className="flex flex-row" key={index}>
                          <Button
                            className="flex flex-row m-2"
                            style={{
                              background:
                                selectedCodiId === item.codiId ? '#714FFF' : '',
                              color:
                                selectedCodiId === item.codiId ? '#fff' : '',
                            }}
                            onClick={() =>
                              handleButtonClick(item.codiId, item.label)
                            }
                          >
                            {`Prescription_${index + 1}`}
                            <ViewButton
                              style={{
                                color:
                                  selectedCodiId === item.codiId
                                    ? '#fff'
                                    : '#714FFF', // Change eye icon color based on selection
                              }}
                              className="ml-2"
                              onClick={(e) => handleEyeClick(e, item.mergedUrl)}
                            />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                  {selectedCodiId &&
                    (manualAddMedsCheck ? (
                      <InputMed
                        placeholder=" Enter Medicine Name"
                        onChange={(e) => medAddManual(e)}
                      />
                    ) : (
                      <AsyncSelect
                        styles={customStyles}
                        theme={(theme) => ({
                          border: '1px solid #714fff',
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary50: '#d5d5d5',
                            primary25: '#f5f5f5',
                            primary: '#4F34D2',
                          },
                        })}
                        placeholder=" Search Medicine"
                        value={selectedMedicineNameValue}
                        cacheOptions
                        isClearable={true}
                        loadOptions={promiseMedicinesOptions}
                        onChange={(option, cb) => MedicineSelection(option, cb)}
                      />
                    ))}
                  {dosageView || manualAddMedsCheck ? (
                    <div
                      style={{
                        padding: '15px 20px',
                        marginBottom: '25px',
                        backgroundColor: '#f9f9f9',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        fontSize: '16px',
                        color: '#333',
                        marginTop: '5px',
                      }}
                    >
                      <h2 className="mb-2">{selectedMedicineName}</h2>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-col mr-6">
                          <span className="mb-1">Time of Day</span>
                          <Select
                            options={[
                              { value: 'Before Food', label: 'Before Food' },
                              { value: 'After Food', label: 'After Food' },
                            ]}
                            onChange={(option) => setTimeOfDay(option.value)}
                          />
                        </div>
                        <div className="flex flex-col ml-3">
                          <span className="mb-1 flex flex-row">
                            Dosage
                            {/* <span style={{ color: 'red', marginLeft: '3px' }}>
                              *
                            </span> */}
                          </span>
                          <Select
                            options={dosageOptions}
                            onChange={(option) => setDosageValue(option.value)}
                          />
                        </div>
                        <div className="flex flex-col ml-3 ">
                          <span className=" flex flex-row">
                            Duration
                            {/* <span style={{ color: 'red', marginLeft: '3px' }}>
                              *
                            </span> */}
                          </span>
                          <Input
                            className="mt-1"
                            type="number"
                            style={{ height: '36px' }}
                            onChange={(e) => setDuration(e.target.value)}
                          ></Input>
                        </div>
                        <div className="flex flex-col mt-1 ml-1">
                          <span className="mb-1"></span>
                          <Select
                            className="mt-4"
                            options={[
                              {
                                value: 'Days',
                                label: 'Days',
                              },
                              { value: 'Weeks', label: 'Weeks' },
                              { value: 'Months', label: 'Months' },
                            ]}
                            onChange={(option) =>
                              setDurationPeriod(option.value)
                            }
                          />
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <button
                          style={{
                            backgroundColor: '#714FFF',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '4px',
                            padding: '8px 16px',
                            cursor: 'pointer',
                            //opacity:
                            // !timeOfDay ||
                            // !dosageValue || !duration || !durationPeriod
                            //   ? 0.5
                            //   : 1, // Set opacity for disabled state
                          }}
                          // disabled={
                          // !timeOfDay ||
                          // !dosageValue || !duration || !durationPeriod
                          // }
                          onClick={() => OnMedicineAdd()}
                        >
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  style={{
                    width: '1px',
                    backgroundColor: '#ddd',
                    height: '100%',
                    margin: '0 10px',
                  }}
                ></div>

                <div style={{ width: '30%', padding: '10px' }}>
                  <MedicineList />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {selectedCodiId &&
                (manualAddMedsCheck ? (
                  <button
                    style={{
                      marginLeft: '5px',
                      padding: '8px 14px 8px 12px',
                      fontWeight: 'bold',
                      color: '#714FFF',
                      borderRadius: '12px',
                      border: '1px solid #714FFF',
                      width: '15rem',
                    }}
                    onClick={() => {
                      medicineModalClearState();
                    }}
                  >
                    Search Medicine
                  </button>
                ) : (
                  <>
                    <p style={{ color: '#714FFF' }}>
                      Cannot find neither original medicine nor substitute
                      medicine?
                    </p>
                    <button
                      style={{
                        marginLeft: '5px',
                        padding: '8px 14px 8px 12px',
                        fontWeight: 'bold',
                        color: '#714FFF',
                        borderRadius: '12px',
                        border: '1px solid #714FFF',
                        width: '15rem',
                      }}
                      onClick={() => {
                        addManualModal();
                      }}
                    >
                      Add Manually
                    </button>
                  </>
                ))}
            </ModalFooter>
          </Modal>
          <ToastContainer />
        </div>
      ) : (
        <Space
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Spin tip="Loading..." size="large">
            {/* <Alert
                        message="Alert message title"
                        description="Further details about the context of this alert."
                        type="info"
                    /> */}
          </Spin>
        </Space>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  console.log(state, '[state]');
  const userName = state.app.opsUser?.name;
  const searchString = state.app.searchString;
  const brokerId = state.app.opsUser?.brokerId;
  return { userName, searchString, brokerId };
};
export default connect(mapStateToProps)(memo(ReimburseClaimRequest));
