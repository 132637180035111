import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';
import moment from 'moment';
import { Table } from 'antd';


const tableColumn = [
    {
        title: 'RID',
        dataIndex: 'RID',
        render: (value, row, index) => {
            return (
                <a
                    href={`/reimburse-claim/${value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#1890ff' }}
                >
                    {value}
                </a>
            );
        },
    },
    {
        title: 'RI type',
        dataIndex: 'RIType',
        render: (value, row, index) => {
            return <p>{value}</p>;
        },
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (value, row, index) => {
            return <p>{value}</p>;
        },
    },

    {
        title: 'RIDType',
        dataIndex: 'RIDType',
        render: (value, row, index) => {
            return <p>{value}</p>;
        },
    },
]


export default function CopyClaims({ isOpen, copyClaimData, onCloseCopyClaimsModal }) {
    const tableData = copyClaimData;
    return (
        <Modal
            isOpen={isOpen}
            toggle={onCloseCopyClaimsModal}

        >
            <ModalHeader
                toggle={onCloseCopyClaimsModal}

            >
                <h1 style={{ fontSize: '25px', fontWeight: 600 }}>
                    Copy claims
                </h1>
            </ModalHeader>
            <ModalBody>
                <div>
                    <Table
                        columns={tableColumn}
                        dataSource={tableData}
                        pagination={true}
                    />
                </div>
            </ModalBody>

        </Modal>
    )

}