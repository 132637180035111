import { useMutation } from 'react-query';
import { opsUserLogin } from '../services/login';

const useOpsLogin = (onSuccess, onError) => {
  return useMutation(
    (body) => {
      return opsUserLogin(body);
    },
    { onSuccess, onError }
  );
};
export { useOpsLogin };
