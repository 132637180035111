import styled, { keyframes } from 'styled-components';

export const MainDigitisationContainer = styled.main`
  display: flex;
  flex-direction: column;
  max-height: 97.5%;
  transition: all 0.25s ease-in;
`;

export const MainDigitisationHeader = styled.header`
  background: #ece9ff;
  background: linear-gradient(135deg, #ece9ff 0%, #d1cfff 100%);
  padding: 20px;
  border-radius: 10px 10px 0 0;
  padding-bottom: 12.5px;
  position: relative;
`;

export const NameHeader = styled.h1`
  font-size: 17.5px;
  color: #3a2ca0;
  font-weight: 700;
`;

export const SimpleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SimpleColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const CallButton = styled.button`
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 5px 17.5px;
  font-size: 12.5px;
  border-radius: 7.5px;
  color: #714fff;
  height: 32.5px;
  font-weight: 500;

  border: 1px solid #e4e4e4;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 11px;
  font-weight: 500;
`;

export const InfoBreaker = styled.div`
  width: 2px;
  height: 12.5px;
  background: #9393a54d;
`;

export const StepText = styled.div`
  color: #714fff;
  font-weight: 700;
  font-size: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CommonHeading = styled.h2`
  color: #000000;
  font-weight: 700;
  font-size: 15px;
`;

export const CommonHelpText = styled.h3`
  font-size: 12.5px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const SelectionGrid = styled.div`
  margin-bottom: 7.5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
`;

export const SelectionItem = styled.div`
  background: #cfc8ff66;
  color: #714fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  font-size: 12.5px;
  border-radius: 5px;
  font-weight: 600;
`;

export const NetworkCenterOption = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChipButton = styled.button`
  height: 32.5px;
  width: 32.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-radius: 5px;
`;

export const NewDigitisationButton = styled.button`
  width: 100%;
  cursor: ${({ disabled, loading }) =>
    disabled || loading ? 'not-allowed' : 'pointer'};
  border: 1.5px solid ${({ disabled }) => (disabled ? '#ddd' : '#714FFF')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 13px;
  background-color: ${({ primary, disabled }) =>
    primary ? (disabled ? '#ddd' : '#714FFF') : 'transparent'};
  color: ${({ primary, disabled }) =>
    primary ? (disabled ? '#aaa' : '#fff') : disabled ? '#aaa' : '#714FFF'};
  font-weight: ${({ primary }) => (primary ? 700 : 600)};
  border-radius: 7px;
`;

export const BreadcrumbChip = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  height: 75%;
  border-radius: 7.5px;
  padding: 0 10px;
  font-weight: 600;
  border: ${({ primary, myColor }) =>
    primary
      ? `1.5px solid ${myColor || '#32AD54'}`
      : `1.5px solid ${myColor || '#3F8CFF'}`};
  color: ${({ primary, myColor }) =>
    primary ? `${myColor || '#32AD54'}` : `${myColor || '#3F8CFF'}`};
`;

export const AltOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 45vh;
  background: #fff;
  border-radius: 7.5px;
  overflow-y: scroll;
  margin: 5px 0;

  border: 1px solid #e4e4e4;
  padding: 7.5px;
  row-gap: 7.5px;
  height: fit-content;
`;

export const AltOptionHeading = styled.h3`
  font-size: 15px;
  color: #333;
  font-weight: 600;
  margin-top: 20px;
`;

export const AltOption = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  cursor: pointer;
  border-radius: 7.5px;
  transition: all 0.25s ease-in;

  &:hover {
    background-color: #eee;
  }
`;

export const FinishImage = styled.img`
  width: 100%;
  transform: translate(-50%, -40%);
  position: absolute;
  top: 0;
  left: 50%;
`;

const spinAnimation = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 3px solid ${({ primary }) => (primary ? '#f3f3f3' : '#4F34D2')};
  border-top: 3px solid ${({ primary }) => (primary ? '#4F34D2' : '#f3f3f3')};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spinAnimation} 1s linear infinite;
`;

export const CommonHyperlink = styled.div`
  color: ${({ disabled }) => (disabled ? '#bbb' : '#714FFF')};
  cursor: pointer;
  font-size: 10px;
  font-weight: 700;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  display: inline-block;
  text-decoration: none;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    color: ${({ disabled }) => (disabled ? '#bbb' : '#3A2CA0')};
  }

  &:active {
    color: ${({ disabled }) => (disabled ? '#bbb' : '#3A2CA0')};
  }
`;
