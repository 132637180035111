import React from 'react';
import MultipleSelectionSearchDropdown from './widgets/MultipleSelectionSearchDropdown';
import { connect } from 'react-redux';
import { useCallback } from 'react';
import { getDiseases } from '../../services/reimbursementClaims';

const DiseaseSelectionInput = ({
  selectedDiseases = [],
  onSelectDisease = () => {},
  helpText = '',
  required = false,
  placeholderText = '',
  disabled = false,
  isAdditionDisabled = false,
}) => {
  const getDiseasesFromServer = useCallback((query) => {
    return getDiseases(query).then((result) => {
      return result.map((item) => ({
        ...item,
        label: `${item.name} - ${item.code}`,
        value: item.code,
      }));
    });
  }, []);

  const selectDisease = useCallback(
    (disease, action) => {
      if (action === 'add') {
        onSelectDisease((prevDiseases) => {
          const prevIndex = prevDiseases.findIndex(
            (item) => item.value === disease.value
          );
          if (prevIndex !== -1) {
            return prevDiseases;
          }
          return [...prevDiseases, disease];
        });
      } else {
        onSelectDisease((prevDiseases) => {
          const prevIndex = prevDiseases.findIndex(
            (item) => item.value === disease.value
          );
          if (prevIndex !== -1) {
            const newDiseases = prevDiseases.slice();
            newDiseases.splice(prevIndex, 1);
            return newDiseases;
          }
          return prevDiseases;
        });
      }
    },
    [onSelectDisease]
  );

  return (
    <MultipleSelectionSearchDropdown
      helpText={helpText}
      placeholderText={placeholderText}
      required={required}
      apiCallback={getDiseasesFromServer}
      onAdd={(disease) => selectDisease(disease, 'add')}
      onRemove={(disease) => selectDisease(disease, 'remove')}
      selections={selectedDiseases}
      disabled={disabled}
      isAdditionDisabled={isAdditionDisabled}
    />
  );
};

export default DiseaseSelectionInput;
